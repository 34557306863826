.directions {
	@include d-flex;
	@include flex-wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.directions__item {
	@include d-flex;
	margin: 0 0 40px;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;

	@media (min-width:$tablet-width-l) {
		@include flex-width(100%/2); 
	}

	@media (max-width:$tablet-width-l - 1) {
		@include flex-width(100%); 
	}
}

.directions__icon {
	@include size(40px,40px);
	margin: 0 24px 0 0;
	color: #283584;
	background-position: 0 0;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	&.directions__icon_government {
		background-image: url(../img/icons/icon-government.svg);
	}

	&.directions__icon_keys {
		background-image: url(../img/icons/icon-keys.svg);
	}

	&.directions__icon_economy {
		background-image: url(../img/icons/icon-economy.svg);
	}

	&.directions__icon_comfort {
		background-image: url(../img/icons/icon-comfort.svg);
	}

	&.directions__icon_roadmap {
		background-image: url(../img/icons/icon-roadmap.svg);
	}
}

.directions__title {
	@include flex-width(calc(100% - 64px)); 
	@include fs-lh(20px,24px);
	font-weight: bold;
	color: #283584;

	&.directions__title_dark {
		cursor: default;
		color: rgba(0,0,0,.6);
	}

	&.directions__title_thin {
		@include fs-lh(14px,18px);
		color: #21A3DC;
		font-weight: 300;
	}

	&:hover {
		color: #21A3DC;
	}
}