.pagination {
	margin: 0 0 40px;
	text-align: right;

	&.pagination_colored {
		@media (max-width:$desktop-width-s - 1) {
			text-align: center;

			.pagination__item {
				@include fs-lh(16px,20px);
				@include offset(0 8px 8px 0,2px 6px);
				font-weight: 600;
				border-radius: 2px;
				background: #53595D;
				color: rgba(255, 255, 255, 0.3);
				
			}

			.pagination__current {
				@include fs-lh(16px,20px);
				@include offset(0 8px 8px 0,2px 6px);
				font-weight: 600;
				border-radius: 2px;
				background: #21A3DC;
				color: $color__theme_white;
			}

			.pagination__prev {
				margin: 0 8px 8px 0;
				background-image: url(../img/icons/icon-arrow-long-white.svg);
			}

			.pagination__next {
				margin: 0 8px 8px 0;
				background-image: url(../img/icons/icon-arrow-long-white.svg);
			}
		}
	}
}
.pagination__news {
	margin-bottom: 0;
	@media (max-width:$tablet-width-m - 1) {
		margin-bottom: 20px;
	}
}
.pagination__inner {
	@include inline-block(middle);
	
	& + .pagination__next {
		margin-left: 8px;
	}
}

.pagination__item {
	@include fs-lh(20px,24px);
	@include inline-block(middle);
	color: rgba(0, 0, 0, 0.5);
	// margin: 0 25px 0 0;
	width: 31px;
    height: 28px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    text-align: center;
    padding-top: 3px;
	&:hover {
		color: #21A3DC;
	}

	&:last-child {
		margin-right: 0;
	}
}

.pagination__current {
	@include fs-lh(20px,24px);
	@include inline-block(middle);
	// margin: 0 25px 0 0;
	color: $color__theme_black;
	width: 31px;
    height: 28px;
	background: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    text-align: center;
    padding-top: 3px;
}

.pagination__prev {
	@include size(40px,10px);
	@include inline-block(middle);
	//margin: 0 18px 0 0;
	margin-right: 8px;
	background: url(../img/icons/icon-arrow-long-prev.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
	background-size: 100% auto;
	
	//-o-transform: rotateY(180deg);
	//-moz-transform: rotateY(180deg);
	//-webkit-transform: rotateY(180deg);
	//transform: rotateY(180deg);
	//-webkit-backface-visibility: hidden;
	//backface-visibility: hidden;
	&:hover {
		background: url(../img/icons/icon-arrow-long-red-prev.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
		//-o-transform: rotateY(180deg);
	//-moz-transform: rotateY(180deg);
	//-webkit-transform: rotateY(180deg);
	//transform: rotateY(180deg);
	}
}

.pagination__next {
	@include size(40px,10px);
	@include inline-block(middle);
	//margin: 0 18px 0 0;
	background: url(../img/icons/icon-arrow-long.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
	background-size: 100% auto;
	&:hover {
		background: url(../img/icons/icon-arrow-long-red.svg) no-repeat scroll 0 0 rgba(0,0,0,0) ;
	}
}

// .pagination-media {
// 	

// 	
// 	&:hover {
// 		background: url(../img/icons/icon-arrow-long-red.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
// 	}
// }
// }

.pagination__media {
	margin: 0px 6px 40px 0;

	@media (min-width: $desktop-width-l) {
		margin: 79px 0 83px;
	}
	
	@media (min-width:$mobile-width) and (max-width:$desktop-width-s - 1) {
		margin: 0px 0 40px;}
	@media (min-width:$mobile-width) and (max-width:$tablet-width-s - 1) {
		text-align: center;	
	}
	.pagination__item {
		margin-right: 4px;
	}
	.pagination__item:nth-child(1) {
		margin-left: 4px;
	}
	.pagination__current {
		margin-right: 4px;
		background: rgba(255, 255, 255, 0.8);
	}
	.pagination__prev,
	.pagination__next {
		margin: 0;
	}
	.pagination__prev,
	.pagination__next{
		margin-right: 4px;
	}
}