.phase {
    padding-left: 10%;
    @media (max-width:$desktop-width-m - 1) {
      padding-left: 80px;
    }
    @media (max-width:$desktop-width-s - 1) {
      padding-left: 0;
    }
    .title__h1 {
		  text-align: left;
      margin: 0 0 60px 0;
      @media (max-width:$desktop-width-s - 1) {
        margin: 0 0 50px 0;
      }
      @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(24px, 29px);
        font-weight: 700;
        margin: 0 0 32px 0;
      }
	  }
  @media (min-width:$desktop-width-xl)  {
    .features__col:before {
      width: 2px;
      height: 135px;
      left: 16%!important;
      bottom: -70px;
    }
    .title__h1 {
      margin: 0 0 100px;
    }
    .features_xl {
      margin: 0 0 280px!important;
      .features__bottom {
        bottom: -130px;
      }
      .features__col .features__icon {
        bottom: -130px;
      }
      .features__col:before {
        bottom: -130px;
      }
    }
  }
  @media (min-width:$desktop-width-s) {
  .features {
    padding-bottom: 20px;
  }
  .features__text {
    @include fs-lh(24px, 29px);
    font-weight: 700;
  }
  .features__desc {
    padding: 12px 0 0 72px;
    display: block;

    transform: matrix(1,.05,0,1,0,0);
    transform-origin: left bottom;

    @include fs-lh(20px, 24px);
    font-weight: 600;
  }
  .features__title_lg {
    top: 5px;
  }
  .features__col .features__icon {
    bottom: -60px;
  }

    .features__title_first {
      left: 12px;
    }
    .features__bottom.features__bottom_lg {
      left: -25.5%;
      right: -3%;
      @media (min-width:$desktop-width-m){
        right: -13.5%;
      }
    }
    .features__bottom.features__bottom_lg {
      margin: 0;
    }
    .features_rotate {
      margin: 200px 0 150px;
      .features__bottom:before {
        content: '';
        border-width: 0 0 4px;
        border-style: solid;
        border-color: #fff;
        position: absolute;
        top: -150px;
        left: 0;
        right: 0;
      }
      .features__col .features__icon {
        bottom: 80px;
      }
      .features__item  {
        position: relative;
        top: 230px;
      }
    }
  }

  .features_rotate {
    margin: 200px 0 150px;

    @media (max-width:$desktop-width-l - 1) {
      margin: 100px 0 150px;

      .features__item  {
        position: relative;
        top: 280px;
      }
    }

    @media (max-width:$desktop-width-m - 1) {
      .features__item  {
        position: relative;
        top: 220px;
      }
    }
    @media (max-width:$desktop-width-s - 1) {
      .features__item  {
        position: relative;
        top: 0px;
      }
    }
  }
  .features {
    @media (max-width:$desktop-width-s - 1) {
      margin-left: 30px!important;
    }
    @media (max-width:$tablet-width-m - 1) {
      margin-left: 0px!important;
    }
    @media (max-width:$tablet-width-l - 1) {
      justify-content: space-between;
    }
  }
  .features__col:before {
    width: 2px;
    height: 135px;
    z-index: 2;
    margin: 0 0 0 -1px;
    background: #fff;
    @media (min-width:$desktop-width-l) {
      left: 20.5%;
      bottom: -70px;
    }
      @media (max-width:$desktop-width-l - 1) {
        left: 22%;
        bottom: -60px;
      }

      @media (max-width:$desktop-width-m - 1 ) {
        left: 18.5%;
        bottom: -60px;
      }

      @media (max-width:1265px ) {
        left: 20.2%;
        bottom: -60px;
      }

      @media (max-width:1230px ) {
        left: 19.5%;
        bottom: -60px;
      }

      @media (max-width:$desktop-width-s - 1) {
        display: none;
      }
    }
  
    .features__desc {
  
      @media (max-width:$desktop-width-s - 1 ) {
        padding: 12px 0 0 70px;
        display: block;
    
        @include fs-lh(20px, 24px);
        font-weight: 600;
      }
      @media (max-width:$tablet-width-m - 1) {
        padding: 12px 0 0 0;
      }
    }

  .features__icon:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: #fb0015d0;
  }

  .features__col_first {
    margin-left: 110px;
    @media (max-width:$desktop-width-s - 1) {
      margin: 0;
    }
  }

  .features__col_second {
    margin-left: 80px;
    @media (max-width:$desktop-width-s - 1 ) {
      margin: 0;
    }
  }

  .features.features_mb {
    margin: 0 0 150px;
    @media (min-width:$desktop-width-l) {
     
    }
    @media (max-width:$desktop-width-l - 1) {
      margin: 50px 0 ;
    }
    @media (max-width:$desktop-width-m - 1 ) {
      margin: 100px 0 ;
    }
    @media (max-width:$desktop-width-s - 1) {
     margin: 0;
    }
  }

  .features__col.features__col_sm {
    @media (max-width:$tablet-width-l - 1) {
      @include flex-width(100%/8*3.5);
    }
    @media (max-width:$tablet-width-m - 1) {
      @include flex-width(100%/8*8);
    }
  }

  .features__text {
    @media (max-width:$tablet-width-m - 1) {
      @include fs-lh(24px, 29px);
      font-weight: 700;
    }
  }
  @media (min-width: $tablet-width-m) and (max-width: $desktop-width-s - 1 ){
    .features__item.features__item_nobg {
        margin: 0 0 40px;
    }
  }
}
