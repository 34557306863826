.form {
}

.form__title {
	@include before;
	@include fs-lh(18px,22px);
	margin: 0 0 16px;
	font-weight: 500;
	display: block;
	cursor: pointer;
	box-sizing: border-box;

	&:before {
		@include size(20px,20px);
		box-sizing: border-box;
		border: 1px solid rgba(83, 89, 93, 0.5);
	}

	&:after {
		@include size(10px,10px);
		content: '';
		display: none;
	}
}

.form__subtitle {
	@include fs-lh(12px,15px);
	color: rgba(0, 0, 0, 0.5);
	margin: 4px 0;
	display: block;
}

.form__checkbox {
	& + .form__title {
		padding: 0 0 0 36px;

		&:before {
			@include pos(-2px,0);
			border-radius: 2px;
		}

		&:after {
			@include pos(3px,5px);
			background: url(../img/icons/icon-checked.svg) no-repeat scroll center center rgba(0,0,0,0);
			background-size: 100% auto;
		}
	}

	&:checked {
		& + .form__title {
			&:before {
				background: #21A3DC;
			}

			&:after {
				display: block;
			}
		}
	}
}

.form__radio {
	& + .form__title {
		@include inline-block;
		margin-right: 16px;
		border-radius: 8px;
		padding: 12px 20px 12px 56px;

		&:hover {
			box-shadow: 0px 2px 8px #21A3DC;
		}

		&:before {
			@include pos(12px,20px);
			border-radius: 50%;
		}

		&:after {
			@include pos(17px,25px);
			border-radius: 50%;
			background: #21A3DC;
		}
	}

	&:checked {
		& + .form__title {
			&:before {
				border: 2px solid #283584;
			}

			&:after {
				display: block;
			}
		}
	}
}