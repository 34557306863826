#hellopreloader>p {
    display: none;
}

#hellopreloader_preload {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    padding: 16px;
    z-index: 1080;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.preloader__icon {
    background: url(../img/icons/icon-logo.svg) center center no-repeat;
    position: relative;
    height: 180px;
    width: 200px;
    text-align: center;
    z-index: 9999;
    animation: preloader-opacity 2.5s ease-in;
    @media (max-width: $tablet-width-l - 1 ) {
        left: -21px;
    }
    &:before  {
        opacity: 0;
        content: '';
        position: absolute;
        display: inline-block;
        width: 250px;
        height: 180px;
        background: #000;
        z-index: 9999;
        top: -44px;
        left: -13%;
        transform: rotate(144deg) translateY(0px);
        animation: move-up 2.5s ease-out;
        
    }
    @keyframes move-up {
        0% {
            opacity: 1;
            transform: rotate(144deg) translateY(0);
          
        }
        100% {
            opacity: .5;
            transform: rotate(144deg) translateY(100px);
        }
    }
    &:after {
        opacity: 0;
        content: '';
        position: absolute;
        display: inline-block;
        width: 250px;
        height: 180px;
        background: #000;
        z-index: 9999;
        top: -18%;
        left: -8%;
        transform: rotate(324deg) translateY(0px);
        animation: move-down 2.5s ease-out;
       
    }
    @keyframes move-down {
        0% {
            opacity: 1;
            transform:rotate(324deg) translateY(0);
        }
        100% {
            opacity: .5;
            transform:rotate(324deg) translateY(177px);
        }
    }
}



// @keyframes preloader-hidden {
//     0% {
//         opacity: 1;
      
//     }
//     25% {
//         opacity: .75;
//        }
//     50% {
//      opacity: .5;
//     }
//     75% {
//         opacity: .25;
        
//        }
//     100% {
//         opacity: 0;
//       }
//   }


@keyframes preloader-opacity {
    0% {
      opacity: 0;
    }
    25% {
        opacity: .25;
       }
    50% {
     opacity: .5;
    }
    75% {
        opacity: .75;
       }
    100% {
        opacity: 1;
      }
  }
