.pictures {
	@include d-flex;
	@include flex-wrap;

	@media (min-width:$desktop-width-m) {
		margin-left: -(100%/6*2);
		margin-right: -(100%/6*2);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-left: -(100%/6);
		margin-right: -40px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		left: -10px;
		margin-left: -(100%/3);
		margin-right: -60px;
		position: relative;
	}

	@media (min-width:$tablet-width-m) {
		margin-top: -30px;
	}
}

.pictures__col {
	position: relative;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);
	background-position: top center;

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		margin-bottom: 32px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-bottom: 8px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
		text-align: center;
		background-image: none !important;
	}

	&.pictures__col_sm {
		@media (min-width:$tablet-width-l) {
			@include flex-width(100%/4);
		}
		
		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			@include flex-width(100%/2);
		}
	}

	&.pictures__col_md {
		@media (min-width:$tablet-width-l) {
			@include flex-width(100%/3);
		}
		
		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			@include flex-width(100%/2);
			
			&:last-child {
				@include flex-width(100%);
			}
		}
	}

	&.pictures__col_lg {
		@include flex-width(100%);
	}
	
	.img-fluid {
		@media (min-width:$tablet-width-m) {
			visibility: hidden;
		}
	}
}

.pictures__inner {
	color: $color__theme_white;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.5);

	@media (min-width:$tablet-width-m) {
		min-height: 165px;
		padding: 16px 18px 20px;
	}

	@media (max-width:$tablet-width-m - 1) {
		min-height: 105px;
		padding: 8px 12px 16px;
		text-align: left;
	}
}

.pictures__title {
	display: block;

	@media (min-width:$tablet-width-m) {
		@include fs-fw(20px,bold);
		margin: 0 0 18px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(16px,20px);
		font-weight: 600;
		margin: 0 0 9px;
	}
}

.pictures__text {
	@include fs-lh(14px,17px);
	display: block;
	letter-spacing: 0.02em;
}