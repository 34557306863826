.megamenu__header {
    position: relative;
    width: 25px;
    height: 12px;
    display: none;
        @media (max-width: $desktop-width-m - 1  ) {
            display: block;
            left: 0;
            opacity: 1;
            visibility: visible;
        }
        @media (max-width: $mobile-width + 21  ) {
            top: -2px;
        }
    }
    .megamenu__active {
        display: none;
        @media (max-width: $desktop-width-m - 1 ) {
            display: block;
            left: 0;
            top: 35px;
            opacity: 1;
            z-index: 7;
            visibility: visible;
            // position: fixed;
        }
        @media (max-width: $tablet-width-m - 1 ) {
            top: 11px;
        }
    }
    .megamenu__line::before,
    .megamenu__line::after,
    .megamenu__line {
        position: absolute;
        top: 5px;
        width: 100%;
        height: 2px;
        background: #fff;
        transition: 0.4s;
        left: 0;
    }
    .megamenu__line::before {
        content: '';
        top: -5px;
    }
    .megamenu__line::after {
        content: '';
        top: 5px;
    }
    
    .megamenu__line {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    
    .megamenu__active .megamenu__line {
        background-color: transparent;
    }
    .megamenu__active .megamenu__line::before {
        top: 0;
        transform: rotate(40deg);
    }
    .megamenu__active .megamenu__line::after{
        top: 0;
        transform: rotate(-40deg);
    }