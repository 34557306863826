.newspromo,
.newspromo__box {
	@media (min-width:$desktop-width-m) {
		margin: 0 -10px;
	}

	&.newspromo_index {

		/* height */
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
			@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
				.newspromo__slide {
					margin-bottom: 10px;
				}

				.newspromo__main {
					padding-top: 22px;
					margin-bottom: 30px;
				}

				.newspromo__inner {
					padding-right: 20px;
					padding-bottom: 14px;

					&:before {
						top: -22px;
					}
				}

				.newspromo__title {
					@include fs-lh(16px, 22px);
					margin: 0 0 14px;
			
				}

				.newspromo__text {
					margin: 0 0 12px;

					p {
						@include fs-lh(12px, 18px);
						margin: 0 0 10px;
					}
				}

				.newspromo__tag {
					@include fs-lh(12px, 14px);
					@include offset(0 4px 4px 0, 2px 6px);
				}

				.newspromo__left {
					&:before {
						height: 139px;
						right: 28px;
					}
				}

				.newspromo__picture {
					max-height: 226px;
					margin: 0 0 14px;
				}

				.newspromo__intro {
					@include fs-lh(12px, 16px);
					padding: 14px;
				}

				.newspromo__post {
					//@include offset(0 0 10px,10px 36px 10px 16px);
					margin: 0 0 15px;
				}

				.newspromo__post-title {
					@include fs-lh(14px, 18px);
					margin: 0 0 8px;
				}

				.newspromo__taglist-item {
					@include fs-lh(10px, 12px);
					margin: 0 4px 4px 0;
				}

				.newspromo__nav-item {
					@include size((100%/7*3), auto);

					.d-block {
						@include inline-block;
					}
				}
			}
		}

		@media (min-width:$laptop-width - 1) and (max-width:$desktop-width-m - 1) and (min-height:$tablet-width-m) and (max-height:$laptop-width - 1) {
			.newspromo__footer {
				margin-top: -45px;
			}
		}
	}
}

.newspromo__slide {
	@include d-flex;
	@include flex-wrap;
	box-sizing: border-box;

	@media (max-width:$desktop-width-xl - 1) {
		@include align-items-start;
	}

	@media (min-width:$desktop-width-m) {
		margin-bottom: 30px;
	}

	@media (max-width:$desktop-width-m - 1) {
		width: auto;
		margin-bottom: 32px;
		//flex-shrink: inherit;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		// margin-left: -10px;
		// margin-right: -10px;
	}

	@media (max-width:$tablet-width-m) {
		height: fit-content;
	}

}

.newspromo__box {
	overflow: hidden;
}

.newspromo__box-main {
	@media (max-width:$tablet-width-m - 1) {
		height: 560px;
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

.newspromo__main {
	@include d-flex;
	@include flex-wrap;
	box-sizing: border-box;

	@media (max-width:$desktop-width-xl - 1) {
		@include align-items-start;
	}

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/10*8);
		padding-top: 24px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-top: 32px;
		margin-bottom: 60px;
	}

	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 4px 16px, 22px 16px 16px);
		border-radius: 16px;
		border: 2px solid $color__theme_black;
		background: $color__theme_white;
		box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
	}

	&.newspromo__main_wide {
		@media (max-width:$desktop-width-xl - 1) {
			@include align-items-stretch;
		}

		@media (min-width:$desktop-width-m) {
			@include flex-width(100%);
		}

		@media (max-width:$desktop-width-m - 1) {
			margin-left: 5%;
		}

		@media (min-width:$laptop-width) and (max-width:$desktop-width-m - 1) {
			margin-bottom: 0;
		}

		@media (max-width:$laptop-width - 1) {
			margin-bottom: 32px;
		}
		@media (max-width:$tablet-width-m - 1) {
			margin-left: 0;
		}
	}
}

.newspromo__left {
	@include before;
	box-sizing: border-box;

	&:before {
		@include pos(21px, 18px);
		right: 38px;
		height: 229px;
		border-radius: 12px;
	}

	&:after {
		content: '';
		height: 75px;
		position: absolute;
		left: 18px;
		right: 18px;
		border-radius: 12px;
	}

	@media (min-width:$tablet-width-m) {
		@include flex-width(100%/8*3);
		padding: 0 10px 0 0;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		//margin-left: (100%/16);
	}

	@media (min-width:$tablet-width-m) {
		&:after {
			bottom: -24px;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		-ms-flex-order: 2;
		order: 2;

		&:after {
			bottom: 24px;
		}
	}

	&.newspromo__left_noshadow {
		padding-bottom: 25px;

		@media (max-width:$tablet-width-m - 1) {
			//margin-top: -40px;
			margin-top: 0;
			-ms-flex-order: 1;
			order: 1;

			.newspromo__meta {
				display: none;
			}
		}

		&:after {
			@media (min-width:$tablet-width-m) {
				display: none;
			}
		}
	}
}

.newspromo__picture {
	@include zi-2;
	display: block;
	overflow: hidden;
	border-radius: 12px;
	text-align: center;
	box-sizing: border-box;
	border: 2px solid $color__theme_white;
	// max-height: 226px;

	@media (max-width:$desktop-width-m - 1) {
		max-height: 226px;
	}

	@media (min-width:$tablet-width-m) {
		margin: 0 0 24px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 32px;
	}
}

.newspromo__picture-press {
	@media (min-width:$desktop-width-l - 1) {
		max-height: 226px;
	}

}

.newspromo__intro {
	@include zi-2;
	font-weight: 600;
	border-radius: 12px;
	box-sizing: border-box;
	border: 2px solid #21a3dc;
	background: $color__theme_white;

	@media (min-width:$desktop-width-l) {
		@include fs-lh(16px, 20px);
		padding: 16px 22px;
	}

	@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
		@include fs-lh(14px, 18px);
		padding: 14px 18px;
	}

	@media (max-width:$desktop-width-m - 1) {
		@include fs-lh(16px, 20px);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		padding: 16px 22px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		padding: 18px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 0 32px, 16px);
	}

	&.newspromo__intro_mb {
		margin-bottom: 36px;
	}
}

.newspromo__inner {
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		@include before;
		@include flex-width(100%/8*5);

		&:before {
			right: 0;
			z-index: -1;
			border-radius: 16px;
			box-sizing: border-box;
			border: 2px solid $color__theme_black;
			background: $color__theme_white;
			box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
		}
	}

	@media (min-width:$desktop-width-xl) {
		&:before {
			bottom: 50px;
		}
	}

	@media (max-width:$desktop-width-xl - 1) {
		&:before {
			bottom: -4px;
		}
	}

	@media (min-width:$desktop-width-m) {
		padding-left: 10px;
		padding-right: 36px;
		padding-bottom: 19px;

		&:before {
			@include pos(-24px, -(100%/5*2));
		}
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin-left: -(100%/16);
		padding-left: (2*100%/8/3);
		padding-bottom: 16px;

		&:before {
			@include pos(-32px, -(100%/5*2));
		}
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		padding-right: 30px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		padding-right: 24px;
	}

	@media (max-width:$tablet-width-m - 1) {
		-ms-flex-order: 1;
		order: 1;
	}

	&.newspromo__inner_fh {
		@media (max-width:$tablet-width-m - 1) {
			-ms-flex-order: 2;
			order: 2;
		}

		&:before {
			bottom: 0;

			// @media (min-width:$desktop-width-l) {
			// 	height: 388px;
			// }
			// @media (min-width:$laptop-width ) and (max-width:$desktop-width-l - 1) {
			// 	height: 420px;
			// }

			// @media (max-width:$desktop-width-l - 1) {
			// 	height: 709px;
			// }

			@media (min-width:$mobile-width) and (max-width:$tablet-width-m - 1) {
				height: 100%;
			}
		}
	}
}

.newspromo__title {
	@include zi-2;
	font-weight: bold;
	display: block;
	&:hover {
		color: #21a3dc;
	}
	@media (min-width:$desktop-width-l) {
		@include fs-lh(26px, 31px);
		margin: 0 0 24px;
	}

	@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
		@include fs-lh(24px, 30px);
		margin: 0 0 20px;
	}

	@media (max-width:$desktop-width-m - 1) {
		@include fs-lh(26px, 31px);
	}

	/**/
	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin: 0 0 24px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		margin: 0 0 16px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 22px;
	}
}

.newspromo__scroll {
	overflow-y: scroll;
	overflow-x: hidden;
	@include zi-2;

	@media (min-width:$laptop-width ){
		height: 180px;
	}

	@media (min-width: $tablet-width-m) and (max-width: $laptop-width - 1) {
		height: 280px;
	}

	@media (max-width:$tablet-width-m - 1) {
		overflow-y: hidden;
		// height: 70%;
	}

	&::-webkit-scrollbar {
		width: 3px;
		background-color: #f9f9fd;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(83, 89, 93, 0.308);
	}

}

.newspromo__text {

	p {
		@media (min-width:$desktop-width-l) {
			@include fs-lh(16px, 20px);
			margin: 0 0 20px;
		}

		@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
			@include fs-lh(14px, 18px);
			margin: 0 0 14px;
		}

		@media (max-width:$desktop-width-m - 1) {
			@include fs-lh(16px, 20px);
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
			margin: 0 0 20px;
		}

		@media (max-width:$tablet-width-l - 1) {
			margin: 0 0 16px;
		}
	}

	a {
		color: #283584;
		font-weight: 500;
		text-decoration: underline;
	}

	@media (min-width:$desktop-width-m) {
		margin: 0 0 28px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin: 0 0 24px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		margin: 0 0 16px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 32px;
	}

	&.newspromo__text_row {
		@include d-flex;
		@include flex-wrap;
		margin-left: -10px;
		margin-right: -10px;
	}

}

.newspromo__content {
	padding-left: 10px;
	padding-right: 30px;
	box-sizing: border-box;

	@media (min-width:$laptop-width) {
		// @include flex-width(100%/2);
	}
	@media (max-width:$tablet-width-m - 1) {
		padding-bottom: 15px;
	}
}

.newspromo__meta {
	@include zi-2;
	box-sizing: border-box;
	width: (100%/3*2);
	margin-left: 0;
	padding-left: 0;
	padding-right: 20px;
	display: flex;
		@media (min-width:$mobile-width) and (max-width:$tablet-width-l - 1) {
		width: 100%;

	}

	// @media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
	// 	width: (100%/3*2);
	// 	margin-left: (100%/3);
	// 	padding-left: 20px;
	// 	padding-right: 20px;

	// }

	// @media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
	// 	width: (100%/4*3);
	// 	margin-left: (100%/4);
	// }
	@media (max-width:$tablet-width-m - 1) {
		margin-top: 30px;
	}
	&.newspromo__meta_tabl {
		@media (min-width:$desktop-width-l) {
			display: none;
		}

	}

	&.newspromo__meta_mobile {
		@media (min-width:$tablet-width-m) {
			display: none;
		}
	}
}

.newspromo__date {
	@include zi-2;
	@include fs-lh(12px, 15px);
	color: rgba(0, 0, 0, 0.5);

	&.newspromo__date_inner {
		@media (min-width:$desktop-width-m) {
			margin: 0 0 8px;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			margin: 0 0 32px;
		}

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	&.newspromo__date_left {
		@media (min-width:$tablet-width-m) {
			display: none;
		}
	}

	&.newspromo__date_lg {
		@include fs-lh(16px, 20px);
		font-weight: 500;
		margin: 0 24px 8px 0px;

	}
}

.newspromo__source {
	@include fs-lh(12px, 15px);
	font-weight: 500;
	display: block;
	margin: 0 0 32px;
	color: rgba(0, 0, 0, 0.5);
}
.newspromo__source-new {
	margin-bottom: 0;
}
.newspromo__icon {
	@include before;
	@include inline-block;
	@include fs-lh(16px, 20px);
	@include offset(0 20px 10px 0, 0 0 0 32px);
	font-weight: 600;
	color: rgba(0, 0, 0, 0.5);

	@media (min-width: $desktop-width-l) {
		@include offset(0 20px 10px 0, 0 0 0 32px);
	}

	&:before {
		@include pos(50%, 0);
		background-position: 0 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-color: rgba(0, 0, 0, 0);
	}

	&.newspromo__icon_view {
		&:before {
			@include offset(-8px 0 0, 16px 0 0 24px);
			background-image: url(../img/icons/icon-view.svg);
		}
	}

	&.newspromo__icon_comments {
		&:before {
			@include offset(-12px 0 0, 24px 0 0 24px);
			background-image: url(../img/icons/icon-comments.svg);
		}
	}
}

.newspromo__footer {
	@include zi-2;
	padding-top: 20px;

	@media (max-width:$desktop-width-m - 1) {
		margin: 0 0 -64px;
	}
	@media (min-width:$mobile-width) and (max-width:$tablet-width-m - 1) {
		margin-top: -100px;
	}
	&.newspromo__footer_standart {
		@media (max-width:$desktop-width-m - 1) {
			margin-bottom: 0;
		}

		// @media (max-width:$laptop-width - 1) {
		// 	display: none;
		// }
	}

	&.newspromo__footer__tabl-up {
		@media (min-width:$laptop-width) {
			display: none;
		}
	}

	// &.newspromo__footer__tabl-down {
	// 	@media (max-width:$laptop-width - 1) {
	// 		display: none;
	// 	}
	// }

	&.newspromo__footer_inner {
		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
			display: none;
		}

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	&.newspromo__footer_col {
		@media (min-width:$desktop-width-m) {
			display: none;
		}

		@media (max-width:$desktop-width-m - 1) {
			@include flex-width(100%/8*5);
			margin-top: -64px;
			margin-bottom: 0;
			margin-left: (100%/8*3);
			padding-left: (2*100%/8/3);
			padding-right: 30px;
			box-sizing: border-box;
		}


		@media (max-width:$tablet-width-l - 1) {
			display: none;
		}
	}

	&.newspromo__footer_mobile {
		@media (min-width:$tablet-width-m) {
			display: none;
		}

		@media (max-width:$tablet-width-m - 1) {
			@include flex-width(100%);
			margin: 0 6px 24px;
			padding: 0;
		}

		&.newspromo__footer_standart {
			box-sizing: border-box;

			@media (min-width:$tablet-width-m) {
				width: (100%/8*7);
				margin-left: (100%/8);
				padding-left: 20px;
				padding-right: 20px;
			}

			@media (max-width:$laptop-width - 1) {
				display: block;
				margin-bottom: 0;
			}
		}
	}
}

.newspromo__tag {
	@include inline-block;
	font-weight: bold;
	text-transform: lowercase;

	&:before {
		content: '#';
	}

	@media (min-width:$desktop-width-m) {
		@include fs-lh(18px, 22px);
		margin: 0 8px 8px 0;
		color: rgba(33, 163, 220, 0.7);

		&:hover {
			color: #21a3dc;
		}
	}

	@media (max-width:$desktop-width-m - 1) {
		@include fs-lh(14px, 17px);
		@include offset(0 8px 8px 0, 2px 8px);
		color: #21a3dc;
		border-radius: 2px;
		background: rgba(255, 255, 255, 0.8);

		&:hover {
			color: #395c91;
		}
	}

	&.newspromo__tag_colored {
		color: #21a3dc;
		padding: 0;
		background: none;

		&:hover {
			color: #283584;
		}
	}

	&.newspromo__tag_bg {
		@include fs-lh(14px, 17px);
		@include offset(0 24px 24px 0, 2px 6px);
		font-weight: 400;
		border-radius: 6px;
		background: #21a3dc;
		color: $color__theme_white;

		&:hover {
			color: $color__theme_white;
		}
	}
}

.newspromo__sidebar {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-column;
		// @include justify-content-between;
		@include flex-width(100%/10*2);
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include align-items-start;
		margin-left: (100%/16);
	}

	@media (min-width:$tablet-width-m) {
		@include d-flex;
		@include flex-wrap;
		padding: 0 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
	}
}

.newspromo__post {
	@include before;
	box-sizing: border-box;

	&:last-child {
		margin-bottom: 0;
	}

	/*
	@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
		&:last-child {
			display: none;
		}
	}
	*/

	@media (max-width:$desktop-width-m - 1) {
		&:before {
			@include pos(0, 0);
			right: 20px;
			bottom: 0;
			z-index: 1;
			border-radius: 16px;
			box-sizing: border-box;
			background: rgba(0, 0, 0, 0.6);
			border: 2px solid $color__theme_black;
		}
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		//@include offset(0 20px 0 -20px,16px 36px 16px 16px);
		padding: 16px 36px 16px 16px;
		/*
		&:before {
			@include pos(0,);
		}
*/
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/3);

		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/2);

		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	@media (min-width:$tablet-width-l) {
		margin: 0 0 20px;
	}

	@media (max-width:$tablet-width-l - 1) {
		margin: 0 0 24px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding: 16px;
	}
}

.newspromo__post-title {
	@include zi-2;
	@include fs-lh(20px, 24px);
	font-weight: 600;
	display: block;
	color: $color__theme_white;

	&:hover {
		color: $color__theme_white;
	}

	@media (min-width:$desktop-width-m) {
		margin: 0 0 8px;
	}

	@media (max-width:$desktop-width-m - 1) {
		margin: 0 0 16px;
	}
}

.newspromo__post-date {
	@include zi-2;
	@include fs-lh(12px, 15px);
	color: rgba(255, 255, 255, 0.5);
}

.newspromo__taglist {
	@media (max-width:$desktop-width-m - 1) {
		margin: 0 0 4px;
	}
}

.newspromo__taglist-item {
	@include zi-2;
	@include inline-block;
	@include fs-lh(14px, 17px);

	&:before {
		content: '#';
	}

	@media (min-width:$desktop-width-m) {
		margin: 0 20px 8px 0;
		color: $color__theme_white;

		&:hover {
			color: $color__theme_white;
		}
	}

	@media (max-width:$desktop-width-m - 1) {
		@include offset(0 8px 8px 0, 2px 8px);
		font-weight: bold;
		border-radius: 2px;
		color: $color__theme_black;
		background: rgba(255, 255, 255, 0.8);

		&:hover {
			color: $color__theme_black;
		}
	}
}

.newspromo__nav {
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	@include d-flex;
	@include flex-wrap;
	@include justify-content-between;
	box-sizing: border-box;


	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/10*7);
		margin-left: (100%/10);
		padding: 0 10px;
	}

	@media (max-width:$desktop-width-m - 1) {
		@include flex-width(100%);
	}


	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		// margin-left: (100%/16);
	}

}
.newspromo__nav-main {
	@media (min-width:$tablet-width-m) {
		margin-bottom: 60px;
	}
}


.newspromo__swiper-bg {
	cursor: pointer;
	position: relative;
	z-index: 100;
	margin: 0 auto;
	margin-right: 0;
	width: 87.5%;
	height: 10px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #21A3DC;
	box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
	border-radius: 8px;

	@media (max-width: $desktop-width-m - 1) {
		width: 81%;
		margin-right: 76px;
	}
	@media (min-width: $desktop-width-m - 64) and (max-width: $desktop-width-m - 1){
		margin-right: 88px;
	}
	@media (min-width: $tablet-width-l) and (max-width:  $laptop-width + 14){
		margin-right: 67px;
	}
	@media (max-width: $tablet-width-l - 1) {
		margin-right: 51px;
	}
}

.newspromo__swiper {
	@media (max-width: $tablet-width-m - 1) {
		display: none;
	}

	.swiper-pagination-bullets {
		display: flex;
		overflow: hidden;
		border-radius: 8px;
		justify-content: space-between;



		.swiper-pagination-bullet {
			flex-grow: 1;
			border-radius: 0;
			max-width: 100%;
			height: 10px;
			margin-right: 2px;

		}

		.swiper-pagination-bullet:last-child {
			margin-right: 0px;
			border-radius: 0 8px 8px 0;

		}

		.swiper-pagination-bullet:first-child {
			border-radius: 8px 0 0 8px;

		}

		.swiper-pagination-bullet-active {
			background-color: #fff !important;
			border-radius: 8px !important;
			height: 6px;
			margin: 2px 4px;

		}

		.swiper-pagination-bullet-active:last-child {
			margin-right: 4px;
		}
	}
}

.newspromo__nav-item {
	-webkit-tap-highlight-color: transparent;
	outline: none;
	@include fs-lh(12px, 15px);
	font-weight: bold;
	position: static;
	margin-top: 0;
	box-sizing: border-box;
	background-image: none;
	color: $color__theme_white;

	&:after {
		display: none;
	}

	&.newspromo__nav-item_prev {
		.newspromo__nav-title {
			&:before {
				cursor: pointer;
				left: 0;
				background-position: left bottom;
				background-image: url(../img/icons/icon-arrow-left.svg);
			}
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-right: 10px;
		}
	}

	&.newspromo__nav-item_next {
		text-align: right;

		.newspromo__nav-title {
			&:before {
				cursor: pointer;
				right: 0;
				background-position: right bottom;
				background-image: url(../img/icons/icon-arrow-right.svg);
			}
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-left: 10px;
		}
	}

	@media (min-width:$tablet-width-m) {
		@include size((100%/7*2), auto);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include size((100%/2), auto);
	}
}

.newspromo__nav-title {
	@include before;
	height: 100%;
	display: block;
	padding-bottom: 30px;
	box-sizing: border-box;

	&:before {
		@include size(100%, 24px);
		background-repeat: no-repeat;
		background-color: rgba(0, 0, 0, 0);
		bottom: 0;
	}
}

.newspromo__nav-item-news {
	-webkit-tap-highlight-color: transparent;
	outline: none;
	@include fs-lh(12px, 15px);
	font-weight: bold;
	position: absolute;
	margin-top: 0;
	box-sizing: border-box;
	background-image: none;
	color: $color__theme_white;
	top: 43%;
	z-index: 150;

	@media (min-width: $desktop-width-l) {
		top: 43%;
	}

	// @media (min-width: $desktop-width-m) and (max-width: $desktop-width-xl - 1){
	//     top: 44%;
	// }
	@media (min-width: $desktop-width-s) and (max-width: $desktop-width-m - 1) {
		top: 43%;
	}

	@media (min-width: $tablet-width-l) and (max-width: $desktop-width-s - 1) {
		top: 44%;
	}

	@media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
		top: 42%;
	}

	@media (max-width: $tablet-width-m - 1) {
		display: none;
	}

	&:after {
		display: none;
	}

	&.newspromo__nav-item_prev {
		left: 0;
		@media (max-width: $tablet-width-m - 1) {
			left: -10px;
		}

		@media (max-width: 780px) {
			left: -20px;
		}

		.newspromo__nav-title-news {
			left: 0;
			background-position: left bottom;
			background-image: url(../img/icons/icon-topic-prev.svg);
			background-repeat: no-repeat;
			height: 35px;
			width: 35px;
			position: absolute;
			margin-left: -35px;
			cursor: pointer;

			@media (min-width: $desktop-width-m) and (max-width: $desktop-width-l - 1) {
				margin-left: -40px;
			}

			@media (min-width: $desktop-width-s) and (max-width: $desktop-width-m - 1) {
				margin-left: 50px;
			}

			@media (min-width: $tablet-width-l) and (max-width: $desktop-width-s - 1) {
				margin-left: 42px;
			}

			@media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
				margin-left: 35px;
			}

			&:before {
				content: '';
				position: absolute;
				display: inline-block;
				border-radius: 50%;
				background: rgba(251, 0, 21, .6);
				height: 68px;
				width: 68px;
				z-index: -1;
				left: -22px;
				top: -14px;
			}

			&:after {
				content: '';
				position: absolute;
				display: inline-block;
				border-radius: 50%;
				background: #fb0015;
				height: 44px;
				width: 44px;
				z-index: -1;
				left: -10px;
				top: -2px;
			}
		}
	}

	&.newspromo__nav-item_next {
		right: 0;
		@media (min-width: $desktop-width-s) and (max-width: $desktop-width-m - 1) {
			right: 50px;
		}

		@media (min-width: $tablet-width-l) and (max-width: $desktop-width-s - 1) {
			right: 45px;
		}

		@media (min-width: 880px) and (max-width: $tablet-width-l - 1) {
			right: 35px;
		}

		@media (max-width: 879px) {
			right: 35px;
		}

		@media (max-width: 780px) {
			right: 28px;
		}

		.newspromo__nav-title-news {
			right: 0;
			background-position: right bottom;
			background-image: url(../img/icons/icon-topic-next.svg);
			height: 35px;
			width: 35px;
			background-repeat: no-repeat;
			position: absolute;
			margin-right: -35px;
			cursor: pointer;

			@media (min-width: $desktop-width-m) and (max-width: $desktop-width-l - 1) {
				margin-right: -36px;
			}

			@media (min-width: $desktop-width-s) and (max-width: $desktop-width-m - 1) {
				//margin-right: 2px;
				margin-right: 0;
			}

			@media (min-width: $tablet-width-l) and (max-width: $desktop-width-s - 1) {
				//margin-right: -6px;
				margin-right: 0;
			}

			@media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
				//margin-right: -6px;
				margin-right: 0;
			}

			&:before {
				content: '';
				position: absolute;
				display: inline-block;
				border-radius: 50%;
				background: rgba(251, 0, 21, .6);
				height: 68px;
				width: 68px;
				z-index: -1;
				left: -10px;
				top: -14px;
			}

			&:after {
				content: '';
				position: absolute;
				display: inline-block;
				border-radius: 50%;
				background: #fb0015;
				height: 44px;
				width: 44px;
				z-index: -1;
				left: 2px;
				top: -2px;
			}
		}
	}

}

.newspromo__slides {
	position: relative;
}

.content__slides-scrollbar {
	overflow: hidden;
	border-radius: 8px;
	background: #f1f1f1;
	box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);

	@media (min-width:$tablet-width-m) {
		left: 37px;
		right: 37px;
	}

	@media (max-width:$tablet-width-m - 1) {
		left: 12px;
		right: 12px;
	}

	.swiper-pagination-progressbar-fill {
		background: #21a3dc;
		    position: absolute;
		    left: 0;
		    top: 0;
		    bottom: 2px;
		    border-radius: 8px;
		    width: 100%;
		    height: 100%;
		    -webkit-transform: scale(0);
		    transform: scale(0);
		    -webkit-transform-origin: left top;
		    transform-origin: left top; 
	}
}
.newspromo__slides-scrollbar {
	bottom: 14px;
	height: 12px;
	overflow: hidden;
	border-radius: 8px;
	background: #f1f1f1;
	box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);

	@media (min-width:$tablet-width-m) {
		left: 37px;
		right: 37px;
	}

	@media (max-width:$tablet-width-m - 1) {
		left: 12px;
		right: 12px;
	}

	.swiper-pagination-progressbar-fill {
		top: 1px;
		left: 2px;
		bottom: 2px;
		height: auto;
		border-radius: 8px;
		background: #21a3dc;
	}
}

.swiper-wrapper_index {
	z-index: 0;
}
.swiper-button-disabled {
	opacity: .35;
    cursor: auto;
    pointer-events: none;
}