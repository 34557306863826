.pathway {
	@include fs-lh(14px,17px);
	color: rgba(255, 255, 255, 0.5);
	overflow: hidden;
	margin: 0 0 10px;

	&.pathway_inner {
		.pathway__item {
			color: rgba(0, 0, 0, 0.5);

			&:before {
				background: url(../img/icons/icon-pathway-link-dark.svg);
			}
		}
	}
}

.pathway__item {
	@include before;
	@include inline-block;
	@include default-transition;
	@include offset(0 8px 6px 0,0 0 0 33px);
	
	&:hover {
		color: $color__theme_white;
	}
	
	&:first-child {
		padding-left: 0;

		&:before {
			display: none;
		}
	}
	
	&:before {
		@include pos(50%,0);
		@include offset(-3px 0 0,8px 0 0 24px);
		opacity: 0.5;
		background: url(../img/icons/icon-pathway-link.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
	}
}

.pathway__current {
	@include before;
	@include inline-block;
	@include offset(0 8px 6px 0,0 0 0 33px);
	color: $color__theme_white;
	
	&:before {
		@include pos(50%,0);
		@include offset(-3px 0 0,8px 0 0 24px);
		background: url(../img/icons/icon-pathway-link.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
	}
}