.project {
	@media (min-width:$tablet-width-l) {
		margin: 0 0 60px;
	}

	@media (max-width:$tablet-width-l - 1) {
		margin: 0 0 28px;
	}
	
	&.project_content {
		@media (max-width:$tablet-width-m - 1) {
			background: $color__theme_white;
			margin-top: 92px;
			margin-left: -32px;
			margin-right: -32px;
		}
	}
}

.project__top {
	@include d-flex;
	@include flex-wrap;
	position: relative;

	@media (min-width:$desktop-width-m) {
		margin-left: -(100%/6*2);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-left: -(100%/6);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		left: -20px;
		margin-left: -(100%/3);
	}

	@media (max-width:$tablet-width-m - 1) {
		padding: 70px 28px 0;
	}
	
	&:hover {
		.project__title {
			color: #21a3dc;
		}
		
		.project__picture {
			box-shadow: 0 0 20px 0 #21a3dc;
		}
	}
}

.project__left {
	@include zi-2;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/8*2);
		text-align: center;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		padding: 0 0 0 20px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/7*2);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		padding: 0 0 0 40px;
	}

	@media (max-width:$tablet-width-m - 1) {
		position: static;
	}
}

.project__picture {
	@include inline-block;
	@include default-transition;
	@include border(15px,$color__theme_white);
	overflow: hidden;
	border-radius: 50%;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: $color__theme_white;
	box-shadow: 0 14px 20px 0 rgba(0,0,0,.25);
	
	&.project__picture_keys {
		background-image: url(../img/icons/icon-keys.svg);
	}
	
	&.project__picture_comfort {
		background-image: url(../img/icons/icon-comfort.svg);
	}
	
	&.project__picture_economy {
		background-image: url(../img/icons/icon-economy.svg);
	}
	
	&.project__picture_management {
		background-image: url(../img/icons/icon-government.svg);
	}

	@media (min-width:$tablet-width-m) {
		@include size(160px,160px);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include pos(-52px,50%);
		@include size(104px,104px);
		margin: 0 0 0 -52px;
		background-size: 75% auto;
	}
}

.project__body {
	box-sizing: border-box;	

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/8*6);
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/7*5);
		padding: 0 0 0 40px;
	}

	@media (min-width:$tablet-width-m) {
		margin-bottom: 32px;
	}
}

.project__title {
	@include default-transition;
	display: block;

	@media (min-width:$tablet-width-m) {
		@include fs-lh(36px,44px);
		font-weight: 800;
		margin: 0 0 16px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(20px,24px);
		font-weight: bold;
		margin: 0 0 12px;
	}
}

.project__bottom {
	@media (max-width:$tablet-width-m - 1) {
		padding: 16px 28px 32px;
	}
}