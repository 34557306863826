.service {
    padding: 170px 0 0 0;
    @media (max-width:$desktop-width-m) {
		padding: 140px 0 0 0;
	}
    @media (max-width:$tablet-width-m - 1) {
        padding: 100px 0 0 0;
    }
    &__h3 {
        color: $color__theme_white;
        @include fs-lh(20px, 24px);
        font-weight: 700;
    }
    &__link {
        display: flex;
        position: relative;
        color: $color__theme_white;
        @include fs-lh(14px, 17px);
        font-weight: 500;
        transition: all 0.5s;
        &::after {
           // content: '';
            position: absolute;
            left: 100px;
            top: 5px;
            width: 100%;
           // height: 10px;
           // background: url('/local/templates/cdt/css/../img/icons/icon-link.svg') no-repeat;
        }
        .icon-fluid {
            transition: all 0.5s;
            position: relative;
            top: 5px;
            left: 8px;
            width: 20%;
            height: 10px;
        }
        &:hover {
            color: #21a3dc;
        }
        &:hover .icon-fluid{
            transform: translateX(20px);
            transition-timing-function: ease;
        }
    }
}

.service__wrapper {
    padding: 78px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width:$desktop-width-m) {
        padding: 72px 0 0 0;
    }
    @media (max-width:$tablet-width-m - 1) {
        padding: 32px 0 0 0;
    }
}

.service__col {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 80px;
    @media (max-width:$tablet-width-l) {
        padding: 0;
    }
    @media (max-width:$tablet-width-m - 1) {
        margin-bottom: 40px;
    }
    
    &_standart {
        @include flex-width(100%/12*3.4);

        @media (max-width:$desktop-width-m) {
            @include flex-width(100%/12*3.4);
        }

        @media (max-width:$tablet-width-l) {
            @include flex-width(100%/12*5.4);
        }       
        
        @media (max-width:$tablet-width-m - 1) {
            @include flex-width(100%/12*5.5);
        }

        @media (max-width:$tablet-width-s) {
            @include flex-width(100%/12*12);
        }
    }
}

.service__card {
    display: block;
    box-sizing: border-box;
    background: $color__theme_black;
    width: 100%;
    height: 450px;
    border: 4px solid #FFFFFF;
    border-radius: 16px;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}

.service__top {
    width: 100%;
    min-height: 199px;
    display: flex;
    text-align: center;
    justify-content: center;
    background: #283584;
    border-radius: 16px 16px 0 0;
    span {
        display: block;
        padding: 92px 0 0 0;
        color: $color__theme_white;
        @include fs-lh(12px, 15px);
        font-weight: 600;
    }
    &.service__top_fluid {
        max-height: 199px;
        .img-fluid {
            width: 100%;
        }
        @media (max-width:$tablet-width-s) {
            max-height: 199px;
            .img-fluid {
                width: 100%;
            }
        }
    }
}

.service__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px;
    padding: 24px 30px 27px 24px;
}

.service__shadow {
    position: relative;
    top: -5px;
    border-radius: 16px;
    box-shadow: 1px 11px 24px -8px #fff;
}
.title__h1-launch-desktop {
    @media (max-width:$tablet-width-l - 1) {
        display: none;
    }
}

.title__h1-launch-mobile {
    display: none;
    @media (max-width:$tablet-width-l - 1) {
        display: block;
    }
    @media (max-width: $mobile-width + 3) {
            font-size: 23px !important
        }
    }



@media (max-width:323px) {
    .title__h1-launch-mobile {
        font-size: 23px !important
    }
}