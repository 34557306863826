.search {
	color: $color__theme_white;

	@media (min-width:$desktop-width-s) {
		@include before;
		padding-left: (100%/10);
		padding-right: (100%/10);
		padding-bottom: 8px;
		margin-bottom: 105px;
	}

	@media (max-width:$desktop-width-s - 1) {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 40px;
	}

	&:before {
		@media (min-width:$desktop-width-s) {
			left: 0;
			right: 0;
			bottom: 0;
			height: 1px;
			background: $color__theme_white;
		}
	}

	&.search_noborder {
		margin-bottom: 0;
		padding-bottom: 0;

		&:before {
			display: none;
		}

		.search__input {
			margin-left: -16px;
		}
	}

	&.search_min {
		@include offset(0 0 20px,15px 0 0);

		@media (min-width:$tablet-width-m) {
			display: none;
		}

		.search__input {
			margin: 0;
			background: rgba(255, 255, 255, 0.5);
		}
	}
}

.search__input {
	height: 40px;
	position: relative;
	border-radius: 20px;
	box-sizing: border-box;
	border: 1px solid #ffffff;
	background: $color__theme_white;
	margin-left: -16px;
	@media (min-width:$desktop-width-s) {
		@include offset(0 0 16px,5px 60px 5px 45px);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include offset(0 0 24px 46px,8px 15px 8px 45px);
	}

	&.focused {
		background: $color__theme_white !important;
		// box-shadow: 0px 0px 6px #ffffff, 0px 0px 24px #ffffff;

		.search__btn {
			opacity: 1;
		}
	}
}

.search__btn {
	@include pos(50%,auto);
	border: 0;
	opacity: 0.5;
	cursor: pointer;
	margin: -11px 0 0;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	&.search__btn_submit {
		padding: 0;
	
		background-image: url(../img/icons/icon-search-dark.svg);
		background-size: 100% auto;

		@media (min-width:$desktop-width-s) {
			@include size(22px,22px);
			left: 20px;
		}

		@media (max-width:$desktop-width-s - 1) {
			@include size(16px,16px);
			margin: -8px 0 0;
			left: 12px;
		}
	}

	&.search__btn_micro {
		@include size(14px,22px);
		right: 20px;
		background-image: url(../img/icons/icon-micro.svg);
		background-size: auto 100%;

		// @media (max-width:$desktop-width-s - 1) {
		// 	display: none;
		// }
	}

	&.search__btn_back {
		@media (min-width:$desktop-width-s) {
			display: none;
		}

		@media (max-width:$desktop-width-s - 1) {
			@include pos(50%,-46px);
			@include offset(-7px 0 0,14px 0 0 24px);
			background-image: url(../img/icons/icon-arrow-short-white.svg);
			background-size: auto 100%;
		}
	}
}

.search__field {
	@include size(100%,100%);
	border: 0;
	box-sizing: border-box;
	background-color: rgba(0,0,0,0);

	@media (min-width:$desktop-width-s) {
		@include fs-lh(26px,32px);
		padding: 0 0 0 30px;
	}

	@media (max-width:$desktop-width-s - 1) {
		@include fs-lh(16px,20px);
	}
}

.search__filter {
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		@include offset(0 0 32px,0 20px);
	}
}

.search__label {
	@include inline-block;
	font-weight: 500;
	cursor: pointer;
	margin: 0 40px 0 0;
	box-sizing: border-box;
	color: rgba(255, 255, 255, 0.5);

	@media (min-width:$desktop-width-s) {
		@include fs-lh(18px,22px);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include fs-lh(16px,20px);
	}

	&.search__label_sortdir {
		@include before;
		@include offset(0,0 54px 0 0);

		&:before {
			@include size(38px,22px);
			@include default-transition;
			top: 0;
			right: 0;
			border-radius: 20px;
			background: rgba(255, 255, 255, 0.5);
		}

		&:after {
			@include size(12px,12px);
			@include default-transition;
			content: '';
			position: absolute;
			top: 5px;
			right: 22px;
			border-radius: 50%;
			background: $color__theme_black;
		}
	}
}

.search__checkbox {
	&:checked {
		& + .search__label {
			color: $color__theme_white;
		}

		& + .search__label_sortdir {
			&:before {
				background: #21A3DC;
			}

			&:after {
				right: 4px;
			}
		}
	}
}

.search__bottom {
	@media (min-width:$desktop-width-s) {
		@include d-flex;
		@include flex-wrap;
		@include align-items-center;
		@include justify-content-between;
		@include fs-lh(18px,22px);
		font-weight: 500;
		padding: 0 20px;
		box-sizing: border-box;
	}

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}
}

.search__text {
	
}

.search_pb {
	padding-bottom: 255px;
	margin-bottom: -50px;
	@media (max-width:$desktop-width-m - 1) {
		padding-bottom: 1px;
	}
}