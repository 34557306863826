.card {
	min-height: 180px;
	position: relative;
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		margin: 0 0 32px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 0 40px,52px 0 0);
	}
	
	&:last-child {
		margin-bottom: 0;
	}
	
	/* height */
	&.card_index {
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
			@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
				min-height: 140px;
				margin: 0 0 18px;
			}
		}
		
		.card__picture {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include size(120px,120px);
					left: -60px;
				}
			}
		}
		
		.card__body {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					padding: 0 32px 0 0;
				}
			}
		}
		
		.card__title {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include fs-lh(20px,24px);
					margin: 0 0 10px;
				}
			}
		}
		
		.card__text {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include fs-lh(12px,20px);
				}
			}
		}
		
		.card__link {
			@include fs-lh(16px,20px);
		}
	}
}

.card__picture {
	@include d-flex;
	@include flex-wrap;
	@include align-items-center;
	@include justify-content-center;
	@include default-transition;
	@include border(15px,$color__theme_white);
	overflow: hidden;
	border-radius: 50%;
	box-sizing: border-box;
	background: $color__theme_white;
	box-shadow: 0 14px 20px 0 rgba(0,0,0,0.25);
	
	@media (min-width:$tablet-width-l) {
		@include pos(0,-80px);
		@include size(160px,160px);
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include pos(0,-74px);
		@include size(148px,148px);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include pos(0,50%);
		@include size(104px,104px);
		margin: 0 0 0 -52px;
	}
	
	&:hover {
		box-shadow: 0 0 20px 0 #21A3DC;
	}
}

.card__body {
	@media (min-width:$tablet-width-m) {
		@include offset(0 0 0 (100%/8),0 40px 0 0);
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin: 0 0 0 (100%/7);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include border(2px,$color__theme_black);
		border-radius: 16px;
		padding: 70px 16px 16px;
		background-color: $color__theme_white;
		box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.25);
	}
}

.card__header {
	margin: 0 0 16px;

	@media (min-width:$tablet-width-l) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.card__title {
		margin: 0 0 8px;
	}

	.card__link {
		@include fs-lh(18px,22px);
	}
}

.card__meta {
	@media (max-width:$tablet-width-l - 1) {
		margin: 0 0 16px;
	}
}

.card__title {
	@include fs-lh(26px,31px);
	font-weight: bold;
	display: block;

	@media (min-width:$tablet-width-m) {
		margin: 0 0 16px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 12px;
	}
}

.card__text {
	@include fs-lh(18px,22px);
	font-weight: 500;
}

.card__link {
	color: #21a3dc;
	font-weight: bold;
}