.chapters {
	@include d-flex;
	@include flex-wrap;
	
	@media (min-width:$desktop-width-m) {
		margin-bottom: 36px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-bottom: 16px;
	}
	
	@media (max-width:$tablet-width-l - 1) {
		margin-bottom: 8px;
	}
	
	@media (min-width:$tablet-width-m) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.chapters__item {
	color: $color__theme_white;
	margin-bottom: 24px;
	box-sizing: border-box;
	
	@media (min-width:$tablet-width-l) {
		@include fs-lh(20px,24px);
		font-weight: bold;
	}
	
	@media (max-width:$tablet-width-l - 1) {
		@include fs-lh(16px,20px);
		font-weight: 600;
	}
	
	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/8*3);
		margin-right: (100%/8);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/7*3);
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/2);
	}
	
	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
	}
	
	&:hover,
	&:focus {
		color: $color__theme_white;
	}
}

.chapters__counter {
	color: #21a3dc;
}

// page Podrazdeleniya 

.chapter {
	width: 100%;
	height: 100%;
	@media (max-width:$desktop-width-s - 1){
		overflow: hidden;
	}
	@media (max-width:$tablet-width-m - 1){
		height: 100%;
	}
	&__bg {
		position: absolute;
		top: 0;
		background-image:linear-gradient(transparent 0%, black 26% ), url('../img/content/projecrs_bg.png');
		background-repeat: no-repeat;
		background-size: contain;
		width: 100%;
		height: 100%;

		@media (max-width:$desktop-width-m - 1){
			background-size: auto;
		}
		@media (max-width:$tablet-width-m - 1){
			display: none;
		}
	}

	.title__h1 {
		//margin-top: 32px;
		text-align: left;
		@media (max-width:$desktop-width-s - 1){
			margin-top: 0;
			@include fs-lh(40px,48px);
		}
		@media (max-width:$tablet-width-m - 1){
			@include fs-lh(36px,43px);
			padding: 0  12px;
		}
		@media (max-width:$tablet-width-s - 1){
			@include fs-lh(34px,43px);
		}
	}
	.pathway {
		margin-bottom: 5px;
	}
}