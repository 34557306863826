.section {
	@include container;

	&.section_fs {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: rgba(0, 0, 0, 0);
		background-position: center top;

		@media (min-width:$desktop-width-l) {
			padding-top: 136px;
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-l - 1) {
			padding-top: 132px;
		}

		@media (min-width:$desktop-width-s) {
			height: 100vh;
			overflow: hidden;
		}

		@media (min-width:$desktop-width-m) {
			@include multi-trans(all 0.8s ease 0.1s);
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			padding-top: 135px;
			padding-bottom: 60px;
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-top: 42px;
			padding-bottom: 42px;
		}

		@media (max-width:$tablet-width-m - 312) {
			padding-top: 0;
		}
	}

	&.section_row {
		@include d-flex;
		@include flex-wrap;
	}

	&.section_pbfooter {
		padding-bottom: 200px;
		@media (max-width:$desktop-width-m - 1) {
			padding-bottom: 0;
		}
	}
	&.section_column {
		@include d-flex;

		@media (min-width:$tablet-width-m) {
			@include flex-nowrap;
			@include flex-column;
		}

		@media (max-width:$tablet-width-m - 1) {
			@include flex-wrap;
		}

		.section__title {
			@media (min-width:$tablet-width-m) {
				flex: auto;
			}
		}
	}

	&.section_main {
		background-color: $color__theme_black;

		/*
		@media (min-width:$tablet-width-m) {
			height: 100vh;
			overflow: hidden;
		}
		*/

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			@include d-flex;
			@include flex-wrap;
			padding-bottom: 235px;
		}

		@media (min-width:$tablet-width-m) {
			background-image: url(../img/bg/section-1.jpg);
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-top: 66px;
			//padding-bottom: 0;
			padding-bottom: 15px;
			background-image: url(../img/bg/section-1-1.jpg);
		}
	}

	&.section_news {
		@media (max-width:$tablet-width-m - 1) {
			background-image: none !important;
		}
	}

	&.section_fw {
		@media (min-width:$desktop-width-m) {
			width: (100%/12*10);
			margin-left: (100%/12);

			padding-left: 20px;
			padding-right: 20px;
		}

		@media (max-width:$tablet-width-m - 1) {
			//overflow: hidden;
			overflow-x: clip;
    		overflow-y: visible;
		}
	}

	&.section_visible {
		@media (max-width:$tablet-width-m - 1) {
			overflow: visible;
		}
	}

	&.section_desktop {
		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			display: none;
		}
	}

	&.section_mb {
		margin-bottom: 246px;
	}
	&.section_docl {
		padding-bottom: 216px;
		@media (max-width:$desktop-width-m - 1) {
			padding-bottom: 0;
		}
	
	}
}

.section_slide {
	@media (min-width:$desktop-width-m) {
		@include pos(0, 0, fixed !important);
		@include size(100%, 100%);
		@include multi-prop(translateY(10%));
	}

	&.section_main {
		@media (min-width:$desktop-width-m) {
			transform: none;
			opacity: 1;
			z-index: 9;
		}
	}

	&.after {
		@media (min-width:$desktop-width-m) {
			@include multi-prop(translateY(10%));
			opacity: 0;
			z-index: 8;
		}
	}

	&.before {
		@media (min-width:$desktop-width-m) {
			@include multi-prop(translateY(-100%));
			opacity: 0;
			z-index: 7;
		}

		&.active {
			@media (min-width:$desktop-width-m) {
				z-index: 10;
			}
		}
	}

	&.selected {
		@media (min-width:$desktop-width-m) {
			transform: none !important;
			opacity: 1;
			z-index: 9;
		}
	}

	&.active,
	&.selected {
		@media (min-width:$desktop-width-m) {
			transition-timing-function: cubic-bezier(0.55, 0.05, 0.35, 0.95);
			-webkit-transition: transform 1.4s, opacity 1.4s;
			-moz-transition: transform 1.4s, opacity 1.4s;
			-o-transition: transform 1.4s, opacity 1.4s;
			-ms-transition: transform 1.4s, opacity 1.4s;
			transition: transform 1.4s, opacity 1.4s;
		}
	}
}

.section__title {
	box-sizing: border-box;
	font-weight: bold;
	color: $color__theme_white;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*10);
		margin-left: (100%/12);
	}

	@media (max-width:$desktop-width-m - 1) {
		@include flex-width(100%);
	}

	@media (min-width:$desktop-width-l) {
		@include fs-lh(48px, 57px);
		margin-bottom: 40px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
		@include fs-lh(40px, 48px);
		margin-bottom: 19px;
	}

	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(20px, 24px);
		margin-bottom: 25px;
		padding-left: 10px;
		padding-right: 6px;
	}

	&.section__title_dark {
		color: $color__theme_black;
	}

	&.section__title_min {
		@media (min-width:$desktop-width-l) {
			@include flex-width(100%/12*9);
			margin-bottom: 32px;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
			margin-bottom: 40px;
		}
	}

	&.section__title_max {
		@media (min-width:$desktop-width-l) {
			@include flex-width(100%/12*9);
			margin-left: (100%/12*2);
			margin-bottom: 49px;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
			margin-bottom: 40px;
		}

		@media (max-width:$desktop-width-m - 1) {
			// text-align: center;
		}
	}

	&.section__title_right {
		text-align: right;

		@media (min-width:$desktop-width-l) {
			@include flex-width(100%/12*8);
		}

		@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
			@include flex-width(100%/12*9);
		}
	}

	/* height */
	&.section__title_index {
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
			@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
				@include fs-lh(28px, 36px);
				margin-bottom: 25px;
			}
		}
	}
}
.section__h1 {
	box-sizing: border-box;
	font-weight: bold;
	color: $color__theme_white;
	@include fs-lh(56px,67px);
	margin-bottom: 32px;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*8);
	}

	@media (max-width:$desktop-width-m) {
		@include fs-lh(40px,48px);
		margin-bottom: 24px;
	}
	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%/12*10);
		@include fs-lh(20px,24px);
		margin-bottom: 16px;
	}
}

.section__desc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-weight: bold;
	color: $color__theme_white;

	
	@media (min-width:$desktop-width-m) {
		p {
			@include flex-width(100%/12*8.8);
			margin-left: -45px;
		}
	}

	@media (min-width:$desktop-width-l) {
		@include fs-lh(20px,24px);
		margin-bottom: 28px;
	}
	@media (max-width:$desktop-width-m) {
		align-items: center;
		p{
			@include flex-width(100%/12*9.6);
			padding-left: 45px;
			
		}
		
	}
	@media (max-width:$tablet-width-l - 1) {
		p{
			padding-left: 75px;
			
		}
		
	}
	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(16px,19px);
		p{
			@include flex-width(100%/12*11.5);
			padding-left: 0;
		}
		
	}
	
	
}

.section_request {
	margin: 211px 0 0 0;
	@media (max-width:$desktop-width-m - 1) {
		margin: 32px 0 0 0;
    }
	@media (max-width:$tablet-width-m - 1) {
		margin: 2px 0 0 0;
		padding-left: 12px;
    }
}

.section_service {
	margin: 136px 0 240px 0;
	position: relative;
	background: #283584;
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: -140px;
		width: 100%;
		height: 200px;
		background: #000;
		transform: matrix(1,-.05,0,1,0,0);
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -65px;
		width: 100%;
		height: 300px;
		background: #000;
		transform: matrix(1,-.05,0,1,0,0);
	}
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		//margin-bottom: 155px;
		
	}
	@media (max-width:$desktop-width-m - 1) {
		margin: 20px 0 0 0;
		padding-left: 42px;
		.section {
			padding: 0;
		}
	}
	@media (max-width:$tablet-width-m - 1) {
		padding-left: 12px;
		padding-right: 12px;
		.title__h1 {
			@include fs-lh(24px,28px);
			font-weight: 700;
		}
	}	
}

.gradient {
	position: absolute;
    top: -250px;
    width: 85%;
    left: -20%;
    height: 20%;
	transform: matrix(-0.83, 0.56, 0.56, 0.83, 0, 0);
	background: linear-gradient(180deg,#283584 0%,#283584 0.5%,rgba(40,53,132,0) 50.75%);
	@media (max-width:$desktop-width-m - 1) {
		display: none;
	}
	@media (max-width:$tablet-width-m - 1) {
		display: none;
	}	
}

.section__title_color-white {
	color: $color__theme_white;
}

.section__title_color-black {
	color: #A7AFB2;
}

.section__subtitle {
	@include fs-lh(26px, 31px);
	font-weight: bold;
	margin: 0 0 32px;
	color: $color__theme_black;
}

.section__inner {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*9);
		margin-left: (100%/12);
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/8*7);
		margin-left: (100%/8);
	}

	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}

	&.section__inner_mb {
		@media (min-width:$desktop-width-m) {
			margin-bottom: 60px;
		}

		@media (max-width:$desktop-width-m - 1) {
			margin-bottom: 48px;
		}
	}
	&.section__inner_hid {
		overflow: hidden;
	}
	&.section__inner_md {
		
		// @media (min-width:$desktop-width-l - 73 ) and (max-width:$desktop-width-l - 1 ){
		// padding-top: 100px;
		// }
		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12*10);
			// padding-bottom: 320px;
		}

		@media (max-width:$desktop-width-m - 1) {
			@include flex-width(100%);
			margin-left: 0;
		
		}
	}

	&.section__inner_sm {
		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12*8);
			margin-left: (100%/12*2);
		}

		/* mobile */
		@media (max-width:$tablet-width-m - 1) {
			@include flex-width(100%);
		}
	}

	&.section__inner_xs {
		@include flex-width(100%/12*4);

		@media (max-width:$desktop-width-xl - 1) {
			@include flex-width(100%/12*4);
		}

		@media (max-width:$desktop-width-l - 1) {
			@include flex-width(100%/12*5);
		}

		@media (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/12*7.2);
		}

		@media (max-width:$tablet-width-l - 1 ) {
			@include flex-width(100%/12*10);
		}

		/* mobile */
		@media (max-width:$tablet-width-m - 1) {
			@include flex-width(100%);
		}
	}

	&.section__inner_ml {
		@media (min-width:$desktop-width-m) {
			margin-left: (100%/12);
		}

		/* mobile */
		@media (max-width:$tablet-width-m - 1) {
			@include flex-width(100%);
		}
	}

	&.section__inner_right {
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/8*7);
			margin-left: (100%/8);
		}
		@media (min-width: $tablet-width-m) {
			margin-top: 83px;
		}
	}

	&.section__inner_flex {
		@include d-flex;
		@include flex-wrap;
		@include align-items-stretch;
		padding-left: 0;
		padding-right: 0;
	}
	&.section__inner_desktop_box {
		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}
	&.section__inner_desktop {
		position: relative;

		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12*10);
		}

		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
			@include flex-width(100%/8*7);
			margin-left: (100%/8);
		}

		@media (min-width:$tablet-width-m) and (max-width:$laptop-width - 1) {
			min-height: 550px;
			overflow: hidden;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%);
		}
	}
	&.section__inner_desktop_pt {
		// @media (max-width:$desktop-width-l - 1) {
		// 	padding-top: 250px;
		// }
		@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
			padding-top: 200px;
		}
		@media (max-width:$desktop-width-m - 1) {
			padding-top: 0px;
		}
	}
	&.section__inner_mobile_box {
		display: none!important;
		@media (max-width:$tablet-width-m - 1) {
			display: block!important;
		}
	}
	&.section__inner_mobile {
		position: relative;

		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12*10);
		}

		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
			@include flex-width(100%/8*7);
			margin-left: (100%/8);
		}

		@media (min-width:$tablet-width-m) and (max-width:$laptop-width - 1) {
			min-height: 550px;
			overflow: hidden;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%);
		}
	}
	&.section__inner_pos {
		@media (max-width:$desktop-width-m - 1) {
			margin-left: 0;
		}
	}
}

.section_fw-tablet {
	margin-bottom: 44px;

	@media (min-width:$tablet-width-s) {
		display: none;
	}
}

.section__bg {
	opacity: 0;
	width: 100%;

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		margin-left: (-100%/8);
		margin-right: (-100%/8);
	}

	@media (max-width:$tablet-width-m - 1) {
		display: none;
	}
}

.section__top {
	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include d-flex;
		@include flex-column;
		@include justify-content-between;
	}

	.section__title-mobile-br {

		@media (max-width:$tablet-width-m - 313) {
			display: flex;
			position: relative;
			z-index: 1;

			&:before {
				content: '';
				position: absolute;
				display: inline-block;
				width: 280px;
				height: 38px;
				background: #D71F1F;
				top: 3px;
				left: -52px;
				z-index: -1;
				transform: skewY(-2deg);
				

			}

			&:after {
				content: '';
				position: absolute;
				display: inline-block;
				width: 227px;
				height: 2px;
				background: #D71F1F;
				top: 50px;
				left: -52px;
				z-index: -1;
				transform: skewY(-2deg);

			}
		}
	}

	.section__title {
		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			@include flex-width(auto);
		}

		@media (max-width:$tablet-width-m - 313) {
			padding: 32px 0 0 36px;
			font-size: 32px;
			line-height: 130%;
			margin-bottom: 54px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				display: inline-block;
				width: 126px;
				height: 2px;
				background: #D71F1F;
				top: 79px;
				left: -52px;
				z-index: -1;
				-webkit-transform: skewY(-2deg);
				transform: skewY(-2deg);

			}

			&:after {
				content: '';
				position: absolute;
				display: inline-block;
				width: 17px;
				height: 5px;
				background: #fff;
				top: 143px;
				left: 220px;
				z-index: 1;
				animation: blink .7s infinite;
				-webkit-animation: blink .7s infinite;
				-moz-animation: blink .7s infinite;
				-webkit-animation: blink .7s infinite;

				@keyframes blink {
					50% {
						opacity: 0;
					}
				}

				@-webkit-keyframes blink {
					50% {
						-webkit-opacity: 0;
						-moz-opacity: 0;
						-webkit-opacity: 0;
					}
				}

			}

		}

	}

	.section__inner {
		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			@include flex-width(auto);
			@include d-flex;
			@include flex-column;
			@include justify-content-end;
			@include flex-grow-1;
		}
	}
}

.section__inner-subtitle {
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 26px;

	@media (min-width:$tablet-width-m - 312) {
		display: none;

	}
}

.section__header {
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}

	.section__title {
		padding: 0;
		margin-left: 0;
	}
}

.section__container {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		width: (100%/10*9);
	}


	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		padding-left: 10px;
		padding-right: 57px;
	}
	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		padding-left: 10px;
		padding-right: 10px;
	}
	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}
}

.section__intro {
	font-weight: 600;
	color: $color__theme_white;

	@media (min-width:$desktop-width-m) {
		width: (100%/9*8);
		margin-left: (100%/10);
		margin-bottom: 72px;

		padding-left: 20px;
		padding-right: 20px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		width: (100%/8*7);
		margin-left: (100%/8);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-bottom: 53px;
	}

	@media (min-width:$tablet-width-l) {
		@include fs-lh(26px, 32px);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include fs-lh(20px, 24px);
		margin-bottom: 30px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(16px, 19px);
		margin-bottom: 32px;
	}

	&.section__intro_dark {
		color: $color__theme_black;
	}

	&.section__intro_right {
		text-align: right;
	}

	&.section__intro_sm {
		@media (min-width:$tablet-width-l) {
			@include fs-lh(20px, 24px);
			margin-bottom: 28px;
		}
	}

	a {
		color: #21a3dc;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: #21a3dc;
			text-decoration: underline;
		}
	}
}

.section_map {
	overflow: hidden;
	padding-bottom: 100px !important;

	@media (max-width:$tablet-width-l - 1 ) {
		padding-bottom: 400px !important;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-bottom: 200px !important;
	}

	@media (max-width:$tablet-width-s - 1 ) {
		padding-bottom: 306px !important;
		padding-top: 30px!important;
	}
}

.section_materials {
	display: none;
	@media (max-width:$desktop-width-m - 1) {
		margin: 120px 0 0 0;
		padding: 0 0 0 100px;
		display: block;

		.materials__title {
			@include fs-lh(26px, 31px);
			font-weight: 700;
			margin: 0 0 32px 0;
			color: $color__theme_white;
		}

		.doclist__title {
			color: $color__theme_white;
		}

		.doclist__text {
			color: $color__theme_white;
			opacity: 0.5;
		}
		.doclist__link {
			color: $color__theme_white;
			opacity: 0.5;
		}
		.doclist__item {
			//margin-bottom: 0;
		}
	}
	@media (max-width:$desktop-width-s - 1) {
		padding: 0 0 0 42px;
	}

	@media (max-width:$tablet-width-l - 1) {
		margin: 40px 0 0 0;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 10px 0 0 0;
		padding: 0 0 0 12px;
		.doclist__item {
			margin-bottom: 23px;
		}
	}	
}

.phase-bg {
	background: url('../img/content/phase_bg.png') no-repeat;
	background-size: cover;
	background-position: 0 -280px;
	width: 100%;
	height: 1015px;
	@media (min-width:$desktop-width-xl)  {
		background-position: 100% 50%;
	}
	@media (max-width:$desktop-width-l - 1) {
		background-position: 0 -220px;
	}

	@media (max-width:$desktop-width-m - 1) {
		background: $color__theme_black;
		height: 1010px;
	}
	@media (max-width:$desktop-width-s - 1) {
		height: auto;
	}
}

.section_phase {
	margin: 150px 0 0 0;
	@media (max-width:$desktop-width-s - 1) {
		margin: 34px 0 0 0;
	}
	@media (max-width:$tablet-width-m - 1) {
        margin: 17px 0 0 0;
		padding-left: 12px;
    }
	.features_tranformed {
		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			justify-content: space-between;
		}
	}

}

.section_agancy {
	position: relative;
	margin: 101px 0 0 0;
	@media (max-width:$desktop-width-s) {
		margin: 97px 0 0 0;
	}
	@media (max-width:$tablet-width-m - 1) {
        margin-top: 40px;
		padding: 0;
		overflow: hidden!important;
    }
	.title__h1 {
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(36px, 43px);
			font-weight: 700;
			padding: 0 12px;
		}
	}
}

.section_sites {
	position: relative;
	height: 100%;
	margin: 66px 0 0 0;
	z-index: 10;
	@media (max-width:$desktop-width-s) {
		margin: 60px 0 0 0;
	}
	@media (max-width:$tablet-width-m - 1) {
		margin: 40px 0 0 0;
	}
	&.section_sites-before {
		&::before {
			content: '';
			position: absolute;
			z-index: -5;
			top: 300px;
			width: 100%;
			height: 80%;
			transform: matrix(1,-.02,0,1,0,0);
			background: #21A3DC;
			@media (max-width:$tablet-width-m - 1) {
				display: none;
			}
		}
	}
	&::after {
		content: '';
		position: absolute;
		z-index: -4;
        bottom: -120px;
        width: 100%;
        height: 25%;
		transform: matrix(1,-.02,0,1,0,0);
        background: #fff;
		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}
	.content {
		margin: 0;
		padding-top: 64px;
		@media (max-width:$desktop-width-s - 1){
			width: 100%;
			padding-top: 40px;
			padding: 40px 59px 0 50px;
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-top:40px!important;
			padding-left: 13px!important;
			padding-right: 0!important;
		}

		&::before {
			border-radius: 0;
		}

		p {
			@include fs-lh(18px, 21px);
			font-weight: 500;
			margin: 16px 0 0 0;
			padding-bottom: 34px;
			@media (max-width:$desktop-width-s - 1){
				margin: 12px 0 0 0;
				padding-bottom: 26px;
			}
		}
	}

	.content_borderTop {
		&::before {
			border-radius: 16px 16px 0 0!important;
			border-top: 2px solid #000;
			border-left: 2px solid #000;
			border-right: 2px solid #000;
			border-bottom: none;
			@media (max-width:$tablet-width-m - 1) {
				border: none;
				border-radius: 0!important;
			}
		}
	}

	.content_borderCenter {
		&::before {
			border-radius: 0!important;
			border-top: none;
			border-left: 2px solid #000;
			border-right: 2px solid #000;
			border-bottom: none;
			@media (max-width:$tablet-width-m - 1) {
				border: none;
				border-radius: 0!important;
			}
		}
	}

	.content__h1 {
		@include fs-lh(36px, 43px);
        font-weight: 800;
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(24px, 30px);
        	font-weight: 800;
		}
	}
	.section__container {
		@media (max-width:$desktop-width-m - 1) {
			width: 95%;
		}
		@media (max-width:$tablet-width-l - 1) {
			padding-left: 40px;
			padding-right: 40px;
		}
		@media (max-width:$tablet-width-m - 1) {
			padding: 0;
		}
		@media (max-width:$desktop-width-s - 1){
			width: 100%;
		}
	}
}

.section_chapter {
	position: relative;
	z-index: 100;
	transition: all 0.5s;
	@media (max-width:$desktop-width-s - 1){
		.pathway {
			//display: none;
		}
	}
	@media (max-width:$tablet-width-m - 1){
		padding: 0;
		.pathway {
			margin-left: 6px;
		}
	}
}

.section_projects {
	position: relative;
	z-index: 15;
	@media (max-width:$tablet-width-m - 1){
		background: #fff;
		padding: 0;
	}
	&.section_projects_last {
		@media (max-width:$tablet-width-m - 1){
			margin-bottom: 0;
		}
	}
	.section__container_pb {
		@media (max-width:$tablet-width-m - 1){
			padding-bottom: 19px;
		}
	}
	.content {
		margin: 0;
		padding-top: 16px;
		p {
			margin: 0;
			padding: 16px 0 31px 0;
			width: 80%;
			@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
				width: 100%;
			}
			@media (max-width:$tablet-width-m - 1){
				width: 100%;
				padding: 21px 0 32px 0;
			}
		}
		@media (max-width:$desktop-width-s - 1){
			padding-left: 153px;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			width: 100%;
			padding-left: 180px;
		}
		@media (max-width:$tablet-width-m - 1){
			padding-left: 20px;
		}

	}
	.content_pt {
		padding-top: 60px;
		@media (max-width:$desktop-width-s - 1){
			padding-bottom: 0;
			padding-top: 40px;
		}
		@media (max-width:$tablet-width-m - 1){
			padding-bottom: 0;
			padding-top: 59px;
		}
	}
	.content_desc {
		@media (max-width:$desktop-width-s - 1){
			padding-left: 76px;
		}
		@media (max-width:$tablet-width-m - 1){
			padding-left: 12px;
		}
		@media (max-width:$tablet-width-m - 1){
			padding: 0;
			margin-bottom: 50px;
		}
	}

	.content__bottom {
		@media (max-width:$desktop-width-s - 1){
			left: 0;
			margin:  0 0 0 -54px;
		}

		@media (max-width:1157px){
			left: 0;
			margin:  0 0 0 -57px;
		}

		@media (max-width:1115px){
			left: 0;
			margin:  0 0 0 -60px;
		}

		@media (max-width:1061px){
			left: 0;
			margin:  0 0 0 -63px;
		}

		@media (max-width:1023px){
			left: 0;
			margin:  0 0 0 -66px;
		}

		@media (max-width:$tablet-width-l - 1){
			left: 0;
			margin:  0 0 0 -106px;
			height: 146px;
		}
	}

	.content__footer {
		@media (max-width:$tablet-width-m - 1){
			display: none;
		}
	}
	.content_borderTop {
		&::before {
			border-radius: 16px 16px 0 0!important;
			border-top: 2px solid #000;
			border-left: 2px solid #000;
			border-right: 2px solid #000;
			border-bottom: none;
			@media (max-width:$tablet-width-m - 1){
				display: none;
				//border-top: none;
				//border-left: none;
				//border-right: none;
				//border-radius: 0!important;
			}
		}
	}

	.content_borderCenter {
		&::before {
			border-radius: 0!important;
			border-top: none;
			border-left: 2px solid #000;
			border-right: 2px solid #000;
			border-bottom: none;
			@media (max-width:$tablet-width-m - 1){
				display: none;
				//border-top: none;
				//border-left: none;
				//border-right: none;
				//border-radius: 0!important;
			}
		}
	}

	.content__h1 {
		color: #000;
		text-align: left;

		@include fs-lh(36px, 43px);
		font-weight: 800;
		@media (max-width:$tablet-width-m - 1) {
			//@include fs-lh(26px, 31px);
			//font-weight: 700;
		}
		@media (max-width:$tablet-width-m - 1){
			@include fs-lh(26px, 31px);
		}
	}

	.preview {
		position: absolute;
		left: 130px;
		//top: 150px;
		margin-top: -100px;
		z-index: 100;
		width: 160px;
		height: 160px;
		border-radius: 50%;
		background: #FFFFFF;
		text-align: center;
		box-shadow: -5px 5px 10px 5px rgba(49, 42, 42, 0.205);
		@media (max-width:$desktop-width-xl - 1){
			left: 40px;
		}
		@media (min-width:$tablet-width-l - 1) and (max-width:$desktop-width-m - 1) {
			left: 20px;
		}
		@media (max-width:$desktop-width-s - 1){
			left: 30px;
			//top: 70px;
			//top: 150px;
		}
		@media (max-width:$tablet-width-l - 1){
			left: 30px;
			//top: 70px;
			//top: 170px;
		}
		
		@media (max-width:$tablet-width-m - 1){
			left: 40%;
			top: 50px;
			width: 104px;
			height: 104px;
		}
		@media (max-width:$tablet-width-s - 1){
			left: 36%;
		}
	}

	.preview__center {
		display: flex;
		justify-content: center;
		height: 100%;
		img {
			max-width: 50%;
    		width: 100%;
		}
	}

}

.section_order {
	background: #25282A;
}
.section__lightBg {
	padding: 0 0 120px 0;
	background: #fff;
	@media (max-width:$desktop-width-s) {
		padding: 0  0 72px 0;
    }
	@media (max-width:$desktop-width-m) {
		padding: 0  0 40px 0;
    }
	@media (max-width:$tablet-width-m - 1) {
		padding: 0  0 30px 0;
	}
	.title__h1 {
		color: #000!important;
		text-align: left;
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(26px, 31px);
			font-weight: 700;
		}
	}

	.doclist {
		margin: 65px 0 0 0;
		@media (max-width:$tablet-width-m - 1) {
			margin: 23px 0 0 0;
		}
	}
}
.section_client {
	background: #fff!important;
	/*padding: 0 0 60px 0;*/
	@media (max-width:$desktop-width-s - 1){
		//display: none;
	}
	@media (max-width:$tablet-width-m - 1) {
		/*padding: 0 0 64px 0;*/
	}
	.title__h1 {
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(26px, 31px);
			font-weight: 700;
		}
	}
}


.section_launch {
	@media (max-width:$tablet-width-l - 1){
		padding-left: 0!important;
		padding-right: 0!important;
		overflow: visible;
		height: auto;
	}
	.section__h1 {
		@media (max-width:$desktop-width-s - 1){
			width: 80%;
		}

		@media (max-width:$tablet-width-l - 1){
			padding-left: 30px;
		}

		@media (max-width:$tablet-width-m - 1){
			padding-left: 0px;
		}
	}
	.section__container {
		@media (max-width:$tablet-width-l - 1){
			padding-left: 30px;
			padding-right: 30px;
		}
		@media (max-width:$tablet-width-m - 1) {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
	.section__desc {
		font-weight: 600;
		@media (max-width:$desktop-width-s - 1){
			margin-bottom: 30px;
		}
		@media (max-width:$tablet-width-m - 1) {
			margin-bottom: 10px;
		}
	}
	.content {
		padding-bottom: 8px;
		@media (max-width:$tablet-width-m - 1) {
			padding-top: 32px;
		}
		@media (max-width:$tablet-width-s - 1) {
			padding: 22px 12px 10px 12px;
		}
	}

	.content__bottom {
		&::before {
			bottom: 0;
		}
	}
	.content__title {
		@media (max-width:$desktop-width-s - 1){
			margin:  0 0 16px 0;
		}
	}
	.pathway {
		@media (max-width:$desktop-width-s - 1){
			
		}
		@media (max-width:$tablet-width-l - 1) {
			margin-left: 30px;
		}
		@media (max-width:$tablet-width-m - 1) {
			//display: none;
			margin-left: 0px;
		}
	}
}

.launch {
	position: relative;
	// .section {
	// 	overflow: hidden;
	// }
	@media (max-width:$tablet-width-l - 1){
		overflow-x: hidden;
		overflow-y: visible;
		padding-bottom: 60px;
	}

	.launch__bg {
		position: absolute;
		bottom: -400px;
		z-index: -10;
		width: 100%;
		height: 100%;
		background: url('../img/content/launch-space-bg.png') no-repeat;
		background-size: 100% 80%;
		@media (max-width:$desktop-width-m ) {
			background: $color__theme_black;
			z-index: 1;
		}
		@media (max-width:$tablet-width-l - 1){
			height: auto;
		}
	}
}

.section_digital {
	position: relative;
	z-index: 10;
}


.scroll__name-fadein {
	transition: 0.2s;
}