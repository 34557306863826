.section__title-tablet-fromsmi {
	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		margin-bottom: 0 !important;
	}
}

.fromsmi__desc {
	display: block;

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		display: none;
	}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		margin-top: 13px;
	}
}

.fromsmi__main {
	display: flex;
	justify-content: space-around;
	box-sizing: border-box;
	margin-bottom: 84px;

	&:before {
		right: 0;
		z-index: 1;
		border-radius: 16px;
		box-sizing: border-box;
		border: 2px solid $color__theme_black;
		background: $color__theme_white;
		box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
	//	z-index: -1;

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			height: 100%;
			width: 100%;
			bottom: -20px !important;
		}
	}

	@media (max-width:$desktop-width-xl - 1) {
		@include align-items-start;
	}

	@media (min-width:$tablet-width-s) and (max-width: $desktop-width-m - 1) {}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		padding-left: 16px;
		max-width: 100% !important;
		display: grid;
		justify-content: center;
	}

	&.fromsmi__main_wide {
		@media (max-width:$desktop-width-xl - 1) {
			@include align-items-stretch;
		}

		@media (min-width:$desktop-width-s) {
			@include flex-width(100%);
		}


	}


	@include before;
	@include flex-width(100%/8*5);

	@media (min-width:$desktop-width-xl) {
		&:before {
			bottom: 7px;
		}
	}

	@media (max-width:$desktop-width-xl - 1) {
		&:before {
			bottom: -30px;
		}
	}

	@media (min-width:$desktop-width-s) and (max-width: $desktop-width-m - 1) {
		&:before {
			bottom: -60px;
		}
	}

	@media (min-width:$desktop-width-s) {
		padding-left: 10px;
		padding-right: 36px;
		padding-bottom: 19px;

		&:before {
			@include pos(-24px, (11%));
		}
	}

	@media (min-width:$desktop-width-xl) {
		&:before {
			@include pos(-24px, (12%));
		}
	}

}

.section__title-fromsmi {
	@media (min-width:$desktop-width-m) {
		margin-bottom: 73px;
	}
}

.fromsmi__left {
	@include before;
	box-sizing: border-box;

	&:after {
		content: '';
		height: 75px;
		position: absolute;
		left: 18px;
		right: 18px;
		border-radius: 12px;
	}

	@media (min-width:$tablet-width-m) {
		padding: 0 10px 0 0;
	}

	@media (min-width:$tablet-width-m) {
		&:after {
			bottom: -24px;
		}
	}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		padding-right: 20px;
	}


	&.fromsmi__left_noshadow {
		padding-bottom: 25px;



		&:after {
			@media (min-width:$tablet-width-m) {
				display: none;
			}
		}
	}
}

.fromsmi__picture {
	@include zi-2;
	display: block;
	overflow: hidden;
	border-radius: 12px;
	text-align: center;
	box-sizing: border-box;
	border: 2px solid $color__theme_white;
	max-height: 226px;
	max-width: 334px;
	box-shadow: 3px 4px 7px -5px #000;


	@media (min-width:$tablet-width-m) {
		margin: 0 0 24px;
	}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		margin-bottom: 16px;
	}


}

.fromsmi__inner {
	box-sizing: border-box;
	max-width: 534px;
	margin-left: 6px;

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		width: 100%;
	}

}

.fromsmi__createdby {
	background: #fff;
	border-radius: 11px;
	height: 26px;
	max-width: 30px;
	padding: 7px 20px;
	display: block;

}

.fromsmi__title {
	@include zi-2;
	font-weight: bold;
	display: block;

	@media (min-width:$desktop-width-s) {
		@include fs-lh(26px, 31px);
		margin: 0 0 24px;
	}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		padding-right: 17px;
		margin-bottom: 17px;
		@include fs-lh(26px, 31px);
	}
}

.fromsmi__subtitle {
	@include fs-lh(16px, 18px);
	font-weight: 600;
	margin: 26px 0 24px 0;
}

.fromsmi__text {
	@include zi-2;

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		margin-right: 20px;
		margin-left: 0;
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
	}

	p {
		@media (min-width:$desktop-width-s) {
			@include fs-lh(16px, 20px);
			margin: 0 0 20px;
		}
	}

	a {
		color: #283584;
		font-weight: 500;
		text-decoration: underline;
	}

	&.fromsmi__text_row {
		@include d-flex;
		@include flex-wrap;
		margin-left: -10px;
		margin-right: -10px;
	}
}

.fromsmi__inner-footer {
	@include zi-2;

	@media (max-width:$desktop-width-m - 1) {
		margin: 0 0 -24px;
	}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		display: grid;
	}

	&.fromsmi__footer_mobile {

		&.fromsmi__footer_standart {
			box-sizing: border-box;

		}
	}
}

.fromsmi__tag {
	@include inline-block;
	font-weight: bold;
	text-transform: lowercase;
	opacity: 0.7;

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		margin: 0 47px 7px 0px;
	}

	&:before {
		content: '#';
	}

	@media (min-width:$desktop-width-m) {
		@include fs-lh(18px, 22px);
		margin: 0 8px 8px 0;
		color: rgba(33, 163, 220, 0.7);

		&:hover {
			color: #21A3DC;
		}
	}

	@media (max-width:$desktop-width-m - 1) {
		@include fs-lh(18px, 20px);
		@include offset(0 8px 8px 0, 2px 8px);
		font-weight: 700;
		color: #21a3dc;
		border-radius: 2px;
		background: rgba(255, 255, 255, 0.8);

		&:hover {
			color: #395c91;
		}
	}

	&.fromsmi__tag_colored {
		color: #21a3dc;
		padding: 0;
		background: none;

		&:hover {
			color: #283584;
		}
	}

	&.fromsmi__tag_bg {
		@include fs-lh(14px, 17px);
		@include offset(0 24px 24px 0, 2px 6px);
		font-weight: 400;
		border-radius: 6px;
		background: #21a3dc;
		color: $color__theme_white;

		&:hover {
			color: $color__theme_white;
		}
	}
}

.fromsmi__right {
	z-index: 1;
	margin-left: 56px;

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		margin-left: 5px;
		margin-top: 62px;
	}

	.fromsmi__date {
		@include zi-2;
		@include fs-lh(12px, 15px);
		color: rgba(0, 0, 0, 0.5);

		&.fromsmi__date_lg {
			@include fs-lh(20px, 24px);
			font-weight: 500;
			margin: 0 0 8px;

			@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
				@include fs-lh(16px, 20px);
				margin: 0 0 -8px;
			}
		}

	}

	.fromsmi__item-pdf {
		display: block;
		background-image: url(../img/icons/icon-pdf.svg);
		width: 48px;
		height: 67px;
		background-position: 0 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-color: transparent;
		z-index: 1;
		margin-top: 26px;
		margin-bottom: 12px;

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin-bottom: 5px;
		}
	}

	.fromsmi__item-yandex {
		display: block;
		background-image: url(../img/icons/icon-yandex.svg);
		width: 48px;
		height: 67px;
		background-position: 0 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-color: transparent;
		z-index: 1;
		margin-top: 26px;
		margin-bottom: 12px;

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin-bottom: 5px;
		}
	}

	.fromsmi__link-download {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		color: #21A3DC;
		display: block;
		margin-bottom: 24px;

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin-bottom: 20px;
		}
	}

}

.fromsmi__doclist {

	.doclist__item {
		margin-bottom: 10px;
		&::before {
			background-image: url(../img/icons/icon-yandex.svg);
			background-position: 0 0;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-color: transparent;
			z-index: 1;
		}
	}

	
	.fromsmi__link-download {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		color: #21A3DC;
		display: block;
		margin: 0;
		padding-top: 30px;
		//margin-bottom: 24px;

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin-bottom: 20px;
		}
	}
}

.section__title-tablet {
	margin-bottom: 72px;
}


///tablet
.fromsmi__tablet {

	display: none;

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		display: block;
		padding-bottom: 75px;
	}

	@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
		padding-top: 60px;
	}

	.fromsmi__main {
		display: block;
		margin: 0 auto;

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			padding-left: 16px;
			max-width: 100% !important;
		}


		&:before {
			position: absolute;
			top: -24px;
			left: -8%;
			right: -29%;

			@media (min-width:$laptop-width) and (max-width: $desktop-width-l - 1) {
				left: -6%;
			}

			@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
				left: 1%;
				right: 1%;
			}
		}

	}

	.fromsmi__title {
		font-weight: 700;
		font-size: 26px;
		line-height: 120%;
		width: 549px;
		color: #000000;
		margin-left: 30px;
		margin-bottom: 24px;

		@media (min-width:$laptop-width + 50) and (max-width:$desktop-width-s - 1) {
			width: 750px;
		}
	}

	.fromsmi__left {
		position: absolute;
		left: -135px;
		width: 270px;
	}

	.fromsmi__inner {
		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin-left: 0;
			padding-right: 15px;
		}

	}

	.fromsmi__text {
		margin-left: 135px;
		width: 413px;
		font-size: 16px;
		line-height: 20px;


		@media (min-width:$laptop-width + 50) and (max-width:$desktop-width-s - 1) {
			overflow: scroll;
			height: 200px;
			padding-right: 25px;
			width: 120%;
		}

		@media (min-width:$tablet-width-l - 51) and (max-width:$laptop-width + 49) {
			overflow: scroll;
			height: 200px;
			padding-right: 25px;
			width: 100%;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 51) {
			width: 83%;
			overflow: scroll;
			height: 200px;
			padding-right: 25px;
		}

	}

	.fromsmi__inner-footer {
		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin: 0 0 -63px;
		}

	}

	.fromsmi__tag {
		font-size: 18px;
		line-height: 22px;
		margin: 0 8px 16px 37px;
		opacity: 0.7;


	}

	.fromsmi__right {
		margin-left: 37px;

		@media (min-width:$desktop-width-s) and (max-width: $desktop-width-m - 1) {
			position: absolute;
			display: flex;
			flex-direction: column;
			top: 0;
			right: -219px;
		}

		@media (min-width:$mobile-width) and (max-width: $tablet-width-m - 1) {
			margin-left: 0;
		}

		.fromsmi__date {
			margin: 55px 0 8px;
			font-size: 16px;
			line-height: 20px;

			@media (min-width:$desktop-width-s) and (max-width: $desktop-width-l - 1) {
				margin: 0;
			}
		}

		.fromsmi__dowload-box {
			display: flex;
			align-items: center;

			@media (min-width:$desktop-width-s) and (max-width: $desktop-width-m - 1) {
				display: block;
			}

		}

		.fromsmi__link-download {
			margin-right: 59px;
			min-width: 170px;

			@media (min-width:$desktop-width-s) and (max-width: $desktop-width-m - 1) {
				margin-right: 59px;
				margin-bottom: 20px;
				width: 162px;
			}

		}

		.fromsmi__item-pdf,
		.fromsmi__item-yandex {
			margin-right: 17px;
			min-width: 48px;
			min-height: 67px;
			margin-bottom: 30px;

		}


	}
}

.content__slides-nav-fromsmi {
	@media (max-width: $tablet-width-l - 1) {
		display: none;
	}
}