.section-content_mb-200 {
    margin-bottom: 200px;
}

.section-content_ptb-100 {
    padding: 100px 0;
}

.content-size-calc {
    width: calc(83.3333% - 40px);
    margin-left: calc(8.3333% + 20px);

    @media (max-width:$desktop-width-m - 1) {
        width: calc(100% - 80px);
        margin-left: 40px;
    }

    @media (max-width: $tablet-width-m - 1) {
        width: calc(100% - 24px);
        margin-left: 12px;
    }
}

.section-header {
    display: flex;
    padding-top: 47px;

    &.section-header__row {
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width:$desktop-width-m - 1) {
            padding: 5px 0 0;
        }
    
        @media (max-width: $tablet-width-s) {
            padding-bottom: 24px;
        }
    }

    &.section-header__column{
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 62px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        @media (max-width:$desktop-width-m - 1) {
            padding: 47px 0 62px;
        }
    
        @media (max-width: $tablet-width-l) {
            padding-bottom: 30px;
        }
    }
}

.section-header__item {
    @media(max-width: $tablet-width-m) {
        &:not(&:last-child) {
            margin-right: 100px;
        }
    }
}
 
.section-header__title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 56px;
    font-weight: 700;
    color: #fff;

    @media(max-width: $desktop-width-m - 1) {
        font-size: 40px;
    }

    @media (max-width: $tablet-width-s) {
        font-size: 36px;
    }

    @media (max-width: $tablet-width-s + 79) {
        font-size: 28px;
    }
}

.bsagents-button {
    padding: 8px 33px;
    background: #283584;
    font-size: 20px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: .4s;

    &.bsagents-feedback__btn {
        width: 334px;
        margin: auto;

        @media (max-width: $tablet-width-s - 1) {
            width: 234px;
        }
    }

    @media(max-width: $tablet-width-m) {
        margin-top: 15px;
    }

    &:hover {
        background: #4A020A;
    }
}

.section-header__text {
     width: 644px;
     margin-top: 32px;
     padding: 0 20px;
     font-size: 20px;
     font-weight: 600;
     line-height: 24px;
     color: #fff;
     position: relative;

     &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 15px;
        background: url('../img/icons/icon-brace-red.svg') center / 100% 100% no-repeat;    
    }
    
    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 15px;
        background: url('../img/icons/icon-brace-red.svg') center / 100% 100% no-repeat;
        transform: rotate(180deg);  
    }

     @media (max-width: $desktop-width-m) {
        width: 515px;
        font-size: 16px;
     }

     @media (max-width: $tablet-width-m) {
        width: 350px;
        font-size: 14px;
     }

     @media (max-width: $tablet-width-s) {
        width: 295px;
     }

     @media (max-width: $tablet-width-s + 79) {
        width: 255px;
        line-height: 17px;
    }
}

.section-bsagents_bg {
    position: relative;
    padding: 0 0 170px;
    background: url('../img/bg/bg_bs_agents.jpg') top left / cover no-repeat;
    clip-path: polygon(0% 0%, 100% 0%, 100% 98%, 0% 100%);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3) 25%, rgba(0,0,0,0.16) 100%);
    }

    @media (max-width: $desktop-width-m - 1) {
        padding: 0 0 26px;
    }
}

.section-bstransform_bg {
    background: url('../img/bg/bg-bsagents-www.jpg') 0% 0% / auto 1500px no-repeat;
    position: relative;
    padding: 50px 0 170px;

    @media (max-width: $tablet-width-m) {
        padding-bottom: 40px ;
    }

    @media (max-width: $tablet-width-s) {
        background: #000;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 400px;
        background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);    }


    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 400px;
        background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);    }
}

.section-bsagents__list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 30px;

    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100vw;
    //     height: 100%;
    //     background: linear-gradient(180deg, #000000 2.83%, rgba(0, 0, 0, 0) 70%);
    // }

    @media (max-width: $tablet-width-m - 1) {
        align-items: flex-start;
    }
}

.section-bsagents__list-item {
    position: relative;
    width: 314px;
    padding: 36px 15px 21px 20px;
    border-bottom: .5px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;

    &:hover {
        .list-item__title {
            top: 17px;
            opacity: 1;
        } 

        .list-item__counter {
            top: - 6px;
        }
    }

    &:first-child {
        margin-right: 334px;
    }

    &:nth-child(2) {
        margin-right: 153px;
    }

    @media (max-width:$desktop-width-m - 1) {
        &:first-child {
            margin-right: 0;
        }
    
        &:nth-child(2) {
            margin-right: 0;
        }
    }

    @media (max-width: $mobile-width + 79) {
        width: 255px;
    }
}

.list-item__counter {
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 76px;
    font-weight: 700;
    background: linear-gradient(180deg, rgba(251, 0, 21, 0.5) 0%, rgba(251, 0, 21, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    transition: .3s ease;
}

.list-item__title {
    position: absolute;
    top: 23px;
    left: 120px;
    font-size: 16px;
    color: #A81D1A;
    white-space: nowrap;
    font-weight: 600;
    opacity: 0;
    transition: .3s ease;
    box-sizing: border-box;
}

.list-item__text {
    position: inherit;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #fff;

    @media (max-width: $mobile-width + 79) {
        font-size: 12px;
    }

    &:before {
        content: '';
        position: absolute;
        left: -20px;
        bottom: 0;
        height: 100%;
        width: 15px;
        background: url('../img/icons/icon-brace-red.svg') center / 100% 100% no-repeat;    
    }
    
    &:after {
        content: '';
        position: absolute;
        bottom: -24.5px;
        right:-18.5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #fff;
    }
}

.section-bsratnsform {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet-width-m - 1) {
        flex-direction: column;
    }

    &__bs-title {
        width: 270px;
        padding: 30px 0;
        font-size: 20px;
        line-height: 24.5px;
        font-weight: 600;
        color: #fff;

        @media (max-width: $desktop-width-m - 1) {
            width: 250px;
            font-size: 16px;
            line-height: 19.5px;
        }

        @media (max-width: $tablet-width-m - 1) {
            width: 100%;
        }
    }

    &__title_drop {
        animation: dispDropFieldReverse 1s ease-out forwards;
    }

    &__bs-content {
        display: flex;
        flex-direction: column;
        width: 60%;

        @media (max-width: $tablet-width-m - 1) {
            width: 100%;
        }
    }

    &__content_drop {
        animation: dispDropFieldReverse 1s ease-out forwards;
    }
}

.bs-contetn-block {
    display: flex;

    &:not(&:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    @media (max-width: $tablet-width-s - 1) {
        flex-direction: column;

        &:not(&:last-child) {
            border-bottom: none;
        }
    }

    &.bs-contetn-block_column {
        flex-direction: column;
    }

    .contetn-block-column {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 30px 0;
        color: #fff;

        @media (max-width: $tablet-width-s - 1) {
            width: 100%;
        }
          
        .column-title {
            font-size: 24px;
            font-weight: 700;
            position: relative;
            padding-left: 45px;
            box-sizing: border-box;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 5px solid rgba(251, 0, 21, 0.6);
            }

            &:after {
                content: '';
                position: absolute;
                left: 11px;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: #fff;
            }

            @media (max-width: $desktop-width-m - 1) {
                font-size: 18px;
            }
        }

        .column-list {
            display: flex;
            flex-direction: column;
            padding: 30px 20px 0 30px;

        .column-list__item {
            position: relative;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;

                &:not(&:last-child) {
                    padding-bottom: 15px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: -30px;
                    width: 15px;
                    height: 1px;
                    background: #fff;
                }

                @media (max-width: $desktop-width-m - 1) {
                    font-size: 14px;
                }
            }
        }

        .column-text {
            padding-top: 30px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;

            @media (max-width: $desktop-width-m - 1) {
                font-size: 14px;
             }
        }
    }

    .contetn-block-column_w100 {
        width: 100%;

        & + .contetn-block-column_w100 {
            border-top: 1px solid rgba(255, 255, 255, 0.5);   
        }
    }
}

.section-bstransform-dropdown {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);

    &:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
}

.bstransform-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    &__text {
         font-size: 24px;
         font-weight: 500;
         line-height: 29px;
         color: #fff;

         @media (max-width: $desktop-width-m - 1) {
            font-size: 20px;
         }

         @media (max-width: $tablet-width-s - 1) {
            font-size: 16px;
        }
    }

    &__close-btn {
        width: 20px;
        height: 20px;
        position: relative;
        background: transparent;
        border-radius: none;
        border: none;
        cursor: pointer;
        transition: .5s ease;

        &.active {
            transform: rotate(45deg);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 2px;
            margin: auto;
            background: #fff;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 100%;
            margin: auto;
            background: #fff;
        }
    }
}


.section-bsratnsform_drop-field {
    display: flex;
    overflow: hidden;

    .column-title {
        transform: translateX(20px);
        opacity: 0;
    }

    .column-text {
        transform: translateY(20px);
        opacity: 0;
    }

    &.disp {
        .section-bsratnsform__content_drop {
            animation: dispDropField 1s ease-out forwards;
        }

        .section-bsratnsform__title_drop {
            animation: dispDropField 1s ease-out forwards;
        }

        .column-text.active {
            animation: slidein 1s ease .1s forwards;
        }

        .column-title.active {
            animation: slideright ease 1s forwards;
        }
    }
}

@keyframes slidein {
    from {
        transform: translateY(40px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slideright {
    from {
        transform: translateY(40px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes dispDropField {
    0% {
        margin-top: -200%;
        opacity: 0;
    }

    70% {
        margin-top: 0;
        opacity: 0;
    }

    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes dispDropFieldReverse {
    0% {
        margin-top: 0;
        opacity: 1;
        visibility: visible;
    }

    30% {
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
    }

    100% {
        margin-top: -200%;
        opacity: 0;
        visibility: hidden;
    }
}
.bsagents-bg-img {
    height: 400px;
    width: 100%;
    background: url('../img/bg/bg-bgagents-city.jpg') center / cover no-repeat;   
    clip-path: polygon(0% 5%, 100% 0%, 100% 95%, 0% 100%);

    @media (max-width: $tablet-width-s) {
        height: 300px;
    }
}

.bsagents-material {
    display: flex;
    flex-direction: column;
    padding-top: 100px;

    &__title {
        font-size: 48px;
        color: #fff;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        padding-top: 65px;

        @media (max-width: $tablet-width-s) {
            flex-direction: column;

        }
    }

    .contetn-block {
        display: flex;
        justify-content: flex-start;
        align-items: flex;
        width: 50%;
        margin-bottom: 50px;

        @media (max-width: $tablet-width-s) {
            width: 100%;
        }

        .block-file {
            min-width: 48px;
            height: 85px;
            margin-right: 20px;
            background: url('../img/icons/icon-xls.svg') center / contain no-repeat;
        }

        .block-info {
            display: flex;
            flex-direction: column;

            &__title {
                width: 80%;
                font-size: 18px;
                color: #fff;
                line-height: 22px;
            }

            &__text {
                width: 80%;
                min-height: 16px;
                padding: 4px 0 10px;
                font-size: 14px;
                line-height: 17px;
                color: rgba(255, 255, 255, 0.5);
            }

            &__link {
                font-size: 12px;
                color: #fff;


                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.bsagents-bg-form {
    position: relative;
    background: url('../img/bg/bg-bsagents-form.jpg') center / cover no-repeat;
    margin-bottom: -100px;   

    @media (max-width: $tablet-width-m - 1) {
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg,#000 20%,transparent 100%);
        z-index: -1;
    }
}

.bsagents-request {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 200px;

    @media (max-width: $desktop-width-m - 1) {
        margin-bottom: 100px;
    }
}

.bsagents-request__subtitle {
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 45px;
    text-align: center;

    @media (max-width: $desktop-width-m - 1) {
        font-size: 36px;
     }

     @media (max-width: $tablet-width-s - 1) {
        font-size: 32px;
    }
}

.bsagents-feedback {
    display: flex;
    flex-direction: column;
    max-width: 570px;
    width: 100%;

    &__item {
        padding-bottom: 15px;
    }

    .bsagents-feedback__input {
        width: 100%;
        padding: 10px 15px;
        outline: none;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        background: transparent;
        box-sizing: border-box;

    }

    .bsagents-feedback__select {
        width: 100%;
        padding: 10px 15px;
        outline: none;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        background: transparent;
        box-sizing: border-box;
        cursor: pointer;
        transition: .2s;

        &:focus {
            box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.6);
        }

        option {
            padding: 5px 10px;
            color: inherit;
            background: #000;
            cursor: pointer;

            &:focus {
                color: #fff;
                background: #000;
            }
        }

    }


    .bsagents-feedback__textarea {
        width: 100%;
        height: 189px;
        padding: 10px 15px;
        margin-top: 15px;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.6);
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }

    .bsagents-feedback__check {
        padding-bottom: 30px;

        .label_checkbox {
            position: relative;
            box-sizing: border-box;
            padding: 3px 0 0 25px;
            font-size: 11px;
            color: rgba(255, 255, 255, 0.6);
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                left: 0;
                width: 14px;
                height: 14px;
                background: 0 0;
                border-radius: 2px;
                border: 1px solid #fff;
            }

            &:after {
                content: '';
                position: absolute;
                top: 9px;
                left: 2px;
                width: 12px;
                height: 12px;
                display: none;
                margin: -6px 0 0;
                background: url('../img/icons/icon-checked-fedb.svg') no-repeat center center;
            }
        }

        .field_checkbox:checked + .label_checkbox:after {
            display: block;
        }
    }
}
