.client {
    padding-top: 250px;
    @media (max-width:$desktop-width-s - 1){
		padding: 160px 0 0 0;
	}
    @media (max-width:$tablet-width-m - 1) {
        padding: 40px 0 0 0;
    }
    .title__h1 {
        color: $color__theme_black!important;
        text-align: left;
    }
    &.section_fw {
        @media (max-width:$desktop-width-s - 1){
            margin-left: 8%;
            margin-right: 8%;
        }
        @media (max-width:$tablet-width-m - 1) {
           margin-left: 12px;
           margin-right: 12px;
        }
    }
}

.client__desc {
    display: flex;
    justify-content: space-between;
    margin: 41px 0 0 0;
    @media (max-width:$desktop-width-s - 1){
		flex-direction: column;
        margin: 24px 0 0 0;
	}
    @media (max-width:$tablet-width-m - 1) {
        margin: 12px 0 0 0;
        flex-direction: column;
    }
    p {
        @include fs-lh(18px, 21px);
        font-weight: 500;
        color: $color__theme_black;
    }
}

.client__col {
    @include flex-width(100%/12*5);
    //margin-right: 50px;
    @media (max-width:$desktop-width-s - 1){
		@include flex-width(100%/8*8);
	}
    @media (max-width:$tablet-width-m - 1) {
        @include flex-width(100%/8*8);
    }
}

.client__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px;
    @media (max-width:$desktop-width-s - 1) {
		grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
        margin: 40px 0 0 0;
	}
    @media (max-width:$tablet-width-m - 1) {
		grid-template-columns: 1fr 1fr ;
        gap: 12px;
	}
    @media (max-width:$tablet-width-s - 1) {
		grid-template-columns: 1fr 1fr;
        gap: 12px;
        margin: 0;
	}
}

.client__item {
    display: block;
    @media (max-width:$desktop-width-s - 1) {
        display: inline-grid;
        justify-content: center;
	}
    @media (max-width:$tablet-width-s - 1) {
		display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
        }
	}
}