.content-size-calc {
    width: calc(83.3333% - 40px);
    margin-left: calc(8.3333% + 20px);

    @media (max-width:$desktop-width-m - 1) {
        width: calc(100% - 80px);
        margin-left: 40px;
    }

    @media (max-width: $tablet-width-m - 1) {
        width: calc(100% - 24px);
        margin-left: 12px;
    }
}

.init-section-bg {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: -15%;
        height: 100%;
        width: 100%;
        background: url('../img/bg/bg-init.png') center / 100% 100% no-repeat;
        z-index: -1;
    }

    @media (max-width: $desktop-width-m) {
        &::before {
            top: -10%;
        }
    }

    @media (max-width: $tablet-width-l) {
        &::before {
            background-position: center bottom;
            background-size: cover;
        }
    }

    @media (max-width: $tablet-width-m) {
        &::before {
            top: -5%;
        }
    }

    &.init-section-bg_reverse {
        &:before {
            transform: rotateY(180deg);
        }
    }

    &.init-section-bg_header {
        margin-top: -150px;
        padding-top: 150px;
    }
}

.init-header-section__title{
    padding: 110px 0 10px;
    font-size: 96px;
    line-height: 1 !important;
    color: #fff;
    font-weight: 600;
    margin-left: -7px;

    @media (max-width: $tablet-width-m - 1) {
        font-size: 40px;
        margin-left: 0;
    }

    @media (max-width: $tablet-width-s - 1) {
        padding-top: 50px;
    }
}

.project__nav {
    display: flex;

    @media (max-width:$tablet-width-s - 1) {
        justify-content: space-between;
    }
}

.project__nav-link {
    font-size: 18px;
    color: #fff;

    &:hover {
        color: #fff;
        text-decoration: underline !important; 
    }

    &:not(&:first-child) {
        margin-left: 30px;
    }

    @media (max-width:$tablet-width-s - 1) {
        font-size: 14px;
        
        &:not(&:first-child) {
            margin-left: 0;
        }
    }
}

.initis-descrit {
    max-width: 400px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    padding: 60px 0 250px 0;
}

.inits-section-progress-proj {
    padding-bottom: 50px;
    overflow: hidden;
}

.project-progress-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-progress-cicles {
    height: 519px;
    width: 519px;

    @media (max-width: $tablet-width-m - 1) {
        width: 336px;
        height: 336px;
    }

    @media (max-width: $mobile-width + 40) {
        width: 306px;
        height: 306px;
    }
}

.cicle-spacetwin {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    transform: rotate(-70deg);
}

.cicle-svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.circle-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(70deg);

    .title {
        background: #000;
        clip-path: polygon(10% 0%,90% 0%,100% 100%,0% 100%);
        font-size: 18px;
        color: #fff;
        padding: 15px 10px;
        font-size: 18px;
        max-width: 160px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;

        a {
            cursor: pointer;
            transition: .3s ease;

            &:hover {
                color: #21A3DC;
            }
        }


        @media (max-width:$tablet-width-m - 1) {
            font-size: 14px;
            max-width: 100px;
            padding: 10px;
        }
    }
}

.init-project {
    color: #fff;
}

.init-project__row {
    margin-bottom: 40px;

    &.init-project__proj-name-bg {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }

    &.init-project__row_margin_bottom {
        margin-bottom: 80px;

        @media (max-width:$tablet-width-m - 1) {
            margin-bottom: 48px;
        }
    }

    @media (min-width:$tablet-width-l) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
    }

    @media (max-width:$tablet-width-l - 1) {
        margin-bottom: 60px;

        &.init-project__proj-name-bg {
            &:before {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    @media (max-width:$tablet-width-m - 1) {
        margin-bottom: 24px;
    }  
}

.init-project__col {
    box-sizing: border-box;

    @media (min-width:$tablet-width-l) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.init-project__col_side_left {
        @media (min-width:$tablet-width-l) {
            @include fw(100%/10*3);
            margin-right: (100%/10);
        }
    }

    &.init-project__col_side_right {
        @media (min-width:$tablet-width-l) {
            @include fw(100%/10*6);
        }
    }

    &.init-project__col_type_desc {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width:$tablet-width-l - 1) {
            width: (100%/8*4);
            margin-bottom: 60px;
        }

        @media (max-width:$tablet-width-m - 1) {
            width: 100%;
        }  
    }

    &.init-project__col_border_top {
        @include before;
        position: static;

        &:before {
            @include pos(30px,10px);
            right: 10px;
            height: 3px;
            background: linear-gradient(90deg, #283584 0%, rgba(33, 163, 220, .6) 100%);

            @media (max-width:$tablet-width-l - 1) {
                left: 0;
                right: 0;
            }

            @media (max-width:$tablet-width-m - 1) {
                top: 25px;
            }
        }
    }

    &.init-project__col_pos_rel {
        @media (max-width:$tablet-width-l - 1) {
            position: relative;
        }
    }
}

.init-project__title {
    @include fs-fw(36px,500);
    line-height: 44px;
    color: #fff;

    @media (max-width:$tablet-width-l - 1) {
        margin: 0 0 20px;
    }   
}

.init-project__subtitle {
    @include fs-fw(24px,500);
    line-height: 36px;

    @media (max-width:$tablet-width-m - 1) {
        @include fs-fw(16px,600);
        line-height: 20px;
    }

    &.init-project__subtitle_margin_top {
        @media (min-width:$tablet-width-l) {
            margin-top: 50px;
        }
    }

    &.init-project__subtitle_margin_bottom {
        @media (max-width:$tablet-width-l - 1) {
            margin-bottom: 20px;
        }   
    }

    &.init-project__subtitle_fs_lg {
        @media (max-width:$tablet-width-m - 1) {
            @include fs-fw(24px,500);
            line-height: 36px;
        } 
    }
}

.init-project__desc {
    @include fs-fw(16px,500);
    line-height: 24px;
    margin-bottom: 40px;

    &.init-project__desc_mb_min {
        margin-bottom: 20px;

        @media (max-width:$tablet-width-m - 1) {
            margin-bottom: 12px;
        } 
    }

    @media (max-width:$tablet-width-l - 1) {
        margin-bottom: 24px;
    }

    @media (max-width:$tablet-width-m - 1) {
        font-size: 14px;
        margin-bottom: 12px;
    }  
}

.init-project__readmore {
    @include fs-fw(20px,500);
    display: flex;
    align-items: center;
    color: #e3e3e3 !important;

    &:hover,
    &:focus {
        color: #e3e3e3 !important;
    }
}

.init-project__readmore-icon {
    @include fw(16px);
    @include size(16px);
    margin: 3px 0 0 4px;
}

.init-project__article {
    padding: 20px;
    box-sizing: border-box;
    color: #000;
    background-color: #fff;

    @media (max-width:$tablet-width-m - 1) {
        padding: 12px;
    } 
}

.init-project__pictures {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;

    @media (max-width:$tablet-width-m - 1) {
        flex-wrap: wrap;
    } 
}

.init-project__pic {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    @media (max-width: $tablet-width-l) {
        width: 50%;
    }

    @media (max-width:$tablet-width-m - 1) {
        margin-bottom: 12px;
    }

    &:last-child {
        @media (max-width:$tablet-width-m - 1) {
            margin-bottom: 0;
        }
    }

    .img-fluid {
        width: 100%;
    }

    @media (max-width: $mobile-width + 80) {
        width: 100%;

        .img-fluid {
            width: 100%;
        }
    }
}

.init-project__top {
    margin-bottom: 20px;

    @media (max-width:$tablet-width-m - 1) {
        margin-bottom: 12px;
    } 
}

.init-project__date {
    @include fs-fw(16px,500);
    line-height: 24px;
    margin-bottom: 20px;
    color:rgba(0, 0, 0, .6);

    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(14px,20px);
        margin-bottom: 12px;
    } 
}

.init-project__link {
    @include fs-fw(16px,500);
    line-height: 24px;
    text-decoration: underline;
    color: #283584 !important;

    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(14px,20px);
    } 

    &:hover,
    &:focus {
        color: #283584 !important;
    }
}

.init-project__meta {
    display: flex;
    margin: 0 0 20px;

    @media (min-width:$tablet-width-m) {
        flex-wrap: wrap;
        align-items: center;
    }

    @media (max-width:$tablet-width-m - 1) {
        flex-direction: column;
    }
}

.init-project__meta-item {
    @include fs-fw(18px,600);
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 18px 0 0;
    color: #fff;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width:$tablet-width-m - 1) {
        margin: 0 0 12px;
    }
}

.init-project__meta-title {
    margin: 0 4px 0 0;
    font-weight: 500;
    color: rgba(255,255,255,.8);
}

.init-project__bar {
    @include size(110px,10px);
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #283584;
}

.init-project__bar-progress {
    height: 100%;
    width: 0%;
    background: linear-gradient(90deg, #283584 0%, rgba(33, 163, 220, 0.6) 100%);
}

.init-project__proj-name-bg {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 40%;
        width: 80%;
        z-index: -1;
    }

    &.init-bg-oculus {
        &:before {
            background: url('../img/bg/inits-bg-Oculus.png') left top/ contain no-repeat;
        }
    }

    &.init-bg-cryptosat {
        &:before {
            background: url('../img/bg/inits-bg-CryptoSat.png') left top/ contain no-repeat;
        }
    }

    &.init-bg-eduxsat {
        &:before {
            background: url('../img/bg/inits-bg-EduXSat.png') left top/ contain no-repeat;
        }
    }

    &.init-bg-spacetwin {
        &:before {
            background: url('../img/bg/inits-bg-SpaceTwin.png') left top/ contain no-repeat;
        }
    }

    @media (max-width:$tablet-width-l - 1) {
        &:before {
            top: -23%;
        }
    }
}

.init-project__cover {
    width: 100%;
    padding: 1px;
    box-sizing: border-box;
    box-shadow: 4px 4px 30px 0 rgba(255, 255, 255, .2);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

    &.init-project__proj-name-bg {
        &:before {
            opacity: 0;
        }
    }

    .img-fluid {
        width: 100%;
    }

    @media (max-width: $tablet-width-l) {
        width: 100%;

        .img-fluid {
            width: 100%;
        }

        &.init-project__proj-name-bg {
            &:before {
                opacity: 1;
            }
        }
    }
}

.init-project__material-block {
    .material-block__subtitle {
        font-size: 24px;
        list-style: 29px;
    }

    .material-block__main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @media (max-width: $desktop-width-s) {
            grid-template-columns: repeat(1, 1fr);

            .material-block__item {
                max-width: 70%;
            }
        }

        @media (max-width: $tablet-width-l) {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 20px;

            .material-block__item {
                max-width: 100%;
            }
        }

        @media (max-width: $tablet-width-m - 100) {
            grid-template-columns: repeat(1, 1fr);
        }

        .material-block__item {
            display: flex;
            padding: 20px;
            background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.12) 28.69%, rgba(255, 255, 255, 0) 100%);
            backdrop-filter: blur(70px);
            border-radius: 16px;

            .material-block__file {
                min-width: 35px;
                height: 66px;
                margin-right: 20px;
                background: url('../img/icons/icon-pdf-light.svg') center / contain no-repeat;
            }

            .material-block__info {
                .info__title {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    text-align: left;
                    margin-bottom: 10px;
                }

                .info__link {
                    font-size: 12px;
                    color: #fff;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.init-project__slides-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 15px 0;
    margin: 0 0 60px;

    @media (max-width:$tablet-width-m - 1) {
        margin: 0 0 48px;
    }

}

.init-project__bullet {
    @include before;
    @include fs-fw(24px,500);
    @include size(30px);
    flex: 0 0 30px;
    margin: 0 75px 0 0;
    line-height: 36px;
    color: #283584;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #fff;

    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(16px,24px);
        @include size(25px);
        flex: 0 0 25px;
        margin: 0 36px 0 0;
    }


    @media (max-width: $mobile-width + 80) {
        @include size(20px);
        flex: 0 0 20px;
        margin-right: 27px;
    }

    &:before {
        @include pos(-50%,-50%);
        @include size(200%);
        border-radius: 50%;
        border: 3px solid #fff;
        box-sizing: border-box;
    }

    &:last-child {
        margin-right: 0;
    }

    &[class*="active"] {
        box-shadow: 0px -4px 20px #5627DB, 4px 4px 4px rgba(40, 53, 132, 0.5), 0px 4px 8px #277ADB;
    }
}

.custom-nav {
    .custom-nav__item {
        width: 100%;
        height: 20px;
        margin: 0 0 18px;
        padding: 6px;
        display: block;
        position: relative;
        box-sizing: border-box;

        &.current {
            .custom-nav__bullet::before {
                background: rgba(255, 255, 255, 0.5);
            }
        }

        .custom-nav__bullet {
            position: relative;
            width: 8px;
            height: 8px;
            display: block;
            border-radius: 50%;
            background: #fff;
            margin: auto;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -4px;
                margin: auto;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: transparent;
                transition: .4s ease;
            }

            &:hover {
                &::before {
                    background: rgba(255, 255, 255, 0.5);
                }

                & + .custom-nav__title {
                    transform: translate(0,0);
                    opacity: 1;
                }
            }
        }

        .custom-nav__title {
            font-size: 11px;
            line-height: 13px;
            padding: 3px 5px;
            transform: translate(0,50px);
            position: absolute;
            bottom: 28px;
            left: 0;
            width: 100%;
            z-index: 99;
            opacity: 0;
            border-radius: 2px;
            box-sizing: border-box;
            pointer-events: none;
            text-align: center;
            text-transform: lowercase;
            background: #000;
            border: 1px solid #fff;
            color: #fff;
            transition: transform .5s ease 0s,opacity .3s ease 0s;

            &::before {
                content: '';
                height: 8px;
                width: 8px;
                background-color: #000;
                border-width: 1px;
                border-style: solid;
                border-color: #fff #fff transparent transparent;
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                margin: auto;
                transform: rotate(135deg);
            }


            // &::after {
            //     content: '';
            //     border-style: solid;
            //     position: absolute;
            //     left: 50%;
            //     border-width: 6px;
            //     border-color: #000 transparent transparent;
            //     bottom: -12px;
            //     margin: 0 0 0 -6px;
            // }
        }
    }
}