html {
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	font: $font-size__default $font-family__default;
	line-height: $line-height__default;
	color: $color__font;
	background: $color__theme_black;
	overflow-x: hidden;
	overflow-y: visible;
}

.main-page {
	border:0;
	width:100%;
	height:100vh;
}

img {
	height: auto;
	vertical-align: top;
}

.img-fluid {
	max-width: 100%;
}

.img-cover {
	@include size(100%);
	object-fit: cover;
}

.icon-fluid {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.img-rounded {
	border-radius: 8px;
}

.cursor-default {
	cursor: default !important;
}

h1,h2,h3,h4,h5,h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: inherit;
}

p {
	margin-top: 0;
}

a {
	@include link-reset;
	
	&:hover {
		@include link-reset;
	}
	
	&:focus {
		@include link-reset;
	}
}

button,
button:focus {
	@include outline-none;
}

input,
input:focus {
	@include outline-none;
}

ul {
	@include list-unstyled;
}

ol {
	@include list-unstyled;
}

input[type="checkbox"],
input[type="radio"] {
	@include visually-hidden;
}

.w-100 {
	width: 100% !important;
	max-width: 100% !important;
}

.h-100 {
	height: 100%;
}

.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

/* display */
.d-block {
	display: block;
}

.d-none {
	display: none;
}

.inline-block {
	@include inline-block;
}

.d-flex {
	@include d-flex;
	@include flex-wrap;
}

/* flex */
.flex-nowrap {
	@include flex-nowrap;
}

.flex-column {
	@include flex-column;
}

.flex-grow-1 {
	@include flex-grow-1;
}

/* justify-content */
.jc-start {
	@include justify-content-start;
}

.jc-end {
	@include justify-content-end;
}

.jc-center {
	@include justify-content-center;
}

.jc-between {
	@include justify-content-between;
}

.jc-between-mobile {
	@media (max-width:$tablet-width-m - 1) {
		@include justify-content-between;
	}
}

/* align-items */
.ai-start {
	@include align-items-start;
}

.ai-end {
	@include align-items-end;
}

.ai-center {
	@include align-items-center;
}

.ai-stretch {
	@include align-items-stretch;
}

/* other */
.pos-rel {
	position: relative;
}

.zi-2 {
	@include zi-2;
}

.zi-1 {
	position: relative;
	z-index: 1;
}

.zi-auto {
	z-index: auto !important;
}

.overflow-hidden {
	overflow: hidden;
}

.t-upp {
	text-transform: uppercase !important;
}

.t-left {
	text-align: left !important;
}

.t-center {
	text-align: center;
}

.t-right {
	text-align: right;
}

.t-underline {
	text-decoration: underline;
}

.fw-bold {
	font-weight: bold;
}

.index {
	@media (min-width:$desktop-width-m) {
		height: 100vh;
		overflow: hidden;
	}
}

.w-auto {
	width: auto;
}

.fw-100 {
	@include flex-width(100%);
}

//color link
.dark-blue {
	color: #283584!important;
	&:hover {
		text-decoration: none!important;
	}
}

/* embed */
.embed__container {
	width: 100%;
	display: block;
	margin: 0 0 24px;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	
	&:before {
		content: '';
		display: block;
		padding-top: 56.25%;
	}
}

.embed__video {
	@include pos(0,0);
	@include size(100%,100%);
	bottom: 0;
	border: 0;
}

.embed__legend {
	@include fs-lh(20px,24px);
	font-weight: 600;

	&.embed__legend_pl {
		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
			padding-left: (100%/6);
		}
	}
}

/* info */
.info {
	@include fs-lh(16px,20px);
	font-weight: 600;
	color: $color__theme_white;	

	a {
		color: #21A3DC;
		text-decoration: underline;

		&:hover {
			color: #21A3DC;
			text-decoration: underline;
		}
	}
}

/* partners */
.partners {
	@include d-flex;
	@include flex-wrap;
	@include align-items-center;
	@include justify-content-between;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 46px;
}

.partners__item {
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 0 24px;
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		@include flex-width(100%/4);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%/2);
	}
}

/* highlighted */
.highlighted {
	background: #21A3DC;
	color: $color__theme_white;

	&.highlighted_black {
		background: $color__theme_black;
	}

	&.highlighted_white {
		color: $color__theme_black;
		background: $color__theme_white;
	}
}

.footer_main-dn {
	display: none;
}

.scroll__down {
	position: absolute;
	bottom: 15%;
	left: -15%;
	z-index: 10;
	@media (max-width: $desktop-width-xl) {
		bottom: 13.5%;
	}
	transform-origin: left bottom;
	span {
		display: block;
		//position: absolute;
		//right: 35%;
		//top: -50%;
		transform: rotate(-90deg);
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		color: $color__theme_white;
	}
	.icon-fluid {
		position: relative;
		top: 100px;
		margin-left: 2px;
		animation: arrow-down 2s infinite;
		@media (max-width: $desktop-width-xl) {
			top: 82px;
		}
	}
}
.scroll__name-opacity {
	margin-right: 20px;
	color: rgba(255, 255, 255, 0.25)!important;
	font-style: normal;

	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
    position: absolute;
    bottom: -27px;
    right: 54px;
	animation: 2s ease 0s infinite normal none running scroll-down;
	@media (max-width: $desktop-width-xl - 1) {
		right: 38px;
	}
	@keyframes scroll-down {
		0%{
			opacity: 0;
			transform: rotate(270deg) translate(15px,0);
		}
		50%{
			opacity: 1;
		}
		100%{
			opacity: 0;
			transform: rotate(270deg) translate(-15px,0);
		}
	}
}
@keyframes arrow-down {
    0%{
        opacity: 0;
        transform: rotate(0deg) translate(0, -15px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(0deg) translate(0,15px);
    }
}
.scroll__down  {
	display: none;
}
.stage-1 .scroll__down{
	display: flex!important;
	flex-direction: column;
}
.stage-2 .scroll__down{
	display: flex!important;
	flex-direction: column;
}
.stage-3 .scroll__down{
	display: flex!important;
	flex-direction: column;
}
.stage-4 .scroll__down {
	display: none!important;
}
.stage-4 .fixed__right_dn {
	display: none!important;
}

.stage-4 .fixed.fixed__left {
    //height: 82%;
//	top: 50px;
}

.fixed__nav-item-four {
	display: none!important;
}
.stage-1 .fixed__nav-item-four {
	display: block!important;
}
.stage-2 .fixed__nav-item-four {
	display: block!important;
}
.stage-3 .fixed__nav-item-four {
	display: block!important;
}
.stage-4 .fixed__nav-item-four {
	display: block!important;
}

.stage-1 .fixed__nav-item_scroll {
	display: none;
}

.stage-2 .fixed__nav-item_scroll {
	display: none;
}

.stage-3 .fixed__nav-item_scroll {
	display: none;
}

.stage-4 .fixed__nav-item_scroll {
	display: none;
}

.gmnoprint {
	right: 100px!important;
}
.gmnoprint .gm-bundled-control {
	right: 100px!important
}
.gmnoprint .gm-bundled-control .gm-bundled-control-on-bottom {
	right: 100px!important
}

.stage-1 .bg{
	display: none;
}
.stage-2 .bg{
	display: none;
}
.stage-3 .bg{
	display: none;
}
.stage-4 .bg{
	display: none;
}

.stage-2 .main-btn  {
	opacity: 1!important;
    pointer-events: all!important;
}

.stage-3 .main-btn {
	opacity: 1!important;
    pointer-events: all!important;
}

.stage-4 .main-btn {
	opacity: 1!important;
    pointer-events: all!important;
}

.main-btn {
	opacity: 0;
    border: 0;
    outline: 0;
    padding: 0;
	position: absolute;
	right: 15px;
	bottom: 49%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 1030;
    border-radius: 11px;
	background: #fff;
    box-shadow: 0 0 8px 0 #00000040;
	transition: all .3s ease;
	a {
		background-image: url('../img/icons/icon-up.svg');
		background-repeat: no-repeat;
		background-position: center;
		color: #666;
		display: block;
		width: 100%;
		height: 100%;
		transition: all .3s ease;
		-webkit-transition:  all .3s ease;
	}
	@media (max-width:$desktop-width-m - 1) {
		display: none;
	}
}

.actions {
	display: flex;
	justify-content: space-between;
	padding: 0 0 28px 0;
	@media (max-width:$desktop-width-s - 1){
		padding: 54px 0 40px 0;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding: 32px 0 28px 0;
	}

	.actions__menu {
		
	}

	.actions__item {
		display: block;
		padding: 0 0 12px 0;
		@include fs-lh(14px, 17px);
        font-weight: 500;
        color: $color__theme_white;
		opacity: 0.5;
	}

}

.product {
	padding-bottom: 1px;
	position: relative;
	z-index: 15;
	padding-top: 64px;
	background: linear-gradient(180deg, #000 2.86%, #21A3DC 94.61%);

	@media (max-width:$desktop-width-s - 1){
		padding-top: 32px;
	}
	@media (min-width:$tablet-width-m ) and (max-width:$desktop-width-m - 1){
		margin-bottom: -136px;
	}
	@media (max-width:$tablet-width-m - 1){
		background: #000;
	}

	.title__h1 {
		text-align: left;
		margin-left: 9%;
		margin-bottom: 40px;
		@media (max-width:$tablet-width-l - 1){
			margin-left: 5%;
		}
		
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(35px, 44px);
			margin-bottom: 111px;
			margin-left: 0;
			padding-left: 12px;
		}
	}
	.lines {
		position: relative;
		top: -120px;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 3px;
			background: #fff;
			transform: rotate(-3deg);
			@media (max-width:$tablet-width-m - 1){
				top: -8px;
			}
		}
		.features__icon {
			top: -30px;
			z-index: 10;

			@media (max-width:$desktop-width-s - 1){
				left: 50%;
			}
			@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
				left: 50%;
			}
			@media (max-width:$tablet-width-m - 1){
				left: 44%;
			}
			@media (max-width:$tablet-width-s - 1){
				left: 42%;
			}
			@media (max-width:326px){
				left: 44%;
			}
		}
		.features__icon:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 16px;
			height: 16px;
			margin: -8px 0 0 -8px;
			border-radius: 50%;
			background: #fff;
		}
		.features__icon:before {
			content: '';
			position: absolute;
			border-radius: 50%;
			background: red;
		}
		.lines__min {
			position: absolute;
			left: 50%;
			height: 2px;
			&::before {
				content: '';
				position: absolute;
				z-index: 15;
				left: -125px;
				width: 250px;
				height: 3px;
				background: #fff;
				transform: rotate(90deg);
				@media (max-width:$desktop-width-s - 1){
					left: -90px;
				}
				@media (max-width:$tablet-width-m  - 1){
					left: -146px;
				}
				@media (max-width:602px){
					left: -136px;
				}
				@media (max-width:530px){
					left: -133px;
				}
				@media (max-width:470px){
					left: -138px;
				}
				@media (max-width:414px){
					left: -135px;
				}
				@media (max-width:390px){
					left: -133px;
				}
				@media (max-width:388px){
					left: -133px;
				}
				@media (max-width:324px){
					left: -120px;
				}
			}
		
		}
	}

	.product__bg {
		position: relative;
		width: 100%;
		z-index: 10;
		&::before {
			content: '';
			position: absolute;
			top: -342px;
			bottom: 0;
			left: 0;
			height: 180px;
			width: 100%;
			z-index: 10;
			// background: #000;
			transform: matrix(1,-.05,0,1,0,0);
		}
		@media (max-width:$tablet-width-m - 1){
			display: none;
		}
	}
	
	.content__bottom {
		@media (min-width:$tablet-width-m - 1){
			bottom: -80px!important;
		}
		&::before {
			transform: matrix(1,-.04,0,1,0,0);
		}
	}
}


.slides  { 
	width: 100%;
	height: 330px;
	@media (max-width:$desktop-width-s - 1){
		width: fit-content;
	}
	@media (max-width:$tablet-width-m - 1) {
		height: auto;
	}
	&.slides_item {
		padding: 38px 40px 32px 38px;
		background: #FFFFFF;
		border: 2px solid #000000;
		box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
		border-radius: 16px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width:$tablet-width-l - 1){
			padding: 20px 0 20px 20px;
		}

		@media (max-width:$tablet-width-m - 1){
			padding: 16px 12px 0 12px; 
			box-shadow: none;
			border-radius: 0;
			border: none;
			width: 90%;
		}
	}

	.slides__title {
		@include fs-lh(26px, 31px);
		font-weight: 700;
		&:hover {
			color: #21a3dc;
		}
	}

	.slides__desc {
		width: 60%;
		margin: 24px 0 0 0;
		@include fs-lh(14px, 17px);
		font-weight: 500;
		@media (max-width:$desktop-width-s - 1){
			width: 80%;
		}
		@media (max-width:$tablet-width-l - 1){
			width: 100%;
		}
		@media (max-width:$tablet-width-m - 1)  {
			margin: 12px 0 0 0;
		}
	}

	.btn_slides {
		margin: 16px 0 0 0;
		display: block;
		text-align: center;
		@include fs-lh(20px, 24px);
		font-weight: 700;
		height: 23px;
		color: #fff;
		background: #21a3dc;
		border-radius: 16px;
		max-width: 221px;
		padding: 2px 23px 6px 23px; 
		@media (max-width:$tablet-width-m - 1) {
			margin: 12px 0 0 0;
			padding: 2px 23px 4px 23px;
			height: auto;
		}
	}
	.slides__top {
		@media (max-width:$tablet-width-l - 1){
			width: 70%;
		}
		@media (max-width:$tablet-width-m - 1){
			width: 100%;
		}
	}
	.slides__bottom {
		max-width: 600px;
		
		@media (max-width:$desktop-width-between-m - 1) {
			max-width: 500px;
		}
		
		@media (max-width:$tablet-width-l - 1) {
			max-width: 300px;
		}
		@media (max-width:$tablet-width-m - 1) {
			margin-top: 34px;
		}
	}
	.slides__icons {
		position: absolute;
		right: 40px;
		bottom: 49px;
	}
	.slides__tags {
		display: flex;
		flex-wrap: wrap;

		.slides__tag {
			display: block;
			margin-right: 20px;
			
			color: #395C91;
			@include fs-lh(14px, 17px);
			font-weight: 700;

			@media (max-width:$tablet-width-m - 1){
				margin-bottom: 15px;
			}

		}
	}
}

//swiper cards effect 
.createSwiper {
	@media (max-width:$tablet-width-m - 1){
		display: none;
	}
	.swiper-pagination {
		margin-top: 16px;
		display: flex;
		justify-content: center;
		.swiper-pagination-bullet {
			cursor: pointer;
			margin-right: 16px;
			width: 12px;
			height: 12px;
            border-radius: 50%;
			background: rgba(255, 255, 255, 0.65);
			box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
		}

		.swiper-pagination-bullet-active {
			background: #fff;
		}
	}
}

#founders{
	@media (max-width:$tablet-width-s - 1) {
		padding-top: 30px;
	}
}