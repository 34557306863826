.container {
	@include container;
}

.row {
	@include d-flex;
	@include flex-wrap;
}

[class^="col-"] {
	@include flex-width(100%);
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	box-sizing: border-box;
}

@for $i from 1 through 12 {	
	.col-#{$i} {
		@include flex-width(100%/12*$i);
	}

	.ml-#{$i} {
		margin-left: 100%/12*$i;
	}

	.ml-0 {
		margin-left: 0;
	}

	.order-#{$i} {
		-ms-flex-order: #{$i};
		order: #{$i};
	}

	.w-#{$i} {
		width: 100%/12*$i;
	}
}

@media (min-width:$tablet-width-s) {
	@for $i from 1 through 12 {	
		.col-sm-#{$i} {
			@include flex-width(100%/12*$i);
		}

		.ml-sm-#{$i} {
			margin-left: 100%/12*$i;
		}

		.ml-sm-0 {
			margin-left: 0;
		}

		.order-sm-#{$i} {
			-ms-flex-order: #{$i};
			order: #{$i};
		}

		.w-sm-#{$i} {
			width: 100%/12*$i;
		}
		/*
		.flex-sm-row {
			@include flex-row;
		}

		.flex-sm-column {
			@include flex-column;
		}

		.ai-sm-center {
			@include align-items-start;
		}

		.ai-sm-center {
			@include align-items-center;
		}

		.jc-sm-start {
			@include justify-content-start;
		}

		.jc-sm-end {
			@include justify-content-end;
		}
		*/
		.d-sm-block {
			display: block;
		}

		.d-sm-none {
			display: none;
		}

		.color-sm-white {
			color: #fff;
		}
	}
}

@media (min-width:$tablet-width-m) {
	@for $i from 1 through 12 {	
		.col-md-#{$i} {
			@include flex-width(100%/12*$i);
		}

		.ml-md-#{$i} {
			margin-left: 100%/12*$i;
		}

		.ml-md-0 {
			margin-left: 0;
		}

		.order-md-#{$i} {
			-ms-flex-order: #{$i};
			order: #{$i};
		}

		.w-md-#{$i} {
			width: 100%/12*$i;
		}
		/*
		.flex-md-row {
			@include flex-row;
		}

		.flex-md-column {
			@include flex-column;
		}

		.ai-md-start {
			@include align-items-start;
		}

		.ai-md-center {
			@include align-items-center;
		}

		.jc-md-start {
			@include justify-content-start;
		}

		.jc-md-end {
			@include justify-content-end;
		}
		*/
		.d-md-block {
			display: block;
		}

		.d-md-none {
			display: none;
		}

		.color-md-white {
			color: #fff;
		}
	}
}

@media (min-width:$tablet-width-l) {
	@for $i from 1 through 12 {	
		.col-lg-#{$i} {
			@include flex-width(100%/12*$i);
		}

		.ml-lg-#{$i} {
			margin-left: 100%/12*$i;
		}

		.ml-lg-0 {
			margin-left: 0;
		}

		.order-lg-#{$i} {
			-ms-flex-order: #{$i};
			order: #{$i};
		}

		.w-lg-#{$i} {
			width: 100%/12*$i;
		}
		/*
		.flex-lg-row {
			@include flex-row;
		}

		.flex-lg-column {
			@include flex-column;
		}

		.ai-lg-center {
			@include align-items-start;
		}

		.ai-lg-center {
			@include align-items-center;
		}

		.jc-lg-start {
			@include justify-content-start;
		}

		.jc-lg-end {
			@include justify-content-end;
		}
		*/
		.d-lg-block {
			display: block;
		}

		.d-lg-none {
			display: none;
		}

		.color-lg-white {
			color: #fff;
		}
	}
}

@media (min-width:$desktop-width-s) {
	@for $i from 1 through 12 {	
		.col-xl-#{$i} {
			@include flex-width(100%/12*$i);
		}

		.ml-xl-#{$i} {
			margin-left: 100%/12*$i;
		}

		.ml-xl-0 {
			margin-left: 0;
		}

		.order-xl-#{$i} {
			-ms-flex-order: #{$i};
			order: #{$i};
		}

		.w-xl-#{$i} {
			width: 100%/12*$i;
		}
		/*
		.flex-xl-row {
			@include flex-row;
		}

		.flex-xl-column {
			@include flex-column;
		}

		.ai-xl-center {
			@include align-items-start;
		}

		.ai-xl-center {
			@include align-items-center;
		}

		.jc-xl-start {
			@include justify-content-start;
		}

		.jc-xl-end {
			@include justify-content-end;
		}
		*/
		.d-xl-block {
			display: block;
		}

		.d-xl-none {
			display: none;
		}

		.color-xl-white {
			color: #fff;
		}
	}
}

@media (min-width:$desktop-width-l) {
	@for $i from 1 through 12 {	
		.col-xxl-#{$i} {
			@include flex-width(100%/12*$i);
		}

		.ml-xxl-#{$i} {
			margin-left: 100%/12*$i;
		}

		.ml-xxl-0 {
			margin-left: 0;
		}

		.order-xxl-#{$i} {
			-ms-flex-order: #{$i};
			order: #{$i};
		}

		.w-xxl-#{$i} {
			width: 100%/12*$i;
		}
		/*
		.flex-xxl-row {
			@include flex-row;
		}

		.flex-xxl-column {
			@include flex-column;
		}

		.ai-xxl-center {
			@include align-items-start;
		}

		.ai-xxl-center {
			@include align-items-center;
		}

		.jc-xxl-start {
			@include justify-content-start;
		}

		.jc-xxl-end {
			@include justify-content-end;
		}
		*/
		.d-xxl-block {
			display: block;
		}

		.d-xxl-none {
			display: none;
		}

		.color-xxl-white {
			color: #fff;
		}
	}
  }

/* test */
.col-8-1 {
	@include flex-width(100%/8);
}