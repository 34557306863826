.text {
	&.text_flex {
		margin-bottom: 46px;

		@media (min-width:$tablet-width-m) {
			@include d-flex;
			@include flex-wrap;
			margin-left: -10px;
			margin-right: -10px;
		}
	}

	p {
		font-weight: 500;
		line-height: 22px;
	}
}

.text__col {
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		@include flex-width(100%/2);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include flex-width(100%);
	}

	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	&.text__col_flex {
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include d-flex;
			@include flex-wrap;
			@include align-items-center;
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: 120px;
		}
	}
}

.text__header {
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		@include flex-width(100%/8*7);
		margin-bottom: 72px;
		padding-left: 10px;
		padding-right: 10px;
		text-align: right;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-bottom: 32px;
	}
}

.text__title {
	font-weight: 800;

	@media (min-width:$tablet-width-m) {
		@include fs-lh(36px,44px);
		margin: 0 0 16px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(26px,32px);
		margin: 0 0 12px;
	}
}

.text__intro {
	@include fs-lh(20px,24px);
	font-weight: 600;

	a {
		color: #21A3DC;
		text-decoration: underline;
	}
}