.taglist {
	@include zi-2;

	&.taglist_mb {
		@media (min-width:$desktop-width-s) {
			margin-bottom: 70px;
			margin-top: 40px;
		}

		@media (max-width:$desktop-width-s - 1) {
			margin-bottom: 40px;
		}
	}

	&.taglist_content {
		@media (max-width:$desktop-width-m - 1) {
			display: none;
		}

		.taglist__item {
			@media (min-width:$desktop-width-m) {
				margin: 0 24px 24px 0;
			}
		}
	}

	&.taglist_section {
		@media (min-width:$desktop-width-m) {
			display: none;
		}

		@media (max-width:$desktop-width-m - 1) {
			margin-bottom: 34px;
			padding-left: 10px;
			text-align: right;
			box-sizing: border-box;
		}

		@media (max-width:$tablet-width-l - 1) {
			margin-top: -30px;
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
			width: (100%/8*3);
			margin-left: (100%/8*5);
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			width: (100%/8*4);
			margin-left: (100%/8*4);
		}

		@media (max-width:$tablet-width-m - 1) {
			padding: 0;
		}
	}
}

.taglist__item {
	@include inline-block;
	@include default-transition;
	@include fs-lh(14px,17px);
	padding: 2px 6px;
	color: $color__theme_white;
	border-radius: 6px;
	//background: #395c91;
	background: rgba(83, 89, 93, 0.4);

	@media (min-width:$desktop-width-m) {
		margin: 0 16px 16px 0;
	}

	@media (max-width:$desktop-width-m - 1) {
		margin: 0 14px 14px 0;
	}
	
	&:before {
		content: '#';
	}
		
	&:hover,
	&:focus {
		color: $color__theme_white;
		background: rgba(83, 89, 93, 0.8);
	}
	
	&.taglist__item_bold {
		color: #283584;
		font-weight: bold;
		background: rgba(0,0,0,0);
		
		&:hover,
		&:focus {
			color: #283584;
		}
	}
	
	&.taglist__item_colored {
		background: #21a3dc;
		
		&:hover {
			background: #395c91;
		}
	}
	
	&.taglist__item_light {
		background: rgba(57, 92, 145, 0.5);
		
		&:hover {
			background: rgba(57, 92, 145, 0.5);
		}
	}
}

.taglist_agancy {
	margin: 18px 0 0 0;
	@media (min-width:$desktop-width-m) {
		margin: 16px 0 0 0;
	}
	.taglist__item {
		margin: 0 12px 5px 0;
		@media (max-width:$tablet-width-m - 1) {
			border-radius: 0;
			margin: 0 12px 12px 0;
		}
	}
}