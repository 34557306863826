.section__title {
    z-index: 11;
}
.map {
    position: relative;
    z-index: 11;
    &__bottom {
        margin: 74px 0 0 0;
        @media (max-width: $desktop-width-s) {
			margin: 64px 0 0 0;
		}
        @media (max-width: $tablet-width-l) {
			margin: 54px 0 0 0;
		}
        @media (max-width: $tablet-width-s) {
			margin: 24px 0 0 0;
		}
    }
}

.map__subtitle {
    word-break: break-word;
    width: 82%;
    @include fs-lh(20px,24px);
    color: $color__theme_white;
    font-weight: bold;
    position: relative;
    &::after {
        content: '';
        margin-top: 8px;
        position: absolute;
        bottom: -15px;
        left: 0;
        background: #21A3DC;
        width: 100%;
        height: 8px;
    }
    @media (max-width:$desktop-width-l) {
        width: 90%;
    }
    @media (max-width:$desktop-width-s) {
        width: 79%;
    }
    @media (max-width:$tablet-width-l) {
        width: 90%;
    }
}
.map__desc {
    margin: 24px 0 0 0;

    word-break: break-word;
    width: 84%;
    @include fs-lh(16px,20px);
    font-weight: 300;
    color: $color__theme_white;
    @media (max-width:$desktop-width-l) {
        width: 90%;
    }
    @media (max-width: $tablet-width-s) {
        @include fs-lh(18px,22px);
    }
    @media (max-width:$tablet-width-m) {
        width: 90%;
    }
    @media (max-width:$tablet-width-s) {
        width: 100%;
    }
}

.map__marker {
    max-width: 86px;
    width: 100%;
    background: #21a3dc;
    margin: 0 0 12px 0;
    span {
        display: block;
        color: $color__theme_white;
        @include fs-lh(22px,26px);
        font-weight: bold;
    }
}

.map__address {
    display: block;
    background: $color__theme_white;
    width: fit-content;
    height: auto;
    span {
        display: block;
        color: $color__theme_black;
        @include fs-lh(18px,21px);
        font-weight: bold;
    }
}
.map-gradient {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    //transform: matrix(0, 1, 1, 0, 0, 0);
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 1;
        pointer-events: none;
        background: linear-gradient(-90deg, rgba(33, 163, 220, 0.2) 0%, rgba(0, 0, 0, 0.9) 81.25%);
       // background: linear-gradient(90deg, rgba(33, 163, 220, 0.2) 37.22%, rgba(0, 0, 0, 0) 100%), linear-gradient(179.41deg, rgba(0, 0, 0, 0.9) 27.6%, rgba(0, 0, 0, 0) 99.49%);
        //background: linear-gradient(90deg, rgba(0,0,0,0) 30%, #21a4dc4f 20%);
    }
    @media (max-width:$desktop-width-m) {
       position: absolute;
    }
}
.map-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @media (max-width:$desktop-width-m) {
        display: none;
    }
}
.map-location {
    filter: hue-rotate(180deg) invert(100%) saturate(50%);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    script{
        position: absolute;
        right: -200px;
        @media (max-width:$desktop-width-l) {
            right: 0;
        }

        
    }
    @media (min-width:$tablet-width-l) {
        display: block;
    }
    @media (max-width:$tablet-width-l - 1) {
        display: none;
    }
}
.map-location__mobile {
    filter: hue-rotate(180deg) invert(100%) saturate(50%);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
        @media (max-width:$desktop-width-l) {
            right: 0;
        }
    @media (min-width:$tablet-width-l) {
        display: none;
    }
    @media (max-width:$tablet-width-l - 1) {
        display: block;
    }
}
.map-icon {
    position: absolute;
    top: 0;
    z-index: 150;
}
.ymaps-2-1-79-inner-panes{
    z-index: 0;
}
.ymaps-2-1-79-controls__control_toolbar, .ymaps-2-1-79-map-copyrights-promo, .ymaps-2-1-79-controls__control{
    opacity: 0;
    display: none;
}