.bar {
    @include flex-width(100%);

    @media (min-width:$tablet-width-m) {
        @include fs-lh(32px,38px);
        margin: 0 0 64px;
        text-align: center;
    }

    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(48px,58px);
        margin: 0 0 32px;
    }

    &.bar_success {
        .bar__inner {
            @media (min-width:$tablet-width-m) {
                background: #09C052;
            }

            @media (max-width:$tablet-width-m - 1) {
                color: #09C052;
            }
        }
    }

    &.bar_dark {
        .bar__inner {
            @media (min-width:$tablet-width-m) {
                background: #283584;
            }

            @media (max-width:$tablet-width-m - 1) {
                color: #283584;
            }
        }
    }

    &.bar_light {
        .bar__inner {
            @media (min-width:$tablet-width-m) {
                background: #21A3DC;
            }

            @media (max-width:$tablet-width-m - 1) {
                color: #21A3DC;
            }
        }
    }

    &.bar_90pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(90%);

            .bar__inner {
                font-size: 0.8em;
            }
        }
    }

    &.bar_80pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(80%);

            .bar__inner {
                font-size: 0.75em;
            }
        }
    }

    &.bar_70pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(70%);

            .bar__inner {
                font-size: 0.7em;
            }
        }
    }

    &.bar_60pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(60%);

            .bar__inner {
                font-size: 0.65em;
            }
        }
    }

    &.bar_50pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(50%);

            .bar__inner {
                font-size: 0.6em;
            }
        }
    }

    &.bar_40pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(40%);

            .bar__inner {
                font-size: 0.55em;
            }
        }
    }

    &.bar_30pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(30%);

            .bar__inner {
                font-size: 0.5em;
            }
        }
    }

    &.bar_20pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(20%);

            .bar__inner {
                font-size: 0.45em;
            }
        }
    }

    &.bar_10pc {
        @media (min-width:$tablet-width-m) {
            @include flex-width(10%);

            .bar__inner {
                font-size: 0.4em;
            }
        }
    }
}

.bar__inner {
    overflow: hidden;
    font-weight: bold;

    @media (min-width:$tablet-width-m) {
        padding: 2px 10px;
        border-radius: 16px;
        background: #395C91;
        color: $color__theme_white;
    }

    @media (max-width:$tablet-width-m - 1) {
        font-weight: 900;
        color: #395C91;
    }

    & + .bar__title {
        @media (min-width:$tablet-width-m) {
            margin-top: 32px;
        }

        @media (max-width:$tablet-width-m - 1) {
            margin-top: 8px;
        }
    }
}

.bar__title {
    font-weight: 600;

    @media (min-width:$tablet-width-m) {
        @include fs-lh(20px,24px);
    }

    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(18px,22px);
    }
}