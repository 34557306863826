.social {
	@include d-flex;
	@include flex-wrap;
	@include align-items-center;
}

.social__item {
	@include inline-block;
	@include size(26px,26px);
	@include default-transition;
	// opacity: 0.8;
	margin: 0 24px 24px 0;
	background-position: 0 0;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);
	
	&:hover {
		opacity: 1;
		color: $color__theme_white;
	}
	
	&.social__item_vk {
		@include size(30px,18px);
		background-image: url(../img/icons/icon-vk.svg);
	}

	&.social__item_inst {
		background-image: url(../img/icons/icon-inst.svg);
	}

	&.social__item_fb {
		display: none;
		background-image: url(../img/icons/icon-fb.svg);
	}

	&.social__item_tg {
		@include size(25px,21px);
		background-image: url(../img/icons/icon-tg.svg);
	}

	&.social__item_tw {
		background-image: url(../img/icons/icon-tw.svg);
	}
	&.social__item_ns {
		background-image: url(../img/icons/icon-ns.svg);
	}


}
.social__item_ns-t {
	margin-top: 3px;
}
.social__item-info {
	&.social__item_vk-dark {
		@include size(30px,18px);
		background-image: url(../img/icons/icon-vk-dark.svg);
	}
	&.social__item_ns-dark {
		@include size(26px,26px);
		background-image: url(../img/icons/icon-ns-dark.svg);
	}
	&.social__item_tg-dark {
		@include size(25px,21px);
		background-image: url(../img/icons/icon-tg-dark.svg);
	}
}
.social__link {
	@include inline-block;
	@include size(20px,20px);
	margin: 0 24px 0 0;
	color: #53595D;
	transition: ease .3s all;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		color: #000000;
	}
}