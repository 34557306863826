.sortby {
	@include d-flex;
	@include flex-wrap;
	@include justify-content-between;
	margin: 0 0 18px;
}

.sortby__item {
	@include before;
	@include inline-block;
	@include fs-lh(18px,22px);
	margin: 0 10px 14px 0;
	color: $color__theme_black;
	
	@media (max-width:$tablet-width-l - 1) {
		margin-bottom: 22px;
	}
	
	&:before {
		left: 0;
		right: 0;
		bottom: -8px;
		height: 4px;
		border-radius: 2px;
		background: #21a3dc;
	}
	
	&.sortby__item_disabled {
		color: rgba(0, 0, 0, 0.3);

		&:before {
			display: none;
		}
	}
	
	&.sortby__item_all {
		&:before {
			width: 30px;
			right: auto;
			display: block;
		}

		.sortby__counter {
			color: $color__theme_black;
		}
	}
	
	&.sortby__item_dashed {		
		&:before {
			background-position: 0 0;
			background-repeat: repeat-x;
			background-color: rgba(0,0,0,0);
			background-image: url(../img/dashed.svg);
		}

		&.sortby__item_colored {
			&:before {
				background-image: url(../img/dashed-lg.svg);
			}
		}

		&.sortby__item_all {
			margin-bottom: 22px
		}

		&.sortby__item_all:before {
			right: 32px;
			width: auto;
		}

		&.sortby__item_all:after {
			content: '';
			height: 4px;
			position: absolute;
			left: 0;
			right: 32px;
			bottom: -16px;
			background: url(../img/dashed-lg.svg) repeat-x scroll 0 0 rgba(0,0,0,0);
		}
	}
	
	&.sortby__item_colored {
		.sortby__counter {
			color: #395c91;
		}
	}
}

.sortby__counter {
	color: #21a3dc;
	font-weight: bold;
}