.order {
    position: relative;
   // min-height: 900px;
    height: fit-content;
    padding: 120px 0 150px 0;
    @media (max-width:$desktop-width-s - 1) {
        padding: 64px 0 150px 0;    
    }
    @media (min-width:$desktop-width-s)  and (max-width:$desktop-width-m - 1){
		margin-bottom: -164px;
	}
    @media (max-width:$tablet-width-m - 1) {
        padding: 40px 0 100px 0;    
        min-height: 500px;
    }
    .title__h1 {
        @include fs-lh(56px, 67px);
        text-align: left;
        color: $color__theme_white;
        opacity: 0.4;
        margin-left: 120px;
        @media (max-width:$desktop-width-s - 1) {
           margin-left: 0;
        }
        @media (max-width:$tablet-width-m - 1) {
            @include fs-lh(36px, 44px);
        }
    }
}

.order__desc {
    padding: 12px 0 0 0; 
    @include fs-lh(22px, 26px);
    font-weight: 400;
    color: $color__theme_white;
    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(18px, 22px);
        margin: 16px 0 0 0;
        padding: 0;
    }
}

.order__wrapper {
    //display: flex;
   // justify-content: space-between;
    margin: 80px 0 0 0;
    @media (max-width:$desktop-width-s - 1) {
      flex-direction: column;
    }
    @media (max-width:$tablet-width-m - 1) {
        margin: 56px 0 0 0;
    }
}

.order__content {
    display: flex;
    flex-wrap: wrap;
  //  justify-content: space-between;
    margin: 80px 0 0 0;
   
    @media (min-width:$desktop-width-xl) {
       @include flex-width(100%/12*6);
	}

    @media (max-width:$desktop-width-xl) {
       width: 70%;
    }
    
    @media (max-width:$desktop-width-xl - 1) {
        //@include flex-width(100%/12*5.2);
	}

    @media (max-width:$desktop-width-s - 1) {
        @include flex-width(100%/8*8);
 
        justify-content: flex-start;
        width: 100%;
        //flex-direction: column;
    }

    @media (max-width:$tablet-width-m - 1) {
        margin: 56px 0 0 0;
    }
}

.order__nav {
   // @include flex-width(100%/12*3);
    @media (max-width:$desktop-width-s - 1) {
      display: none;
    }
}

.order-arrwos {
    display: flex;
}

.order-prev{
     cursor: pointer;
    display: flex;
    margin-top: 12px;
    margin-right: 5px;
    span {
        opacity: 0.4;
        color: #fff;
        margin-left: 10px;
        @include fs-lh(16px, 18px);
    }
}

.order-next {
    cursor: pointer;
    display: block;
    width: 45px;
    transform: rotate(-180deg);
    svg {
        fill: #fff;
        stroke: #fff;
    }
}

.order-disabled {
    opacity: 0.4;
    cursor: pointer;
    display: block;
    width: 45px;
    transform: rotate(-180deg);
}

.order__check {
    margin-top: 15px;
    .feedback__label_checkbox {
        @include fs-lh(18px, 13px);
        padding: 0 0 0 25px;
        &::before {
            top: 2px;
        }
        &::after {
            top: 10px;
        }
    }
}
.order__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include fs-lh(22px, 26px);
    font-weight: 500;
    color: $color__theme_white;
    border: 1px solid #FFFFFF;

    box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    background: transparent;
    transition: all 0.6s;

    margin: 0 24px 32px 0;
    padding: 45px 0;
    width: 289px;
    &:hover {
       // background: #fff;
       // color: #000;
    }

    @media (max-width:$desktop-width-s - 1) {
        margin-right: 42px;
    }

    @media (max-width:$tablet-width-m - 1) {
        width: 100%;
        margin: 0 0 12px 0;
        @include fs-lh(16px, 20px);
        padding: 25px 0;
    }
    
    &.order__btn_mr {
       margin-right: 64px;
    }
}

.order__btn_active {
    background: #fff;
    color: #000;
}

.order__line {
    position: absolute;
    top: 0;
    background: #53595D;
    width: 1px;
    height: 100%;
    transform: rotate(180deg);
    @media (max-width:$desktop-width-s - 1) {
        display: none;
    }
    &.order__line_second {
        left: 98px;
    }  
}

.order__step {
    //margin-left: 120px;
    overflow: hidden;
    @media (max-width:$desktop-width-s - 1){
        margin-left: 0;
    }
    &.order__step_1 {
       // display: none;
    }
    &.order__step_2 {
        //display: none;
        transform: translateX(-1000px);
        transition: all 1s;
        opacity: 0;
        //width: 100%!important;
    }
    &.order__step_3 {
        //display: none;
        transform: translateX(-1200px);
        transition: all 1s;
        opacity: 0;
       // width: 100%!important;
    }
    &.order__step_4 {
       // display: none;
       transform: translateX(-1300px);
       transition: all 1s;
       opacity: 0;
     //  width: 100%!important;
    }
    &.order__step_5 {
      //  display: none;
      transform: translateX(-1400px);
      transition: all 1s;
      opacity: 0;
    //  width: 100%!important;
    }
    &.order__step_6 {
      //  display: none;
      transform: translateX(-1500px);
      transition: all 1s;
      opacity: 0;
      //width: 100%!important;
    }

    &.order__step_6 {
        .order__content {
            display: block;
            @media (min-width:$desktop-width-xl) {
                @include flex-width(100%/12*3.5);
            }
        
            
            @media (max-width:$desktop-width-xl - 1) {
                @include flex-width(100%/12*4);
            }

            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*6);
            }

            @media (max-width:$tablet-width-m - 1) {
                 @include flex-width(100%/8*8);
            }
        }
    }
}

.order__current {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    @media (max-width:$desktop-width-xl - 1) {
       flex-wrap: wrap;
       justify-content: flex-start;
    }
}
.order__pagination {
    display: flex;
    margin-left: 25px;

    .swiper-pagination-total {
        color: $color__theme_white;
        @include fs-lh(32px, 54px);
        font-weight: 500;
    }
    .swiper-pagination-current {
        color: #FB0015!important;
        padding: 10px 0 0 0; 
        @include fs-lh(16px, 20px);
        font-weight: 500;
    }
    .active {
        color: #FB0015!important;
    }
}

.order__status {
    margin: 16px 0 8px 0;
    @include fs-lh(18px, 22px);
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
    margin-left: 25px;
    span {
       color: $color__theme_white;
    }
}

.order__dots {
    display: flex;
    align-items: center;

    @media (max-width:$desktop-width-xl - 1) {
        margin-left: 25px;
    }

    .dots {
        pointer-events: none;
        margin: 0 15px 0 0;
        background: $color__theme_white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
    .swiper-slide-thumb-active  {
        background: #FB0015!important;
    }
    .swiper-slide-next {
        opacity: 1!important;
    }
}

//steps 

.steps {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-left: 120px;
    @media (max-width:$desktop-width-s - 1){
       margin-left: 0;
       flex-direction: column;
    }
}

.steps__all {
    @include flex-width(100%/12*9);
    @media (max-width:$desktop-width-xl - 1) {
        @include flex-width(100%/12*7.5);
    }
    @media (max-width:$desktop-width-s - 1) {
        @include flex-width(100%/12*6.5);
    }
    @media (max-width:$tablet-width-m - 1) {
        width: 100%;
    }
}

.steps__navigation {
    @include flex-width(100%/12*2.8);
    @media (max-width:$desktop-width-xl - 1) {
        @include flex-width(100%/12*4);
    }
}

.stepsSlide {
    .swiper-slide-next  {
        //position: absolute;
        //right: -200px;
       // transform: translateX(-850px);
        transition: all 1s;
       // opacity: 0;
       position: relative;
       z-index: 10;
    }
    .swiper-slide-prev  {
        //position: absolute;
        //right: -100px;
    }
    .swiper-slide {
      //  position: absolute;
      //  right: 0;
       
    }

    .swiper-slide-active {
        //  position: absolute;
        //  right: 0;
          transform: translateX(0px);
          transition: all 1s;
          opacity: 1;

          position: relative;
          z-index: 20;
    }
    .swiper-transforms {
        transform: translateX(0px);
        transition: all 1s;
    }

    .swiper-slide-next {
        opacity: 0;
    }
    .swiper {
        width: 100%!important;
    }
}