.feedback {
    margin: 24px 0 0 0;
    @media (max-width:$tablet-width-m - 1) {
        margin: 16px 0 0 0;
    }
    
}

.feedback__item {
    margin: 0 0 16px 0;
    width: 95%;
}

.feedback__input {
    border: none;
    outline: none;
    padding: 8px 0 9px 13px;
    border-radius: 16px;
    width: 100%;
    background: $color__theme_white;
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        @include fs-lh(12px,14px);
        font-weight: 400;
    }
}

.feedback__textarea {
    resize: none;
    border: none;
    outline: none;
    padding: 8px 0 9px 13px;
    border-radius: 8px;
    background: $color__theme_white;
    height: 173px;
    width: 100%;
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        @include fs-lh(12px,14px);
        font-weight: 400;
    }
    @media (max-width:$tablet-width-m - 1) {
        height: 140px;
    }
    
}
.feedback__btn {
    cursor: pointer;
    margin: 33px 0 0 0;
    outline: none;
    border: none;

    display: flex;
    text-align: center;
    padding: 2px 56px 5px 56px;

    background: #283584;
    border-radius: 16px;

    @include fs-lh(20px,24px);
    font-weight: 600;
    color: $color__theme_white;
    transition: all 0.5s;
	&:hover {
		opacity: 0.8;
	}
    @media (max-width:$tablet-width-m - 1) {
        margin: 17px 0 0 0;
        //padding: 2px;
        justify-content: center;
        width: 95%;
    }
}

.feedback__check {
    display: flex;
}

.feedback__label_checkbox {
    @include fs-lh(11px,13px);
    font-weight: 400; 
    color: rgba(255, 255, 255, 0.6);
}

.feedback__label.feedback__label_checkbox {
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    padding: 3px 0 0 25px;
}

input[type=checkbox], input[type=radio] {
    position: absolute!important;
    clip: rect(1px,1px,1px,1px);
    padding: 0!important;
    border: 0!important;
    height: 1px!important;
    width: 1px!important;
    overflow: hidden;
}

.feedback__label.feedback__label_checkbox:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 14px;
    height: 14px;
    background: transparent;
    border-radius: 2px;
    border: 1px solid #fff;
}

.feedback__field.feedback__field_checkbox:checked+.feedback__label_checkbox:after {
    display: block;
}

.feedback__label.feedback__label_checkbox:after {
    position: absolute;
    top: 9px;
    left: 2px;
    width: 12px;
    height: 12px;
    content: '';
    display: none;
    margin: -6px 0 0;
    background: url('/local/templates/cdt/css/../img/icons/icon-checked-fedb.svg') no-repeat center center;
    @media (max-width:$desktop-width-m - 1) {
       // top: 31%;
    }
    @media (max-width:$desktop-width-s - 1) {
        //top: 30%;
       // top: 50%;
    }
    @media (max-width:858px) {
      //  top: 30%;
    }
    @media (max-width:$tablet-width-m - 1) {
        top: 9px;
    }
}

.js-input-error .feedback__input {
    border: 2px solid #ff0000;
}

.js-input-error .feedback__textarea {
    border: 2px solid #ff0000;
}

.js-input-success .feedback__input {
    border: none;
}

.js-input-success .feedback__textarea {
    border: none;
}

small {
    display: none;
    visibility: hidden;
}

.js-input-error small {
    display: block !important;
    visibility: visible!important;
    
    padding: 10px 0 0 0;
    color: #ff0000;

    @include fs-lh(12px,15px);
    font-weight: 500;
}

// feedback order

.feedback_order {
    margin: 0;
    .feedback__item {
        width: 100%;
        @media (max-width:$tablet-width-m - 1) {
          width: 97%;
        }
    }

    .feedback__btn_order { 
        padding: 4px 56px 5px 56px;
        background: #21A3DC;
    }

    .feedback__input {
        border-radius: 16px;
    }
}