
.page-btn {
    background-image: url(../img/icons/icon-up.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    width: 30px;
	height: 30px;
    position: fixed;
    z-index: 1030;
    border-radius: 11px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

    @media (min-width: 1199px) {
        right: 15px;
    }
    
    @media (min-width: 768px) and (max-width: 1199.98px) {
        right: 45px;
    }
    
    @media (max-width: 991px) {
        right: 15px;
    }

    &.page-btn_up {
        opacity: 0;
        color: #666;
        padding: 20px;
        bottom: 300px;
        pointer-events: none;
        transition: all .3s ease;

        &.active {
            opacity: 1;
            pointer-events: all;
            z-index: 101;
        }
    }
}