.doclist {
	@include d-flex;
	@include flex-wrap;

	@media (min-width:$tablet-width-m) {
		margin-left: -10px;
		margin-bottom: -15px;
	}
}

.doclist__item {

	@include before;
	box-sizing: border-box;
	
	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/8*3);
		margin-right: (100%/8);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/7*3);
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/2);
	}
	
	@media (min-width:$tablet-width-m) {
		padding-left: 74px;
		padding-right: 10px;
		margin-bottom: 65px;
	}
	
	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
		min-height: 85px;
		padding-left: 58px;
		margin-bottom: 16px;
	}
	
	&:before {
		padding: 85px 0 0 48px;
		background-position: 0 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-color: rgba(0,0,0,0);

		@media (min-width:$tablet-width-m) {
			@include pos(0,10px);
		}

		@media (max-width:$tablet-width-m - 1) {
			@include pos(0,0);
		}
	}
	
	&.doclist__item_xls {
		&:before {
			background-image: url(../img/icons/icon-xls.svg);
		}
	}
	
	&.doclist__item_doc {
		&:before {
			background-image: url(../img/icons/icon-doc.svg);
		}
	}

	&.doclist__item_pdf {
		&:before {
			background-image: url(../img/icons/icon-pdf.svg);
		}
	}

	&.doclist__item_pdfl {
		&:before {
			background-image: url(../img/icons/icon-pdf-light.svg);
		}
	}
}

.doclist__title {
	@include fs-lh(18px,22px);
	font-weight: 500;
	margin: 0 0 16px;
}

.doclist__text {
	@include fs-lh(14px,17px);
	color: rgba(0,0,0,0.6);
	margin: 0 0 8px;

	&.doclist__text_light {
		@media (max-width:$desktop-width-s - 1) {
			color: rgba(255, 255, 255, 0.6);
		}
	}
}

.doclist__link {
	@include fs-lh(12px,15px);
	@include dec-underline;
	color: #283584;

	&.doclist__link_light {
		color: #21A3DC;
	}
	
	&:hover,
	&:focus {
		color: #283584;
	}
}

.doclist_tab {
	margin-top: 24px;
	color: #fff;
	margin-bottom: 57px;

	@media (max-width:$desktop-width-s - 1){
		//flex-wrap: nowrap;
	}

	@media (max-width:$tablet-width-m - 1) {
		flex-wrap: wrap;
		margin-top: 0;
		padding-bottom: 29px;
		margin-bottom: 0;
	}

	.doclist__item {
		//@include flex-width(100%/12*2.7);
		margin-right: 32px;
		margin-bottom: 37px;
		@media (min-width:$desktop-width-xl - 1){
			@include flex-width(100%/12*3);
		}

		@media (max-width:$desktop-width-xl - 1){
			@include flex-width(100%/12*4);
		}

		@media (max-width:$desktop-width-m - 1){
			@include flex-width(100%/12*4);
			margin-right: 32px;
		}
		@media (max-width:$desktop-width-s - 1){
			@include flex-width(100%/8*3.7);
			margin-right: 32px;
		}
		@media (max-width:$tablet-width-m - 1){
			@include flex-width(100%/8*8)
		}
	}
	.doclist__item_box {
		padding-top: 20px;	
		padding-bottom: 20px;
		padding-left: 88px;
		background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.12) 28.69%, rgba(255, 255, 255, 0) 100%);
		backdrop-filter: blur(140px);
		border-radius: 16px;
		@media (max-width:$tablet-width-m - 1) {
			background: none;
			border-radius: 0;
			padding-top: 24px;
			padding-left: 80px;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	.doclist__text {
		color: #fff;
		opacity: 0.6;
		@media (max-width:$tablet-width-m - 1) {
			color: #000;
		}
	}

	.doclist__link {
		color: #fff;
		&:hover {
			text-decoration: none!important;
			opacity: 0.6;
		}
		@media (max-width:$tablet-width-m - 1) {
			color: #000;
		}
	}

	.doclist__title {
		margin:  0 0 8px 0;
		@media (max-width:$tablet-width-m - 1) {
			color: #000;
		}
	}

	.doclist__item:before {
		position: absolute;
		top: 20px;
		left: 20px;
		@media (max-width:$tablet-width-m - 1) {
			top: 24px;
		}
	} 
}