.offers {
	@media (min-width:$tablet-width-m) {
		margin-left: -30px;
		margin-right: -30px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-left: -12px;
		margin-right: -12px;
	}
}

.offers__wrapper {
	padding: 0 30px 60px;
	box-sizing: border-box;
}

.offers__item {
	@include before;
	height: auto;
	padding: 30px 28px 24px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-xl) {
		@include flex-width(100%/5);
	}

	@media (max-width:$desktop-width-xl - 1) {
		margin: 0 0 24px;
	}

	@media (max-width:$desktop-width-xl - 1) {
		@include flex-width(246px);
	}

	&:before {
		@include pos(0,10px);
		right: 10px;
		bottom: 0;
		z-index: 2;
		border-radius: 12px;
		background: $color__theme_white;
	}

	&:after {
		@include pos(55px,20px);
		right: 20px;
		bottom: -16px;
		content: '';
		z-index: 1;
		-o-filter: blur(20px);
		-moz-filter: blur(20px);
		-webkit-filter: blur(20px);
		filter: blur(20px);
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.7);
	}

	&.offers__item_inverted {
		@media (max-width:$desktop-width-xl - 1) {
			display: none;
		}

		&:before {
			@include border(2px,$color__theme_white);
			background: #21A3DC;
		}

		&:after {
			display: none;
		}

		.offers__title {
			color: $color__theme_white;
		}

		.offers__desc {
			color: $color__theme_white;
		}
	}
}

.offers__title {
	@include zi-2;
	@include fs-lh(20px,24px);
	font-weight: 600;
	margin: 0 0 16px;
}

.offers__desc {
	@include zi-2;
	@include fs-lh(14px,17px);
	margin: 0 0 28px;
}

.offers__subtitle {
	@include zi-2;
	@include fs-lh(14px,17px);
	margin: 0 0 16px;
}

.offers__info {
	@include zi-2;
	@include fs-lh(11px,13px);
	margin: 0 0 12px;
	color: rgba(0, 0, 0, 0.6);
}

.offers__footer {
	@include zi-2;
	text-align: center;
}

.offers__readmore {
	@include fs-lh(14px,17px);
	font-weight: bold;
	color: #21A3DC;
	text-transform: lowercase;
}

.offers__form {
	@include zi-2;
	@include offset(0 0 20px,0 42px 0 0);
	width: 100%;
	box-sizing: border-box;
}

.offers__field {
	@include fs-lh(11px,13px);
	@include border(1px,rgba(0, 0, 0, 0.1));
	width: 100%;
	padding: 4px;
	border-radius: 2px;
	box-sizing: border-box;
	box-shadow: 0px 2px 2px 0 rgba(40, 53, 132, 0.5) inset;
	background: $color__theme_white;
}

.offers__btn {
	@include size(34px,22px);
	position: absolute;
	top: 0;
	right: 0;
	border: 0;
	cursor: pointer;
	border-radius: 2px;
	background: url(../img/icons/icon-envelope.svg) no-repeat scroll center center#283584;
	background-size: 50% auto;
}