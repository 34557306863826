.projects {
	z-index: 2;
	box-sizing: border-box;
	
	@media (min-width:$desktop-width-s) {
		left: (100%/12);
		width: (100%/12*10);
		padding: 0 20px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		left: 0;
		right: 0;
	}

	@media (min-width:$tablet-width-m) {
		position: absolute;
		bottom: 0;
	}

	&.projects_desktop {
		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	/* mobile */
	&.projects_mobile {
		margin-left: -6px;
		margin-right: -6px;
		
		@media (min-width:$tablet-width-m) {
			display: none;
		}
		
		.projects__item {
			cursor: pointer;
		}
		
		.projects__inner {
			top: auto;
			min-height: 104px;
			padding: 8px 12px 16px;
		}
		
		.projects__slides {
			@include multi-prop(translate(0,0));
			position: static;
			opacity: 1;
		}
		
		.projects__slides-item {
			@include flex-width(auto);
			width: auto;
			overflow: hidden;
			border-radius: 16px;
			margin: 0 16px 0 0;
		}
		
		.projects__slides-inner {
			padding: 18px 16px 16px;
		}
		
		.projects__slides-title {
			margin-bottom: 0;
		}
		
		.projects__slides-counter {
			@include fs-lh(20px,24px);
			font-weight: 600;
			margin: 0 0 10px;
			letter-spacing: 0.01em;
		}
	}

	/* height */
	&.projects_index {
		@media (min-width:$laptop-width) and (max-width:$desktop-width-m) and (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
			.projects__picture {
				max-width: 100%;
			}
			
			.projects__title {
				@include fs-lh(14px,18px);
			}
			
			.projects__text {
				@include fs-lh(12px,16px);
			}
		}
	}
}

.projects__swiper {
	&.projects__swiper_mobile {
		@media (max-width:$tablet-width-m - 1) {
			visibility: hidden;
			padding: 16px 0 16px 16px;
		}
	}
}

.projects__item {
	@include zi-1;
	width: auto;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	&:hover {
		z-index: 2;
		overflow: visible;

		.projects__slides {
			@include multi-prop(translate(0,0));
			opacity: 1;
		}
	}

	@media (min-width:$laptop-width) and (max-width:$desktop-width-l - 1) {
		height: 180px;
	}
	
	&.projects__item_next {
		@media (min-width:$desktop-width-l) {
			& + .projects__item {
				& + .projects__item {
					& + .projects__item {
						.projects__slides {
							@include multi-prop(translate(100%,0));
							right: 0;
							left: -100%;
						}
						
						&:hover {
							.projects__slides {
								@include multi-prop(translate(0,0));
							}
						}
					}
				}
			}
		}

		@media (min-width:$laptop-width - 1) and (max-width:$desktop-width-l - 1) {
			& + .projects__item {
			}
				& + .projects__item {
					.projects__slides {
						@include multi-prop(translate(100%,0));
						right: 0;
						left: -100%;
					}

					&:hover {
						.projects__slides {
							@include multi-prop(translate(0,0));
						}
					}
				}
		}

		@media (min-width:$tablet-width-m) and (max-width:$laptop-width - 2) {
			& + .projects__item {
				.projects__slides {
					@include multi-prop(translate(100%,0));
					right: 0;
					left: -100%;
				}

				&:hover {
					.projects__slides {
						@include multi-prop(translate(0,0));
					}
				}
			}
		}
	}
	
	&.projects__item_active {
		@media (max-width:$tablet-width-m - 1) {
			background-image: none !important;
			background-color: $color__theme_black;
		}

		.projects__inner {
			@media (max-width:$tablet-width-m - 1) {
				display: none;
			}
		}
			
		.projects__swiper {
			@media (max-width:$tablet-width-m - 1) {
				visibility: visible;
			}
		}
	}
}

.projects__picture {
	@media (min-width:$tablet-width-m) {
		visibility: hidden;
	}
}

.projects__inner {
	@include d-flex;
	@include flex-column;
	@include justify-content-between;
	@include pos(60px,0);
	right: 0;
	bottom: 0;
	overflow: hidden;
	padding: 8px 16px 12px;
	background: rgba(0, 0, 0, 0.6);
}

.projects__title {
	@include fs-lh(16px,20px);
	display: block;
	margin: 0 0 10px;
	font-weight: 600;
	color: $color__theme_white;
}

.projects__text {
	@include fs-lh(14px,1);
	color: $color__theme_white;
}

.projects__slides {	
	@include multi-prop(translate(-100%,0));
	@include pos(0,0);
	right: -100%;
	bottom: 0;
	opacity: 0;
	/*
	-webkit-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	-moz-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	-o-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	-ms-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	*/
	-webkit-transition: transform 0.5s, opacity 0.3s;
	-moz-transition: transform 0.5s, opacity 0.3s;
	-o-transition: transform 0.5s, opacity 0.3s;
	-ms-transition: transform 0.5s, opacity 0.3s;
	transition: transform 0.5s, opacity 0.3s;
	transition-timing-function: cubic-bezier(0.55, 0.05, 0.35, 0.95);
}

.projects__slides-scroller {
	@include d-flex;
	@include size(100%,100%);
	overflow: hidden;
}

.projects__slides-item {
	@include zi-1;
	@include flex-width(100%);
	@include multi-trans(margin-left 0.5s ease 0s);
	color: $color__theme_white;
	display: block;
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);
	
	&:hover,
	&:focus {
		color: $color__theme_white;
	}

	.img-fluid {
		@media (min-width:$tablet-width-m) {		
			visibility: hidden;
		}
	}
}

@for $i from 1 through 4 {
	.projects__slides-toggler:nth-of-type(#{$i}):checked {
		& ~ .projects__slides-scroller {
			.projects__slides-item:first-of-type {
				margin-left: (-100%)*($i+(-1));
			}
		}

		& ~ .projects__bar {
			.projects__bar-inner {
				&:before {
					width: (100%/4*$i);
				}
			}
		}

		& ~ .projects__control {
			.projects__control-item:nth-of-type(#{$i+(-1)}) {
				@include multi-prop(rotate(180deg));
				display: block;
				left: 0;
				right: auto;
			}

			.projects__control-item:nth-of-type(#{$i+1}) {
				display: block;
				left: auto;
				right: 0;
			}
		}
	}
}

.projects__slides-inner {
	@include d-flex;
	@include flex-column;
	@include justify-content-between;
	@include pos(0,0);
	right: 0;
	bottom: 0;
	padding: 55px 32px 14px;
	box-sizing: border-box;
	background: rgba(0,0,0,.5);
}

.projects__slides-title {
	@include fs-lh(16px,20px);
	font-weight: 600;
	margin: 0 0 10px;
	display: block;
}

.projects__slides-text {
	@include fs-lh(14px,17px);
	display: block;
	letter-spacing: 0.02em;
}

.projects__bar {
	@include d-flex;
	@include align-items-center;
	@include fs-lh(20px,24px);
	@include pos(16px,32px);
	right: 32px;
	font-weight: 600;
	letter-spacing: 0.01em;
	color: $color__theme_white;
	z-index: 2;
}

.projects__bar-inner {
	@include before;
	@include size(160px,2px);
	margin: 0 3px 0 0;
	background: $color__theme_white;
	
	&:before {
		@include pos(0,0);
		@include size(25%,2px);
		@include multi-trans(width 0.5s ease 0.5s);
		background: $color__theme_red;
	}
}

.projects__bar-left {
	margin: 0 3px 0 0;
}

.projects__control-item {
	display: none;
	width: 30px;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 3;
	cursor: pointer;
	background: url(../img/icons/icon-sm-arrow-left.svg) no-repeat scroll center center rgba(0,0,0,0);
}

/* nav */
.projects__nav {
	@include size(auto,auto);
	@include offset(0,24px 0 0 98px);
	top: -24px;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);
	
	@media (max-width:$desktop-width-m - 1) {
		display: none;
	}
	
	&:after {
		display: none;
	}
	
	&.projects__nav_prev {
		left: 20px;
		background-position: left center;
		background-image: url(../img/icons/icon-arrow-left.svg);
	}

	&.projects__nav_next {
		right: 20px;
		background-position: right center;
		background-image: url(../img/icons/icon-arrow-right.svg);
	}
}

/* pagination */
.projects__pagination {
	left: auto;
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		@include fs-lh(32px,54px);
		font-weight: 600;
		color: $color__theme_white;
	}

	@media (min-width:$desktop-width-m) {
		width: (100%/10);
		right: -10%;
		bottom: 24px;
		padding: 0 20px 0 0;		
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		width: (100%/8);
		top: -56px;
		right: 10px;
		bottom: auto;
		padding: 0 20px 0 0;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding: 16px 12px;
		text-align: center;
		background: $color__theme_black;
	}
	
	.swiper-pagination-current {
		position: relative;
		top: -10px;
		font-size: 0.8em;
		color: #fb0015;
	}
}

@mixin projects__pagination-current {
	@include inline-block;
	@include size(24px,24px);
	@include fs-lh(16px,20px);
	@include offset(0 8px 0 0,1px 4px 4px);
	font-weight: 600;
	border-radius: 2px;
	box-sizing: border-box;
}

.projects__pagination-current,
.projects__pagination-current:hover,
.projects__pagination-current:focus {
	@include projects__pagination-current;
	color: $color__theme_white;
	background: #21a3dc;
}

.projects__pagination-link,
.projects__pagination-link:hover,
.projects__pagination-link:focus {
	@include projects__pagination-current;
	color: rgba(255, 255, 255, 0.3);
	background: #53595d;
}

.projects__pagination-arrow,
.projects__pagination-arrow:hover,
.projects__pagination-arrow:focus {
	@include projects__pagination-current;
	width: auto;
	padding-left: 18px;
	padding-right: 18px;
	color: $color__theme_white;
	background: #21a3dc;
}