.accordion {
    padding: 0 64px;
    border: 2px solid #FFFFFF;
    background: #000;
    box-sizing: border-box;
    border-radius: 16px;

    @media (max-width:$desktop-width-s) {
        padding: 0 32px;
    }

    &.accordion_agancy {
        margin: 24px 0 0 0;
        .title__h3 {
            display: flex;
            align-items: center;
            color: $color__theme_white;
            @include fs-lh(36px, 43px);
            font-weight: 800;
            @media (max-width:$desktop-width-s) {
                @include fs-lh(32px, 39px);
                font-weight: 800;
            }
            @media (max-width:$tablet-width-m - 1) {
                @include fs-lh(24px, 29px);
                font-weight: 800;
                width: 80%;
            }
        }

        @media (max-width:$tablet-width-m - 1) {
            border-radius: 0;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            border-left: none;
            border-right: none;
            padding: 0 16px;
            margin: 16px 0 0 0;
        }
    }
}

.active {
    opacity: 1;
    transition: all .3s ease 0s;
    .accordion__icon {
        transition: all .3s ease 0s;
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            margin: -10px 0 0 -10px;
            background: url('../img/icons/icon-minus.svg') no-repeat 0 0;
            top: 42px;
            left: 35px;
            @media (max-width:$tablet-width-m - 1) {
                top: 32px;
                left: 24px;
            }
        }
    }
}

.accordion__header {
    display: flex;
    justify-content: space-between;

    padding: 24px 0;
    background: #000;
}

.accordion__body {
    animation: showContentAcc 0.5s ease;
    display: none;
    //opacity: 0;
    //max-height: 0;
    overflow: hidden;
    height: auto;
    background: #000;
    transition: all .3s ease;
    @media (max-width:$desktop-width-s - 1){
        //padding-bottom: 29px;
    }
    @media (max-width:$tablet-width-m - 1) {
       // padding: 0 0 32px 0;
       overflow: visible;
    }
}

.accordion__center {
    margin-bottom: 29px;
    @media (max-width:$tablet-width-m - 1) {
        padding-left: 15px;
        padding-bottom: 32px;
        margin-bottom: 0;
    }
}

.accordion__top {
    border-top: 1px solid #fff;
    padding: 32px 0 0 0;
    @media (max-width:$tablet-width-m - 1) {
       padding: 16px 15px 0 25px;
       margin-left: -15px;
       margin-right: -15px;
    }
    .title__h4 {
        color: $color__theme_white;
        @include fs-lh(20px, 24px);
        font-weight: 600;
    }
}
.accordion__btn {
    cursor: pointer;
    position: relative;
    background: rgba(251,0,21,.6);
    border-radius: 50%;
    width: 68px;
    height: 68px;
    span {
        display: block;
        color: $color__theme_white;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    @media (max-width:$tablet-width-m - 1) {
        width: 46px;
        height: 46px;
    }
}

.accordion__icon {
    transition: all .3s ease 0s;
    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: #000;
        top: 12px;
        left: 12px;
        width: 44px;
        height: 44px;
        @media (max-width:$tablet-width-m - 1) {
            top: 7px;
            left: 7px;
            width: 33px;
            height: 33px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        margin: -10px 0 0 -10px;
        background: url('../img/icons/icon-plus.svg') no-repeat 0 0;
        top: 35px;
        left: 35px;
        
        @media (max-width:$desktop-width-l - 1) {
            left: 34px;
        }
        @media (max-width:$tablet-width-m - 1) {
           top: 23px;
           left: 24px;
           width: 20px;
           height: 20px;
        }
    }
}

@-webkit-keyframes showContentAcc {
    0% {
      transform: scaleY(0.8);
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
      opacity: 1;
    }
  }
  @keyframes showContent {
    0% {
      transform: scaleY(0.8);
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
      opacity: 1;
    }
}