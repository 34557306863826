.modal-overlay {
	@include pos(165px,0,fixed);
	right: 0;
	z-index: 1060;
	display: none;

	@media (max-width:$tablet-width-m) {
		display: none !important;
	}
}

.modal-backdrop {
	@include pos(0,0,fixed);
	bottom: 0;
	right: 0;
	z-index: 1040;
	display: none;
	cursor: pointer;
	background: rgba(0,0,0,0.8);

	@media (max-width:$tablet-width-m) {
		display: none !important;
	}
}
