.features {
	@include d-flex;
	@include flex-wrap;
	position: relative;
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		padding-bottom: 98px;
	}
	
	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}

	@media (min-width:$tablet-width-l) {
		margin: 0 0 115px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		margin: 0 0 24px;
	}

	&.features_pt {
		@media (min-width:$desktop-width-s) {
			padding-top: 98px;
			padding-bottom: 0;
			margin: 115px 0 0;
		}

		.features__col {
			@include before;
			box-sizing: border-box;
			
			@media (min-width:$desktop-width-s) {	
				&:before {
					bottom: auto;
				}
				
				.features__icon {
					bottom: auto;
				}

				&:first-of-type {
					&:before {
						height: 105px;
						top: -105px;
					}
					
					.features__icon {
						top: -105px;
					}
				}
		
				&:nth-of-type(2) {
					&:before {
						height: 120px;
						top: -120px;
					}
					
					.features__icon {
						top: -120px;
					}
				}
		
				&:nth-of-type(3) {
					&:before {
						height: 135px;
						top: -135px;
					}
					
					.features__icon {
						top: -135px;
					}
				}
			}
		}

		.features__item {
			&.features__item_nobg {
				@media (min-width:$desktop-width-s) {
					margin: 32px 0 0;
				}
			}
		}

		.features__icon {		
			@media (min-width:$desktop-width-s) {
				margin-top: -34px;
				margin-bottom: 0;
			}
		}
	}

	&.features_mb {
		@media (min-width:$desktop-width-s) {
			margin: 0 0 235px;
		}

		@media (max-width:$desktop-width-s - 1) {
			margin: 0;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&.features_tranformed {
		@media (min-width:$desktop-width-s) {
			@include multi-prop(matrix(1,-.05,0,1,0,0));
			transform-origin: left bottom;
		}

		.features__title {
			@media (min-width:$desktop-width-s) {
				@include multi-prop(matrix(1,.05,0,1,0,0));
				transform-origin: left bottom;
			}
		}

		.features__text {
			@media (min-width:$desktop-width-s) {
				@include multi-prop(matrix(1,.05,0,1,0,0));
				transform-origin: left bottom;
			}
		}

		.features__icon {
			@media (min-width:$desktop-width-s) {
				@include multi-prop(matrix(1,.05,0,1,0,0));
				transform-origin: left bottom;
			}
		}
	}
}

.features__col {
	@include before;
	box-sizing: border-box;
	
	@media (min-width:$desktop-width-s) {
		@include flex-width(100%/4);
		padding: 0 24px;
		// text-align: center;

		/*
		&:first-of-type {
			&:before {
				height: 135px;
				bottom: -135px;
			}

			.features__icon {
				bottom: -135px;
			}
		}

		&:nth-of-type(2) {
			&:before {
				height: 120px;
				bottom: -120px;
			}

			.features__icon {
				bottom: -120px;
			}
		}

		&:nth-of-type(3) {
			&:before {
				height: 105px;
				bottom: -105px;
			}

			.features__icon {
				bottom: -105px;
			}
		}

		&:nth-of-type(4) {
			&:before {
				height: 95px;
				bottom: -95px;
			}

			.features__icon {
				bottom: -95px;
			}
		}
		*/
	}

	&:before {
		@media (min-width:$desktop-width-s) {
			width: 2px;
			height: 135px;
			left: 50%;
			bottom: -135px;
			z-index: 2;
			margin: 0 0 0 -1px;
			background: $color__theme_white;
		}
	}

	.features__icon {
		@media (min-width:$desktop-width-s) {
			bottom: -135px;
		}
	}

	&.features__col_sm {
		@media (min-width:$desktop-width-s) {
			@include flex-width(100%/3);
			padding-left: 36px;
			padding-right: 36px;
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/2.5);
		}

		@media (max-width:$tablet-width-l - 1) {
			@include flex-width(100%);
		}

		&:before {
			left: 72px;
		}

		.features__item {
			max-width: 100%;
		}

		.features__icon {
			@media (min-width:$desktop-width-s) {
				left: 72px;
			}

			@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
				top: 25px;
				left: -30px;
			}
		}
	}
}

.features__top {
	@media (min-width:$desktop-width-l) {
		margin: 0 14px;
	}

	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-l - 1) {
		margin: 0 4px;
	}

	@media (min-width:$desktop-width-s) {
		@include before;
		position: absolute;
		top: 0;
		left: -(100%/8);
		right: -(100%/8);
		box-sizing: border-box;

		&:before {
			@include border(0 0 4px,$color__theme_white);
			@include multi-prop(matrix(1,-.05,0,1,0,0));
			@include pos(0,0);
			left: 0;
			right: 0;
			
			-webkit-transform-origin: left bottom;
			-moz-transform-origin: left bottom;
			-o-transform-origin: left bottom;
			-ms-transform-origin: left bottom;
			transform-origin: left bottom;
		}
	}

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}

	&.features__top_lg {
		@media (min-width:$desktop-width-l) {
			margin: 0 -20px;
		}
	
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-l - 1) {
			margin: 0;
		}

		@media (min-width:$desktop-width-s) {
			top: 10px;
			left: -(100%/8*2);
			right: -(100%/8*2);
		}
	}
}

.features__bottom {
	@media (min-width:$desktop-width-l) {
		margin: 0 14px;
	}

	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-l - 1) {
		margin: 0 4px;
	}

	@media (min-width:$desktop-width-s) {
		@include before;
		position: absolute;
		left: -(100%/8);
		right: -(100%/8);
		bottom: -56px;
		box-sizing: border-box;

		&:before {
			@include border(0 0 4px,$color__theme_white);
			@include pos(-20px,0);
			left: 0;
			right: 0;
			
			/*
			@include multi-prop(matrix(1,-.05,0,1,0,0));
			-webkit-transform-origin: left bottom;
			-moz-transform-origin: left bottom;
			-o-transform-origin: left bottom;
			-ms-transform-origin: left bottom;
			transform-origin: left bottom;
			*/
		}
	}

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}

	&.features__bottom_lg {
		@media (min-width:$desktop-width-l) {
			margin: 0 -20px;
		}
	
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-l - 1) {
			margin: 0;
		}

		@media (min-width:$desktop-width-s) {
			left: -(100%/8*2);
			right: -(100%/8*2);
		}
	}
}

.features__item {
	@include before;
	text-align: left;
	position: relative;
	box-sizing: border-box;
	color: $color__theme_white;
	
	&:hover,
	&:focus {
		color: $color__theme_white;
	}

	@media (min-width:$desktop-width-s) {
		@include inline-block;
		@include default-transition;
		max-width: 228px;
		height: 100%;
		overflow: hidden;
		border-radius: 16px;
		padding: 12px 12px 20px;
		background: rgba(0, 0, 0, 0.7);

		&:hover {
			border-color: #21a3dc;
			background: $color__theme_black;
			box-shadow: 0px 0px 24px #21a3dc, 0px 0px 16px #21a3dc;
			
			&:before {
				@include border(2px,#21a3dc);
				width: auto;
				top: 0;
				right: 0;
				background: none;
				border-radius: 15px;
			}

			.features__footer,
			&:after {
				display: none;
			}
		}
		
		&:before,
		&:after {
			bottom: 0;
			width: 2px;
			background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(255,255,255,1) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#ffffff',GradientType=0 );
		}

		&:before {
			@include pos(25%,0);
		}
		
		&:after {
			content: '';
			position: absolute;
			top: 25%;
			right: 0;
		}
	}

	@media (max-width:$desktop-width-s - 1) {
		display: block;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		@include offset(0 0 24px,17px 0 0);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 0 38px,12px 0 0 24px);
	}

	&.features__item_nobg {
		border-radius: 0;
		overflow: visible;
		background: none;

		@media (min-width:$desktop-width-s) {
			margin: 0 0 -32px;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			margin: 0 0 100px;
		}

		@media (min-width:$tablet-width-m) {
			padding: 0;
		}

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}

		&:hover {
			box-shadow: none;
		}

		.features__footer {
			display: none;
		}
	}
}

.features__title {
	@include zi-2;
	font-weight: bold;
	display: block;

	@media (min-width:$desktop-width-s) {
		@include fs-lh(22px,26px);
		word-wrap: break-word;
		margin: 0 0 16px;
	}

	@media (max-width:$desktop-width-s - 1) {
		margin: 0 0 8px;
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		@include fs-lh(28px,34px);
		padding: 0 0 0 34px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(18px,22px);
	}

	&.features__title_lg {
		@include pos(-32px,0);
		@include fs-lh(100px,122px);
		font-weight: 900;

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			padding-left: 0 !important;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
			padding-left: 0 !important;
		}

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}

		& + .features__text {
			@media (min-width:$tablet-width-m) {
				padding: 32px 0 0 72px;
			}
		}
	}
}

.features__text {
	@include zi-2;
	display: block;
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		width: (100%/8*6);
		margin-left: (100%/8);
	}

	@media (min-width:$tablet-width-m) {
		@include fs-lh(14px,17px);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(12px,14px);
	}
	
	a {
		@include dec-underline;
		color: #21a3dc;
		
		&:hover,
		&:focus {
			color: #21a3dc;
		}
	}

	&.features__text_lg {
		@include fs-lh(20px,24px);
		font-weight: 600;
		@media (min-width:$desktop-width-s ) {
			width: 200px;
		}
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.features__icon {
	@include before;
	border-radius: 50%;
	z-index: 1;
	background: rgba(251, 0, 21, 0.6);

	&:hover {
		@media (min-width:$desktop-width-s) {
			background: rgba(0, 0, 0, 0);
		}
	}
	
	&:before {
		border-radius: 50%;
		//background: #fb0015;000
		background: #000;
	}

	@media (min-width:$desktop-width-s) {
		@include default-transition;
		position: absolute;
		left: 50%;
		margin: 0 0 -34px -34px;
		z-index: 1;
		
		&:before {
			@include pos(50%,50%);
			@include size(44px,44px);
			margin: -22px 0 0 -22px;
		}
		
		&:after {
			content: '';
			@include pos(50%,50%);
			@include size(16px,16px);
			margin: -8px 0 0 -8px;
			border-radius: 50%;
			background: $color__theme_white;
		}
	}

	@media (max-width:$desktop-width-s - 1) {
		@include pos(0,0);
	}

	@media (min-width:$tablet-width-m) {
		@include size(68px,68px);
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		&:before {
			@include pos(12px,12px);
			@include size(44px,44px);
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		@include size(46px,46px);

		&:before {
			@include pos(8px,8px);
			@include size(30px,30px);
		}
	}
}

.features__footer {
	@media (min-width:$desktop-width-s) {
		@include before;
		position: absolute;
		overflow: hidden;
		display: block;
		height: 30px;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}
	
	&:before {
		@include pos(-30px,0);
		@include border(0 2px 2px,$color__theme_white)
		right: 0;
		bottom: 0;
		border-radius: 16px;
	}
}

// features digital

.features_digital {
	display: none;
	margin: 0 0 57px 0!important;
	padding: 0!important;

	.features__col { 
		&::before {
			display: none!important;
		}
	}

	.features__title {
		@include fs-lh(22px, 26px);
        font-weight: 700;
        color: $color__theme_white;
	}

	.features__desc {
		width: 60%;
		@include fs-lh(14px, 16px);
        font-weight: 500;
        color: $color__theme_white;
	}

	.features__ray {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			width: 400px;
			height: 2px;
			top: 12px;
			left: 0;
			background: #fff;
		}
	}
	.features__ray-inner {
		position: absolute;
		top: 11px;
		left: -159px;
		width: 160px;
		height: 220px;
		overflow: hidden;
		&::before {
			content: '';
			position: absolute;
			width: 280px;
			height: 200px;
			top: 0;
			right: 0;
			border: 2px solid #fff;
			transform: rotate(-10deg);
			transform-origin: right top;
		}
	}

	.features__circles {
		position: relative;
	}
}
