.section__library {
    @include container;
	position: relative;
	color: $color__theme_white;
	background-repeat: no-repeat;
    background-position: center top;
    background-color: transparent;
    background-size: cover;
    background-image: url(../img/bg/media-footer-bg.jpg);
    padding-top: 150px;
    transform: matrix(1,-.05,0,1,0,0);
    @media (min-width:$desktop-width-xl) {
        padding-bottom: 450px;
        margin-bottom: -240px;
        padding-top: 70px;
	}
	@media (min-width:$desktop-width-m)and (max-width:$desktop-width-xl - 1){
		padding-bottom: 350px;
        margin-bottom: -115px;
        padding-top: 70px;
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
        margin-top: 115px;
        padding-bottom: 60px;
        padding-top: 70px;
        margin-bottom: -100px;
    }
	@media (max-width:$tablet-width-l - 1) {
		padding: 80px 6px 100px;
        //margin-bottom: -70px;
        margin-bottom: -90px;
	}
	@media (max-width:$tablet-width-s + 100) {
        padding: 70px 0px 70px;
        margin-bottom: -80px;

	}

}

.library__container {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
    transform: matrix(1,0.05,0,1,0,0);
}
.library__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 65px;
    @media (min-width: $tablet-width-s) and (max-width: $desktop-width-m - 1) {
        margin-bottom: 43px;
    }
    @media (min-width: $mobile-width) and (max-width: $tablet-width-s - 1) {
        font-size: 32px;
        margin-bottom: 32px;

    }
}

.library__items {
    flex-wrap: wrap;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 22px;

    @media (min-width: $desktop-width-xl + 241) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: $desktop-width-l - 1) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $tablet-width-l - 193) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.library__item {
    min-height: 266px;
    min-width: 372px;
    margin-bottom: 22px;
    background: #000000;
    border: 2px solid #21A3DC;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 80px 24px 24px;
    @media (min-width: $mobile-width) and (max-width: $tablet-width-s - 1) {
        min-height: 234px;
        min-width: 336px;

    }
    @media (min-width: $mobile-width) and (max-width: $mobile-width + 39) {
        min-width: 0;
        padding: 80px 0px 24px;
    }
    
}
.library__footer {
    @media (min-width: $mobile-width) and (max-width: $mobile-width + 39) {
        margin-left: 24px;
    }
}
.library__info-item-img {
    text-align: center;
}

.library__item-image {
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: transparent;
    padding-bottom: 15px;
}

.library__item-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    max-width: 245px;
    color: #FFFFFF;
    margin-bottom: 0px;
    min-height: 40px;
    display: block;
}

.library__link-exten {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #21A3DC;
}

