.members {
	@include d-flex;
	@include flex-wrap;

	@media (min-width:$desktop-width-m) {
		margin-left: -(100%/6*2);
		margin-right: -(100%/6*2);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-left: -(100%/6);
		margin-right: -40px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		left: -10px;
		margin-left: -(100%/3);
		margin-right: -60px;
		position: relative;
	}

	&.members_nomx {
		left: 0;
		margin-left: 0;
		margin-right: 0;
	}
	@media (max-width:$tablet-width-s) {
		text-align: -webkit-center;
	}
	
}

.members__col {
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		@include flex-width(100%/5);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
		@include flex-width(100%/4);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/3);
	}

	@media (min-width:$tablet-width-s) and (max-width:$tablet-width-m - 1) {
		@include flex-width(100%/2);
	}

	@media (min-width:$tablet-width-s) {
		padding: 0 10px;
	}

	@media (max-width:$tablet-width-s - 1) {
		@include flex-width(100%);
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		margin-bottom: 32px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-bottom: 24px;
	}

	&.members__col_mb {
		@media (min-width:$tablet-width-m) {
			margin: 0 0 36px;
		}
	}

	&.members__col_md {
		@media (min-width:$desktop-width-l) {
			@include flex-width(100%/3);
		}

		@media (min-width:$desktop-width-m) and (max-width:$desktop-width-l - 1) {
			@include flex-width(100%/2);
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
			@include flex-width(100%/3);
		}

		@media (min-width:$tablet-width-s) and (max-width:$tablet-width-l - 1) {
			@include flex-width(100%/2);
		}

		@media (max-width:$tablet-width-s - 1) {
			@include flex-width(100%);
		}
	}

	&.members__col_nophoto {
		.members__item {
			@include before;
			overflow: hidden;

			&:before {
				@include pos(0,0);
				bottom: 0;
				right: 0;
				z-index: 1;
				background: #E6E6E6;
			}
		}

		.members__top {
			@include zi-2;
			padding: 36px 10px 10px;
		}

		.members__footer {
			z-index: 2;

			@media (max-width:$tablet-width-s - 1) {
				position: relative;
				left: auto;
				right: auto;
				bottom: -4px;
			}
		}
	}
}

.members__item {
	min-height: 423px;
	display: block;
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	border: 2px solid #21A3DC;
	@media (max-width:$tablet-width-s) {
		max-width: 300px;	
	}
}

.members__top {
	min-height: 262px;
	display: block;
	text-align: center;
	box-sizing: border-box;

	@media (min-width:$tablet-width-s) {
		height: 100%;
	}
}

.members__nophoto {
	max-width: 100%;
	margin: 0 0 10px;
}

.members__info {
	@include fs-lh(10px,12px);
	color: rgba(0, 0, 0, 0.5);
	display: block;
}

.members__footer {
	@include border(2px 0 0,#21A3DC);
	border-width: 2px 0 0;
    border-style: solid;
    border-color: #21a3dc;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0px;
    min-height: 200px;
    box-sizing: border-box;
    padding: 16px 12px 12px;
	color: $color__theme_black;
	background: $color__theme_white;

	&.members__footer_flex {
		@include d-flex;
		@include flex-column;
		@include justify-content-between;
		padding-bottom: 30px;
	}
	@media (max-width:$tablet-width-s) {
		padding: 16px 16px 16px;
		text-align: left;
	}
}

.members__title {
	@include fs-lh(20px,24px);
	font-weight: 600;
	display: block;
	margin: 0 0 16px;
}

.members__text {
	@include fs-lh(14px,17px);
	display: block;
	color: rgba(0, 0, 0, 0.6);
	height: 30px;
}

.members__social {
	padding-bottom: 20px;
    position: absolute;
    bottom: 0;
	display: flex;
}
.members__social-link {
	width: 20px;
    height: 20px;
    margin: 0 15px 0 0;
    color: #53595d;
    transition: ease .3s all;
	display: inline-block;
    vertical-align: top;
}
