.tab {
   
    &.tab_agancy {
        margin: 40px 0 0 0;
        display: flex;
        justify-content: space-between;
        @media (max-width:$tablet-width-m - 1) {
           flex-direction: column-reverse;
           margin: 23px 0 0 0;
        }
    }
}

.tab__content {
    p {
        color: $color__theme_white;
        @include fs-lh(16px, 20px);
        font-weight: 400;
    }
    ul {
        li {
            list-style-type:none;
            color: $color__theme_white;
            @include fs-lh(16px, 20px);
            font-weight: 400;
            position: relative;
            padding-left: 10px;
            &::before {
                content: '•';
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 5px;
                //background: $color__theme_white;
               //background-repeat: no-repeat;
                border-radius: 50%;
                color: $color__theme_white;
            }
        }
    }
}

.tab__slide {
    p {
        color: $color__theme_white;
        @include fs-lh(16px, 20px);
        font-weight: 400;
    }
}
//agancy 

.tab_agancy  {
    .tab__col {
        &.tab__col_left {
            @include flex-width(100%/12*5);
            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*5);
            }
            @media (max-width:$tablet-width-m - 1) {
                @include flex-width(100%/8*8);
                margin-top: 23px;
            }
        }
    
        &.tab__col_right {
            @include flex-width(100%/12*2.3);
            @media (max-width:$desktop-width-m - 1){
                @include flex-width(100%/12*2.3);
            }
            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*2);
            }
            @media (max-width:$tablet-width-m - 1) {
                @include flex-width(100%/8*6.5);
                margin-left: -40px;
                background: #395c91;
                width: 200%;
            }
        }

        &.tab__col_laptop {
            display: block;
            @media (max-width:$tablet-width-m - 1) {
               display: none;
            }
        }
        &.tab__col_mobile {
            display: none;
            @media (max-width:$tablet-width-m - 1) {
               display: block;
            }
        }
    }
    
    .tab__content {
        //opacity: 0;
        display: none;
        animation: showContent 1s forwards;
        p {
            color: $color__theme_white;
            @include fs-lh(16px, 20px);
            font-weight: 400;
        }
        ul {
            li {
                list-style-type:none;
                color: $color__theme_white;
                @include fs-lh(16px, 20px);
                font-weight: 400;
                position: relative;
                padding-left: 10px;
                &::before {
                    content: '•';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    //background: $color__theme_white;
                   //background-repeat: no-repeat;
                    border-radius: 50%;
                    color: $color__theme_white;
                }
            }
        }
    }

    .tab__content_active {
        display: block;
    }

    .content-visible {
       display: block!important;
    }
    
    .tab__link {
        display: inline-block;
        margin: 16px 0 0 0;
        color: $color__theme_white;
        @include fs-lh(16px, 20px);
        font-weight: 600;
        border-bottom: 1px solid #FF0000;
        &:hover {
            border: none;
            color: $color__theme_white;
            opacity: 0.6;
        }
    }
    
    .tab__menu {
        &.tab__menu_laptop {
            text-align: end;
        }
        .tab__item {
            cursor: pointer;
            @include fs-lh(16px, 20px);
            font-weight: 600;
            color: rgba(255, 255, 255, 0.5);
            margin: 0 0 16px 0;
            @media (max-width:$tablet-width-m - 1) {
               margin: 0;
               background: #395C91;
               padding-left: 20px;
               padding-top: 25px;
               padding-bottom: 50px;
            }
        }
        @media (max-width:$tablet-width-m - 1) {
            display: flex;
            background: #395C91;
            padding-left: 25px;
            width: 130%;
        }
    }

    .simplebar-track {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;
        overflow: hidden;
        background: #F1F1F1;
        border: 2px solid #FFFFFF;
        box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 88%;
        height: 5px;
        bottom: 10px;
        left: 32px!important;
        right: 32px!important;
    }
    .simplebar-content {
        display: flex;
    }

    .simplebar-scrollbar::before{
        background: #21A3DC;
        border-radius: 8px;
        top: -1px!important;
        height: 3px!important;
    }
    .simplebar-wrapper {
        background: #395C91;
    }
}

.tab__active--agancy {
    color: $color__theme_white!important;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        //right: 130px;
        margin-left: -40px;
        background-color: #fff;
        width: 32px;
        height: 1px;
        @media (max-width:$tablet-width-m - 1) {
            display: none;
        }
    }
}

//chapter 

.tab_chapter {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 93px 0 0 0;
    @media (max-width:$desktop-width-s - 1){
	    //justify-content: space-between;
	}
    @media (max-width:$tablet-width-m - 1){
      flex-direction: column;
      align-items: flex-start;
      margin: 49px 0 0 0;
    }
    .slidesMobile {
        display: none;
        @media (max-width:$tablet-width-m - 1){
            display: block;
        }
    }

    .createSwFirst  {
        @media (max-width:$tablet-width-m - 1){
            display: none;
        }
    }
    .tab__head {
        display: none;
        @media (max-width:$tablet-width-m - 1){
           display: block;
        }
    }
    .tab__body {
        transition: 0.25s;
        display: none;
        @media (max-width:$tablet-width-m - 1){
            display: block;
        }
    }

    .tab__slide {
        @media (max-width:$tablet-width-m - 1){
            display: none;
        }
    }
    .tab__content {
      display: none;
      opacity: 0;
      transform-origin: center top;
      transform: scaleY(0.8);
      animation: showContent 1s forwards;
      height: 100%!important;
    }

    .tab__content_active {
        display: block;
    }

    .tab__col {
        &.tab__col_left {
           // margin-top: 213px;
            @include flex-width(100%/12*2.5);
            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*1.8);
               // margin-top: 0;
               margin-right: 80px;
            }
            @media (max-width:$tablet-width-m - 1){
                @include flex-width(100%/8*5);
                margin-top: 0;
            }
        }
    
        &.tab__col_right {
            @include flex-width(100%/12*7);
            margin-left: 100px;
           // margin-top: 80px;
            //overflow: hidden;
            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*5);
                margin-left: 0;
            }
            @media (max-width:$tablet-width-m - 1){
                @include flex-width(100%/8*8);
                margin-top: 27px;
                background: #fff;
            }
        }
    }
    .tab__nav {
        @media (max-width:$tablet-width-m - 1){
           margin-bottom: 51px;
           margin-left: 24px;
        }
    }
    .tab__item {
        //width: 180px;
        cursor: pointer;
        @include fs-lh(18px, 22px);
        font-weight: 700;
        color: #FFFFFF;
        margin: 0 0 20px 13px;
        transition: all 0.5s;
        @media (max-width:$desktop-width-l - 1){
          // width: 75%;
        }
        @media (max-width:$desktop-width-s - 1){
           // width: 90%;
        }
        @media (max-width:$tablet-width-m - 1){
            margin: 0 0 20px 24px;
           // width: 60%;
        }
        @media (max-width:$tablet-width-s - 1){
            margin: 0 0 20px 24px;
           // width: 160px;
        }
        span {
            padding-right: 30px;
        }
    }

    .tab__item_active {
        opacity: 1;
        position: relative;
        &::before {
            content:'';
            position: absolute;
            left: -30px;
            width: 40px;
            top: 15px;
            height: 4px;
            background: #FB0015;
            transform: rotate(-90deg);
            transition: all 0.5s;
            @media(max-width: $tablet-width-m - 1) {
                top: 21px;
            }
        }
        &::after {
            content:'';
            position: absolute;
            top: 10px;
            //right: 0;
            width: 35px;
            height: 100%;
            background: url('../img/icons/icon-tab-arrow.svg') no-repeat;
            transition: all 0.5s;
            @media (max-width:$tablet-width-m - 1){
                display: none;
            }
        }
    }

    .tab__pagination {
        @media (max-width:$tablet-width-m - 1){
            display: none!important;
        }
    }
}

.tab__materials {
    margin-top: 35px;
    &.tab__materials_laptop {
        @media (max-width:$tablet-width-m - 1){
           display: none!important;
        }
    }

    &.tab__materials_mobile {
        display: none;
        @media (max-width:$tablet-width-m - 1){
          display: block;
          opacity: 1;
          visibility: visible;
          height: auto;
        }
    }

    @media (max-width:$tablet-width-m - 1){
        margin-top: 0;
        padding-top: 40px;
        background: #fff;
    }
    .tab__title {
        @include fs-lh(26px, 31px);
        font-weight: 700;
        color: #fff;
        @media (max-width:$tablet-width-m - 1) {
            color: #000;
            padding-left: 12px;
        }
    }
    .doclist__item_pdf:before {
        background-image: url('../img/icons/icon-pdfLight.svg');
    }

    .doclist__item_box {
        @media (min-width:$tablet-width-m){
            min-height: 120px;
        }
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

}

.active__materials {
   display: block!important;
  // opacity: 1!important;
  // visibility: visible!important;
  // height: auto!important;
   //transition: all 1s;
}

.hide__materials {
    display: none!important;
    //opacity: 0;
   // visibility: hidden;
   // height: 0;
   @media (max-width:$tablet-width-m - 1){
    display: block!important;
  }
}
.tab__materials_1 {
    display: block;
    @media (max-width:$tablet-width-m - 1){
      display: none;
    }
}

.tab__materials_2  {
    display: none;
    //opacity: 0;
    //visibility: hidden;
    //height: 0;
    @media (max-width:$tablet-width-m - 1){
        display: none;
      }
}

.tab__materials_3 {
    display: none;
    //opacity: 0;
    //visibility: hidden;
    //height: 0;
    @media (max-width:$tablet-width-m - 1){
        display: none;
    }
}

.tab_hidden {
    overflow: hidden;
    @media (max-width:$desktop-width-s - 1){
		overflow: visible;
	}
}

@-webkit-keyframes showContent {
    0% {
      transform: scaleX(0.8);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes showContent {
    0% {
      transform: scaleX(0.8);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
}

.tab__pagination {
    height: 50%;
    @media (max-width:$tablet-width-m - 1) {
        display: none;
    }
    .swiper-slide-thumb-active {
        opacity: 1;
        position: relative;
        &::before {
            content:'';
            position: absolute;
            left: -30px;
            width: 40px;
            top: 20px;
            height: 4px;
            background: #FB0015;
            transform: rotate(-90deg);
            transition: all 0.5s;
        }
        &::after {
            content:'';
            position: absolute;
            left: 224px;
            top: 14px;
            //right: 0;
            width: 35px;
            height: 100%;
            background: url('../img/icons/icon-tab-arrow.svg') no-repeat;
            transition: all 0.5s;
            @media (max-width:$tablet-width-l - 1) {
                left: 210px;
            }
        }
    }
    //margin: 0 0 16px 0;
    @media (max-width:$tablet-width-m - 1) {
       margin: 0;
       background: #395C91;
       padding-left: 20px;
       padding-top: 25px;
       padding-bottom: 50px;
    }

    @media (max-width:$tablet-width-m - 1) {
        display: flex;
        background: #395C91;
        padding-left: 25px;
        width: 130%;
    }
}