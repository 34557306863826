.subscribe {
	@include d-flex;
	@include flex-wrap;
	@include justify-content-between;	
	align-items: center;
	margin-left: -10px;
	margin-right: -10px;
	padding-bottom: 216px;
	@media (max-width:$desktop-width-m - 1 ) {
		padding-bottom: 0;
	}
		&.subscribe_mobile {
		@media (min-width:$desktop-width-xl) {
			display: none;
		}
	}

	&.subscribe_noflex {
		margin-left: 0;
		margin-right: 0;

		@media (max-width:$desktop-width-xl - 1) {
			display: block;
		}
	}
}

.subscribe__col {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
	
	@media (min-width:$desktop-width-s) {
		@include flex-width(100%/10*3);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include flex-width(100%/2);
	}
	@media (min-width:$mobile-width) and (max-width:$tablet-width-s) {
		@include flex-width(100%);
	}
	&.subscribe__col_wide {
		@media (min-width:$desktop-width-s) {
			@include flex-width(100%/10*4);
		}
	}

	
	&.subscribe__col_main {
		@media (max-width:$desktop-width-s - 1) {
			@include flex-width(100%);
		}
	}
}

.subscribe__title {
	font-weight: bold;
	color: #21a3dc;
	cursor: pointer;
	&:hover {
		color: #21a3dc;
	}
	&:active {
		color: #21a3dc;
	}
	@media (min-width:$desktop-width-xl) {
		@include fs-lh(47px,50px);
	}

	@media (max-width:$desktop-width-xl - 1) {
		@include fs-lh(40px,45px);
	}

	@media (max-width:$desktop-width-l - 1) {
		@include fs-lh(35px,45px);
	}

	@media (min-width:$desktop-width-s) {
		text-align: right;
	}

	@media (max-width:$desktop-width-s - 1) {
		margin: 0 0 24px;
	}

	&.subscribe__title_light {
		color: $color__theme_white;
	}

	&.subscribe__title_sm {
		font-weight: 600;

		@media (min-width:$tablet-width-l) {
			@include fs-lh(32px,39px);
			margin: 0 0 8px;
		}

		@media (max-width:$tablet-width-l - 1) {
			@include fs-lh(20px,24px);
			margin: 0 0 12px;
		}
	}
}

.subscribe__subtitle {
	font-weight: 600;
	margin: 0 0 25px;

	@media (min-width:$desktop-width-s) {
		@include fs-lh(20px,24px);
		color: #21a3dc;
	}

	@media (max-width:$desktop-width-s - 1) {
		@include fs-lh(18px,22px);
	}

	&.subscribe__subtitle_light {
		color: $color__theme_white;
	}

	&.subscribe__subtitle_sm {
		@include fs-lh(14px,17px);
		font-weight: 400;

		@media (min-width:$tablet-width-l) {
			margin: 0 0 40px;
		}

		@media (max-width:$tablet-width-l - 1) {
			margin: 0 0 22px;
		}
	}
}

.subscribe__form {
	width: 100%;
	margin: 0 0 10px;
	position: relative;
	border-radius: 2px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		padding: 2px 110px 2px 2px;
		border-radius: 2px;
		background: rgba(83, 89, 93, 0.2);
	}

	&.subscribe__form_white {
		background: $color__theme_white;
	}

	&.subscribe__form_colored {
		padding: 0;
		background: none;

		@media (min-width:$tablet-width-l) {
			padding-right: calc((100%/6*2) + 20px);
		}

		.subscribe__field {
			height: 32px;
			padding: 8px 12px;
			border-radius: 16px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			background: $color__theme_white;
			box-shadow: inset 0px 2px 2px rgba(40, 53, 132, 0.5);
		}

		.subscribe__btn {
			@include fs-lh(20px,24px);
			font-weight: 600;
			height: 32px;
			padding: 2px 12px;
			border-radius: 16px;
			background: #283584;
			color: $color__theme_white;

			@media (min-width:$tablet-width-l) {
				width: (100%/6*2);
				position: absolute;
				top: 0;
				right: 0;
			}

			@media (max-width:$tablet-width-l - 1) {
				width: 100%;
			}
		}
	}

	&.focused {
		background: $color__theme_white;

		.subscribe__btn {
			color: $color__theme_white;
			background: #21A3DC;
		}
	}

	&.subscribe__form_error {
		margin: 0 0 8px;
		background: $color__theme_white;
		border: 2px solid $color__theme_red;

		.subscribe__btn {
			color: $color__theme_white;
			background: rgba(0, 0, 0, 0.3);
		}

		& + .subscribe__error {
			display: block;
		}
	}
}

.subscribe__error {
	@include fs-lh(12px,15px);
	color: $color__theme_red;
	display: none;
}

.subscribe__field {
	box-sizing: border-box;

	&:invalid {
		border: 0;
		box-shadow: none;
	}

	@media (min-width:$desktop-width-s) {
		@include size(100%,26px);
		@include fs-lh(16px,20px);
		border: 0;
		padding: 3px 5px 3px 10px;
		background: rgba(0,0,0,0);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include size(100%,32px);
		@include fs-lh(12px,15px);
		@include offset(0 0 16px,8px 12px);
		text-align: center;
		border-radius: 16px;
		background: #ebf0f3;
		box-shadow: 0 2px 2px 0 rgba(40,53,132,0.5) inset;
	}
}

.subscribe__btn {
	border: 0;
	cursor: pointer;

	@media (min-width:$desktop-width-s) {
		@include size(108px,26px);
		@include fs-lh(12px,15px);
		font-weight: 600;
		position: absolute;
		top: 2px;
		right: 2px;
		border-radius: 2px;
		color: rgba(255, 255, 255, 0.5);
		background: rgba(0, 0, 0, 0.2);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include size(100%,32px);
		@include fs-lh(20px,24px);
		font-weight: 600;
		border-radius: 16px;
		background: #283584;
		color: $color__theme_white;
	}
}

.subscribe__info {
	@include d-flex;
	@include flex-column;
	justify-content: center;
	box-sizing: border-box;
	min-height: 200px;
	// padding: 20px;
	text-align: center;
	border-radius: 16px;
	background: $color__theme_black;
	position: relative;
	-webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    cursor: pointer;
	background-color: #EEEEED;
	will-change: transform;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
	// &:hover {
	// 	-webkit-transform: scale(1.1);
	// 			transform: scale(1.1)
	// }
	@media (max-width: $desktop-width-s) {
		max-width: 85%;
		margin-left: 20px;
	}
	.subscribe__text {
		margin: 0 0 50px;
		color: $color__theme_white;
	}

	.subscribe__link {
		color: $color__theme_white;
		background-color: #1F1E1E;
		padding: 0 18px;
		-webkit-transition: all 1.2s ease;
		transition: all 1.2s ease;
		padding: 90px 112px;
		@media (max-width:$tablet-width-s - 120) and (max-width:$tablet-width-s) {
			width: 334px;
		}
		@media (min-width:$mobile-width ) and (max-width:$tablet-width-s - 121) {
			width: 290px;
		}
	}
}

.subscribe__text {
	@include fs-lh(16px,20px);
	font-weight: 500;
}




.subscribe__link {
	@include fs-lh(28px,32px);
	font-weight: 600;


}
.subscribe__link {

	@media (min-width:$tablet-width-s) and (max-width:$tablet-width-m) {
		font-size: 20px;
	}
	// @media (max-width:$tablet-width-s - 1) {
	// 	font-size: 16px;
	// }
	// @media (min-width:$mobile-width ) and (max-width:$tablet-width-s - 121) {
	// 	font-size: 13px;
	// }
} 


.subscribe__footer {
	@include fs-lh(12px,14px);
	color: rgba(0,0,0,0.5);
	@media (min-width:$mobile-width) and (max-width:$tablet-width-s) {
	margin-bottom: 40px;
	}
}

.container__info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
    z-index: -1;
    width: 344px;
	@media (min-width: $tablet-width-m ) and (max-width: $tablet-width-l - 1) {
		width: 250px;
	}
	@media (max-width: $tablet-width-m - 1) {
		width: 200px;
	}
	@media (max-width: $tablet-width-s - 1) {
		width: 344px;
	}
	@media (max-width: $mobile-width + 50) {
		width: 250px;
	}
  }
  .container__info i {
	opacity: 0;
	font-size: 28px;
	color: #1f1e1e;
	will-change: transform;
	-webkit-transform: scale(0.1);
	transform: scale(0.1);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
  }
  
  .container__info i:nth-of-type(1) {
	-webkit-transition-delay: 0.9s;
	transition-delay: 0.9s;
  }
  
  .container__info i:nth-of-type(2) {
	-webkit-transition-delay: 0.7s;
	transition-delay: 0.7s;
  }
  
  .container__info i:nth-of-type(3) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
  }
  
  .subscribe__info:hover .subscribe__link {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
	-webkit-transform: translateX(-460px);
	transform: translateX(-460px);
  }
  
  .subscribe__info:hover i {
	opacity: 1;
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
	@media (min-width: $tablet-width-s) and (max-width: $tablet-width-l - 1){
		-webkit-transform: scale(1.3);
		transform: scale(1.3);
	}
  }
.subscribe__item {
	width: 33%;
	text-align: center;
}

.social__item-info {
	vertical-align: top;
	display: inline-block;
	opacity: .8;
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 32px;
    height: 26px;
    transition: all .3s ease 0s;
}