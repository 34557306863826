.block {
	box-sizing: border-box;
	
	@media (min-width:$tablet-width-l) {
		@include border(2px 2px 0,$color__theme_black);
		border-radius: 16px 16px 0 0;
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include border(2px,$color__theme_black);
		border-radius: 16px;
	}

	@media (min-width:$tablet-width-m) {
		padding-top: 32px;
		padding-bottom: 32px;
		background-color: $color__theme_white;
		// box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.25);
	}
	
	/* height */
	&.block_index {
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
			@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
				padding-top: 22px;
			}
		}
	}
}