.notfound {
	@include inline-block;
    position: relative;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 500px;
    margin: 0 0 1vw;
}

.notfound__title {
	@include inline-block;
    line-height: 1;
    transition: all 1s ease-in-out;
	opacity: 0.1;
	
	@media (min-width:$desktop-width-m) {
		font-size: 30vw;
	}

	@media (max-width:$desktop-width-m - 1) {
		font-size: 40vw;
	}
}

.notfound__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.notfound__item {
    position: absolute;
    width: 5vw;
    width: 7%;
    display: block;
    border-radius: 0.25rem;
    transition: all 1s ease-in-out;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.notfound__img {
    transition: all 0.2s ease-in-out;
}

.notfound__item:hover {
    z-index: 1;
}

.notfound__item:hover .notfound__img {
    transform: scale(1.5) !important;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
}

.notfound__item:nth-child(1) {
    left: 19%;
    top: 18%;
}
.notfound__item:nth-child(2) {
    left: 15%;
    top: 25%;
}
.notfound__item:nth-child(3) {
    left: 12%;
    top: 32%;
}
.notfound__item:nth-child(4) {
    left: 8%;
    top: 39%;
}
.notfound__item:nth-child(5) {
    left: 4%;
    top: 47%;
}
.notfound__item:nth-child(6) {
    left: 1%;
    top: 55%;
}
.notfound__item:nth-child(7) {
    left: -2%;
    top: 62%;
}
.notfound__item:nth-child(8) {
    left: 5%;
    top: 62%;
}
.notfound__item:nth-child(9) {
    left: 12%;
    top: 62%;
}
.notfound__item:nth-child(10) {
    left: 19%;
    top: 62%;
}
.notfound__item:nth-child(11) {
    left: 25.5%;
    top: 62%;
}
.notfound__item:nth-child(12) {
    left: 19%;
    top: 27%;
}
.notfound__item:nth-child(13) {
    left: 19%;
    top: 35.5%;
}
.notfound__item:nth-child(14) {
    left: 19%;
    top: 43.5%;
}
.notfound__item:nth-child(15) {
    left: 19%;
    top: 53%;
}
.notfound__item:nth-child(16) {
    left: 19%;
    top: 70%;
}
.notfound__item:nth-child(17) {
    left: 19%;
    top: 79%;
}
.notfound__item:nth-child(18) {
    left: 41%;
    top: 20%;
}
.notfound__item:nth-child(19) {
    left: 38%;
    top: 26%;
}
.notfound__item:nth-child(20) {
    left: 36%;
    top: 34%;
}
.notfound__item:nth-child(21) {
    left: 35%;
    top: 43%;
}
.notfound__item:nth-child(22) {
    left: 35%;
    top: 52%;
}
.notfound__item:nth-child(23) {
    left: 35.5%;
    top: 61%;
}
.notfound__item:nth-child(24) {
    left: 37%;
    top: 69%;
}
.notfound__item:nth-child(25) {
    left: 41%;
    top: 76%;
}
.notfound__item:nth-child(26) {
    left: 47%;
    top: 79%;
}
.notfound__item:nth-child(27) {
    left: 52%;
    top: 76%;
}
.notfound__item:nth-child(28) {
    left: 55.5%;
    top: 69%;
}
.notfound__item:nth-child(29) {
    left: 57%;
    top: 61%;
}
.notfound__item:nth-child(30) {
    left: 58%;
    top: 52%;
}
.notfound__item:nth-child(31) {
    left: 58%;
    top: 43%;
}
.notfound__item:nth-child(32) {
    left: 57%;
    top: 34%;
}
.notfound__item:nth-child(33) {
    left: 55%;
    top: 26%;
}
.notfound__item:nth-child(34) {
    left: 52%;
    top: 20%;
}
.notfound__item:nth-child(35) {
    left: 47%;
    top: 18%;
}
.notfound__item:nth-child(36) {
    left: 88%;
    top: 18%;
}
.notfound__item:nth-child(37) {
    left: 84%;
    top: 25%;
}
.notfound__item:nth-child(38) {
    left: 81%;
    top: 32%;
}
.notfound__item:nth-child(39) {
    left: 77%;
    top: 39%;
}
.notfound__item:nth-child(40) {
    left: 73%;
    top: 47%;
}
.notfound__item:nth-child(41) {
    left: 70%;
    top: 55%;
}
.notfound__item:nth-child(42) {
    left: 67%;
    top: 62%;
}
.notfound__item:nth-child(43) {
    left: 74%;
    top: 62%;
}
.notfound__item:nth-child(44) {
    left: 81%;
    top: 62%;
}
.notfound__item:nth-child(45) {
    left: 88%;
    top: 62%;
}
.notfound__item:nth-child(46) {
    left: 94.5%;
    top: 62%;
}
.notfound__item:nth-child(47) {
    left: 88%;
    top: 27%;
}
.notfound__item:nth-child(48) {
    left: 88%;
    top: 35.5%;
}
.notfound__item:nth-child(49) {
    left: 88%;
    top: 43.5%;
}
.notfound__item:nth-child(50) {
    left: 88%;
    top: 53%;
}
.notfound__item:nth-child(51) {
    left: 88%;
    top: 70%;
}
.notfound__item:nth-child(52) {
    left: 88%;
    top: 79%;
}

.notfound__img {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    display: block;
    transition: all 0.3s ease-in-out;
}

@media (max-width: $tablet-width-m - 1) {
    .notfound__item,
    .notfound__img {
        border-radius: 0.125rem;
	}
}