.feature {
	box-sizing: border-box;
	display: block;
	color: $color__theme_white;

	&:hover,
	&:focus {
		color: $color__theme_white;
	}

	&:hover {
		.feature__icon {
			@media (min-width:$desktop-width-s) {
				@include multi-prop(rotate(45deg));
				border-color: rgba(0, 0, 0, 0);
			}
		}
	}

	@media (min-width:$desktop-width-m) {
		right: 10px;
	}

	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
		right: (100%/4);
	}

	@media (min-width:$desktop-width-s) {
		@include border(0 0 1px, $color__theme_white);
		padding: 0 0 12px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		@include border(0 0 0 2px, $color__theme_white);
		right: (100%/3);
		padding: 0 0 0 9px;
	}

	@media (min-width:$tablet-width-m) {
		position: absolute;
		left: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 0 38px, 12px 0 0 24px);
		position: relative;
	}

	@media (max-width:$tablet-width-m - 312) {
		opacity: 1 !important;

	}

	&:hover {
		color: $color__theme_white;
	}

	/* desktop */
	&.feature_desktop {
		@media (min-width:$desktop-width-s) {
			padding-right: 128px;
			padding-left: 10px;
			border: 0;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			padding-left: 7px;
			padding-right: 10px;
			margin-left: 10px;
		}

		@media (min-width:$tablet-width-m) {
			position: absolute;
			padding-bottom: 98px;
		}

		.feature__subtitle {
			@media (min-width:$desktop-width-s) {
				@include border(0 0 1px, $color__theme_white);
				padding: 0 0 12px;
			}
		}

		.feature__footer {
			//overflow: visible;
			//border: 1px solid #f00;

			@media (min-width:$desktop-width-s) {
				@include size(96px, 66px);
				right: 32px;
				bottom: 33px;

				&:before {
					@include pos(0, 0);
					@include size(120%, 100%);
					@include multi-prop(rotate(35deg));
					-o-transform-origin: left top;
					-moz-transform-origin: left top;
					-webkit-transform-origin: left top;
					transform-origin: left top;
				}
			}

			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}

		.feature__icon {
			@media (min-width:$desktop-width-s) {
				right: 0;
				bottom: 0;
			}

			@media (min-width:$desktop-width-m) and (max-width:$desktop-width-s - 1) {
				right: -20px;
				bottom: -20px;
			}
		}
	}
	
	&.feature_desktop-1 {
		@media (min-width:$desktop-width-m) {
			right: (100%/10*7);
		}

		@media (min-width:$desktop-width-s) {
			left: 0;
			bottom: 50%;
		}
		@media (min-width:$desktop-width-s ) and (max-width:$desktop-width-l - 74 ){
			bottom: 33%;
			left: -50px;
			right: 79%;
		}
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			left: (100%/8);
			right: (100%/8*5);
			bottom: 52%;
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			top: 0;
			bottom: 52%;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			height: 376px;
			bottom: 28%;
		}
	}
	@media (min-width:$desktop-width-l - 73 ) and (max-width:$desktop-width-l - 1 ){
		
		}
	&.feature_desktop-2 {
		@media (min-width:$desktop-width-m) {
			left: (100%/10*2);
			right: (100%/10*5);
		}


		@media (min-width:$desktop-width-s) {
			bottom: 65%;
		}
		@media (min-width:$desktop-width-s ) and (max-width:$desktop-width-l - 74 ){
			left: 17%;
			right: 59%;
			margin-left: -40px;
			bottom: 49%;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			left: 30%;
			right: 37.5%;
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			bottom: 52%;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			height: 280px;
			bottom: 26%;
		}
	}

	&.feature_desktop-3 {
		@media (min-width:$desktop-width-l) and (max-width:$desktop-width-xl + 100) {
			bottom: 50%!important;
		}
		@media (min-width:$desktop-width-m) {
			left: (100%/10*6);
			right: (100%/10);
		}

		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
			left: (100%/7*4);
			right: 0;
		}

		@media (min-width:$desktop-width-s) {
			padding-left: 128px;
			padding-right: 10px;
			padding-bottom: 182px;
			bottom: 60%;
		}
		@media (min-width:$desktop-width-s ) and (max-width:$desktop-width-l - 74 ){
			bottom: 55%;
			left: 50%;
		}
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			left: (100%/8*5);
			right: 0;
			top: 0;
		}

		@media (min-width:$laptop-width) and (max-width:$desktop-width-s - 1) {
			top: -10%;
			bottom: 65%;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			height: 260px;
			bottom: 33%;
		}

		.feature__icon {
			@media (min-width:$desktop-width-s) {
				left: 0;
				bottom: -20px;
			}
		}

		.feature__footer {
			height: 170px;
			left: 32px;
			bottom: 13px;

			&:before {
				@include size(100%, 120%);
				@include multi-prop(rotate(30deg));
				left: 96px;
			}
		}
	}

	&.feature_desktop-4 {
		@media (min-width:$desktop-width-l) and (max-width:$desktop-width-xl + 100) {
			bottom: 40%!important;
		}
		@media (min-width:$desktop-width-m) {
			left: (100%/10*7);
		}

		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
			left: (100%/7*5);
			margin-right: -40px;
		}

		@media (min-width:$desktop-width-s) {
			padding-left: 128px;
			padding-right: 10px;
			bottom: 50%;
		}
		@media (min-width:$desktop-width-s ) and (max-width:$desktop-width-l - 74 ){
			bottom: 34%;
			left: 62%;
			right: 44px!important;
		}
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			left: (100%/8*6);
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			bottom: 52%;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			height: 182px;
			bottom: 28%;
		}

		@media (min-width:$tablet-width-m) {
			right: 0;
		}

		.feature__icon {
			@media (min-width:$desktop-width-s) {
				left: 0;
				bottom: -20px;
			}
		}

		.feature__footer {
			height: 86px;
			left: 32px;
			bottom: 13px;

			&:before {
				@include multi-prop(rotate(-42deg));
				width: 130%;
				right: 0;
				left: auto;
				-o-transform-origin: right top;
				-moz-transform-origin: right top;
				-webkit-transform-origin: right top;
				transform-origin: right top;
			}
		}
	}

	/* height */
	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
		@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
			.feature__title {
				@include fs-lh(18px, 22px);
				margin: 0 0 8px;
			}

			.feature__subtitle {
				@include fs-lh(12px, 16px);
				padding: 0 0 8px;
			}
		}
	}
}

.feature__title {
	@include zi-2;
	font-weight: bold;
	display: block;
	cursor: pointer;
	@media (min-width:$tablet-width-m) {
		@include fs-lh(22px, 26px);
		margin: 0 0 12px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(18px, 22px);
		margin: 0 0 8px;
	}

	@media (max-width:$tablet-width-m - 312) {
		font-weight: 700;
		font-size: 20px;
		line-height: 120%;
		margin: 0 0 8px 17px;
		width: 85%;
	}

}
.feature__width {
	width: 210px;
}

.feature__title-br {
    @media (min-width:$tablet-width-s - 23){
        display:none;
    }
}

.feature__subtitle {
	@include zi-2;
	@include fs-lh(14px, 17px);
	display: block;
}

.feature__icon {
	@include before;
	box-sizing: border-box;
	position: absolute;
	display: block;
	border-radius: 50%;

	&:before {
		border-radius: 50%;
		//background: #fb0015;
		background: #000;
	}

	&:after {
		@include pos(50%, 50%);
		content: '';
	}

	@media (min-width:$desktop-width-s) {
		@include size(68px, 68px);
		@include default-transition;
		right: -98px;
		bottom: -98px;
		border: 12px solid rgba(251, 0, 21, 0.6);

		&:before {
			@include pos(0, 0);
			@include size(44px, 44px);
		}

		&:after {
			@include size(20px, 20px);
			margin: -10px 0 0 -10px;
			background: url(../img/icons/icon-plus.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
		}
	}

	@media (max-width:$tablet-width-m - 312) {
		&:before {
			background: #FB0015 !important;
		}
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		@include size(40px, 40px);
		left: -20px;
		bottom: -20px;
		background: rgba(251, 0, 21, 0.6);

		&:before {
			@include pos(50%, 50%);
			@include size(26px, 26px);
			margin: -13px 0 0 -13px;
		}

		&:after {
			@include size(12px, 12px);
			margin: -6px 0 0 -6px;
			border-radius: 50%;
			background: $color__theme_white;
		}
	}

	@media (min-width:$tablet-width-m) {
		z-index: 2;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include size(46px, 46px);
		top: 0;
		left: 0;
		z-index: 1;
		background: rgba(251, 0, 21, 0.6);

		&:before {
			@include pos(50%, 50%);
			@include size(30px, 30px);
			margin: -15px 0 0 -15px;

			@media (max-width:$tablet-width-m - 312) {
				@include size(20px, 20px);
				@include pos(65%, 65%);
			}
		}
	}

	@media (max-width:$tablet-width-m - 312) {
		@include size(32px, 32px);
		top: 4px;
		left: 25px;
	}
}

.feature__footer {
	@include before;
	@include size(64px, 64px);
	overflow: hidden;
	position: absolute;
	right: -64px;
	bottom: -64px;
	//background: rgba(255,0,0,0.6);

	&:before {
		@include multi-prop(rotate(45deg)) @include size(128px, 128px);
		@include pos(27px, -65px);
		border: 1px solid $color__theme_white;
	}

	@media (min-width:$desktop-width-s) {
		display: block;
	}

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}
}


