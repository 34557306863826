.header {
	@include d-flex;
	@include flex-wrap;
	@include align-items-center;
	@include container;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1040;
	color: $color__theme_white;
	box-sizing: border-box;
	-o-transition: transform 0.3s, background 0.5s;
	-ms-transition: transform 0.3s, background 0.5s;
	-moz-transition: transform 0.3s, background 0.5s;
	-webkit-transition: transform 0.3s, background 0.5s;
	transition: transform 0.3s, background 0.5s;
	//transition-timing-function: cubic-bezier(0.55, 0.05, 0.35, 0.95);

	@media (min-width:$desktop-width-l) {
		padding-top: 50px;
		padding-bottom: 10px;
		margin-bottom: 6px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
		padding-top: 52px;
		padding-bottom: 7px;
		margin-bottom: 5px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-top: 16px;
		padding-bottom: 9px;
		margin-bottom: 6px;
	}

	@media (min-width:$tablet-width-m) {
		position: fixed;
	}

	@media (max-width:$tablet-width-m - 1) {
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
	}
	
	&.header_inner {
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;

		@media (max-width:$tablet-width-m - 1) {
			margin-bottom: 28px;
		}
	}
	
	&.header_up {
		background: $color__theme_black;

		@media (min-width:$desktop-width-l) {
			@include multi-prop(translateY(-20px));
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
			@include multi-prop(translateY(-21px));
		}

		@media (max-width:$tablet-width-m - 1) {
			@include multi-prop(translateY(-8px));
			
		}

		.header__toggler {
			&.header__toggler_search {
				@media (min-width:$desktop-width-m) {
					@include multi-prop(rotateX(90deg));
				}
			}
		}
	}
	
	&.header_down {
		@include multi-prop(translateY(-150px));
	}
	
	&.header_fixed {
		@media (max-width:$desktop-width-m - 1) {
			@include before;
			@include align-items-start;
			@include multi-prop(translate(0,0));
			position: fixed;
			bottom: 0;
			margin-bottom: 0;
			color: $color__theme_white;
			background: $color__theme_black;
			
			&:before {
				@include zi-2;
				@include pos(0,0);
				right: 0;
				background: $color__theme_black;
			}
		
			.logo {
				color: $color__theme_white;

				&:before {
					background-image: url(../img/icons/icon-logo.svg);
				}
			}
			
			.header__col{
				position: relative;
				// z-index: 3;
			}

			.header__toggler {
				&.header__toggler_lang {
					display: none;
				}

				&.header__toggler_search {
					display: none;
				}

				&.header__toggler_menu {
					background-size: auto 100%;
					background-image: url(../img/icons/icon-close.svg);
					margin-top: 20px;
					@media (max-width:$tablet-width-m - 1) { 
						margin-top: 9px;
						margin-right: -6px;
					}
				}
			
			}
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
			padding-top: 52px;

			&:before {
				height: 124px;
			}
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-top: 16px;

			&:before {
				height: 90px;
			}
		}
		
		.megamenu {
			@media (max-width:$desktop-width-m - 1) {
				display: block;
			}
		}
	}
}

[class*="stage-"] {
	.header {
		@media (max-width:$tablet-width-m - 1) {
			position: fixed;
		}
	}
}

.header__col {
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
		// margin-bottom: -30px;
	}

	&.header__col_left {
		z-index: 995;
		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12*4);
			margin-left: (100%/12);
		}

		@media (max-width:$desktop-width-m - 1) {
			@include flex-width(100%/8*6);
		}
		@media (max-width:$tablet-width-m - 1) {
			margin-bottom: -25px;
		}
	}

	&.header__col_right {
		position: relative;
		text-align: center;
		z-index: 995;
		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12);
		}

		@media (max-width:$desktop-width-m - 1) {
			@include d-flex;
			@include flex-wrap;
			@include align-items-center;
			@include justify-content-between;
			@include flex-width(100%/8*2);
			-ms-flex-direction: row-reverse;
			flex-direction: row-reverse;
		}
		@media (min-width:$tablet-width-m - 1) {
			margin-top: -23px;
		}
	}
}

.header__nav {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include d-flex;
		@include flex-wrap;
		//@include justify-content-between;
		@include flex-width(100%/12*6);
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-around;
		margin-top: -20px;
	}

	@media (max-width:$desktop-width-m - 1) {
		display: none;
	}
}

.header__nav-link {
	@include fs-lh(20px,24px);
	@include default-transition;
	font-weight: 300;
	letter-spacing: 0.01em;
	color: $color__theme_white;
	
	&:hover,
	&:focus {
		color: $color__theme_white;
		text-decoration: underline !important;
	}
}

.header__toggler {
	@include multi-trans(transform 0.5s);
	border: 0;
	cursor: pointer;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		display: block;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include inline-block;
	}

	&.header__toggler_lang {
		@include size(36px,26px);
		@include fs-lh(12px,26px);
		padding: 0 0 0 7px;
		font-weight: bold;
		text-align: left;
		text-transform: uppercase;
		background: url(../img/icons/icon-lang.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
		opacity: 20%!important;

		@media (min-width:$desktop-width-m) {
			margin: 0 auto -6px auto;
		}

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	&.header__toggler_search {
		background: url(../img/icons/icon-search.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
		background-size: 100% 100%;

		@media (min-width:$desktop-width-m) {
			@include size(26px,26px);
			position: absolute;
			left: 50%;
			bottom: -60px;
			margin: 0 0 0 -13px;
		}

		@media (max-width:$desktop-width-m - 1) {
			@include size(22px,22px);
		}

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	&.header__toggler_menu {

		@media (min-width:$desktop-width-m) {
			display: none;
		}

		@media (max-width:$desktop-width-m - 1) {
			@include inline-block;
			@include size(24px,17px);
			background: url(../img/icons/icon-menu.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
			
		}
		@media (max-width:$tablet-width-m - 1) { 
			margin-bottom: 29px;
		}
	}
}

.logo {
	@include before;
	@include inline-block;

	&:before {
		@include pos(0,0);
		background: url(../img/icons/icon-logo.svg) no-repeat scroll left center rgba(0,0,0,0);
		background-size: 100% auto;
	}

	@media (min-width:$desktop-width-m) {
		//@at-root fs-lh(10px,16px);
		@include fs-lh(15px,23px);
		padding: 0 0 0 60px;

		&:before {
			padding: 48px 0 0 48px;
		}
	}

	@media (max-width:$desktop-width-m - 1) {
		//@include fs-lh(8px,12px);
		@include fs-lh(12px,17px);
		padding: 0 0 0 42px;

		&:before {
			padding: 34px 0 0 34px;
		}
	}
}

.logo__title {
	font-weight: bold;
}

.header_remove {
	background: transparent!important;
	
}