.footer {
	@include container;
	// overflow: hidden;
	position: relative;
	z-index: 15;
	color: $color__theme_white;
	background: $color__theme_black;
	@media (max-width:$desktop-width-between-m - 1) {
		z-index: 22;
	}
	@media (min-width:$desktop-width-m) {
		// margin-top: 240px;
		padding-bottom: 64px;
	}
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		// margin-top: 155px;
		padding-bottom: 40px;
	}
	@media (max-width:$tablet-width-l - 1) {
		padding: 0 6px 0px;
		// margin-top: 58px;
	}
	@media (max-width:$tablet-width-s + 100) {
		// margin-top:40px;
		padding: 0 6px 15px;
	}

	&.footer_main {
		display: none;
		@media (max-width:$desktop-width-m - 1) {
			display: block;
		}
	}
	.social {
		@media (min-width:$tablet-width-l - 1) {
			margin-bottom: 38px;
		}
		// @media (min-width:$desktop-width-s) {
		// 	position: absolute;
		// 	bottom: 170px;
		// }
		@media (max-width:$tablet-width-l - 1) {
			position: absolute;
			bottom: 60px;
		}
		// @media (min-width:$tablet-width-l) {
		// 	position: absolute;
		// 	bottom: 160px;
		// }

		@media (max-width:$tablet-width-m - 1) {
			position: absolute;
			bottom: 70px;
		}
		// @media (max-width:$tablet-width-s + 100) {
		// 	bottom: 72px;
		// }
		@media (max-width:$tablet-width-s - 1) {
	
			bottom: 60px;
		}
	}
	.footer__intro-tablet {
		// @media (max-width:$tablet-width-l - 1) {
		// 	position: absolute;
		// 	bottom: 0px;
		// }

		@media (max-width:$tablet-width-m - 1) {
			bottom: 15px;
		}

		@media (max-width:$tablet-width-s + 100) {

			bottom: 11px;
		}

		@media (max-width:$tablet-width-s - 1) {
			bottom: 6px;
		}


	}
}

.logo {
	margin: 0 0 24px;
}

// класс для всех страниц кроме главной
.footer__up {
	@media (min-width:$desktop-width-m) {
		//margin-top: 216px;
	}
	@media (min-width:$desktop-width-s)  and (max-width:$desktop-width-m - 1){
		margin-top: 164px;
	}
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		margin-top: 154px;
	}
	@media (max-width:$tablet-width-m - 1) {
		margin-top: 91px;
	}
}

.footer__up-division {
	@media (min-width:$desktop-width-s)  and (max-width:$desktop-width-m - 1){
		margin-top: 0px;
	}
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		margin-top: 0px;
	}
	@media (max-width:$tablet-width-m - 1) {
		margin-top: 91px;
	}
}
.footer__top {
	@include before;
	@include pos(0, 0);
	right: 0;
	bottom: 0;

	overflow: hidden;

	@media (min-width:$desktop-width-m) {
		top: -200px;
		height: 200px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		top: -115px;
		height: 115px;
	}

	@media (max-width:$tablet-width-m - 1) {
		top: -75px;
		height: 75px;
	}

	&:before {
		@include multi-prop(matrix(1, -.05, 0, 1, 0, 0));
		@include pos(20px, -20px);
		right: -20px;
		bottom: -20px;
		// border: 3px solid #21a3dc;
		// box-shadow: 0px 0px 24px #939da1, 0px 0px 16px #939da1;
		background: $color__theme_black;

		-webkit-transform-origin: right top;
		-moz-transform-origin: right top;
		-o-transform-origin: right top;
		-ms-transform-origin: right top;
		transform-origin: right top;
	}
}

// page Запуски
.footer__top_launch {
	&::before {
		border: 4px solid #fff;
	}
}

// page Подразделения
.footer__top_rotate {
	position: relative;
	width: 100%;

	&::before {
		content: '';
		transform: matrix(1, -.05, 0, 1, 0, 0);
		position: absolute;
		top: -70px;
		left: 30px;
		right: 30px;
		bottom: 30px;
	}
}

.footer__container {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*10);
		margin-left: (100%/12);
	}

	@media (min-width:$laptop-width) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/8*7);
	}

	@media (min-width:$tablet-width-m ) and (max-width:$laptop-width - 1) {
		margin-left: (100%/8);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
		padding-left: 6px;
		padding-right: 6px;
	}

	@media (min-width:$tablet-width-s) and (max-width:$tablet-width-s + 100) {
		padding-bottom: 102px;
	}
}
.footer__container-bottom {
	padding-bottom: 0;
}
.footer__row {
	@include d-flex;
	@include flex-wrap;


	@media (min-width:$desktop-width-m) {
		margin-left: -40px;
		margin-right: -40px;
	}

	@media (max-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin-left: 15px;
		margin-right: -21px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$laptop-width - 1) {
		// margin-left: 40px;
		// margin-right: -40px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-left: 0px;
		margin-right: -11px;
	}

	&.footer__row_min {
		margin-left: -10px;
		margin-right: -10px;
	}

	@media (max-width:$tablet-width-s - 1) {
		margin-bottom: 90px;
	}
}
.footer__row-bottom {
	@media (min-width: $mobile-width) and (max-width:$tablet-width-m - 1 ){
		margin-bottom: 0!important;
	}
}
.footer__col {
	box-sizing: border-box;
	@media (min-width:$tablet-width-l + 1) and (max-width:$desktop-width-xl) {
		padding-right: 0;
	
	}
	@media (min-width:$desktop-width-m) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-left: 26px;
		padding-right: 26px;
	}

	@media (min-width:$tablet-width-l) {
		@include flex-width(100%/3);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
	}


}

.footer__wrapper {
	display: flex;
	justify-content: space-between;
	@media (min-width:$tablet-width-l + 1) and (max-width:$desktop-width-xl) {
		margin-bottom: 40px;
	}
	@media (max-width:$tablet-width-m - 1) {
		flex-direction: column;
		justify-content: end;
		align-items: end;
	}
}

.footer__col {
	&_left {
		flex-direction: column;
		display: flex;
		justify-content: flex-end;
		@include flex-width(100%/12*3.5);
		@media (min-width:$tablet-width-l + 1) and (max-width:$desktop-width-xl) {
			@include flex-width(100%/12*4);
		}
		@media (max-width:$tablet-width-l) {
			@include flex-width(100%/12*5);
		}

		@media (max-width:$tablet-width-m - 1) {
			@include flex-width(100%/8*8);
		}
	}

	&_center {
		display: flex;
		justify-content: end;
		flex-direction: column;
		align-items: flex-end;
		@include flex-width(100%/12*4.5);
		text-align: center;

		@media (max-width:$tablet-width-l) {
			display: none;
		}
	}

	&_right {
		@include flex-width(100%/12*5);
		display: flex;
		flex-direction: column;
		justify-content: end;
		text-align: end;
		@media (min-width:$tablet-width-l + 1) and (max-width:$desktop-width-xl) {
			@include flex-width(100%/12*4.5);
		}
		@media (max-width:$desktop-width-m) {
			@include flex-width(100%/12*4);
		}

		@media (max-width:$tablet-width-l) {
			@include flex-width(100%/12*6);
		}

		@media (max-width:$tablet-width-m - 1) {
			@include flex-width(100%/8*8);
		}
	}
}

.footer__content {
	@include fs-lh(18px, 0);
	font-weight: 500;
	display: flex;
	align-items: baseline;
	color: $color__theme_white; 
	a {
		// text-decoration: underline;
	}
}
.footer__content-box {
	display: flex;
	align-items: center;
	white-space: nowrap;
}
.footer__content-box-link {
	text-decoration: underline;
}
.footer__content-text {
	text-decoration: none!important;
}
.footer__content-img {
	width: 25px;
    height: 19px;
    background-image: url(../img/icons/icon-label.svg);
	background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
	margin-top: -2px;
	
}
.footer__develop {
	display: block;
	@include fs-lh(18px, 36px);
	font-weight: 500;
	color: $color__theme_white;
	text-decoration: underline;
}

.footer__intro {
	line-height: 22px;
	// margin: 0 0 32px;
}

.footer__nav {
	margin: 2px 0 32px;
}

.footer__intro-poem {
	@media (max-width:$tablet-width-l ) {
		margin-bottom: 29px;
	}
	@media (min-width:$tablet-width-l - 1) {
		margin-bottom: 110px;
	}

}

.footer__nav-midle {
	margin-left: 45px;

	@media (max-width:$tablet-width-l - 1) {
		margin-left: 0;
	}
}

.footer__col_desktop-nav {
	max-width: 40%;

	@media (max-width:$tablet-width-s + 100) {
		max-width: 100%;
	}
}

.footer__col_desktop-info {
	max-width: 60%;

	@media (max-width:$tablet-width-s + 100) {
		max-width: 100%;
		padding-bottom: 5px;
	}
}

.footer__title {
	@include fs-lh(28px, 34px);
	font-weight: 500;
	margin: 0 0 24px;

	&.footer__title_lg {
		@media (min-width:$tablet-width-m) {
			@include flex-width(100%/10*4);
			@include fs-lh(56px, 67px);
			font-weight: bold;
			padding-left: 10px;
			padding-right: 10px;
			margin: 0 0 60px;
			box-sizing: border-box;
			color: $color__theme_white;
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
}

.footer__link {
	@include default-transition;
	display: block;
	line-height: 28px;
	font-weight: 500;
	margin: 0 0 15px;

	&:hover {
		color: #21a3dc;
	}

	&.footer__link_min {
		@include fs-lh(14px, 17px);

		@media (min-width:$tablet-width-l) {
			margin-bottom: 0;
		}

		@media (max-width:$tablet-width-l - 1) {
			margin-right: 15px;
		}
	}
}
.footer__link-bf {
		width: 20px;
		height: 20px;
		background-size: 100% auto;
	}
.footer__contacts {
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 0 90px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/10*2);
		margin-left: (100%/10);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/7*2);
	}

	@media (max-width:$tablet-width-l - 1) {
		@include flex-width(100%);
	}
}

.footer__map {
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 0 90px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/10*5);
		margin-left: (100%/10);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/7*5);
	}

	@media (max-width:$tablet-width-l - 1) {
		@include flex-width(100%);
	}
}

.footer__bottom {
	@include d-flex;
	@include flex-wrap;
	@include justify-content-between;
	@include flex-width(100%);
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
}

.footer__content_dn {
	display: none;

	@media (max-width:$tablet-width-l) {
		display: block;
		margin-bottom: 24px;
	}

	@media (max-width:$tablet-width-m - 1) {
		display: block;
		margin-bottom: 5px;
	}
}

.footer-opacity {
	opacity: 0;
}

.section__footer {
	position: absolute !important;
	bottom: 0;
	left: 0;
	//height: 200px!important;
	z-index: 13;
	width: 100%;
	height: auto;
	//display: none;
	opacity: 0;
	visibility: hidden;
	//transition: all 1s;
	transition: all 220ms ease-in-out;

	@media (max-width:$desktop-width-between-m) {
		display: none;
	}

	@media (max-width:$desktop-width-m - 1) {
		display: none;
	}
}

.footer__show {
	//display: block;
	opacity: 1;
	visibility: visible;
}

.footer__main {
	position: relative;
	//position: absolute;
	bottom: 0;
	width: 100%;

	.footer__bottom {
		position: relative;
		z-index: 20;
		background: $color__theme_black;
		padding-bottom: 50px;

		@media (max-width:$desktop-width-xl) {
			padding-bottom: 15px;
		}

		@media (max-width:$desktop-width-m) {
			padding-top: 5px;
		}

		@media (max-width:$desktop-width-m) {
			padding-bottom: 15px;
		}

		.social {
			position: relative;
			z-index: 10;
		}
	}

	.footer__top {
		top: -140px;
		height: 150px;

		&::before {
			border-top: 1px solid #000;
		}

		@media (max-width:$desktop-width-xl) {
			top: -99px;
			height: 120px;
			z-index: 12;
		}

		@media (max-width:$tablet-width-m - 1) {
			top: -50px;
			height: 50px;
		}
	}

	.footer__develop {
		display: flex;
		//-webkit-box-pack: end;
		//-ms-flex-pack: end;
		//justify-content: end;
		justify-content: flex-end;

		&:hover {
			color: $color__theme_white;
			text-decoration: none;
		}
	}
}
.footer__main-bottom {
	@media (min-width:$tablet-width-s + 100)  and (max-width:$tablet-width-l - 1 ){
		padding-bottom: 46px !important;
		padding-top: 43px;
	}
	@media (max-width:$tablet-width-s + 99) {
		padding-bottom: 45px !important;
		padding-top: 23px;
	}

}
.section__footer {
	@media (max-width:$desktop-width-m - 1) { 
		position: relative !important;
		width: 100%;
		height: auto !important;
	}

	.footer__container {
		@media (max-width:$desktop-width-m - 1) {
			margin-left: 0;
			@include flex-width(100%/8*8);
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-bottom: 0;
		}

	}

	.footer__col {
		padding-left: 0px;
		padding-right: 44px;
		@media (min-width:$tablet-width-l + 1) and (max-width:$desktop-width-xl) {
			padding-right: 0;
		
		}
		@media (max-width:$tablet-width-l - 1) {
			padding-right: 0;
		}
	}
}

.footer__simba {
	position: relative;
	right: 5px;
	top: 4px;
	width: 20px;
	height: 20px;
}

.footer__bottom-social {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	@media (min-width: $laptop-width) and (max-width:$desktop-width-m - 1 ){
		margin-left: 30px;
	}
	@media (min-width: $tablet-width-l) and (max-width:$laptop-width - 1 ){
		margin-left: 28px;
	}
	@media (min-width: $tablet-width-m) and (max-width:$tablet-width-l - 1 ){
		margin-left: 28px;
	}
	@media (min-width: $mobile-width) and (max-width:$tablet-width-s + 99 ){
		display: block;
	}
	.social__bottom {
		display: flex;
		flex-direction: column;
	}
}
.footer__social-phone {
	color: #fff;
	font-weight: 500;
	margin-top: 4px;
	display: inline-block;
	margin-bottom: 12px;
}
.footer__social-email {
	color: #fff;
	text-decoration: underline;
	&:hover {
		color: #fff;
		text-decoration: underline;
	}
	@media (max-width: $tablet-width-s + 99) {
		margin-bottom: 34px;
	}
}
.footer__social-phone-desktop {
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	line-height: 200%;
	display: inline-block;
	&:hover {
		color: #fff;
	}
}
.footer__intro-bottom {
	font-weight: 400;
	display: flex;
    flex-direction: column;
    justify-content: end;
}

.social__item-footer {
	@media (min-width: $mobile-width + 30) and (max-width:$tablet-width-s + 99 ){
		margin: 0px 25px 42px 0!important;
	}
	@media (min-width: $mobile-width) and (max-width:$mobile-width + 29 ){
		margin: 0px 15px 42px 0!important;
	}

}