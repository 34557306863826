.person {
	color: #283584;
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		@include before;
		@include offset(0 0 65px,128px 0 0 200px);
	}

	@media (max-width:$tablet-width-m - 1) {
		text-align: center;
		margin: 0 0 44px;
	}

	&:before {
		@media (min-width:$desktop-width-s) {
			@include pos(110px,0);
			right: 0;
			height: 2px;
			background: #395C91;
		}
	}

	&.person_mt {
		@media (min-width:$desktop-width-s) {
			margin-top: -56px;
		}
	}

	&.person_col {
		@media (min-width:$desktop-width-s) {
			@include flex-width(100%);
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/8*2);
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

.person__picture {
	max-width: 100%;
	border-radius: 50%;
	border: 2px solid $color__theme_white;
	box-shadow: 4px 4px 12px #21A3DC;

	@media (min-width:$desktop-width-s) {
		@include pos(0,-40px);
	}

	@media (max-width:$desktop-width-s - 1) {
		margin: 0 0 40px;
	}
}

.person__title {
	@include fs-lh(20px,24px);
	font-weight: 600;
	margin: 0 0 12px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		max-width: 50%;
	}
}

.person__intro {
	@include fs-lh(14px,17px);
	font-weight: 500;
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		max-width: 70%;
	}
}