.request {
    &.request_display {
        display: flex;
    }
    &.request_pb {
        padding-bottom: 255px;
        @media (max-width:$desktop-width-m - 1) {
           padding-bottom: 0;
        }
    }
}

.request__bg {
    text-align: center;
    margin: 98px 0 0 0;
    @media (max-width:$desktop-width-m - 1) {
        img {
            width: 60%;
        }
    }
}

.request__col {
    &.request__col_left {
        @media (min-width:$desktop-width-m) {
            @include flex-width(100%/12*6);
            margin-right: 108px;
        }
        @media (max-width:$desktop-width-m - 1) {
            @include flex-width(100%/12*6);

        }
        @media (max-width:$tablet-width-m - 1) {
            display: none;
        }
        
    }

    &.request__col_right {
        @media (min-width:$desktop-width-m) {
            @include flex-width(100%/12*4.3);
            padding-top: 10px;
        }
        @media (max-width:$desktop-width-m - 1) {
            @include flex-width(100%/12*5);
        }
        @media (max-width:$tablet-width-m - 1) {
            @include flex-width(100%/12*12);
        }
    }
}

.request__subtitle {
    width: 70%;
    @include fs-lh(20px, 24px);
    font-weight: 600;
    color: $color__theme_white;
    @media (max-width:$desktop-width-m - 1) {
        @include fs-lh(18px, 22px);
        font-weight: 500;
        width: 80%;
    }
    @media (max-width:$tablet-width-m - 1) {
      width: 90%;
    }
}

.title__h1_desktop {
    @media (max-width:$desktop-width-m - 1) {
        display: none;
    }
}

.title__h1_mobile {
    display: none;
    @media (max-width:$desktop-width-m - 1) {
        display: block;
        text-align: left;
        margin: 0 0 40px 0;
    }
    @media (max-width:$tablet-width-m - 1) {
        @include fs-lh(26px, 32px);
        font-weight: 700;
        margin: 0 0 16px 0;
    }
}