.divider {
}

.divider__text {
	p {
		line-height: 22px;
		font-weight: 500;
	}
}

.divider__title {
	font-weight: bold;
	word-wrap: break-word;

	@media (min-width:$tablet-width-m) {
		@include fs-lh(36px,44px);
		margin: 0 0 26px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(26px,32px);
		margin: 0 0 16px;
	}

    &.divider__title_extrabold {
		font-weight: 900;
		margin: 0 0 16px;
		color: #21A3DC;

		@media (min-width:$tablet-width-m) {
			@include fs-lh(56px,67px);
		}
	
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(44px,52px);
		}
	}
}

.divider__list {
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		margin-bottom: 70px;
		margin-left: (100%/8);
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-bottom: 32px;
	}

	li {
		@include fs-lh(24px,29px);
		list-style: disc;
		font-weight: 500;
		margin: 0 0 10px 20px;
		color: #283584;
	}
}

.divider__blockquote {
	@media (min-width:$tablet-width-m) {
		margin: 0 0 60px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 32px;
	}
	
	.divider__text {
		@media (min-width:$tablet-width-m) {
			margin-left: (100%/8);
		}
	}
}