.overlay {
	position: relative;
	background: $color__theme_white;

	&.overlay__colored {
		background: #21A3DC;
	
		@media (min-width:$desktop-width-s) {
			margin-top: 200px;
		}
		
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			margin-top: 115px;
		}
	
		@media (min-width:$desktop-width-m) {
			padding-bottom: 240px;
		}
		
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
			padding-bottom: 155px;
		}
		
		@media (max-width:$tablet-width-m - 1) {
			margin-top: 75px;
			padding-bottom: 95px;
		}

		.overlay__divider {
			&:before {
				background: #21A3DC;
				box-shadow: -4px -4px 24px #21A3DC;
			}
		}

		& + .footer {
			margin-top: 0;
		}
	}
}

.overlay__divider {
	@include before;
	@include pos(0,0);
	right: 0;
	bottom: 0;
	overflow: hidden;
	
	@media (min-width:$desktop-width-s) {
		top: -200px;
		height: 200px;
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		top: -115px;
		height: 115px;
	}

	@media (max-width:$tablet-width-m - 1) {
		top: -75px;
		height: 75px;
	}
	
	&:before {
		@include multi-prop(matrix(1,-.05,0,1,0,0));
		@include pos(20px,-20px);
		right: -20px;
		bottom: -20px;
		background: $color__theme_white;
		
		-webkit-transform-origin: right top;
		-moz-transform-origin: right top;
		-o-transform-origin: right top;
		-ms-transform-origin: right top;
		transform-origin: right top;
	}
}

.overlay__content {
	@include before;
	margin-top: 105px;

	&:before {
		@include pos(-5%,0);
		right: 0;
		bottom: 0;
		z-index: 1;
		background: url(../img/bg/overlay__content.png) no-repeat scroll center top rgba(0,0,0,0);

		@media (min-width:$desktop-width-m) {
			background-size: 100% auto !important;
		}

		@media (max-width:$desktop-width-m - 1) {
			background-size: cover;
		}
	}
}

.overlay__stages {
	@include before;

	@media (min-width:$desktop-width-s) {
		padding-bottom: 470px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
		padding-bottom: 250px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-bottom: 250px;
	}

	&:before {
		@include pos(auto,0);
		right: 0;
		bottom: 0;
		z-index: 1;
		background: url(../img/bg/tower-820001.jpg) no-repeat scroll center top rgba(0,0,0,0);

		@media (min-width:$desktop-width-l) {
			top: -35%;
		}

		@media (max-width:$desktop-width-l - 1) {
			top: -40%;
		}

		@media (min-width:$desktop-width-m) {
			background-size: 100% auto !important;
		}

		@media (max-width:$desktop-width-m - 1) {
			background-size: cover;
		}
	}

	&.overlay__stages_min {
		@media (min-width:$desktop-width-s) {
			padding-bottom: 235px;
		}

		@media (max-width:$desktop-width-m - 1) {
			top: 30px;
		}

		&:before {
			@media (min-width:$desktop-width-l) {
				top: -17%;
			}
			@media (max-width:$desktop-width-l - 1) {
				top: -20%;
			}
			@media (min-width:$tablet-width-l - 1) {
				bottom: 50px;
			}
			@media (max-width:$tablet-width-l - 1) {
				bottom: -50px;
			}
			@media (max-width:$tablet-width-m - 1) {
				bottom: 50px;
			}
		}
	}
}

.overlay__bottom {
	@include zi-2;

	.overlay__divider {
		@media (min-width:$desktop-width-s) {
			top: 140px;
		}
	
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			top: 225px;
		}
	
		@media (max-width:$tablet-width-m - 1) {
			top: 165px;
		}
	}
}