/* mixin */
@mixin content__title {
	font-weight: bold;

	@media (min-width:$tablet-width-m) {
		@include fs-lh(32px, 39px);
		margin: 0 0 24px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(26px, 32px);
		text-align: center;
		margin: 0 0 12px;
	}

	&.content__title_bold {
		font-weight: 800;

		@media (min-width:$tablet-width-m) {
			@include fs-lh(36px, 44px);
		}

		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(22px, 27px);
			margin: 0 0 32px;
			text-align: left;
		}
	}

	&.content__title_lg {
		@media (min-width:$desktop-width-s) {
			@include fs-lh(56px, 67px);
			margin: 0 0 40px;
		}
	}

	&.content__title_right {
		text-align: right;
	}
}

@mixin content__subtitle {
	font-weight: 600;

	@media (min-width:$tablet-width-m) {
		@include fs-lh(24px, 29px);
		margin: 0 0 40px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(20px, 24px);
		margin: 0 0 32px;
	}
}

/* styles */
.content {
	@include before;
	color: $color__theme_black;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		padding-left: (100%/9*2);
		padding-right: (100%/9);
		margin: 0 0 142px;

		&:before {
			@include pos(0, (100%/9));
		}
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		padding-left: (100%/8);

		&:before {
			@include pos(0, (100%/15.6));
			margin: 0 0 0 -20px;
		}
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-bottom: 10px;
		padding-right: 22px;
		margin: 0 0 100px;

	}

	@media (max-width:$tablet-width-l - 1) {
		&:before {
			@include pos(0, 0);
		}
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		width: (100%/8*7);
		margin-left: (100%/8);
		padding-right: 32px;
		padding-left: (100%/8);
		margin-bottom: 136px;
	}

	@media (min-width:$tablet-width-m) {
		padding-top: 43px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 0 100px, 22px 20px 10px);
	}

	&.content_skewed {
		margin-top: 80px;
		padding-top: 70px;
		padding-bottom: 90px;

		&:before {
			-o-transform: skewY(-3deg);
			-moz-transform: skewY(-3deg);
			-webkit-transform: skewY(-3deg);
			transform: skewY(-3deg);
		}

		&:after {
			-o-transform: skewY(-3deg);
			-moz-transform: skewY(-3deg);
			-webkit-transform: skewY(-3deg);
			transform: skewY(-3deg);
		}

		.content__bottom {
			display: none;
		}
	}

	&.content_colored {
		color: $color__theme_white;

		&:before {
			border: 0;
			background: #ffffff;
			background: -moz-linear-gradient(top, #ffffff 0%, #283584 100%);
			background: -webkit-linear-gradient(top, #ffffff 0%, #283584 100%);
			background: linear-gradient(to bottom, #ffffff 0%, #283584 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#283584', GradientType=0);
		}

		&:after {
			content: '';
			right: 2px;
			bottom: 2px;
			border-radius: 14px;
			background: #21A3DC;

			@media (min-width:$desktop-width-m) {
				@include pos(2px, (100%/9));
				margin-left: 2px;
			}

			@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
				@include pos(2px, (100%/16));
				margin: 0 0 0 -16px;
			}

			@media (max-width:$tablet-width-l - 1) {
				@include pos(2px, 2px);
			}
		}
	}

	&.content_pulled {
		@media (min-width:$tablet-width-l) {
			margin-top: -340px;
		}

		@media (max-width:$tablet-width-l - 1) {
			margin-top: -240px;
		}
	}

	&.content_nobg {
		@media (max-width:$tablet-width-m - 1) {
			padding-top: 0;
			padding-bottom: 0;
			margin-bottom: 20px;
		}

		&:before {
			@media (max-width:$tablet-width-m - 1) {
				display: none;
			}
		}

		.content__footer {
			@media (max-width:$tablet-width-m - 1) {
				display: none;
			}
		}
	}

	&.content_white {
		@media (max-width:$tablet-width-m - 1) {
			padding-left: 0;
			padding-right: 0;
		}

		&:before {
			@media (max-width:$tablet-width-m - 1) {
				left: -12px;
				right: -12px;
				border: 0;
				border-radius: 0;
				box-shadow: none;
			}
		}

		.content__bottom {
			&:before {
				@media (max-width:$tablet-width-m - 1) {
					left: 10px;
					right: 10px;
					border: 0;
					border-radius: 0;
					box-shadow: none;
				}
			}
		}
	}

	&.content_black {
		@media (max-width:$desktop-width-s - 1) {
			@include offset(0 0 50px, 0);
			color: $color__theme_white;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			width: 100%;
		}

		&:before {
			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}

		.content__footer {
			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}
	}

	&:before {
		right: 0;
		bottom: 0;
		border-radius: 16px;
		border: 2px solid $color__theme_black;
		// box-shadow: 4px 4px 24px #fff;
		background: $color__theme_white;
	}

	&:after {
		// box-shadow: 4px 4px 24px #000;
	}

	h2 {
		@include content__title;
	}

	h3 {
		@include content__subtitle;
	}

	h4 {
		@include fs-lh(20px, 24px);
		font-weight: bold;
		color: #283584;
		margin: 0 0 32px;
	}

	h5 {
		@include fs-lh(16px, 20px);
		font-weight: 600;
		margin: 0 0 24px;
	}

	h6 {
		@include fs-lh(12px, 15px);
		font-weight: 600;
		margin: 0 0 16px;
	}

	p,
	li {
		font-weight: 500;
		line-height: 22px;

		a {
			color: #21a3dc;
			text-decoration: underline;

			&:hover,
			&:focus {
				color: #21a3dc;
				text-decoration: underline;
			}
		}
	}

	li {
		margin: 0 0 18px;
	}

	ul,
	ol {
		padding: 0 0 0 35px;
	}

	ol {
		li {
			list-style: decimal;
		}
	}

	ul {
		li {
			list-style: circle;
		}
	}
}

.content__title {
	@include content__title;
}

.content__subtitle {
	@include content__subtitle;
}

.content__list {
	@media (min-width:$tablet-width-m) {
		margin: 0 0 60px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 18px;
	}

	&.content__list_lg {
		li {
			font-weight: 500;

			@media (min-width:$tablet-width-m) {
				@include fs-lh(24px, 29px);
			}

			@media (max-width:$tablet-width-m - 1) {
				@include fs-lh(18px, 22px);
				margin: 0 0 10px;
			}
		}
	}

	&.content__list_colored {
		li {
			list-style: disc;
			color: #283584;
		}
	}
}

.content__blockquote {
	@include before;
	font-weight: bold;
	color: #21a3dc;

	@media (min-width:$desktop-width-m) {
		margin: 0 0 34px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin: 0 0 58px;
	}

	@media (min-width:$tablet-width-m) {
		@include fs-lh(20px, 24px);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(16px, 20px);
		margin: 0 0 34px;
	}

	&:before {
		background: url(../img/icons/icon-blockquote.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
		background-size: 100% auto;
	}

	@media (min-width:$tablet-width-m) {
		&:before {
			@include size(30px, 30px);
			@include pos(-4px, -34px);
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		&:before {
			@include inline-block;
			@include size(23px, 23px);
			position: relative;
			margin: -4px 8px 0 0;
		}
	}

	&:after {
		@include inline-block;
		@include size(30px, 30px);
		@include multi-prop(rotate(180deg));
		content: '';
		position: relative;
		background: url(../img/icons/icon-blockquote.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
		background-size: 100% auto;
	}

	@media (min-width:$tablet-width-m) {
		&:after {
			@include size(30px, 30px);
			bottom: -8px;
			margin: 0 0 0 10px;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		&:after {
			@include size(23px, 23px);
			bottom: -4px;
			margin: 0 0 0 8px;
		}
	}
}

.content__chapter {
	@media (min-width:$tablet-width-m) {
		margin: 0 0 60px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 32px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.content__divider {
	@include before;
	@include fs-lh(26px, 32px);
	@include offset(0 0 16px, 0 0 0 38px);
	font-weight: 800;
	color: #21a3dc;

	@media (min-width:$desktop-width-m) {
		margin-left: -(100%/6);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		left: -20px;
		margin-left: -(100%/16);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		left: -20px;
		margin-left: -(100%/8);
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 58px;
	}

	&:before {
		@include pos(-10px, -40px);
		@include size(70px, 52px);
		background: url(../img/icons/icon-divider.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
		background-size: cover;

		@media (max-width:$tablet-width-m - 1) {
			left: -10px;
		}
	}
}

.content__loadmore {
	@include inline-block;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.5);

	@media (min-width:$desktop-width-m) {
		width: (100%/6*5);
		margin-left: (100%/6);
		padding: 0 0 0 10px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		width: (100%/7*4);
		margin-left: (100%/7*2);
		padding: 0 0 0 10px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		margin-left: -(100%/16);
	}
}

.content__row {
	@include d-flex;
	@include flex-wrap;
	//@include align-items-start;

	@media (min-width:$desktop-width-m) {
		margin-bottom: 22px;
		margin-left: -(100%/6*2);
	}



	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		margin-bottom: 40px;
		margin-left: -(100%/4);
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 24px;
	}

	&.content__row_mb {
		margin-bottom: 42px;
	}

	&.content__row_wide {
		.content__row-side {
			@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
				@include flex-width(100%/8*6);
				margin-bottom: 16px;
			}
		}

		.content__row-text {
			@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
				@include flex-width(100%/8*7);
				margin-left: (100%/8*1);
				padding-left: 0;	
			}
		}
	}

	&.content__row_right {
		position: relative;

		@media (min-width:$desktop-width-m) {
			margin-left: 0;
			margin-right: -(100%/6*2);
			padding-right: (100%/6*2);
		}

		@media (max-width:$tablet-width-l - 1) {
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 0;
		}

		.content__row-text {
			padding-left: 0;

			@media (min-width:$desktop-width-m) {
				@include flex-width(100%);
			}

			@media (max-width:$tablet-width-l - 1) {
				@include flex-width(100%);
				// margin-bottom: 40px;
			}
		}

		.content__row-side {
			padding-right: 0;

			&:before {
				display: none;
			}

			@media (min-width:$tablet-width-l) {
				@include flex-width(100%);
				position: absolute;
				top: 0;
				left: (100%/8*6);
				padding-left: 20px;
			}

			@media (max-width:$tablet-width-l - 1) {
				@include flex-width(100%);
				margin-left: 0;
			}

			@media (max-width:$tablet-width-m - 1) {
				display: none;
			}
		}

		/*
		.content__bold {
			@media (max-width:$desktop-width-l - 1) {
				margin-left: (100%/6*2);
			}
		}
		*/
	}
}
.content__row p {
	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		max-width: 65%
	}

}
.embed {
	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-bottom: 44px;
		margin-left: -(100%/4);
	}
}
.content__row-side {
	@include before;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		padding-right: 10px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin-left: -20px;
		margin-right: -20px;
	}

	@media (min-width:$tablet-width-l) {
		@include flex-width(100%/8*3);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/8*4);
		margin-left: -20px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
		margin: 0 0 32px;
	}

	&:before {
		@include pos(auto, 18px);
		bottom: 0;
		height: 55px;
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.7);
		z-index: 1;
		-o-filter: blur(20px);
		-moz-filter: blur(20px);
		-webkit-filter: blur(20px);
		filter: blur(20px);
	}

	@media (min-width:$tablet-width-m) {
		&:before {
			right: 28px;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		&:before {
			right: 18px;
		}
	}
}

.content__row-top {
	@include before;

	@media (min-width:$tablet-width-m) {
		&:before {
			height: 265px;
			right: 28px;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		&:before {
			height: 185px;
			right: 18px;
		}
	}

	&:before {
		@include pos(21px, 18px);
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.7);
		z-index: 1;
		-o-filter: blur(20px);
		-moz-filter: blur(20px);
		-webkit-filter: blur(20px);
		filter: blur(20px);
	}
}

.content__row-picture {
	@include zi-2;
	max-width: 100%;
	margin: 0 0 34px;
	border-radius: 12px;
	border: 2px solid $color__theme_white;
	box-sizing: border-box;
}

.content__row-footer {
	@include zi-2;
	border-radius: 12px;
	border: 2px solid #21a3dc;
	box-sizing: border-box;
	background: $color__theme_white;

	@media (min-width:$desktop-width-m) {
		padding: 18px;
	}

	@media (max-width:$desktop-width-m - 1) {
		text-align: center;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding: 12px 18px 18px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding: 16px 8px;
	}

	.content__row-title {
		@include fs-lh(16px, 20px);
	}

	.content__row-subtitle {
		@include fs-lh(15px, 18px);
		font-weight: 500;
	}
}

.content__row-title {
	display: block;
	font-weight: 600;

	@media (min-width:$desktop-width-m) {
		margin: 0 0 15px;
	}

	@media (max-width:$desktop-width-m - 1) {
		margin: 0 0 8px;
	}

	&.content__row-title_bold {
		font-weight: bold;

		@media (min-width:$tablet-width-m) {
			@include fs-lh(32px, 39px);
		}

		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(18px, 22px);
		}
	}
}

.content__row-text {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		padding-left: 10px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		padding-left: 40px;
	}

	@media (min-width:$tablet-width-l) {
		@include flex-width(100%/8*5);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/8*4);
		padding-left: 20px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
	}

	&.content__row-text_column {
		@include d-flex;
		@include flex-column;
		@include justify-content-between;
	}
}

.content__bold {
	@media (max-width:$tablet-width-l - 1) {
		margin: 0 0 42px;
	}

	&.content__bold_mb {
		margin-bottom: 60px;
	}

	p {
		@include fs-lh(20px, 24px);
		font-weight: 600;
	}
}

.content__flex {
	@include d-flex;
	@include flex-wrap;

	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 38px;
}

.content__col {
	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	@media (min-width:$tablet-width-l) {
		@include flex-width(100%/2);
	}

	@media (max-width:$tablet-width-l - 1) {
		@include flex-width(100%);
	}
}

.content__col-picture {
	max-width: 100%;
	margin: 0 0 20px;
}

.content__footer {
	position: relative;
	// min-height: 20px;
}

.content__bottom {
	@include zi-1;
	@include before;
	z-index: -1;
	overflow: hidden;

	@media (min-width:$desktop-width-m) {
		@include pos(-41px, -(100%/6));
		right: -(100%/6);
		margin: -14px -30px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include pos(-30px, -(100%/16));
		right: -52px;
		margin: 0 0 0 -59px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include pos(-30px, -(100%/8));
		right: -62px;
		margin: 0 0 0 -56px;
	}

	@media (min-width:$tablet-width-m) {
		bottom: -110px!important;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include pos(-30px, -50px);
		right: -50px;
		bottom: -60px!important;

		//border: 1px solid #f00;
	}

	&:before {
		@include multi-prop(matrix(1, -.05, 0, 1, 0, 0));
		@include pos(-70px, 30px);
		right: 30px;
		bottom: 15px;
		z-index: 1;
		border-radius: 16px;
		border: 2px solid $color__theme_black;
		// box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.5);
		background: $color__theme_white;

		-webkit-transform-origin: left bottom;
		-moz-transform-origin: left bottom;
		-o-transform-origin: left bottom;
		-ms-transform-origin: left bottom;
		transform-origin: left bottom;
	}
}

.content__slides {
	margin-top: 40px;
	overflow: hidden;
	flex-grow: 1;
	position: relative;
	box-sizing: border-box;
	// box-shadow: -4px 4px 24px #21a3dc;
	background: $color__theme_white;
	@media (min-width:$desktop-width-l){
		border-radius: 16px;
		margin-left: -33%;
		margin-right: -33%;
		margin-bottom: 43px;
		padding: 0 37px 32px;
	}
	@media (min-width:$desktop-width-m)  and (max-width:$desktop-width-l - 1){
		border-radius: 16px;
		margin-left: -(100%/6*2);
		margin-right: -(100%/6*2);
		margin-bottom: 43px;
		padding: 0 37px 32px;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		left: -20px;
		margin-left: -(100%/10);
		margin-right: -80px;
		margin-bottom: 48px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding: 12px 40px 43px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		left: -50px;
		margin-left: -(100%/3);
		margin-right: -122px;
		margin-bottom: 32px;
	}

	@media (min-width:$tablet-width-m) {
		border: 2px solid #21a3dc;
		border-radius: 16px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include offset(0 -32px 32px, 8px 0 38px);
		border-bottom: 2px solid #21a3dc;
		border-top: 2px solid #21a3dc;
	}

	.img-fluid {
		max-width: 100%;
		@media (min-width:$desktop-width-m)  and (max-width:$desktop-width-l - 1){
			border-radius: 16px;
		}
		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
			border-radius: 16px;
		}
	}
}
.content__slides-simple {
	@media (min-width:$tablet-width-m) {
		border: 2px solid #21a3dc;
		flex-direction: column;
		display: flex;
		margin: 0 auto;
		border-radius: 16px;
	}
}


.content__slides-media {
	overflow: hidden;
	text-align: center;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		margin: -4px -41px 22px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin: 0 0 22px;
	}

	@media (min-width:$tablet-width-m) {
		border-radius: 16px;
		border: 2px solid $color__theme_white;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 16px;
	}
}
.content__slides-top {
	overflow: hidden;
	text-align: center;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		margin: -4px -41px 22px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin: 0 0 22px;
	}

	@media (min-width:$tablet-width-m) {
		border-radius: 16px;
		border: 2px solid $color__theme_white;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 16px;
	}
}

.content__slides-thumbs {
	overflow: hidden;
	@media (min-width:$tablet-width-m) {
		margin-left: -10px;
		margin-right: -10px;
		
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}
}

.content__slides-thumb {
	width: auto;
	cursor: pointer;
	padding-bottom: 10px;
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}

	.img-fluid {
		border-radius: 8px;
		border: 2px solid rgba(0, 0, 0, 0);
	}

	&.swiper-slide-thumb-active {	
		.img-fluid {
			border-color: #21a3dc;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
		}
	}
}

@mixin content__slides-arrow {
	@include size(auto, auto) @include offset(0, 24px 0 0 12px);
	position: absolute;
	top: auto;
	bottom: 86px;
	opacity: 0.3;
	cursor: pointer;
	background: url(../img/icons/icon-slides-nav.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
	background-size: 100% auto;

	@media (max-width:$tablet-width-m - 1) {
		display: none;
	}

	&:hover {
		opacity: 1;
	}

	&:after {
		display: none;
	}
}

.content__slides-prev {
	@include content__slides-arrow;
	left: 10px;
}

.content__slides-next {
	@include content__slides-arrow;
	@include multi-prop(rotate(180deg));
	right: 10px;
}

.content__slides-scrollbar {
	position: absolute;
	bottom: 14px;
	height: 12px;
	overflow: hidden;
	border-radius: 8px;
	background: #f1f1f1;
	box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);

	@media (min-width:$tablet-width-m) {
		left: 37px;
		right: 37px;
	}

	@media (max-width:$tablet-width-m - 1) {
		left: 12px;
		right: 12px;
	}
	.swiper-pagination-progressbar-fill {
		background: #21a3dc;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 2px;
		border-radius: 8px;
		width: 100%;
		height: 100%;
		-webkit-transform: scale(0);
				transform: scale(0);
		-webkit-transform-origin: left top;
				transform-origin: left top; }
}


.content__meta {
	box-sizing: border-box;

	@media (min-width:$desktop-width-l) {
		right: 0;
	}

	@media (max-width:$desktop-width-l - 1) {
		right: -(100%/16*2);
		padding-right: 20px;
	}
}

.content__info {
	@include offset(0 0 32px, 18px 24px);
	border-radius: 12px;
	box-sizing: border-box;
	border: 2px solid rgba(0, 0, 0, 0.5);
	background: $color__theme_white;
	// box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5), -4px 4px 24px rgba(0, 0, 0, 0.5);
	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		margin: 0 32px 32px -68px;
	}

	p {
		@include fs-lh(12px, 15px);
	}

	&.content__info_bold {
		p {
			font-weight: 600;
		}
	}

	&.content__info_mobile {
		@media (min-width:$tablet-width-m) {
			display: none;
		}

		@media (max-width:$tablet-width-m - 1) {
			@include offset(0 0 24px, 22px 16px 16px);
		}
	}

	&.content__info_noborder {
		@include offset(0 0 24px, 0);
		border: 0;
		box-shadow: none;
	}
}

.content__date {
	@include fs-lh(20px, 24px);
	font-weight: 600;
}

.content__source {
	@include fs-lh(12px, 15px);
	font-weight: 500;
	margin: 0 0 25px;
	display: block;
	color: #395c91;
	text-decoration: underline;

	&:hover {
		color: #395c91;
	}
}

.content__download {
	@include before;
	@include d-flex;
	@include flex-column;
	@include justify-content-end;
	@include fs-lh(12px, 15px);
	min-height: 70px;
	padding: 0 0 0 66px;

	&:before {
		@include pos(0, 0);
		@include size(50px, 70px);
		background: url(../img/icons/icon-document.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
		background-size: 100% auto;
	}
}

.content__download-link {
	color: #283584;
	text-decoration: underline;

	&:hover {
		color: #283584;
	}
}

.content__icon {
	@include before;
	@include inline-block;
	@include fs-lh(20px, 24px);
	@include offset(0 20px 0 0, 0 0 0 32px);
	color: rgba(0, 0, 0, 0.5);
	font-weight: 600;

	&:before {
		@include pos(50%, 0);
		background-position: 0 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-color: rgba(0, 0, 0, 0);
	}

	&.content__icon_view {
		&:before {
			@include offset(-8px 0 0, 16px 0 0 24px);
			background-image: url(../img/icons/icon-view.svg);
		}
	}

	&.content__icon_comments {
		&:before {
			@include offset(-12px 0 0, 24px 0 0 24px);
			background-image: url(../img/icons/icon-comments.svg);
		}
	}
}

.content__tableholder {
	width: 100%;
	display: block;
	margin: 0 0 30px;
	overflow-x: auto;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
}

.content__table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	box-sizing: border-box;
	background: $color__theme_white;

	tr {
		@include border(0 0 2px, rgba(0, 0, 0, 0.25));
		box-sizing: border-box;

		&:nth-child(2n) {
			background: rgba(0, 0, 0, 0.1);
		}
	}

	th {
		@include fs-lh(24px, 29px);
		font-weight: bold;
		text-align: left;
		box-sizing: border-box;
		padding: 0 8px 8px 20px;
	}

	td {
		@include fs-lh(18px, 22px);
		@include border(0 2px 0 0, rgba(0, 0, 0, 0.25));
		box-sizing: border-box;
		padding: 32px 8px 32px 20px;

		&:last-child {
			border-right: 0;
		}
	}

	a {
		color: #395C91;
		text-decoration: underline;
	}
}

.content__title-top {
	font-weight: bold;
	font-size: 48px;
	line-height: 120%;
	padding-top: 80px;
	margin-bottom: 49px;

	@media (max-width:$desktop-width-s) {
		padding-left: 140px;
	}
	@media (max-width:$tablet-width-m) {
		padding-left: 15px;
		color: #fff;
	}
	@media (max-width:$tablet-width-s) {
		padding-left: 12px;
		font-size: 26px;
		margin-bottom: 16px;
		padding-top: 0px;
	}
}

.content__title-top-white {
	color: #fff;
}
.content__title-top-black {
	color: #000;
}
.content__topic {
	width: 100% !important;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		margin-left: -30px;
		margin-right: -30px;
	}


}

.content__topic-col {
	max-width: 33%;
	padding: 0 40px;
	position: relative;
	height: auto;
	box-sizing: border-box;

	@media (max-width:$desktop-width-s) {
		padding: 0 26px;
	}

	@media (max-width:$laptop-width) {
		padding: 0 13px;
	}

	@media (max-width:$tablet-width-l - 1) {
		max-width: 50%;
		padding-bottom: 33.5px;
	}

	@media (max-width:$tablet-width-m - 1) {
		max-width: 100%;
		padding-bottom: 33.5px;
	}
}

.content__topic-item {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	box-sizing: border-box;
	border: 2px solid #fff;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25))
}

.content__topic-header {
	padding: 4px 27px 15px;
	background-color: #000;
	z-index: -1;
	min-height: 180px;

	@media (max-width:$tablet-width-s) {
		padding: 10px 18px 16px;
	}
}

.content__topic-meta {
	padding-top: 18px;
}

.content__topic-date {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000;
	margin-bottom: 11px;
	padding: 2px 6px;
	background-color: #FFFFFF;
	border-radius: 2px;
	display: inline-block;
	vertical-align: middle;


}

.content__topic-title {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #FFFFFF;

	&:hover {
		color: #FFFFFF;
	}

	@media (max-width:$tablet-width-s) {
		font-size: 18px;
	}


}

.content__topic-inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	background-color: #fff;
	padding: 11px 27px;
	box-sizing: border-box;

	@media (max-width:$tablet-width-s) {
		padding: 16px 18px;
	}

}

.content__topic-text {
	font-size: 18px;
	line-height: 22px;

	@media (max-width:$tablet-width-s) {
		font-size: 14px;
		line-height: 17px;
	}
}

.content__topic-footer {
	padding-top: 14px;
}

.taglist__topic-item {
	padding: 2px 6px;
	background: rgba(83, 89, 93, 0.4);
	border-radius: 6px;
	color: #fff;
	margin-bottom: 10px;
	display: inline-block;
	font-size: 14px;
	line-height: 17px;
}

.taglist__topic-item:before {
	content: '#';
}

.taglist__topic-item:hover {
	background: rgba(83, 89, 93, .8);
	color: #fff;
	transition: all 0.4s;
}



// .content__mobile {
// 	overflow: hidden;
// 	position: relative;
// }


//   .swiper-slide {
// 	width: inherit!important;
// 	margin-right: -90px!important;
//   }


.content__well {
	display: block;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding-top: 78px;
	@media (max-width:$desktop-width-m) {
		padding-left: 40px;
	}
	@media (max-width:$tablet-width-l) {
		padding-top: 22px;
		padding-left: 13px;
	}
	@media (max-width:$tablet-width-s) {
		padding-left: 0;
	}
}
.content__well-text {
	color: #000;
	font-size: 20px;
	line-height: 24px;
}

.content__well-arrow {
	height: 17px;
	width: 304px;
	cursor: pointer;
}

// content blue 

.content_blue {
	margin: 0;
	&::before {
		content: '';
		background: #283584;
		border: 4px solid #ffffff94;
		border-bottom: none;
	}
	.content__footer {
		@media (max-width:$tablet-width-m) {
			display: none;
		}
	}
	.content__bottom {
		&::before {
			content: '';
			background: #283584;
			border: 4px solid #a1a9e08c;
			@media (max-width:998px) {
				left: 38px;
			}
			@media (max-width:856px) {
				left: 41px;
			}
		}
	}
}
.content_top {
	&::before {
		content: '';
		border-radius: 16px 16px 0 0;
	}
}

.content_bottom-r {
	padding-top: 32px;
	@media (max-width:$desktop-width-m) {
		padding-top: 24px;
	}
	@media (max-width:$tablet-width-m - 1) {
		padding-top: 32px;
	}
	&::before {
		content: '';
		background: #283584;
		border-left: 4px solid #a1a9e08c;
		border-right: 4px solid #a1a9e08c;
		border-top: none;
		border-radius: 0!important;
		//border-radius: 0 0 16px 16px;
	}
	@media (max-width:$tablet-width-m ) {
		&::after {
			content: '';
			position: absolute;
			width: 98.9%;
			height: 50px;
			left: 0;
			margin-top: -12px;
			transform: skewY(-3deg);
			border-radius: 0 0 16px 16px;
			background: #283584;
			border-left: 4px solid #a1a9e08c;
			border-right: 4px solid #a1a9e08c;
			border-bottom: 4px solid #a1a9e08c;
			border-top: none;
		}
	}
	@media (max-width:500px) {
		&::after {
			width: 98.2%;
			height: 35px;
			left: 0;
			margin-top: -5px;
		}
	}
	@media (max-width:395px) {
		&::after {
			width: 97.8%;
		}
	}
	@media (max-width:345px) {
		&::after {
			width: 97.5%;
		}
	}
}

// color light whith bg blue 

.content_light {
	color: $color__theme_white;
	
	@media (max-width:$tablet-width-l - 1) {
		width: 100%;
	}
	.content__title {
		@include fs-lh(36px, 44px);

		@media (max-width:$tablet-width-l - 1) {
			word-break: break-word;
			width: 70%;
		}

		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(24px, 29px);
			text-align: left;
			margin: 0 0 16px;
		}
		@media (max-width:$tablet-width-s) {
			width: 100%;
		}
	}
	p {
		margin: 0;
		padding: 0 0 24px 0;
	}
	.fz-18 {
		@include fs-lh(18px, 22px);
	}
	.fz-20 {
		@include fs-lh(20px, 24px);
		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(18px, 21px);
			font-weight: 500;
		}
	}
	.text-dn {
		@media (max-width:$tablet-width-m) {
			display: none;
		}
	}
	.list {
		&.list__normal {
			padding: 0 0 0 20px;
			li {
				@include fs-lh(18px, 22px);
				margin: 0 0 1px;
				list-style: disc;
			}
		}
		&.list__bold {
			padding: 0 0 0 24px;
			margin: 0 0 24px 0;
			li {
				@include fs-lh(20px, 24px);
				font-weight: 600;
				margin: 0 0 1px;
				list-style: disc;
				@media (max-width:$tablet-width-m - 1) {
					@include fs-lh(18px, 21px);
					font-weight: 500;
				}
			}
		}
	}
	.social {
		flex-direction: column;
		position: absolute;
		right: -100px;
		top: 100px;
		.social__item {
			opacity: 1;
			margin: 0 24px 40px 0;
		}
		@media (max-width:$desktop-width-m) {
			margin: 65px 0 0 0;
			flex-direction: row;
			position: relative;
			left: 0;
			top: 0;
			.social__item {
				margin: 0 32px 0 0;
				transition: all 0.5s;
				&:hover {
					opacity: 0.5;
					color: $color__theme_white;
				}
			}
		}
		@media (max-width:$tablet-width-m) {
			margin: 40px 0 0 0;
		}
	}
	.content__subtitle {
		margin: 32px 0 24px 0;
		font-weight: 700;
		@media (max-width:$tablet-width-m - 1) {
			margin: 24px 0 16px 0;
		}
	}
	.content__h4 {
		@include fs-lh(20px, 24px);
		font-weight: 600;
		margin-bottom: 32px;

		@media (max-width:$tablet-width-m - 1) {
			@include fs-lh(18px, 22px);
			font-weight: 500;
			margin-bottom: 24px;
		}
	}
	.content__h3 {
		@include fs-lh(24px, 29px);
		font-weight: 700;
		margin-bottom: 24px;
		@media (max-width:$tablet-width-m - 1) {
			margin-bottom: 16px;
			@include fs-lh(20px, 24px);
			font-weight: 600;
		}
	}
	.taglist__group {
		margin: 20px 0 0 0;
		.taglist__item {
			background: rgba(255, 255, 255, 0.4);
			color: $color__theme_white;
			transition: all 0.5s;
			&:hover {
				opacity: 0.5;
				color: $color__theme_white;
			}
		}
	}
	.content__bottom {
		bottom: -73px!important;
		@media (max-width:$desktop-width-m - 1 ) {
			bottom: -100px!important;
		}
	}
}

.content__materials {
	margin: 60px 0 0 0;
	@media (max-width:$desktop-width-m) {
		display: none;
	}
	.materials__title {
		@include fs-lh(26px, 31px);
		font-weight: 700;
		margin: 0 0 32px 0;
	}
	.doclist__item {
		padding-right: 0;
		@media (min-width:$desktop-width-m) {
			@include flex-width(100%/12*5.5);
			margin-right: 30px;
		}
	}
	.doclist__text {
		color: $color__theme_white;
		opacity: 0.6;
	}
	.doclist__link {
		color: $color__theme_white;
		transition: all 0.5s;
		&:hover {
			opacity: 0.5;
			color: $color__theme_white;
			text-decoration: none!important;
		}
	}
}

.title__h1 {
    text-align: end;
    @include fs-lh(48px, 57px);
    font-weight: 700;
    color: $color__theme_white;
}
.content__hoverTop {
	&:hover{
		color: #21A3DC;
	}
}