.contacts {
	margin: 0 0 32px;

	&:last-child {
		margin-bottom: 0;
	}
}

.contacts__title {
	@include fs-lh(14px,17px);
	margin: 0 0 8px;
}

.contacts__text {
	@include fs-lh(20px,24px);
	font-weight: 600;
	display: block;
}