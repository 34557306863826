.result {
	@include d-flex;
	@include flex-wrap;
	margin-bottom: 40px;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		margin-left: -(100%/6*2);
		margin-right: -(100%/6);
	}

	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
		margin-left: -(100%/8);
	}

	@media (min-width:$desktop-width-s) {
		@include justify-content-end;
	}

	&.result_news {
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/2);
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			padding: 0 64px 0 0;
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			padding: 0 32px 0 0;
		}

		.result__sidebar {
			@media (max-width:$desktop-width-s - 1) {
				@include flex-width(100%);
				margin: 0 0 16px;
				padding-right: 0;
			}

			&:before {
				@media (max-width:$desktop-width-s - 1) {
					display: none;
				}
			}
		}

		.result__text {
			@media (max-width:$desktop-width-s - 1) {
				@include flex-width(100%);
				padding-right: 0;
			}
		}

		.result__intro {
			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}

		.result__title {
			@media (max-width:$desktop-width-s - 1) {
				margin-bottom: 0;
			}
		}
	}

	&.result_projects {
		@media (min-width:$desktop-width-s) {
			@include justify-content-start;
		}

		@media (min-width:$desktop-width-m) {
			margin-right: 0;
		}

		.result__sidebar {
			@media (min-width:$tablet-width-m) {
				@include flex-width(100%/2);
			}

			&:before {
				@media (min-width:$desktop-width-s) {
					display: none;
				}
			}
		}

		.result__picture {
			border: 0;
			border-radius: 0;
		}

		.result__text {
			padding-right: 0;

			@media (min-width:$tablet-width-m) {
				@include flex-width(100%/2);
			}
		}

		.result__title {
			@media (max-width:$desktop-width-s - 1) {
				@include fs-lh(20px,1);
			}
		}

		.result__intro {
			@media (max-width:$desktop-width-s - 1) {
				@include fs-lh(14px,17px);
				font-weight: normal;
			}
		}

		.result__date {
			margin: 0 0 40px;

			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}

		.taglist {
			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}
	}

	&.result_nosidebar {
		margin-left: 0;
		margin-right: 0;

		@media (min-width:$desktop-width-s) {
			@include justify-content-start;
		}

		.result__text {
			padding-right: 0;

			@media (min-width:$tablet-width-m) {
				@include flex-width(100%);
			}
		}

		.result__title {
			@media (max-width:$desktop-width-s - 1) {
				@include fs-lh(20px,1);
				margin-bottom: 0;
			}
		}

		.result__intro {
			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}

		.result__date {
			margin: 0 0 40px;

			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}

		.taglist {
			@media (max-width:$desktop-width-s - 1) {
				display: none;
			}
		}
	}

	.pathway {
		@media (max-width:$desktop-width-s - 1) {
			display: none;
		}
	}
}

.result__sidebar {
	@include before;
	@include align-self-start;
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		@include flex-width(100%/9*2);
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
		@include flex-width(100%/6*2);
	}

	@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/8*3);
	}

	@media (min-width:$tablet-width-m) {
		padding-right: 20px;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
		margin: 0 0 16px;
	}

	&:before {
		@include pos(20px,20px);
		right: 40px;
		bottom: -20px;
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.7);
		z-index: 1;
		-o-filter: blur(20px);
		-moz-filter: blur(20px);
		-webkit-filter: blur(20px);
		filter: blur(20px);

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	&.result__sidebar_noshadow {
		@include align-self-stretch;

		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}

		&:before {
			display: none;
		}
	}

	& + .result__text {
		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/6*4);
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			@include flex-width(100%/8*5);
		}
	}
}

.result__info {
	@include d-flex;
	@include flex-column;
	@include justify-content-between;
	height: 100%;
	padding: 24px 18px;
	border-radius: 12px;
	box-sizing: border-box;
	border: 4px solid #21A3DC;
	color: $color__theme_black;
	background: $color__theme_white;
	box-shadow: 0px 0px 12px 0 #21A3DC;

	p {
		@include fs-lh(12px,15px);
	}
}

.result__picture {
	@include zi-2;
	max-width: 100%;
	border-radius: 12px;
	box-sizing: border-box;
	border: 4px solid $color__theme_white;
}

.result__category {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 20px;
	z-index: 3;
	padding: 16px 24px;
	color: $color__theme_white;
	background: rgba(0, 0, 0, 0.5);

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}

	.result__intro {
		@include fs-lh(14px,17px);
		font-weight: normal;
		letter-spacing: 0.02em;
	}
}

.result__subtitle {
	@include fs-lh(20px,1);
	font-weight: bold;
	margin: 0 0 8px;
}

.result__download {
	@include before;
	@include d-flex;
	@include flex-column;
	@include justify-content-end;
	@include fs-lh(12px,15px);
	min-height: 70px;
	padding: 0 0 0 66px;

	&:before {
		@include pos(0,0);
		@include size(50px,70px);
		background: url(../img/icons/icon-document.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
		background-size: 100% auto;
	}
}

.result__download-link {
	color: #283584;
	text-decoration: underline;
	
	&:hover {
		color: #283584;
	}
}

.result__text {
	box-sizing: border-box;

	@media (min-width:$desktop-width-s) {
		@include flex-width(100%/9*5);
		padding-right: 20px;
	}

	@media (max-width:$desktop-width-s - 1) {
		@include flex-width(100%);
	}
}

.result__content {
	@media (max-width:$tablet-width-m - 1) {
		display: none;
	}
}

.result__meta {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		padding-right: 20px;
	}

	@media (min-width:$desktop-width-s) {
		@include d-flex;
		@include flex-column;
		@include justify-content-between;
		@include flex-width(100%/9*2);
	}

	@media (max-width:$desktop-width-s - 1) {
		display: none;
	}
}

.result__title {
	display: block;
	font-weight: bold;

	@media (min-width:$desktop-width-s) {
		@include fs-lh(32px,39px);
	}

	@media (max-width:$desktop-width-s - 1) {
		@include fs-lh(24px,29px);
	}

	@media (min-width:$tablet-width-m) {
		margin: 0 0 16px;
	}
}

.result__intro {
	@media (min-width:$desktop-width-s) {
		@include fs-lh(20px,24px);
		font-weight: 600;
		margin: 0 0 16px;
	}

	@media (max-width:$desktop-width-s - 1) {
		@include fs-lh(18px,22px);
		font-weight: 500;
	}

	@media (max-width:$tablet-width-m - 1) {
		display: none;
	}
}

.result__date {
	@include fs-lh(14px,17px);
	color: rgba(0, 0, 0, 0.5);
}