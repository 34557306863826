.digital {
    position: relative;
    //height: 100vh;
    //min-height: 919px;
    @media (max-width:$desktop-width-s - 1){
       // display: none;
    }
    @media (max-width:$tablet-width-m - 1) {
		display: block;
	}
    &::before {
        //display: none!important;
        content: '';
        position: absolute;
        bottom: -100px;
        left: 0;
        width: 100%;
        height: 100px;
        background: #000;
        transform: matrix(1,-.02,0,1,0,0);
        transform-origin: left bottom;
        @media (max-width:$tablet-width-m - 1) {
           display: none;
        }
    }
    &.digital_bg {
        //z-index: -10;
        width: 100%;
        height: 100vh;
        //height: 100%;
        background: url('../img/bg/agancy_bg.png') no-repeat;
        background-size: 100% 100%;

        @media (max-width:$desktop-width-s - 1) {
            background-size: auto;
            height: 100vh;
            background-position-x: -50px;
        }

        @media (max-width:$tablet-width-m - 1) {
            background: none;
            height: 100%;
            z-index: 10;
        }

    }

    .title__h1 {
        text-align: left;
        @include fs-lh(56px, 67px);
        font-weight: 700;
        color: $color__theme_white;

        @media (max-width:$desktop-width-s - 1) {
            @include fs-lh(40px, 48px);
        }

        @media (max-width:$tablet-width-m - 1) {
            @include fs-lh(36px, 43px);
        }
    }
    .pathway {
        @media (max-width:$tablet-width-m - 1) {
           // display: none;
        }
    }
}

.digital__phase {
    display: none;
    position: absolute;
    left: 2%;
    top: 10%;
    width: 50%;
    height: 70%;
    border-right: 1px solid #fff;
    border-radius: 50%;
    @media (max-width:$desktop-width-s - 1) {
       display: none;
    }
}

.digital__btn {
    cursor: pointer;
    margin: 20px 0 0 0;
    display: block;
    text-align: center;
    @include fs-lh(20px, 24px);
    font-weight: 600;
    height: 24px;
    color: $color__theme_white;
    background: #21A3DC;
    border-radius: 16px;
    max-width: 221px;
    padding: 2px 23px 4px 23px;
    transition: all 0.5s;
    &:hover {
        opacity: 0.6;
        color: #fff;
    }
    @media (max-width:$tablet-width-m - 1) {
        padding: 4px 0 6px 0;
        text-align: center;
        height: 24px;
    }
}

.digital__about {
    position: relative;
    height: 100vh;

    @media (max-width:$desktop-width-s - 1) {
        margin-top: 78px;
        width: 72%;
    }

    @media (max-width:1068px) {
      width: 80%;
    }

    @media (max-width:982px) {
        width: 90%;
    }

    @media (max-width:892px) {
        width: 100%;
    }

    @media (max-width:$tablet-width-m - 1) {
		height: 100%;
        margin: 40px 0 0 0;
	}

    .feature {
        @media (max-width:$tablet-width-m - 1) {
            margin: 0 0 24px 0;
        }
    }
    .feature_digital {
        cursor: pointer!important;
    }
    .feature_digital-1 {
        bottom: 86% !important;
        //left: 55%;

        left: 37%;

        right: 0;
        padding-left: 70px;
        padding-right: 150px;
        padding-bottom: 0!important;

        /*
        @media (max-width:$desktop-width-xl - 1) {
            left: 52%;
        }

        @media (max-width:$desktop-width-l) {
            left: 48%;
            padding-right: 110px;
            padding-left: 110px;
        }
        
        @media (max-width:$desktop-width-m - 1) {
            left: 53%;
        }
        */

        @media (max-width:$desktop-width-s - 1) {
            left: 10%;
            width: 21%;
            top: 0;
            height: 320px;
            padding: 0;
        }

        @media (max-width:$tablet-width-m - 1) {
            left: 0;
            width: 100%;
            height: fit-content;
        }
        .feature__footer {
            display: none;

          overflow: visible;
          height: 120px;
          left: -12px;
          bottom: 63px;
          &::before {
              top: 182px;
              left: 97px;
              height: 180%;
              transform: rotate(80deg);
              border: none;
              border-left: 1px solid #fff!important;
              @media (max-width:$desktop-width-l) {
                height: 180%;
                left: 120px;
                top: 182px;
            }
          }
        }
        .feature__icon {
            bottom: -34px;
            left: 0;
            /*
            bottom: -90px;
            left: -190px;

            @media (max-width:$desktop-width-l - 1) {
                bottom: -70px;
                left: -120px;
            }
            */

            @media (max-width:$desktop-width-s - 1) {
               bottom: -30px;
               left: -20px;
            }
            @media (max-width:$tablet-width-m - 1) {
               left: 0;
            }
        }
        .feature__title {            
            @media (max-width:$desktop-width-l - 1) {
                //margin-left: 30px;
            }

            @media (max-width:$desktop-width-m - 1) {
                margin-left: 0;
            }

            @media (max-width:$desktop-width-s - 1) {
                text-align: left;
                padding-left: 7px;
            }
            @media (max-width:$tablet-width-m - 1) {
                 width: 50%;
                 padding-left: 20px;
                 padding-top: 15px;
            }
        }
        .feature__desc {
            @media (max-width:$desktop-width-l - 1) {
                //margin-left: 30px;
            }

            @media (max-width:$desktop-width-m - 1) {
                margin-left: 0;
            }
            
            @media (max-width:$desktop-width-s - 1) {
                padding-left: 7px;
            }
           @media (max-width:$tablet-width-m - 1) {
                padding-left: 20px;
            }
        }

    }
    .feature_digital-2 {
        bottom: 56% !important;
        left: 45%;
        padding-right: 150px;
        @media (max-width:$desktop-width-s - 1) {
            top: 8%;
            left: 35%;
            width: 25%;
            padding: 0;
            height: 160px;
        }
        @media (max-width:$desktop-width-l) {
            padding-right: 110px;
        }

        @media (max-width:$desktop-width-m) {
            padding-right: 70px;
        }

        @media (max-width:$tablet-width-m - 1) {
            left: 0;
            width: 100%;
            height: fit-content;
            padding: 0;
        }
        .feature__footer {
            display: none;
        }
        .feature__icon {
            bottom: 65px;
            left: 70px;

            @media (max-width:$desktop-width-s - 1) {
                bottom: -20px;
                left: -20px;
            }

            @media (max-width:$tablet-width-m - 1) {
                left: 0;
            }
        }
        .feature__title {
            margin-right: -20px;
            @media (max-width:$desktop-width-s - 1) {
                text-align: left;
                padding-left: 7px;
            }

            @media (max-width:$tablet-width-m - 1) {
                padding-left: 20px;
                padding-top: 15px;
            }
        }
        .feature__desc {
            margin-left: 0px;
           // margin-left: -5px;
           @media (max-width:$desktop-width-s - 1) {
            padding-left: 7px;
            }
            @media (max-width:$tablet-width-m - 1) {
                margin-left: 0;
                padding-left: 20px;
            }
        }
        .feature__group {
            display: grid;
            /*
            justify-content: center;
            text-align: center;
            */
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid #fff;
            @media (max-width:$desktop-width-s - 1) {
               border: none;
               justify-content: flex-start;
               text-align: left;
               align-items: center;
            }

            @media (max-width:$tablet-width-m - 1) {
                display: block;
            }
        }
        .feature__subtitle {
            border: none;
        }

    }
    .feature_digital-3 {
        bottom: 38% !important;
        left: 49%;
        padding-right: 150px;
        @media (max-width:$desktop-width-l) {
            padding-right: 110px;
        }

        @media (max-width:$desktop-width-m) {
            padding-right: 60px;
        }

        @media (max-width:$desktop-width-s - 1) {
            top: 2%;
            left: 65%;
            width: 25%;
            padding: 0;
            bottom: 68% !important;
            //height: 160px;
        }
       
        @media (max-width:$tablet-width-m - 1) {
            left: 0;
            width: 100%;
            height: fit-content;
            padding: 0;
        }

        .feature__footer {
            display: none;
        }
        .feature__icon {
            bottom: 65px;
            left: 70px;
            @media (max-width:$desktop-width-s - 1) {
               bottom: -30px;
               left: -20px;
            }
            @media (max-width:$tablet-width-m - 1) {
               left: 0;
            }
        }
        .feature__title {
            //margin-left: -25px;
           //margin-left: -44px;

           @media (max-width:$desktop-width-s - 1) {
                text-align: left;
                padding-left: 7px;
                margin-left: 0;
            }
            @media (max-width:$tablet-width-m - 1) {
              margin-left: 0;
              padding-left: 20px;
              padding-top: 15px;
            }
        }
        .feature__desc {
            //margin-left: -25px;

           @media (max-width:$desktop-width-s - 1) {
                padding-left: 7px;
                margin-left: 0;
            }
            @media (max-width:$tablet-width-m - 1) {
               margin-left: 0;
               padding-left: 20px;
            }
        }
        .feature__group {
            display: grid;
            /*
            justify-content: center;
            text-align: center;
            */
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid #fff;
            @media (max-width:$desktop-width-s - 1) {
                border: none;
                justify-content: flex-start;
                text-align: left;
                align-items: center;
            }

            @media (max-width:$tablet-width-m - 1) {
                display: block;
            }
        }
        .feature__subtitle {
            border: none;
        }
    }
   .feature_digital-4 {
        bottom: 20% !important;
        left: 45%;
        padding-right: 150px;

        @media (max-width:$desktop-width-l) {
            padding-right: 110px;
        }

        @media (max-width:$desktop-width-m) {
            padding-right: 70px;
        }

        @media (max-width:$desktop-width-s - 1) {
            bottom: 50% !important;
            left: 75%;
            width: 20%;
            height: 250px;
            padding: 0;
            //height: 160px;
        }
        @media (max-width:840px) {
            left: 82%;
        }
        @media (max-width:$tablet-width-m - 1) {
            left: 0;
            width: 100%;
            height: fit-content;
            padding: 0;
        }
       
        .feature__footer {
            display: none;
        }
        .feature__icon {
            bottom: 65px;
            left: 70px;
            @media (max-width:$desktop-width-s - 1) {
              bottom: -10px;
              left: -20px;
            }
            @media (max-width:$tablet-width-m - 1) {
                left: 0;
            }
        }
        .feature__title {
            //margin-left: 50px;
            //margin-left: -120px;

             @media (max-width:$desktop-width-s - 1) {
                margin-left: 7px;
                text-align: left;
            }

            @media (max-width:$tablet-width-m - 1) {
               margin-left: 0;
               padding-left: 20px;
               padding-top: 15px;
            }

            @media (max-width:$tablet-width-s - 1) {
               width: 20%;
            }
        }
        .feature__desc {
            //margin-left: 50px;
            //padding-left: 180px;

            @media (max-width:$desktop-width-s - 1) {
                margin-left: 7px;
            }

            @media (max-width:$tablet-width-m - 1) {
               padding-left: 20px;
               margin-left: 0;
            }
        }
        .feature__group {
            display: grid;
            /*
            justify-content: center;
            text-align: center;
            */
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid #fff;
            @media (max-width:$desktop-width-s - 1) {
                border: none;
            }
            @media (max-width:$tablet-width-m - 1) {
               display: block;
            }
        }
        .feature__subtitle {
            border: none;
        }
    }
    .feature__icon {
        &::after {
            width: 16px;
            height: 16px;
            margin: -8px 0 0 -8px;
            border-radius: 50%;
            background: #fff;
            @media (max-width:$tablet-width-m - 1) {
                background: #fb0015e5;
            }
        }
        &::before {
            @media (max-width:$tablet-width-m - 1) {
                background: #fb0015e5;
            }
        }
    }   
}

.feature_digital {
    opacity: 1!important;
    //padding-right: 128px;
    padding-left: 138px;

    @media (max-width:$tablet-width-m - 1) {
       padding-right: 0;
    }

    &.feature_digital-2 {
        @media (min-width:$desktop-width-s) {
            padding-left: 138px;
        }
    }

    &.feature_digital-3 {
        @media (min-width:$desktop-width-s) {
            padding-left: 138px;
        }
    }

    &.feature_digital-4 {
        @media (min-width:$desktop-width-s) {
            padding-left: 138px;
        }
    }

    .feature__title {
        padding-left: 20px;

        position: relative;
        display: flex;
        text-align: center;
        justify-content: flex-start;

        @include fs-lh(22px, 26px);
        font-weight: 700;
        @media (max-width:$tablet-width-m - 1) {
           justify-content: flex-start;
           text-align: left;
           width: 50%;

           @include fs-lh(24px, 29px);
           font-weight: 700;
           margin: 0 0 12px 0;
        }
    }
    .feature__desc {
        padding-left: 20px;

        display: flex;
        justify-content: flex-start;
        //width: 50%;
        align-items: center;
        @include fs-lh(14px, 16px);
        font-weight: 500;
        color: $color__theme_white;
        @media (max-width:$tablet-width-m - 1) {
            justify-content: flex-start;
            width: 95%;

            @include fs-lh(20px, 24px);
            font-weight: 600;
        }
    }
}
@media (max-width:425px) {
    .br__display-none{
        display:none;
    }
}