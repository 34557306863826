.division-slider {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    .division-slider-tabs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 330px;
        flex: 1;

        @media (min-width: 1680px) {
            padding-right: 70px;
            box-sizing: border-box;
        }

        @media (max-width: 1680px) {
            max-width: 250px;
        }

        @media (max-width: 1200px) {
            max-width: 230px;
        }

        @media (max-width: 992px) {
            max-width: 180px;
        }

        @media (max-width: 767px) {
            max-width: 80%;
            padding: 0 10px 27px;
        }

        &__item {
            display: flex;
            align-items: center;
            min-height: 42px;
            max-width: 100%;
            padding-left: 14px;
            margin-bottom: 10px;
            position: relative;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            color: #fff;
            cursor: pointer;
            box-sizing: border-box;

            &.active-slide {
                &::before {
                    content: '';
                    position: absolute;
                    left: 0px;
                    width: 4px;
                    top: 0;
                    height: 100%;
                    background: #fb0015;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: -25px;
                    top: 0;
                    width: 20px;
                    height: 100%;
                    background: url(../img/icons/icon-tab-arrow.svg) 50% 50% no-repeat;
                }
            }
        }
    }

    .division-slider-cards {
        position: relative;
        max-width: 1050px;
        width: calc(100% - 430px);
        height: 432px;
        margin-left: 100px; 
        box-sizing: border-box;

        @media (max-width: 1680px) {
            width: calc(100% - 350px);
        }

        @media (max-width: 1200px) {
            width: calc(100% - 280px);
            margin-left: 50px;
        }

        @media (max-width: 992px) {
            width: calc(100% - 230px);
            margin-left: 50px;
        }

        @media (max-width: 767px) {
            width: 100%;
            height: auto;
            margin-left: 0;
            background: #fff;
        }
    }

    .division-slider-cards-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        transform-style: preserve-3d;
        transition: .5s ease;

        @media (max-width: 767px) {
            display: flex;
        }
    }

    .division-slider-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        left: 0;
        top: 0;
        height: calc(100% - 28px);
        width: calc(100% - 50px);
        padding: 38px 36px 32px;
        background: #fff;
        border: 2px solid #000;
        box-shadow: 4px 4px 16px rgba(0,0,0,.25);
        border-radius: 16px;
        box-sizing: border-box;
        transition: .5s ease;

        .slider-card__shadow {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: #000;
            opacity: .2;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &.active-slide {
            .slider-card__shadow {
                display: none;
            }
        }

        @media (max-width: 992px) {
            padding: 20px 20px 16px;
            width: calc(100% - 10px);
        }

        @media (max-width: 767px) {
            min-width: 100%;
            height: auto;
            position: relative;
            border: none;
            border-radius: 0;
            padding: 20px 10px;
            box-shadow: none;
        }

        &__top {
            display: flex;
            flex-direction: column;


            @media (max-width: 992px) {
                max-width: 80%;
            }

            @media (max-width: 767px) {
                max-width: 100%;
            }

            .btn_slides {
                margin: 16px 0 0;
                display: block;
                text-align: center;
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                height: 23px;
                color: #fff;
                background: #21a3dc;
                border-radius: 16px;
                max-width: 221px;
                padding: 2px 23px 6px;
                transition: .3s ease;

                &:hover {
                    background: #283584;
                }
            }
        }
        
        &__title {
            font-size: 26px;
            line-height: 31px;
            font-weight: 700;
            transition: .3s ease;

            &:hover {
                color: #21a3dc;
            }

            @media (max-width: 992px) {
                font-size: 22px;
            }
        }

        &__desc {
            width: 60%;
            margin: 24px 0 0;
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;

            @media (max-width: 992px) {
                width: 100%;
            }
        }

        &__bottom {

            @media (max-width: 767px) {   
                padding-top: 34px;
            }
        }

        &__tags {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 152px);

            @media (max-width: 767px) {
                width: 100%;
            }

            .slides__tag {
                display: block;
                margin-right: 20px;
                color: #395c91;
                font-size: 14px;
                line-height: 17px;
                font-weight: 700;
                 transition: .3s ease;

                &:hover {
                    color: #21a3dc;
                }
            }
        }

        &__icons {
            position: absolute;
            right: 40px;
            bottom: 32px;

            @media (max-width: 992px) {
                right: 20px;
                bottom: 16px;
            }

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .division-slider-dots {
        display: flex;
        width: 100%;
        height: 28px;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        @media (max-width: 767px) {
            background: #fff;
            position: relative;
        }

        &__item {
            cursor: pointer;
            margin-right: 16px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: rgba(255,255,255,.65);
            box-shadow: inset 0 4px 4px rgba(0,0,0,.25);

            &.active-slide {
                background: #fff;
            }

            @media (max-width: 767px) {
                background: rgba(0,0,0,.4);
                box-shadow: inset 0 4px 4px rgba(255,255,255,.25);


                &.active-slide {
                    background: #000;
                }
            }
        }
    }

    .division-slider-docs {
        width: 100%;
        padding-top: 40px;

        &__item {
            display: none;

            &.active-slide {
                display: block;
            }

            .tab__title {
                font-size: 26px;
                line-height: 31px;
                font-weight: 700;
                color: #fff;
            }
            .doclist .doclist__item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 120px;
            }
        }

        @media (max-width: 767px) {
            padding: 40px 10px;
            background: #fff;

            &__item {
                .tab__title  {
                    color: #000;
                }
            }
        }
    }
}