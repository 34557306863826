.bg {
	@include pos(0,0);
	right: 0;
	bottom: 0;
	z-index: -1;

	&.bg_section_init {
		@include before;
		z-index: inherit;
		padding: 150px 0;
		overflow: hidden;
		background: url('../img/bg/bg-init.png') center top/ 100% auto no-repeat;

		@media (max-width:$tablet-width-l - 1) {
			padding: 140px 0;
		}

		@media (max-width:$tablet-width-m - 1) {
			padding: 75px 0;
			padding-left: 6px;
			padding-right: 6px;
		}

		@media (max-width:$tablet-width-s + 80) {
			background-size: 150% auto;
		}

		@media (max-width: $mobile-width + 40) {
			background-size: 200% auto;
		}
	}

	&.bg_padding_bottom {
		padding-bottom: 300px;

		@media (max-width:$tablet-width-l - 1) {
			padding-bottom: 280px;
		}

		@media (max-width:$tablet-width-m - 1) {
			padding-bottom: 150px;
		}
	}
}
.ssss {
	padding: 10px;
}

.bg,
.bg__top {
	@media (min-width:$mobile-width) and (max-width:$desktop-width-s - 1) {
		bottom: 200px;
	}
	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m - 1) {
		bottom: 250px;
	}
}

.bg__top {
	@include pos(0,0,fixed);
	right: 0;
	bottom: 0;
	opacity: 0.5;
	background-repeat: no-repeat;
	background-position: center top;
	background-color: rgba(0,0,0,0);
	background-image: url(../img/bg/bg-top.jpg);

	@media (min-width:$desktop-width-s) {
		background-size: 100% auto !important;
	}

	@media (max-width:$desktop-width-s - 1) {
		background-size: cover !important;
	}
	
	&.bg__top_plain {
		background: $color__theme_black;
	}
	
	&.bg__top_corp {
		background-image: url(../img/bg/bg-corp.jpg);
	}
	
	&.bg__top_leads {
		background-image: url(../img/bg/bg-leads.jpg);
	}
	
	&.bg__top_national {
		background-image: url(../img/bg/bg-national.jpg);
	}
	
	&.bg__top_regional {
		background-image: url(../img/bg/bg-regional.jpg);
	}
	
	&.bg__top_news {
		background-image: url(../img/bg/bg-news.jpg);
	}
	
	&.bg__top_notfound {
		background-image: url(../img/bg/bg-notfound.jpg);
	}
	&.bg__top_media {
		background-image: url(../img/bg/bg-media.jpg);
	}
}



.bg__bottom {
	@include pos(25%,0);
	right: 0;
	bottom: 0;
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
		top: 9%!important;
	}
	/*
	@include before;
	
	&:before {
		@include multi-prop(matrix(1,-.05,0,1,0,0));
		@include pos(0,0);
		right: 0;
		z-index: 2;
		background: url(../img/bg/bg-bottom.jpg) no-repeat scroll center top rgba(0,0,0,0);
		
		@media (min-width:$tablet-width-l) {
			background-size: 100% auto;
		}
		
		@media (max-width:$tablet-width-l - 1) {
			background-size: auto 100%;
		}
	}
	
	&:after {
		@include pos(30%,0);
		content: '';
		right: 0;
		bottom: 0;
		z-index: 1;
		background: $color__theme_black;
	}
	
	&.bg__bottom_standart {
		@media (min-width:$desktop-width-s) {
			&:before {
				bottom: 50%;
			}
		}

		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-s - 1) {
			&:before {
				bottom: 40%;
			}
		}

		@media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
			&:before {
				bottom: 30%;
			}
		}

		@media (max-width:$tablet-width-m - 1) {
			&:before {
				bottom: 25%;
			}
		}
	}
	*/
	
	&.bg__bottom_regional {
		.bg__inner_top {
			background-image: url(../img/bg/bg-regional-bottom.jpg);
		}
	}

	&.bg__bottom_desktop {
		@media (max-width:$desktop-width-s - 1) {
			display: none;
		}
	}
}

.bg__inner {	
	&.bg__inner_top {
		@include before;
		@include multi-prop(matrix(1,-.05,0,1,0,0));
		@include pos(0,0);
		right: 0;
		z-index: 2;
		background-repeat: no-repeat;
		background-position: center top;
		background-color: rgba(0,0,0,0);
		background-image: url(../img/bg/bg-bottom.jpg);
		
		@media (min-width:$tablet-width-l) {
			background-size: 100% auto;
		}
		@media (max-width:$tablet-width-l - 1) {
			background-size: auto 100%;
		}
		@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m) {
			bottom: 64%!important;
		}
	

		&:before {
			@include pos(0,0);
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,0.5);
		}
	}
	&.bg__inner_top-media {
		@include before;
		@include multi-prop(matrix(1,-.05,0,1,0,0));
		@include pos(0,0);
		right: 0;
		z-index: 2;
		background-repeat: no-repeat;
		background-position: center top;
		background-color: #21A3DC;
		
		@media (min-width:$tablet-width-l) {
			background-size: 100% auto;
		}
		@media (max-width:$tablet-width-l - 1) {
			background-size: auto 100%;
		}
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-l - 1) {
			bottom: 68%!important;
		}
		@media (min-width:$mobile-width) and (max-width:$tablet-width-m - 1) {
			bottom: 61.7%!important;
		}

		&:before {
			@include pos(0,0);
			right: 0;
			bottom: 0;

		}
	}
	&.bg__inner_footer-media {
		@include before;
		@include multi-prop(matrix(1,-.05,0,1,0,0));
		@include pos(0,0);
		bottom: 100px;
		right: 0;
		z-index: 2;
		background-repeat: no-repeat;
		background-position: center top;
		background-color: transparent;
    	background-image: url(../img/bg/media-footer-bg.jpg);
		top:65%;
		

	}
	&.bg__inner_bottom {
		@include pos(30%,0);
		right: 0;
		bottom: 0;
		z-index: 1;
		background-color: $color__theme_black;
	}
	
	&.bg__inner_white {
		background-color: $color__theme_white;
	}
	
	&.bg__inner_colored {
		background-color: #21a3dc;
	}
}