.case {
   
    @include flex-width(100%/12*12);

    @media (max-width:$desktop-width-m) {
		@include flex-width(100%/12*12);
	}
    &.case_bg {
        border: 4px solid #FFFFFF;
        border-radius: 16px;
        background: $color__theme_black;
        @media (max-width:$tablet-width-l) {
            border-radius: 0;
        }
    }

    &.case_launch {
        padding: 28px 34px;
        @media (max-width:$desktop-width-m) {
            padding: 28px 34px 24px 40px;
        }
        @media(max-width:$tablet-width-s) {
            padding: 24px 0px 24px 30px;
        }
        @media (max-width:$tablet-width-l){
            margin-left: -25px;
            margin-right: -25px;
            padding: 28px 0px 24px 40px;
        }

        .case__link {
            display: block;
            text-align: center;
            background: #283584;
            color: $color__theme_white;
            border-radius: 6px;
            padding: 2px 6px;
            margin: 0 16px 0 0;
        
            @include fs-lh(14px, 17px);
        }
        
    }
    
    &.case_digital {
        padding: 0 32px 0 40px;
        position: relative;
        z-index: 10;
        @media (max-width:$desktop-width-s - 1){
            padding: 0;
            border-radius: 14px;
            border: 2px solid #fff;
        }
        @media (max-width:$tablet-width-m - 1) {
            border: none;
            border-radius: 0;
        }
    }
}

.case__top {
    display: flex;

    padding: 0 0 24px 0;
    border-bottom:1px solid rgba(255, 255, 255, 0.2);
    @media(max-width:$tablet-width-s) {
       //padding: 0 0 32px 0;
      // padding: 0 0 56px 0;
    }
}

.case__body {
    padding: 26px 0 0 0;
    overflow: hidden;
    .case__flex {
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
        @media(max-width:$tablet-width-l - 1) {
            width: 1000px;
        }
        @media(max-width:$tablet-width-m - 1) {
            width: 950px;
        }

        @media(max-width:$tablet-width-s - 1) {
            width: 600px;
        }

        .case__info {
            @media(max-width:$tablet-width-s - 1) {
               left: 0;
               @include flex-width(100%/8*3.5);
            }
        }
    }

}

.case__subtitle {
    color: $color__theme_white;
    @include fs-lh(14px, 17px);
    margin: 0 125px 0 0;

    @media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*2);
	}
    @media(max-width:$tablet-width-s) {
        display: none;
    }
    span {
        display: block;
        opacity: 0.5;
    }
}

.case__tab {
    display: none;

    @media (max-width:$tablet-width-l) {
		@include flex-width(100%/12*3);
        display: flex;
        align-items: center;
	}

    .case__btn {
        cursor: pointer;
        margin-right: 24px;
        list-style-type: none;
        @include fs-lh(16px, 20px);
        color: $color__theme_white;
        opacity: 0.5;
    }
}

.case__tabActive {
    opacity: 1!important;
    overflow: visible!important;
    transition: all 0.5s!important;
    height: auto!important;
}

.case__active_scroll {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid  $color__theme_white;
    opacity: 1!important;
}


.case__links {
    display: flex;
    height: 21px;

    @media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*2);
    }

    @media (max-width:$tablet-width-l) {
        @include flex-width(100%/8*8);
    }
}


.case__tag {
    display: none!important;
    display: flex;
    align-items: center;
    color: $color__theme_white;
    @include fs-lh(14px, 17px);
    font-weight: 500;
    margin-right: 16px;
    &:hover {
        color: $color__theme_white;
        opacity: 0.5;
    }
}

.case__info {
    color: $color__theme_white;
    @include fs-lh(14px, 17px);
    opacity: 0.5;
    font-weight: 500;

    @media (min-width:$tablet-width-l) {
		@include flex-width(100%/12*3.1);
        text-align: end;
    }

    @media (max-width:$tablet-width-l - 1) {
        @include flex-width(100%/8*3.3);
        text-align: end;
        position: relative;
        left: 50px;
    }

    @media(max-width:$tablet-width-s) {
        @include flex-width(100%/8*7.5);
        position: relative;
        left: 100px;
    }
}

.case__content {
    padding: 18px 0 0 0;

    display: flex;
    justify-content: space-between;
    @media (max-width:$tablet-width-l) {
        padding: 26px 0 0 0;
        margin-bottom: 30px;
    }
    @media(max-width:$tablet-width-s)  {
        padding: 10px 0 0 0;
        margin-bottom: 30px;
    }

    .case__col {
        &.case__col_left {
            @media (min-width:$tablet-width-l) {
                @include flex-width(100%/12*3);
            }
            @media(max-width:$tablet-width-l - 1) {
                //@include flex-width(100%/8*3.5);
                @include flex-width(100%/8*2.2);
              //  margin-right: 50px;
            }
    
            @media(max-width:$tablet-width-s) {
               // @include flex-width(100%/8*7);
               @include flex-width(100%/8*2.5);
               margin-right: 70px;
            }
    
            .case__item {
                margin: 0 0 16px 0;
                .case__about {
                    color: $color__theme_white;
                    @include fs-lh(14px, 17px);
                    font-weight: 500;
                } 
                &.case__item_mb {
                    @media (max-width:$tablet-width-l) {
                        //margin: 0 0 26px 0;
                        margin: 0 0 16px 0;
                    }
                }            
            }
        }
    
        &.case__col_center {
            @media (min-width:$tablet-width-l) {
                @include flex-width(100%/12*5);
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
            @media (max-width:$desktop-width-s) {
                img {
                    width: 100%;
                }
            }
            @media(max-width:$tablet-width-l - 1) {
                //@include flex-width(100%/8*7);
                @include flex-width(100%/8*4);
                margin-right: 50px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: fit-content;
                }
            }
            @media(max-width:$tablet-width-s) {
                //@include flex-width(100%/8*8);
                @include flex-width(100%/2.2);
                margin-right: 70px;
                display: flex;
                justify-content: center;
                text-align: center;
                img {
                    //width: 100%;
                }
            }
        }
    
        &.case__col_right {
            @media (min-width:$tablet-width-l) {
                @include flex-width(100%/12*2);
                padding: 65px 0 0 0;
            }
            @media(max-width:$tablet-width-l - 1) {
                //@include flex-width(100%/8*2);
                @include flex-width(100%/8*1);
                margin-right: 30px;
            }
            @media(max-width:$tablet-width-s) {
               // @include flex-width(100%/8*3);
                flex: 0 0 15%;
                max-width: 15%;
                padding-right: 20px;
                margin-right: 150px;
            }
            .case__item {
                margin: 0 0 24px 0;
                @media(max-width:$tablet-width-l) {
                    margin: 0 0 24px 0;
                }
                .case__about {
                    color: $color__theme_white;
                    @include fs-lh(24px, 29px);
                    font-weight: 700;
                } 
                &.case__item_mb {
                    @media (max-width:$tablet-width-l) {
                        margin: 0 0 26px 0;
                    }
                }           
            }
        }
    }
}

.case__content_dn {
    @media (max-width:$tablet-width-l) {
        display: none!important;
    }
}

.case__title {
    color: $color__theme_white;
    @include fs-lh(24px, 29px);
    font-weight: 700;
}

.case__text {
    color: $color__theme_white;
    @include fs-lh(14px, 17px);
    font-weight: 500;
}

.case__minitext {
    color: $color__theme_white;
    @include fs-lh(12px, 15px);
    font-weight: 400;
}

.case__desc {
    color: $color__theme_white;
    @include fs-lh(16px, 20px);
    font-weight: 600;
}

.case__opacity {
    opacity: 0.5;
}

.case-btn-group {
    display: flex;
    justify-content: space-between;
    @media (max-width:$tablet-width-l) {
        display: none;
    }
}

.case-btn-swiper {
	outline: none;
    border: none;
    background: transparent;
	cursor: pointer;
	padding: 32px 0 0 0;
	transition: all 0.5s;
	.img-fluid {
		fill: #fff;
		opacity: 0.5;
		&:hover {
			opacity: initial;
			fill: #fff;
		}
	}
}

#scrollbar {
    @media (max-width:$tablet-width-l) {
        width: 100%;
        height: auto;
        //overflow-x: auto;
    }
  }


.case__group {
    @media (max-width:$tablet-width-l) {
        display: none;
    }
}

.case__scrollbar {
    display: none;
    @media (max-width:$tablet-width-l) {
       display: block;
       //position: relative;
    }
}

.dn {
    display: none!important;
}

.case__scrollbar {
    .swiper-slide {
        width: 1500px;
        box-sizing: border-box;
        @media(max-width:$tablet-width-s) {
            width: 900px;
            height: 100%;
        }
    }

    .case__swiper-scrollbar {
        overflow: hidden;
        display: block!important;
        width: 99%;
        background: #1C1C1C;
        border: 4px solid #2E2E38;
        box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        .swiper-scrollbar-drag {
            background: #283584;
            border-radius: 8px;
            height: 5px;
            width: 40%!important;
            @media (max-width:$tablet-width-s - 1 ) {
                width: auto;
            }
        }
        @media (max-width:$tablet-width-s - 1 ) {
            width: 98.2%;
        }
    }
}

.case_launch {
    position: relative;
    .swiper {
        position: initial!important;
        @media(max-width:$tablet-width-l) {
            margin-right: 30px;
        }
    }
    .case__numbers {
        display: flex;
        align-items: center;

        position: absolute;
        top: 28px;
        left: 354px;
    
        @media (min-width:$desktop-width-m) {
            @include flex-width(100%/12*3);
        }

        @media (max-width:$tablet-width-s - 1 ) {
           //top: 9px;
           top: 24px;
           left: 40px;
        }


        .swiper-pagination-bullet {
            cursor: pointer;
            margin-right: 24px;
            list-style-type: none;
            @include fs-lh(16px, 20px);
            color: $color__theme_white;
            opacity: 0.5;
        }

        .swiper-pagination-bullet-active {
            //padding: 8px;
            border-radius: 8px;
            border: 1px solid  $color__theme_white;
            opacity: 1!important;
            width: 36px;
            height: 36px;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .case__number {
            cursor: pointer;
            margin-right: 24px;
            list-style-type: none;
            @include fs-lh(16px, 20px);
            color: $color__theme_white;
            opacity: 0.5;
        }

        .swiper-slide-thumb-active {
            border-radius: 8px;
            border: 1px solid  $color__theme_white;
            opacity: 1!important;
            width: 36px;
            height: 36px;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}

// case digital 

.case_digital {
    .case__desc {
        margin: 25px 0 0 0;
        font-weight: 400;
    }

    .case__link {
        display: inline-block;
        margin: 12px 0 0 0;
        @include fs-lh(16px, 20px);
        font-weight: 600;
        color: $color__theme_white;
        border-bottom: 1px solid  #FF0000;
        &:hover {
            border: none;
        }
    }
}

.case__colum {
    display: flex;
    //justify-content: space-between;
    @media (max-width:$desktop-width-s - 1){
        flex-direction: column;
    }
    .case__col {
        &_left {
            @include flex-width(100%/12*5);
            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*8);
            }
        }

        &_right {
            margin: 40px 0 0 87px;
            @include flex-width(100%/12*4.5);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @media (max-width:$desktop-width-m - 1){
               // @include flex-width(100%/8*4!important);
               @include flex-width(100%/12*6);
            }
            @media (max-width:$desktop-width-s - 1){
                margin: 64px 0 0 0px;
                @include flex-width(100%/8*6);
            }
            @media (max-width:$tablet-width-m - 1){
                @include flex-width(100%/8*8);
            }
        }
    }
}

.case__project {
    position: relative;
    .case__bg {
       height: 100%;
       position: relative;
       left: -40px;
       img {
           width: 100%;
       }
    }
}

.case__logotype {
    position: absolute;
    bottom: 147px;

    color: $color__theme_white;
    @include fs-lh(36px, 44px);
    font-weight: 700;
}

.case__logolink {
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: center;
    a {
        margin-left: 12px;
        display: flex;
        align-items: center;

        color: $color__theme_white;
        @include fs-lh(12px, 14px);
        font-weight: 400;
        border-bottom: 1px solid #fff;
        &:hover {
            border-bottom: none;
        }
    }
}

.case__navigation {
    position: absolute;
    top: 0;
    right: 0;

    background: #283584;
    border-radius: 0px;
    width: 196px;
    height: 340px;
}

.case__company {
    p {
        @include fs-lh(18px, 21px);
        color: $color__theme_white;
        font-weight: 500;
        span {
            font-weight: 700;
        }
    }
}

// case projects

.case_projects {
    padding: 0;
    border-radius: 0!important;
    @media (max-width:$tablet-width-m - 1){
        border: none!important;
        background: #fff!important;
    }
    .case__groups {
        display: flex;
        @media (max-width:$tablet-width-m - 1){
			flex-direction: column;
		}
    }

    .case__card {
        display: block;
        position: relative;
       // height: fit-content;
        &.case__card_sm {
            @include flex-width(100%/12*3);

            @media (max-width:$desktop-width-s - 1){
               min-height: 320px;
            }

            @media (max-width:$tablet-width-m - 1){
                @include flex-width(100%/8*8);
                min-height: 0;
                max-height: 280px;
                margin-bottom: 8px;
            }
        }
        &.case__card_lg {
            @include flex-width(100%/12*4);

            @media (max-width:$desktop-width-s - 1){
                min-height: 320px;
            }

            @media (max-width:$tablet-width-m - 1){
                @include flex-width(100%/8*8);
                min-height: 0;
                max-height: 280px;
                margin-bottom: 8px;
            }
        }
    }

    .case__imgprev {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 403px;
        max-width: 100%;

        @media (max-width:$tablet-width-l - 1){
            height: 320px;
            max-width: 100%;
        }

        @media (max-width:$tablet-width-m - 1){
           display: flex;
           justify-content: center;
           height: 280px;
           max-width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width:$desktop-width-s - 1){
               object-fit: cover;
            }
            @media (max-width:$tablet-width-m - 1){
                //height: 280px;
                height: 100%;
                width: 100%;
            }
        }
    }
    .case__derection {
        position: absolute;
        bottom: 0;
        //width: 91%;
        height: 130px;
        background: rgba(0, 0, 0, 0.5);
        padding: 18px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width:$desktop-width-s - 1){
          // padding: 16px 12px 4px 15px;
          padding: 18px 10px;
        }
        @media (max-width:$tablet-width-m - 1){
			padding: 16px 12px;
            width: 100%;
            height: 104px;
		}
        @media (max-width:$tablet-width-s - 1){
			width: fit-content;
		}
    }

    .case__subtitle {
        @include fs-lh(20px, 21px);
        color: $color__theme_white;
        font-weight: 700;
        margin: 0;

        @media (min-width:$desktop-width-s){
           // @include flex-width(100%/12*12);
           max-width: 100%!important;
        }

        @media (max-width:$desktop-width-s - 1){
            @include fs-lh(16px,17px);
        }
        @media (max-width:$tablet-width-m - 1){
			display: block;
            @include fs-lh(16px,19px);
		}
    }

    .case__desc {
        padding: 23px 0 0 0;
        @include fs-lh(14px, 16px);
        color: $color__theme_white;
        font-weight: 400;
        @media (max-width:$desktop-width-s - 1){
            padding: 6px 0 0 0;
            @include fs-lh(12px, 14px);
        }
        @media (max-width:$tablet-width-m - 1){
			@include fs-lh(14px, 14px);
		}
    }

    .swiper-scrollbar {
        border-radius: 10px;
        position: relative;
        background: #1C1C1C;
        border: 4px solid #2E2E38;
        box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        height: 5px!important;
    }
    
    .swiper-scrollbar-drag {
        height: 100%;
        //width: 120.5px!important;
       // width: 115px!important;
        position: relative;
        background: #283584;
        border-radius: 8px;
        left: 0;
        top: 0;
    }
    
    .swiper-container-horizontal>.swiper-scrollbar {
        position: absolute;
        left: 1%;
        bottom: 3px;
        z-index: 50;
        width: 87%!important;
        //width: 93%;
    }
}

// case digital 

.case_digital {
    overflow: hidden;
    .case__desc {
        margin: 32px 0 0 0;
        font-weight: 400;
        @media (max-width:$desktop-width-s - 1){
            margin: 24px 0 0 0;
        }
        @media (max-width:$tablet-width-m - 1) {
           margin: 12px 0 0 0;
        }
    }

    .case__link {
        display: inline-block;
        margin: 12px 0 0 0;
        @include fs-lh(16px, 20px);
        font-weight: 600;
        color: $color__theme_white;
        border-bottom: 1px solid  #FF0000;
        &:hover {
            border: none;
        }
        @media (max-width:$tablet-width-m - 1) {
            margin: 24px 0 0 0;
        }
    }
}

.case__colum {
    display: flex;
    //justify-content: space-between;
    @media (max-width:$desktop-width-s - 1){
        flex-direction: column;
    }
    .case__col {
        &_left {
            @include flex-width(100%/12*5);
            @media (max-width:$desktop-width-s - 1){
                @include flex-width(100%/8*8);
            }
        }

        &_right {
            margin: 40px 0 0 87px;
            @include flex-width(100%/12*4.5);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @media (max-width:$desktop-width-s - 1){
                margin: 24px 0 0 0px;
                @include flex-width(100%/8*6);
                padding: 0 0 0 40px;
            }
            @media (max-width:$tablet-width-m - 1) {
                padding: 0 12px;
                @include flex-width(100%/8*8);
            }
        }
    }
}

.case__project {
    position: relative;
    .case__bg {
       height: 554px;
       width: 106.5%;
       position: relative;
       left: -40px;
        img {
           width: 100%;
           height: 100%;
            @media (max-width:$desktop-width-s - 1){
                border-radius: 14px 14px 0 0;
                object-fit: cover;
            }

            @media (max-width:$tablet-width-m - 1) {
               //object-fit: fill;
                border-radius: 0;
            }
        }
        @media (max-width:$desktop-width-s - 1){
            left: 0;
            max-height: 404px;
            display: flex;
            justify-content: center;
        }
        @media (max-width:$tablet-width-m - 1) {
            max-height: 220px;
            object-fit: fill;
        }
    }
}

.case__logotype {
    position: absolute;
    bottom: 147px;

    color: $color__theme_white;
    @include fs-lh(36px, 44px);
    font-weight: 700;

    @media (max-width:$desktop-width-s - 1){
        padding: 0 0 0 40px;
    }

    @media (max-width:$tablet-width-m - 1) {
        padding: 0 0 0 12px;
        bottom: 90px;

        @include fs-lh(24px, 29px);
        font-weight: 700;
    }

    &.case__logotype_lg {
        bottom: 125px;
        @media (max-width:$desktop-width-s - 1){
            bottom: 104px;
        }
        @media (max-width:$tablet-width-m - 1) {
            bottom: 70px;
        }
    }
}

.case__logolink {
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: center;

    @media (max-width:$desktop-width-s - 1){
        padding: 0 0 0 40px;
    }

    @media (max-width:$tablet-width-m - 1) {
        padding: 0 0 0 12px;
        bottom: 25px;
    }
    a {
        margin-left: 12px;
        display: flex;
        align-items: center;

        color: $color__theme_white;
        @include fs-lh(12px, 14px);
        font-weight: 400;
        border-bottom: 1px solid #fff;
        &:hover {
            border-bottom: none;
        }
    }
}

.case__navigation {
    position: absolute;
    top: 0;
    right: 0;

    background: #283584;
    border-radius: 0px;
    width: 196px;
    height: 340px;
    @media (max-width:$desktop-width-s - 1){
        top: -72px;
        right: 72px;
        transform: rotate(90deg);
        border-radius: 14px 0 0 0;
    }

    @media (max-width:$tablet-width-m - 1) {
        width: 150px;
        height: 85px;
        top: 0;
        right: 0;
        transform: rotate(0deg);
        border-radius: 0;
    }

    .case__progress {
        background: transparent;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        width: 290px;
        height: 290px;

        position: absolute;
        top: 23px;
        left: -25%;
        @media (max-width:$desktop-width-s - 1) {
            display: none;
        }

        .spinner {
            position: relative;
            border-radius: 100% 0 0 100%/50% 0 0 50%;
            z-index: 200;
            border-right: none;
            animation: rota 15s linear infinite;
            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                background: #fff;
                border: 1px solid #FB0015;
                box-shadow: inset 0 0 3px #FB0015;
                border-radius: 50%;
                top: 0px;
                right: 0px;
                display: none;
            }
        }
        .pie {
            width: 50%;
            height: 100%;
            transform-origin: 100% 50%;
            position: absolute;
            background: transparent;
            border: 2px solid #FB0015;
            border-right: 0;
        }

        .filler {
            border-radius: 0 100% 100% 0/0 50% 50% 0;
            left: 50%;
            opacity: 0;
            z-index: 100;
            animation: fill 15s steps(1, end) infinite;
            border-left: none;
        }

        .mask {
            width: 50%;
            height: 100%;
            position: absolute;
            background: inherit;
            opacity: 1;
            z-index: 300;
            animation: mask 20s steps(1, end) infinite;
            border-radius: 50%;
        }
    }
}


.case__company {
    p {
        @include fs-lh(18px, 21px);
        color: $color__theme_white;
        font-weight: 500;

        @media (max-width:$tablet-width-m - 1) {
           // @include fs-lh(18px, 21px);
            padding-left: 0px;
            margin: 0 0 12px 0;
        }
        span {
            font-weight: 700;
        }
    }
}

.case__pagination-holder {
    @include pos(0,0);
    width: 100%/12*5;
    display: flex;
    justify-content: flex-end;

    @media (max-width:$desktop-width-s - 1){
        width: 100%/8*8;
    }

    @media (min-width:$tablet-width-m){
        height: 340px;
    }

    @media (max-width:$tablet-width-m - 1) {
        height: 85px;
    }
}

.case__pagination {
    height: 100%;
    margin: 0 -10px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    color: $color__theme_white;
    z-index: 5;
    border-radius: 50%;
    @media (max-width:$desktop-width-s - 1) {
       flex-direction: row;
       height: 56%;
       justify-content: left;
    }

    @media (max-width:$tablet-width-m - 1) {
      // height: 100%;
       //width: 130px;
       position: relative;
       right: 60px;
       top: 15px;
    }

    .swiper-pagination-current {
        @include fs-lh(100px, 120px);
        font-weight: 700;
        z-index: 350;
        @media (max-width:$desktop-width-s - 1) {
            @include fs-lh(24px, 40px);
            font-weight: 600;
            color: #FB0015;
        }

        @media (max-width:$tablet-width-m - 1) {
            @include fs-lh(17px, 25px);
            font-weight: 600;
        }
    }

    .swiper-pagination-total {
        @media (max-width:$desktop-width-s - 1) {
            @include fs-lh(32px, 54px);
            font-weight: 600;
        }

        @media (max-width:$tablet-width-m - 1) {
            @include fs-lh(20px, 34px);
            font-weight: 600;
        }
    }

    /*
    @media (max-width:$desktop-width-s - 1) {
        transform: rotate(-90deg);
        flex-direction: row;
        height: fit-content;
        position: absolute;
        top: 140px;
    }

    @media (max-width:$tablet-width-m - 1) {
        transform: rotate(0deg);
        flex-direction: row;
        height: fit-content;
        position: absolute;
        top: 25px;
    }
    */

    @media (min-width:$tablet-width-m){
        width: 196px;
    }
}

.case__arrows {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
   // right: -10px;
    z-index: 10;
    width: 190px;
    height: 95%;

    @media (max-width:$desktop-width-s - 1 ){
        //width: 196px;
        width: 350px;
        height: 90%;
        flex-direction: row;
    }

    @media (max-width:$tablet-width-m - 1) {
        width: 214px;
    }

    @media (max-width:$desktop-width-s - 1) {
        justify-content: space-between;
        flex-direction: column;
    }

    @media (max-width:$tablet-width-m - 1) {
       // justify-content: space-between;
      // flex-direction: row;
       // right: 0;
       flex-direction: row-reverse;
       display: none;
    }

    img {
        width: 46px;
    }

    .arrow-laptop {
        position: relative;
       // top: 50%;
        top: 5%;
        left: 8px;
        @media (max-width:$desktop-width-s - 1) {
           display: none;
        }
    }

    .arrow-mobile {
        display: none;

        @media (max-width:$desktop-width-s - 1) {
            display: block;
            width: 68px;
            height: 68px;
        }

        @media (max-width:$tablet-width-m - 1) {
            display: block;
            width: 37px;
            height: 37px;
        }
    }

    .case__next {
        outline: none;
        border: none;
        background: transparent;
        @media (max-width:$desktop-width-s - 1) {
            position: relative;
            left: 30px;
            top:60px;
            width: 100px;
           // transform: rotate(-90deg);
        }

        @media (max-width:$tablet-width-m - 1) {
            position: relative;
            left: -10px;
            top: -20px;
            //top: 50%;
            //right: 24px;
           // right: 0;
           // left: 155px;
           // top: -15px;
            transform: rotate(180deg);
           // transform: rotate(180deg);
        }
    }

    .case__prev {
        background: transparent;
        outline: none;
        border: none;
        display: none;

        @media (max-width:$desktop-width-s - 1) {
            display: block;
           position: relative;
           top: -177px;
           //left: -30px;
           right: -230px;
           width: 100px;
           transform: rotate(180deg);
        }

        @media (max-width:$tablet-width-m - 1) {
            display: block;
            position: relative;
            top: 20px;
            left: 10px;
           // top: 50%;
            //right: 24px;
          //  top: 25px;
          //  right: 0;
           // left: -175px;
            transform: rotate(-0deg);
        }
    }
}

@keyframes rota {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fill {
    0%        { opacity: 0; }
    50%, 100% { opacity: 1; }
  }
  
  @keyframes mask {
    0%        { opacity: 1; }
    50%, 100% { opacity: 0; }
  }
    

  .swiper-slide-block {
        @media (max-width:$tablet-width-l - 1) {
        scroll-snap-align: none!important;
      }
      @media (max-width:$tablet-width-s - 1) {
        scroll-snap-align: none!important;
      }

  }