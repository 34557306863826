

.section-content_mb-200 {
    margin-bottom: 200px;
}

.section-content_mb--100 {
    margin-bottom: -100px;
}

.section-content_ptb-100 {
    padding: 100px 0;
}

.section-content_pb-200 {
    padding-bottom: 200px;
}

.content-size-calc {
    width: 83.3333%;
    margin-left: 8.3333%;

    @media (max-width:$desktop-width-m - 1) {
        width: calc(100% - 80px);
        margin-left: 40px;
    }

    @media (max-width: $tablet-width-m - 1) {
        width: calc(100% - 24px);
        margin-left: 12px;
    }
}

.integrator-section1-bg {
    position: relative;
    padding-bottom: 200px;

    @media (max-width: $desktop-width-m) {
        padding-bottom: 130px;
    }

    @media (max-width: $tablet-width-m) {
        padding-bottom: 80px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -50%;
        height: 150%;
        width: 100%;
        background: url('../img/bg/bg-integrator-section1.jpg') center / cover no-repeat;
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        top: -50%;
        height: 150%;
        width: 100%;
        background: linear-gradient(180deg, #07080D 0%, rgba(7, 8, 13, 0) 65%);
        z-index: -1;
    }

}

.integrator-bg-shadow {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background: linear-gradient(0deg, #000 10.17%, rgba(7, 8, 13, 0) 80%);
    }
}

.integrator-section__owf {
    overflow: hidden;
}

.integrator-section-header {
    display: flex;
    flex-direction: column;
    padding-top: 35px;

    @media (max-width: $tablet-width-m) {
        padding-top: 35px;
    }
}
 
.integrator-section-header__title {
    font-size: 67px;
    font-weight: 700;
    color: #fff;

    @media(max-width: $desktop-width-m - 1) {
        font-size: 40px;
    }

    @media (max-width: $tablet-width-s) {
        font-size: 36px;
        line-height: 44px;
    }
}

.integrator-button {
    width: 336px;
    padding: 8px 33px;
    background: #283584;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: .4s;

    &.integrator-feedback__btn {
        margin: auto;
    }

    &:hover {
        background: #4A020A;
    }

    @media (max-width: $tablet-width-s) {
        width: 269px;
    } 
}

.integrator-section-header__text {
     width: 644px;
     padding: 20px 0;
     font-size: 20px;
     font-weight: 600;
     line-height: 24px;
     color: #fff;
     position: relative;

     @media (max-width: $desktop-width-m) {
        width: 515px;
        font-size: 16px;
     }

     @media (max-width: $tablet-width-m) {
        width: 350px;
        font-size: 14px;
        line-height: 21px;
        padding: 30px 0;
     }

     @media (max-width: $tablet-width-s) {
        width: 295px;
        font-size: 12px;
     }
}

.integrator-section-arrows {
     padding: 20px 10px 0 0;
     display: flex;
     justify-content: flex-end;

     @media (max-width: $tablet-width-m) {
        padding-top: 10px;
     }

     @media (max-width: $tablet-width-s) {
        padding-top: 5px;
     }

     &.integrator-section-arrows__services {
        opacity: 0;
        visibility: hidden;

        @media (max-width: $tablet-width-m) {
            opacity: 1;
            visibility: visible;
        }
     }

     .section-arrows {
        width: 48px;
        height: 20px;
        position: relative;
        cursor: pointer;
        opacity: 1;

        &:not(.deactive) {
            &:hover {
                animation: anim-larrow 1s infinite;
            }
        }

        &.deactive {
            opacity: .5;
            cursor: not-allowed;
        }

        &__reverse {
            transform: rotate(-180deg);

            &:not(.deactive) {
                &:hover {
                    animation: anim-rarrow 1s infinite;
                }
            }

            &.deactive {
                opacity: .5;
                cursor: not-allowed;
            }
        }

        & + .section-arrows {
            margin-left: 20px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 10px;
            width: 10px;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 2px;
            background: #fff;
        }
     } 
     @keyframes anim-larrow  {
        0% {
            transform: translateX(0);
        }

        50% {
            transform: translateX(10px);
        }

        100% {
            transform: translateX(0);
        }
     }

     @keyframes anim-rarrow  {
        0% {
            transform: rotate(-180deg) translateX(0);
        }

        50% {
            transform: rotate(-180deg) translateX(10px);
        }

        100% {
            transform: rotate(-180deg) translateX(0);
        }
     }
}

.integrator-service-block {
    position: relative;
    padding: 34px 0;

    @media (max-width: $tablet-width-m) {
        width: 200%;
        transition: .5s;
    }
}

.integrator-service-line {
    width: 100%;
    height: 5px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    box-sizing: border-box;
    position: absolute;
    bottom: 34px;

    .line-filling {
        height: 100%;
        width: 20%;
        background: #fff;
        transition: .5s;
    }
}

.integrator-service-types {
    display: flex;
    padding: 0 10%;
    width: 80%;
}

.service-types-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 25%;

    &.active {
        .type-subtitle {
            opacity: 1;
        }

        .type-circle {
            background: transparent;
        }
    }

    &.hover-ev {
        .type-subtitle {
            opacity: 1;
        }

        .type-circle {
            background: transparent;
        }
    }

    @media (max-width: $desktop-width-s) {
        &:nth-child(even) {
            .type-line {
                height: 70px;
            }
        }
    }

    .type-subtitle {
        display: flex;
        color: #fff;
        align-items: center;
        max-width: 225px;
        opacity: .6;
        transition: .5s;

        .subtitle__num {
            font-size: 36px;
            font-weight: 700;
        }

        .subtitle__text {
            padding-left: 12px;
            font-size: 16px;
            font-weight: 600;
        }

        @media (max-width: $tablet-width-m) {
            max-width: 170px;

            .subtitle__num {
                font-size: 28px;
            }

            .subtitle__text {
                padding-left: 6px;
                font-size: 14px;
            }
        }

        @media (max-width: $tablet-width-s) {
            flex-direction: column;
            align-items: flex-start;
            max-width: 120px;

            .subtitle__num {
                font-size: 24px;
            }

            .subtitle__text {
                padding-left: 0;
                font-size: 12px;
            }
        }
    }

    .type-line {
        height: 140px;
        width: 1px;
        background: #fff;
    }

    .type-circle {
        position: absolute;
        bottom: -31.5px;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: rgba(251, 0, 21, 0.6);
        transition: .5s ease;
        cursor: pointer;

        @media (max-width: $tablet-width-s) {
            bottom: -23.5px;
            width: 51px;
            height: 51px;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 66.6666%;
            width: 66.6666%;
            border-radius: 50%;
            background: #FB0015;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 20%;
            width: 20%;
            border-radius: 50%;
            background: #fff;
        }
    }
}

.integrator-section2-bg {
    background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(36,47,112,1) 60%, rgba(0,0,0,1) 100%);
}

.integrator-section-services {
    display: none;
    flex-direction: column;
    padding-top: 100px;

    &.active {
        display: flex;
    }

    .service-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 800px;
        opacity: 0;
        transform: translateY(20px);

        // &.show-block {
        //     animation: showserviceblock .5s ease forwards;
        // }

        &__title {
            padding-bottom: 20px;
            font-size: 36px;
            color: #fff;
            font-weight: 700;

            @media (max-width: $tablet-width-m) {
                font-size: 36px;
                line-height: 34px;
            }

            @media (max-width: $tablet-width-s) {
                font-size: 24px;
            }
        }

        &__text {
            font-size: 16px;
            color: #fff;
            font-weight: 500;
            line-height: 19.5px;

            @media (max-width: $tablet-width-s) {
                font-size: 14px;
                list-style: 17px;
            }
        }
    }

    .service-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        width: 100%;
        padding-top: 20px;

        @media (max-width: $desktop-width-s) {
            justify-content: start;
            width: 100%;
            padding-top: 30px;
        }

        .service-list-item {
            display: flex;
            width: calc(30% - 20px);
            max-width: 400px;
            padding: 30px 20px 50px 0;
            margin: 20px 20px 0 0;
            border-bottom: 1px solid rgba(251, 0, 21, 0.7);
            opacity: 0;
            transform: translateY(20px);

            // &.show-block {
            //     animation: showserviceblock .5s ease forwards;
            // }

            &__num {
                font-size: 24px;
                color: rgba(255, 255, 255, 0.7);
            }

            &__text {
                padding-left: 20px;
                font-size: 24px;
                font-weight: 700;
                color: #fff;
            }

            @media (max-width: $laptop-width) {
               width: calc(45% - 20px);

                &__text {
                    font-size: 18px;
                }
            }

            @media (max-width: $tablet-width-s + 100px) {
                width: calc(100% - 20px);
                padding: 10px 0 30px 0;
            }
        }
    }

    &.active {
        display: flex;

        .service-header {
            animation: showserviceblock .5s .1s ease forwards;
        }

        .service-list .service-list-item {
            animation: showserviceblock .5s ease forwards;
        }
    }

    @keyframes showserviceblock {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.integrator-section-services__footer {
    width: 100%;
    height: 600px;
    background: url('../img/bg/bg-integrator-section2-footer.png') top center / cover no-repeat;

    @media (max-width: $desktop-width-xl) {
        height: 450px;
    }

    @media (max-width: $desktop-width-s) {
        height: 350px; 
    }

    @media (max-width: $tablet-width-m) {
        height: 250px;  
    }
}

.integrator-section-projects {
    padding-bottom: 150px;

    @media (max-width: $tablet-width-l) {
        padding-bottom: 50px;
    }

    @media (max-width: $tablet-width-m) {
        padding-bottom: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;

        .projects-header-title {
            font-size: 48px;
            font-weight: 700;
            color: #fff;


            @media (max-width: $tablet-width-m) {
                font-size: 36px;
            }

            @media (max-width: $tablet-width-s) {
                font-size: 24px;
            }
        }
    }

    &__content {
        position: relative;
        margin: 150px 0;
        height: 760px;
        overflow: hidden;


        .projects-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 100%;
            width: 926px;
            background: url('../img/bg/bg-integrator-section3.svg') top center / contain no-repeat;
            z-index: -1;

            @media (max-width: $desktop-width-s) {
                width: 806px;
            }

            @media (max-width: $laptop-width) {
                width: 90%;
            }

            @media (max-width: $tablet-width-m) {
                display: none;
            }
        }

        @media (max-width: $desktop-width-s) {
            height: 700px;
        }

        @media (max-width: $tablet-width-m) {
            height: auto;
            margin-top: 50px;
        }

        .projects-list {
            display: flex;
            padding: 0 10px;
            transition: .5s;
            touch-action: auto;

            &__item {
                display: flex;
                min-width: 100%;

                &:not(&:last-child) {
                    margin-right: 20px;
                }

                .list-item-wrap-desc {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media (max-width: $tablet-width-m) {
                        display: none;
                    }
                }

                .project-title {
                    width: 926px;
                    padding: 60px 80px 32px;
                    font-size: 36px;
                    font-weight: 700;
                    color: #000;
                    box-sizing: border-box;

                    @media (max-width: $desktop-width-s) {
                        width: 866px;
                    }

                    @media (max-width: $laptop-width) {
                        width: 90%;
                        padding-top: 40px;
                        font-size: 24px;
                    }
                }

                .list-item-wrap-mob {
                    display: none;
                    flex-direction: column;
                    width: 100%;
                    padding: 15px 15px 35px;
                    background: #fff;
                    border-radius: 16px;


                    @media (max-width: $tablet-width-m) {
                        display: flex;
                    }

                    .project-title_mob {
                        padding-bottom: 20px;
                        font-weight: 700;
                        font-size: 18px;
                        color: #000;
                    }

                    .project-info-img_mob {
                        width: 100%;
                        height: 276px;
                        background: url('../img/bg/bg-integrator-project1.jpg') center / cover no-repeat;
                    }

                    .project-info-text_mob {
                        padding: 15px 0 30px;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17px;
                        color: #000;
                        text-align: left;
                    }

                    .project-info-link_mob {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        padding: 18px 0;
                        background: #283584;

                        .link-arrow {
                            height: 16px;
                            width: 27px;
                            background: url('../img/icons/icon-arrow-short-white.svg') center / contain no-repeat;
                            transform: rotate(180deg);
                        }

                        .link-text {
                            padding-right: 50px;
                            font-size: 18px;
                            font-weight: 600;
                            color: #fff;
                        }

                        &:hover {
                            background: #1f2863;
                        }
                    }

                    .project-info-count {
                        padding-top: 20px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: baseline;
    
                        .count__from {
                            font-size: 90px;
                            font-weight: 600;
                            color: #000; 
                        }
    
                        .count__all {
                            padding-left: 5px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #283584;
                        }
                    }
                }
            }
        }

        .project-info {
            display: flex;
            width: 100%;
            height: 480px;
            border: 4px solid #FFFFFF;

            &__left {
                display: flex;
                width: 50%;
                height: 100%;

                .project-info-img {
                    height: 100%;
                    width: 100%;
                    background: url('../img/bg/bg-integrator-project1.jpg') center / cover no-repeat;
                }

                .project-info-link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 118px;
                    height: 100%;
                    background: #283584;
                    transform: rotate(180deg);
                    transition: .5s;


                    @media (max-width: $laptop-width) {
                        width: 89px;
                    }

                    &:hover {
                        background: #1f2863;
                    }

                    .link-text {
                        font-size: 36px;
                        font-weight: 600;
                        color: #fff;
                        writing-mode: vertical-lr;

                        @media (max-width: $laptop-width) {
                            font-size: 24px;
                            line-height: 19.5px; 
                        }
                    }

                    .link-arrow {
                        height: 50px;
                        width: 50%;
                        background: url('../img/icons/icon-arrow-short-white.svg') center / contain no-repeat;
                        transform: rotate(-90deg);
                    }
                }
            }


            &__right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 50%;
                padding: 60px 30px 70px 19px;
                background: #000;

                @media (max-width: $laptop-width) {
                    padding: 60px 30px 70px 19px; 
                }

                .project-info-text {
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                    line-height: 21.94px;
                    text-align: left;
                    opacity: .8 ;

                    @media (max-width: $laptop-width) {
                        font-size: 16px;
                        line-height: 19.5px; 
                    }
                }

                .project-info-count {
                    display: flex;
                    justify-content: flex-end;
                    align-items: baseline;

                    .count__from {
                        font-size: 90px;
                        font-weight: 00;
                        color: #fff;   
                    }

                    .count__all {
                        padding-left: 5px;
                        font-size: 14px;
                        font-weight: 600;
                        color: #283584;
                    }
                }
            }
        }
    }
}

.integrator-bg-form {
    position: relative;

    @media (max-width: $desktop-width-m - 1) {
        margin-bottom: -200px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: url('../img/bg/bg-integrator-form.jpg') center / cover no-repeat;   
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3) 35%, rgba(0,0,0,0.2) 100%);
        z-index: -1;
    }
}

.integrator-request {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 150px;
    margin-bottom: 150px;

    @media (max-width:$desktop-width-m - 1) {
        margin-bottom: 75px;
    }

    @media (max-width: $tablet-width-m) {
        padding-top: 0;
        margin-bottom: 50px;
    }
}

.integrator-request__subtitle {
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 45px;
    text-align: center;

    @media (max-width: $desktop-width-m - 1) {
        font-size: 36px;
     }

     @media (max-width: $tablet-width-s - 1) {
        font-size: 32px;
    }
}

.integrator-feedback {
    display: flex;
    flex-direction: column;
    max-width: 570px;
    width: 100%;

    &__item {
        padding-bottom: 15px;
    }

    .integrator-feedback__input {
        width: 100%;
        padding: 10px 15px;
        outline: none;
        border: none;
        border: 1px solid #fff;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        background: rgba(255, 255, 255, 0.26);
        box-sizing: border-box;

    }

    .integrator-feedback__textarea {
        width: 100%;
        height: 189px;
        padding: 10px 15px;
        margin-top: 15px;
        background: rgba(255, 255, 255, 0.26);
        border: 1px solid #fff;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }

    .integrator-feedback__check {
        padding-bottom: 30px;

        .label_checkbox {
            position: relative;
            box-sizing: border-box;
            padding: 3px 0 0 25px;
            font-size: 11px;
            color: rgba(255, 255, 255, 0.6);
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                left: 0;
                width: 14px;
                height: 14px;
                background: 0 0;
                border-radius: 2px;
                border: 1px solid #fff;
            }

            &:after {
                content: '';
                position: absolute;
                top: 9px;
                left: 2px;
                width: 12px;
                height: 12px;
                display: none;
                margin: -6px 0 0;
                background: url('../img/icons/icon-checked-fedb.svg') no-repeat center center;
            }
        }

        .field_checkbox:checked + .label_checkbox:after {
            display: block;
        }
    }
}
