.megamenu {
	@include pos(0, 0);
	z-index: 994;
	right: -17px;
	height: 100vh;
	box-sizing: border-box;
	padding-right: 17px;
	padding-bottom: 46px;
	position: fixed;
	background: $color__theme_black;
	@media (min-width:$desktop-width-m) {
		display: none;
	}

	@media (max-width:$desktop-width-m - 1) {
		@include flex-width(100%);
		box-sizing: border-box;
		display: none;
	}

	@media (min-width:$tablet-width-m) {
		padding-top: 124px;
		padding-left: 40px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-top: 90px;
		padding-left: 12px;
	}
}
.megamenu__wrapper {
	z-index: 11;
	position: absolute;
	top: 90px;
	left: 0;
	right: 0;
	bottom: 0;
	height: 85%;
	padding: 0 15px;
	counter-reset: list;
	overflow-y: scroll;
	overflow-x: hidden;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media (max-width: $tablet-width-m - 1 ) {
		height: 81%;
	}
}
.megamenu__section-top {
	@media (min-width:$tablet-width-m) {
		margin-top: 31px;
		margin-left: 41px;
	}
	
}
.megamenu__row {
	@include d-flex;
	@include flex-wrap;
	margin-bottom: 226px;

	@media (min-width:$tablet-width-m - 1) {
		margin-left: -10px;
		margin-right: -10px;
	}

	@media (max-width:$tablet-width-s - 1) {
		display: block;
		flex-wrap: nowrap;
		// margin-top: -30px;
		margin-top: 6px;
	}
}

.megamenu__col {
	position: relative;
	margin-bottom: 70px;
	margin-top: 10px;
	box-sizing: border-box;
	margin-right: 67px;
	padding-right: 25px;

	// @media (min-width:$tablet-width-m) {
	// 	padding-left: 10px;
	// 	padding-right: 10px;
	// }

	// @media (min-width:$tablet-width-m) {
	// 	// @include flex-width(100%/4);
	// }
	&:last-child {
		@media (max-width:$tablet-width-m - 1) {
			display: none;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		margin-bottom: 0;
		margin-left: 12px;
	}

	@media (min-width:$tablet-width-s - 1) and (max-width:$tablet-width-l - 1) {
		margin-right: 50px;
		// @include flex-width(100%);
	}
	@media (max-width:$tablet-width-s - 1) {
		margin-top: 0;
	}
	@media (min-width:$tablet-width-s - 100) {
		margin-bottom: 0;
	}
}

.megamenu__title {
	&:before {
		content: '';
		position: absolute;
		display: inline-block;
		border-radius: 50%;
		background: rgba(251, 0, 21, .6);
		height: 45px;
		width: 45px;
		z-index: -1;
		left: -20px;
		top: -6px;
		@media (max-width:$tablet-width-m - 1) {
			left: -18px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		display: inline-block;
		border-radius: 50%;
		background: #FB0015;
		height: 30px;
		width: 30px;
		z-index: -1;
		left: -12px;
		top: 1px;
		@media (max-width:$tablet-width-m - 1) {
			left: -10px;
		}

	}

	@include fs-lh(24px, 29px);
	font-weight: bold;
	margin: 0 0 24px;

	&.megamenu__title_link {
		@media (min-width:$tablet-width-s) {
			display: block;
		}

		@media (max-width:$tablet-width-s - 1) {
			display: none;
		}
	}

	// &.megamenu__title_mobile {
	// 	@media (max-width:$tablet-width-m - 1) {
	// 		display: block;
	// 	}
	// }



}
.megamenu__btn-circle {
	-webkit-tap-highlight-color: transparent;
	outline: none;

	&:focus {
		outline: none;
	}

	&:hover {
		outline: none;
	}
	&:before {
		content: '';
		position: absolute;
		display: inline-block;
		border-radius: 50%;
		background: rgba(251, 0, 21, .6);
		height: 40px;
		width: 40px;
		z-index: -1;
		left: -18px;
		top: -6px;
		@media (min-width:$tablet-width-s) {
			display: none;
		}
	}

	&:after {
		content: '';
		position: absolute;
		display: inline-block;
		border-radius: 50%;
		background: #FB0015;
		height: 26px;
		width: 26px;
		z-index: -1;
		left: -11px;
		top: 1px;
		@media (min-width:$tablet-width-s) {
			display: none;
		}
	}
	@media (max-width:$tablet-width-s - 1) {
		@include before;
		@include inline-block;
		color: $color__theme_white;
		padding: 0 20px 0 0;
		border: 0;
		cursor: pointer;
		background: none;
	}
}
.megamenu__title_btn {

	@include fs-fw(24, bold);
	line-height: 29px;

	@media (min-width:$tablet-width-s) {
		display: none;
	}

	@media (max-width:$tablet-width-s - 1) {
		@include fs-fw(18px, bold);
		line-height: 29px;
		@include before;
		@include inline-block;
		color: $color__theme_white;
		padding: 0 20px 0 0;
		border: 0;
		cursor: pointer;
		background: none;
		margin-bottom: 35px;

		&:before {
			@include offset(-4px 0 0, 12px 0 0 6px);
			top: 50%;
			right: 0;
			background: url(../img/icons/icon-arrow-min.svg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
			background-size: auto 100%;
			
	}
			
		}


	&.active {
		&:before {
			@include offset(-1px 0 0, 6px 0 0 12px);
			background-image: url(../img/icons/icon-arrow-min-active.svg);
			background-size: 100% auto;
		}

		&+.megamenu__nav {
			display: block;
		}


	}
}
.megamenu__title_btn-arrowNone {
	&:before {
		display: none;
	}
}


.megamenu__nav {
	margin: 24px 0 40px;

	@media (max-width:$tablet-width-s - 1) {
		display: none;
		padding: 0 0 0 26px;
		margin: -21px 0 32px;
	}
	@media (min-width:$mobile-width - 70) and (max-width:$mobile-width +10){
		padding: 0 0 0 8px;
	}
}

.megamenu__item {
	@include fs-fw(16px, 500);

	display: block;
	line-height: 20px;

	&.megamenu__item_nomb {
		margin-bottom: 0;
	}

	@media (max-width:$tablet-width-s - 1) {
		color: rgba(255, 255, 255, 0.5);
	}
}

.megamenu__item-full {
	margin: 0 0 16px;
}

.megamenu__section-bottom {
	margin-top: -122px;
}

.megamenu__footer {
	position: absolute;
	bottom: 0;
	@include fs-lh(16px, 20px);
	margin: 0 0 46px;
	box-sizing: border-box;
	color: rgba(255, 255, 255, 0.6);

	@media (min-width:$tablet-width-m) {
		padding-right: 10px;
		margin-left: 40px;
	}

	@media (max-width:$tablet-width-m - 1) {
		display: none;
	}

	.d-block {
		margin: 0 0 24px;
	}
}

.megamenu__footer-tablet {
	// @media (min-width:$tablet-width-m) {
	// 	top: 160px;
	// 	right: 0;
	// 	position: absolute;

	// }
	// @media (max-width:$tablet-width-m) {
	// position: absolute;
	// bottom: 0px;
	// }

}

.megamenu__footer-item-box {
	@media (min-width:$tablet-width-m) {
		display: none;
	}
}
.megamenu__footer-text {
	color: rgba(255, 255, 255, 0.5);
}

.megamenu__footer-item {
	@include fs-fw(16px, 500);
	margin: 0 0 16px;
	display: block;
	line-height: 20px;
	@media (min-width:$tablet-width-m) {
		margin: 0 41px 20px 0;
	}
	
}


.megamenu__footer-item-box {
	margin-bottom: 56px;
}

.megamenu__social {
	@include d-flex;
	@include flex-wrap;
	@include align-items-center;
	@media (min-width:$tablet-width-m) {
		flex-direction: column;
	}
	@media (min-width:$tablet-width-m) {
		position: absolute;
		top: 51px;
		right: 0;
	}

}

.megamenu__social-item {
	@include inline-block;
	@include size(20px,20px);
	@include default-transition;
	margin: 0 32px 30px 0;
	background-position: 0 0;
	background-size: 100% auto;
	background-repeat: no-repeat;

	
	&:hover {
		opacity: 1;
		color: $color__theme_white;
	}
	
	&.megamenu__social-item_vk {
		@include size(22px,16px);
		background-image: url(../img/icons/icon-vk.svg);
	}
	&.megamenu__social-item_ns {
		@include size(22px,18px);
		background-image: url(../img/icons/icon-ns.svg);
	}

	&.megamenu__social-item_inst {
		background-image: url(../img/icons/icon-inst.svg);
	}

	&.megamenu__social-item_fb {
		background-image: url(../img/icons/icon-fb.svg);
	}

	&.megamenu__social-item_tg {
		@include size(22px,19px);
		background-image: url(../img/icons/icon-tg.svg);
	}
	@media (min-width:$tablet-width-m) {
		margin: 0 41px 21px 0;
	}
}

.megamenu__social-link {
	@include inline-block;
	@include size(20px,20px);
	margin: 0 24px 0 0;
	color: #53595D;
	transition: ease .3s all;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		color: #000000;
	}
}