.article {
	@include before;
	padding-bottom: 128px;
	background: $color__theme_white;

	z-index: 1;

	@media (min-width:$desktop-width-m) {
		@include d-flex;
		@include flex-wrap;
		padding-top: 183px;
	}

	@media (min-width:$tablet-width-l) {
		margin: -236px 0 0;
	}

	@media (max-width:$tablet-width-l - 1) {
		margin: -210px 0 0;
	}

	&:before {
		@media (min-width:$desktop-width-m) {
			@include pos(0,0);
			bottom: 0;
			z-index: 1;
			width: (100%/12*3);
			background: #283584;
		}
	}
}

.article__sidebar {
	@include zi-2;
	box-sizing: border-box;
	color: $color__theme_white;

	@media (min-width:$desktop-width-m) {
		@include flex-column;
		@include justify-content-between;
		@include flex-width(100%/12*2);
		margin-left: (100%/12);
		padding-left: 20px;
	}

	@media (max-width:$desktop-width-m - 1) {
		@include flex-wrap;
		@include offset(0 0 40px,160px 30px 40px);
		background: #283584;
	}

	@media (min-width:$tablet-width-m) {
		@include d-flex;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 12px;
		padding-right: 12px;
		margin-bottom: 22px;
	}
}

.article__main {
	position: relative;
	z-index: 3;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		@include flex-width(100%/12*8);
		padding-left: 20px;
		padding-right: 20px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.article__category {
	@include fs-lh(14px,17px);

	@media (max-width:$desktop-width-m - 1) {
		position: absolute;
		top: 195px;
		right: 40px;
	}

	@media (max-width:$tablet-width-l - 1) {
		display: none;
	}

	.article__counter {
		@include fs-lh(20px,24px);
		font-weight: 600;

		@media (max-width:$desktop-width-m - 1) {
			text-align: right;
		}
	}
}

.article__sidetitle {
	text-transform: uppercase;
	font-weight: 900;

	@media (min-width:$desktop-width-m) {
		@include fs-lh(100px,120px);
		@include multi-prop(rotate(-90deg));
		margin: 0 0 -25% -50%;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/8*6);
		margin-left: (100%/8);
		margin-bottom: 32px;
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
	}

	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include fs-lh(133px,160px);
	}

	@media (max-width:$tablet-width-l - 1) {
		@include fs-lh(63px,76px);
	}

	@media (max-width:$tablet-width-m - 1) {
		font-size: 58px;
		margin: 0 0 48px;
	}
}

.article__bottom {
	@media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
		@include d-flex;
		@include flex-wrap;
		@include justify-content-between;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		@include flex-width(100%/8*6);
		margin-left: (100%/8);
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
	}
}

.article__company {
	display: block;
	font-weight: 500;
	line-height: 22px;

	&:last-child {
		margin-bottom: 0;
	}

	@media (min-width:$desktop-width-m) {
		margin: 0 0 24px;
	}

	@media (max-width:$tablet-width-l - 1) {
		margin: 0 0 24px;
	}

	.article__counter {
		@include fs-lh(12px,15px);
		color: $color__theme_red;
	}
}