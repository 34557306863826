.agancy {
    .title__h1 {
        display: flex;
        justify-content: left;
        text-align: left;
        @media (max-width:$desktop-width-s) {
            @include fs-lh(40px, 48px);
            font-weight: 700;
        }
        @media (max-width:$tablet-width-m - 1) {
            @include fs-lh(36px, 44px);
            font-weight: 700;
        }
    }

    .btn_agancy {
        cursor: pointer;
        display: block;
        text-align: center;
        max-width: 221px;
        margin: 26px 0 0 0;
        padding: 2px 0 5px 0;
        @include fs-lh(20px, 24px);
        font-weight: 600;
        color: $color__theme_white;
        background: #21A3DC;
        border-radius: 16px;
        transition: all 0.5s;
        &:hover {
            opacity: 0.6;
        }
        @media (max-width:$tablet-width-m - 1) {
            margin: 15px 0 0 0;
            max-width: 217px;
        }
    }
}

.agancy__desc {
    margin: 24px 0 64px 0;
    @include flex-width(100%/12*6);
    p {
        @include fs-lh(20px, 24px);
        font-weight: 600;
        color: $color__theme_white;
        @media (max-width:$desktop-width-s) {
            @include fs-lh(16px, 19px);
            font-weight: 600;
        }
    }
    @media (max-width:$desktop-width-s) {
        margin: 24px 0 55px 0;
        @include flex-width(100%/12*12);
    }
    @media (max-width:$tablet-width-m - 1) {
       margin: 16px 0 24px 0;
       padding: 0 12px;
    }
}

.agancy__bg {
    position: absolute;
    bottom: -200px;
    z-index: -10;
    width: 100%;
    height: 100%;
    //height: 100%;
    background: url('../img/bg/agancy_rocket.png') no-repeat;
    background-size: 100% 100%;        
    @media (max-width:$desktop-width-s - 1){
        width: 90%;
    }
    @media (max-width:$tablet-width-m - 1) {
        display: none;
    }
}


.elements {
    //position: relative;
    .element__line {
        position: absolute;
        top: 27%;
        left: 0;
        height: 16px;
        width: 100%;
        background: #fff;
        opacity: 0.2; 
        transform: rotate(-2deg);
        @media (max-width:$desktop-width-s - 1){
           display: none;
        }
    }
}