.sites {
    position: relative;
    @media (max-width:$tablet-width-m - 1) {
        padding: 0;
    }
    .title__h1 {
        text-align: left;
        @media (max-width:$desktop-width-s - 1){
            @include fs-lh(40px, 48px);
        }
        @media (max-width:$tablet-width-m - 1) {
            @include fs-lh(36px, 43px);
            padding-left: 12px;
        }
    }
    .content__footer {
        min-height: 50px;
        @media (max-width:$desktop-width-s - 1){
           min-height: 5px;
        }
        @media (max-width:$tablet-width-m - 1) {
            display: none;
        }
        .content__bottom:before {
            transform: matrix(1,-.02,0,1,0,0);
        }
    }
    .content__bottom {
        z-index: 2;
        top: 0;
        left: 7%;
        right: 11%;
        @media (max-width:$desktop-width-m - 1){
           right: 7%;
        }
        @media (max-width:$tablet-width-l - 1){
           right: 0;
           left: 0;
           margin: 0;
        }
        &::before {
            content: '';
            background: #fff;
            border: 2px solid #000;
            box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
            top: -20px;
            left: 80px;
            right: 30px;
            bottom: 0px;
            @media (max-width:$desktop-width-s - 1){
              left: 30px;
              right: 60px;
            }
            @media (max-width:$tablet-width-l - 1){
                box-shadow: none;
                left: 40px;
                right: 40px;
            }
        }
        @media (max-width:$desktop-width-s - 1){
           width: 100%;
        }
    }
}

.sites__desc {
    margin:16px 0 64px 0;
    @include flex-width(100%/12*5);

    @media (max-width:$desktop-width-s - 1){
        @include flex-width(100%/8*7);
        margin:24px 0 64px 0;
    }
    @media (max-width:$tablet-width-m - 1) {
       margin: 16px 0 24px 0;
       @include flex-width(100%/8*7);
       padding-left: 12px;
    }
    p {
        @include fs-lh(20px, 24px);
        font-weight: 600;
        color: $color__theme_white;
        @media (max-width:$desktop-width-s - 1){
            @include fs-lh(16px, 20px);
        }
    }
}

.sites__bg {
    position: absolute;
    bottom: 100px;
    z-index: -4;
    width: 100%;
    height: 40%;
    //height: 100%;
    background: url('../img/content/bg-digital.png') no-repeat;
    background-size: cover;
}