.infoblock {
	margin: 0 0 24px;
	position: relative;
	box-sizing: border-box;

	@media (min-width:$tablet-width-l) {
		@include flex-width(100%/12*4);
	}

	@media (min-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2)) and (max-width:$tablet-width-l - 1) {
		@include flex-width(100%/12*6);
	}
	
	@media (min-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2)) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2) - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}
	
	&.infoblock_black {
		.infoblock__inner {
			background: $color__theme_black;
		}

		.infoblock__title {
			color: $color__theme_white;
			border-color: $color__theme_red;
		}

		.infoblock__text {
			color: $color__theme_white;
		}

		.infoblock__tag {
			color: $color__theme_red;
		}

		@media (max-width:$desktop-width-l - 1) {
			.infoblock__tag {
				color: $color__theme_red;
				background: $color__theme_black;
			}
		}
	}

	&.infoblock_red {
		.infoblock__inner {
			background: $color__theme_red;
		}

		.infoblock__title {
			color: $color__theme_white;
			border-color: $color__theme_black;
		}

		.infoblock__text {
			color: $color__theme_black;
		}

		.infoblock__tag {
			color: $color__theme_white;
		}

		@media (max-width:$desktop-width-l - 1) {
			.infoblock__tag {
				color: $color__theme_red;
			}
		}
	}
	
	&:hover {
		.infoblock__inner {
			border-color: #21a3dc;
			background: $color__theme_white;
			box-shadow: 0px 0px 20px #21a3dc, 0px 0px 20px rgba(33, 163, 220, 0.6);
		}

		.infoblock__title {
			border-color: #283584;
			color: $color__theme_black;
		}

		.infoblock__text {
			color: $color__theme_black;
		}

		.infoblock__tag {
			color: #283584;
		}

		@media (max-width:$desktop-width-l - 1) {
			.infoblock__tag {
				background: $color__theme_white;
			}
		}
		
		.highlighted {
			background: #21A3DC;
			color: $color__theme_white;
		}
	}
	
	/* height */
	&.infoblock_index {
		@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
			@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
				margin: 0 0 18px;
			}
		}
		
		.infoblock__inner {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include offset(0 0 10px,12px 16px);
					height: 165px;
				}
			}
		}
		
		.infoblock__title {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include fs-lh(16px,22px);
					height: 78px;
				}
			}
		}
		
		.infoblock__text {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include fs-lh(12px,18px);
					height: 56px;
				}
			}
		}
		
		.infoblock__tag {
			@media (min-width:$desktop-width-s) and (max-width:$desktop-width-m) {
				@media (min-height:$tablet-width-m - 150) and (max-height:$laptop-width - 1) {
					@include fs-lh(11px,14px);
					margin-right: 4px;
				}
			}
		}
	}
}

.infoblock__inner {
	@include default-transition;
	display: block;
	border-radius: 12px;
	box-sizing: border-box;
	border: 2px solid rgba(0,0,0,0);
	background: rgba(255, 255, 255, 0.5);
	
	@media (min-width:$desktop-width-l) {
		min-height: 266px;
	}
	
	@media (min-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2)) and (max-width:$desktop-width-l - 1) {
		height: 218px;
		margin: 0 0 12px;
	}
	
	@media (min-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2)) {
		padding: 16px 22px;
	}
	
	@media (max-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2) - 1) {
		@include offset(0 0 8px,22px 16px);
	}
}

.infoblock__title {
	@include fs-lh(26px,32px);
	@include border(0 0 8px,#21a3dc);
	@include offset(0 0 5px,0 0 5px);
	@include default-transition;
	display: block;
	overflow: hidden;
	font-weight: bold;
	box-sizing: border-box;
	color: $color__theme_white;

	&.infoblock__title_dark {
		border-color: #395c91;
	}

	&.infoblock__title_black {
		border-color: $color__theme_black;
	}

	&.infoblock__title_red {
		border-color: $color__theme_red;
	}
	
	@media (min-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2)) {
		height: 109px;
	}
}

.infoblock__text {
	@include fs-lh(18px,22px);
	@include default-transition;
	font-weight: 500;
	display: block;
	overflow: hidden;
	color: $color__theme_white;
	
	@media (min-width:($tablet-width-s + ($tablet-width-m - $tablet-width-s)/2)) and (max-width:$desktop-width-l - 1) {
		height: 66px;
	}
}

.infoblock__footer {
	@media (min-width:$desktop-width-l) {
		position: absolute;
	}

	@media (min-width:$tablet-width-m) {
		left: 32px;
		right: 32px;
		bottom: 12px;
	}
}

.infoblock__tag {
	@include inline-block;
	@include fs-lh(14px,17px);
	@include default-transition;
	font-weight: bold;
	color: #21a3dc;
	margin: 0 8px 0 0;
	
	&.infoblock__tag_dark {
		color: #395c91;
	}
	
	&.infoblock__tag_black {
		color: $color__theme_black;
	}

	&:before {
		content: '#';
	}

	&:last-child {
		margin-right: 0;
	}
	
	@media (max-width:$desktop-width-l - 1) {
		padding: 2px 8px;
		margin-bottom: 4px;
		border-radius: 2px;
		background: rgba(255, 255, 255, 0.8);
	}
}