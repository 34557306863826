.about {
	@include d-flex;
	@include flex-wrap;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		margin-left: -30px;
		margin-right: -30px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		margin-left: -10px;
		margin-right: -10px;
	}

	@media (max-width:$desktop-width-s - 1) {
		margin-bottom: 0;
	}

	&.about_mx {
		margin-bottom: 55px;

		@media (min-width:$desktop-width-m) {
			margin-left: -40px;
			margin-right: -40px;
		}

		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
			margin-left: -26px;
			margin-right: -26px;

			.about__col {
				padding-left: 26px;
				padding-right: 26px;
			}
			.about__col-topic {
				padding-left: 26px;
				padding-right: 26px;
			}
		}
	}

	&.about_slides {
		margin-bottom: 0;
		padding-bottom: 55px;

		@media (max-width:$tablet-width-m - 1) {
			margin-right: -12px;
			overflow: hidden;
		}

		.about__col {
			@media (max-width:$tablet-width-m - 1) {
				flex: auto;
				padding: 0 12px 0 0;
			}
		}
		.about__col-topic {
			@media (max-width:$tablet-width-m - 1) {
				flex: auto;
				padding: 0 12px 0 0;
			}
		}

		.about__item {
			@media (min-width:$tablet-width-s) and (max-width:$tablet-width-m - 1) {
				min-width: 480px;
			}

			@media (max-width:$tablet-width-s - 1) {
				min-width: 232px;
			}
		}

		.about__header {
			background: rgba(0,0,0,0.5);
			@media (max-width:$tablet-width-s - 1) {
				padding: 16px;
			}
		}

		.about__inner {
			@media (max-width:$tablet-width-s - 1) {
				padding: 16px 16px 10px;
			}
		}

		.about__text {
			@media (max-width:$tablet-width-s - 1) {
				@include fs-lh(14px, 17px);
			}
		}
	}
}

.about__col,
.about__col-topic {
	@include before;
	height: auto;
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-left: 20px;
		padding-right: 20px;
	}

	@media (min-width:$tablet-width-m) {
		@include flex-width(100%/3);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include flex-width(100%);
		margin: 0 0 24px;
	}

	&.about__col_mb {
		margin-bottom: 60px;
	}

	&.about__col_standart {
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/2);
		}
	}

}

.about__item {
	@include before;
	@include d-flex;
	@include flex-column;
	height: 100%;
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	box-sizing: border-box;
	border: 2px solid $color__theme_white;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	background: $color__theme_white;

	&.about__item_nobg {
		background: rgba(0, 0, 0, 0);
		// box-shadow: 0 18px 20px 0 rgba(255, 255, 255, 0.5);

		&:before {
			@include pos(0, 0);
			right: 0;
			bottom: 0;
			pointer-events: none;
			box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.25) inset;
		}

		.about__header {
			background: rgba(0, 0, 0, .5);
		}

		.about__inner {
			background: $color__theme_white;
		}
	}
}

.about__header {
	min-height: 180px;
	padding: 4px 27px 15px;
	box-sizing: border-box;
	color: $color__theme_white;
	background: $color__theme_black;

	&.about__header_standart {
		padding-top: 16px;
	}
}

.about__meta {}

.about__date {
	@include fs-lh(14px, 17px);
	@include inline-block(middle);
	@include offset(0 14px 9px 0, 2px 6px);
	border-radius: 2px;
	color: $color__theme_black;
	background: $color__theme_white;

	&.about__date_red {
		font-weight: 600;
		color: $color__theme_white;
		background: $color__theme_red;
	}

	&.about__date_black {
		color: $color__theme_white;
		background: $color__theme_black;
	}
}

.about__info {
	@include fs-lh(14px, 17px);
	@include inline-block(middle);
	@include offset(0 14px 9px 0, 2px 0);
	color: $color__theme_red;
}

.about__title {
	@include fs-lh(60px, 73px);
	font-weight: 800;

	&.about__title_sm {
		@include fs-lh(20px, 24px);
		font-weight: 600;

	}
}


.about__col {
	&:nth-last-child(-n+3) {
		@media (min-width:$desktop-width-m - 167) and (max-width:$desktop-width-m - 1){
			.about__title,
			.about__title_sm {
				color: #000;
			}
			.about__header {
				background: #fff;
			}
			.about__item_nobg {
				background: rgba(0, 0, 0, 0);
				// box-shadow: 0 18px 20px 0 rgba(0, 0, 0, 0.5);	
			}

		}
		@media (min-width:$mobile-width) and (max-width:$tablet-width-m - 1) {
			.about__title,
			.about__title_sm {
				color: #000;
			}
			.about__header {
				background: #fff;
			}
			.about__item_nobg {
				background: rgba(0, 0, 0, 0);
				box-shadow: 0 18px 20px 0 rgba(0, 0, 0, 0.5);	
			}
		}

	}
		&:nth-last-child(-n+2) {
			@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
				.about__title,
				.about__title_sm {
					color: #000;
				}
				.about__header {
					background: #fff;
				}
				.about__item_nobg {
					background: rgba(0, 0, 0, 0);
					// box-shadow: 0 18px 20px 0 rgba(0, 0, 0, 0.5);	
				}
			}
		}

}


.about__subtitle {
	@include fs-lh(24px, 29px);
	font-weight: bold;
}

.about__inner {
	@include d-flex;
	@include flex-column;
	@include justify-content-between;
	@include flex-grow-1;
	padding: 11px 27px 11px;
	box-sizing: border-box;
}

.about__text {
	font-weight: 500;
	line-height: 22px;
	margin: 0 0 20px;

	@media (min-width:$desktop-width-s) and (max-width:$desktop-width-l - 1) {
		font-size: 14px;
	}
	a {
		color: #21a3dc;
    	text-decoration: underline;
	}
}

.about__footer {}

.about__tag {
	@include inline-block;
	@include default-transition;
	border-radius: 6px;
	margin: 0 16px 16px 0;
	color: $color__theme_white;
	background: rgba(83, 89, 93, 0.4);

	@media (min-width:$desktop-width-m) {
		padding: 2px 6px;
	}

	@media (max-width:$desktop-width-m - 1) {
		@include fs-lh(14px, 17px);
		padding: 2px 8px;
	}

	&:before {
		content: '#';
	}

	&:hover,
	&:focus {
		color: $color__theme_white;
		background: rgba(83, 89, 93, 0.8);
		//background: rgba(57, 92, 145, 0.5);
	}

	&.about__tag_sm {
		@include fs-lh(14px, 17px);
	}
}

.about__nav {
	position: static;

	@media (min-width:$tablet-width-m) {
		display: none;
	}
}

.about__bullet {
	@include inline-block;
	@include size(12px, 12px);
	margin: 0 16px 0 0;
	cursor: pointer;
	border-radius: 50%;
	background: $color__theme_white;

	&.about__bullet_active {
		background: #21A3DC;
	}
}

.about__swiper {
	@media(min-width:$tablet-width-m) {
		display: none!important;
	}
	@media(max-width:$tablet-width-m - 1) {
		display: block!important;
	}
	
}