@mixin container {
	box-sizing: border-box;

	@media (min-width:$desktop-width-m) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@media (max-width:$tablet-width-m - 1) {
		padding-left: 6px;
		padding-right: 6px;
	}
}

@mixin btn {
	@include inline-block;
	box-sizing: border-box;
	padding: 0;
	border: none;
	border-radius: 0;
	cursor: pointer;
	text-align: center;
	outline: 0 !important;

	background-color: rgba(0,0,0,0);
	cursor: pointer;
}

@mixin link-reset {
	color: inherit;
	@include dec-none;
	@include outline-none;
}

@mixin list-unstyled {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin input-reset {
	width: 100%;
	display: block;
	box-sizing: border-box;
	border: none;
	background-color: rgba(0,0,0,0);
}

@mixin visually-hidden {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}

/* main */
@mixin size($w,$h:$w) {
	width: $w;
	height: $h;
}

@mixin offset($m,$p) {
	margin: $m;
	padding: $p;
}

@mixin zi-1 {
	position: relative;
	z-index: 1;
}

@mixin zi-2 {
	position: relative;
	z-index: 2;
}

@mixin inline-block($va:top) {
	display: inline-block;
	vertical-align: $va;
}

@mixin border($bw,$bc,$bs:solid) {
	border-width: $bw;
	border-style: $bs;
	border-color: $bc;
}

@mixin pos($pt,$pl,$pp:absolute) {
	position: $pp;
	top: $pt;
	left: $pl;
}

@mixin before {
	position: relative;

	&:before {
		content: '';
		position: absolute;
	}
}

/* font */
@mixin fs-fw($fs,$fw) {
	font-size: $fs;
	font-weight: $fw;
}

@mixin fs-lh($fs,$lh) {
	font-size: $fs;
	line-height: $lh;
}

@mixin dec-none {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

@mixin dec-underline {
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: underline !important;
	}
}

@mixin outline-none {
	outline: none;
	
	&:hover,
	&:focus {
		outline: none;
	}
}

/* flex */
@mixin d-flex {
	display: -ms-flexbox;
	display: flex;
}

@mixin flex-wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

@mixin flex-nowrap {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

@mixin flex-row {
	-ms-flex-direction: row;
	flex-direction: row;
}

@mixin flex-column {
	-ms-flex-direction: column;
	flex-direction: column;
}

@mixin flex-column-reverse {
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}

@mixin flex-grow-1 {
	-ms-flex-positive: 1;
	flex-grow: 1;
}

@mixin flex-width($fw) {
	flex: 0 0 $fw;
	max-width: $fw;
}

@mixin fw($fw) {
	@include flex-width($fw);
}

/* align-items */
@mixin align-items-start {
	-ms-flex-align: start;
	align-items: flex-start;
}

@mixin align-items-end {
	-ms-flex-align: end;
	align-items: flex-end;
}

@mixin align-items-center {
	-ms-flex-align: center;
	align-items: center;
}

@mixin align-items-stretch {
	-ms-flex-align: stretch;
	align-items: stretch;
}

/* justify-content */
@mixin justify-content-start {
	-ms-flex-pack: start;
	justify-content: flex-start;
}

@mixin justify-content-end {
	-ms-flex-pack: end;
	justify-content: flex-end;
}

@mixin justify-content-center {
	-ms-flex-pack: center;
	justify-content: center;
}

@mixin justify-content-around {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

@mixin justify-content-between {
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@mixin align-self-start {
	-ms-flex-item-align: start;
	align-self: flex-start;
}

@mixin align-self-stretch {
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

@mixin default-transition {
  -o-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@mixin multi-trans($mt) {
	-webkit-transition: $mt;
	-moz-transition: $mt;
	-o-transition: $mt;
	-ms-transition: $mt;
	transition: $mt;
}

@mixin multi-prop($mp) {
	-webkit-transform: $mp;
	-moz-transform: $mp;
	-o-transform: $mp;
	-ms-transform: $mp;
	transform: $mp;
}