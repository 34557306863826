.formfact__row-text-mobile {
    @media (min-width: $tablet-width-m) {
        display: none;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        display: block;
        margin-left: 23px;
    }
}

.formfact__row-text-desc {
    @media (min-width: $tablet-width-m) {
        display: block;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        display: none;
    }
}

.formfact__inner {
    margin-bottom: 96px;

    @media (min-width: $desktop-width-s) and (max-width: $desktop-width-m - 1) {
        margin-bottom: 145px;
    }
    @media (min-width: $mobile-width) and (max-width: $tablet-width-l - 1) {
        margin-bottom: 0px;
    }
}

.fromfact__title {
    font-size: 26px;
    line-height: 120%;
    font-weight: 700;
    max-width: 304px;
    color: #000;
    display: block;
    margin-bottom: 46px;

    @media (min-width: $desktop-width-l) {
        max-width: 100%;
    }

    @media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 32px;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        margin-bottom: 79px;
    }
}

.fromfact {
    position: relative;

    &::before {
        border: 2px solid #21a3dc !important;
    }

    @media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
        width: 100%;
        margin-left: 0;
    }

}

.fromfact__item {
    position: absolute;
    top: 80px;
    left: -226px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 2px solid $color__theme_white;
    border-radius: 16px;
    box-sizing: border-box;
    max-width: 328px;
    box-shadow: 3px 4px 7px -5px #000;

    @media (min-width: $tablet-width-l) and (max-width: $desktop-width-l - 1) {
        top: 95px;
    }

    @media (min-width: $tablet-width-l) and (max-width: $desktop-width-m - 1) {
        left: -140px;

    }

    @media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
        position: inherit;
        flex-direction: row;
        max-width: 560px;
        margin-bottom: 24px;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        top: -86px;
        left: -2px;
    }
}

.fromfact__top {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    box-sizing: border-box;
    max-height: 179px;
    max-width: 328px;

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        max-height: 167px;
    }
}

.fromfact__footer {
    background-color: $color__theme_white;
    display: flex;
    flex-direction: column;
    padding: 16px 26px 52px 26px;

    @media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
        padding: 20px 17px 17px 17px
    }


    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        padding: 28px 21px;
    }
}

.fromfact_cart-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 11px;
}

.fromfact__cart-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;


}

.fromfact__cart-social {
    font-size: 18px;
    line-height: 22px;
    color: #21A3DC !important;
}

.fromfact__text {
    margin-left: 120px;

    @media (min-width: $tablet-width-l) and (max-width: $desktop-width-m) {
        margin-left: 215px;
        padding-right: 50px;
    }

    @media (min-width: $tablet-width-m) and (max-width: $tablet-width-l - 1) {
        margin-left: 0;
        max-width: 560px;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        margin-left: 0;
        margin-top: 301px;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
}

.fromfact__info {
    margin-bottom: 69px;

    @media (min-width: $tablet-width-l) and (max-width: $desktop-width-m - 1) {
        margin-left: 120px;
    }

    @media (min-width: $desktop-width-xl) {
        margin-left: 120px;
    }
}

.fromfact__info-items {
    max-width: 721px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        margin-left: 14px;
    }
}

.fromfact__info-item {
    margin-top: 53px;
    width: 50%;

    @media (min-width: $tablet-width-m - 167) and (max-width: $tablet-width-m - 1) {
        width: 40% !important;
        margin: 50px auto;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        width: 100%;
    }
}

        .fromfact__info-item:nth-of-type(1) { 
            @media (min-width: $mobile-width) and (max-width: $tablet-width-s + 120) {
            order: 1; 
            }
        }
        .fromfact__info-item:nth-of-type(2) { 
            @media (min-width: $mobile-width) and (max-width: $tablet-width-s + 120) {
                order: 3; 
                }
        }
        .fromfact__info-item:nth-of-type(3) { 
            @media (min-width: $mobile-width) and (max-width: $tablet-width-s + 120) {
                order: 2; 
                } 
        }
        .fromfact__info-item:nth-of-type(4) { 
            @media (min-width: $mobile-width) and (max-width: $tablet-width-s + 120) {
                order: 4; 
                }
        }
        .fromfact__info-doc:nth-of-type(5) { 
            @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
                order: 5; 
                } 
        }




.fromfact__info-item-year {
    font-weight: 900 !important;
    font-size: 96px;
    color: rgba(33, 163, 220, 0.4);
    margin-bottom: 26px;
}

.fromfact__info-item-image {
    margin-bottom: 10px;
    width: 59px;
    height: 59px;
    color: #21A3DC;
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: transparent;
    margin: -12px 24px 0 80px;

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        margin: -12px 16px 0 16px;
    }
}

.fromfact__info-item-text {
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 24px !important;
    color: #283584;
    max-width: 181px;
    margin-left: 119px;

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        margin-left: 17px;
        max-width: 224px;
    }
}

.fromfact__info-doc {
    margin-top: 100px;
    margin-left: 185px;

    @media (min-width: $tablet-width-m - 167) and (max-width: $tablet-width-m - 1) {
        margin-left: 106px !important;
    }

    @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
        text-align: start;
        margin-left: 79px;
        max-width: 192px;
        margin-top: -9px;
        margin-bottom: -44px;
    }
    @media (min-width: $mobile-width) and (max-width: $tablet-width-s + 120) {
        margin-top: 43px;

    }
    
}

.fromfact__info-doc-link {
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #21A3DC;

    &::before {
        background-image: url(../img/icons/icon-pdf.svg);
        content: '';
        position: absolute;
        left: -62px;
        top: -34px;
        padding: 70px 0 0 48px;
        background-position: 0 0;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-color: transparent;

        @media (min-width: $mobile-width) and (max-width: $tablet-width-m - 1) {
            top: -14px;
        }
    }
}

.fromfact__bottom {
    &::before {
        border: 2px solid #21a3dc !important;
    }
}

.fromfact__slides {
    position: relative;
    @media (min-width:$desktop-width-m) {
        margin-left: -(100%/6*2);
        margin-right: -(100%/6*2);
    }

    @media (min-width:$tablet-width-l) and (max-width:$desktop-width-m - 1) {
        margin-left: -17%;
    }

    @media (min-width:$tablet-width-m) and (max-width:$tablet-width-l - 1) {
        margin-left: -13.5%;
    }

}

.fromfact__slides-box {
    display: none;
    width: 222px;
    position: absolute;
    z-index: 10;
    bottom: 221px;
    right: 56px;
    transform: rotate(180deg);
}
.fromfact__slides-dowload {
    width: 60px;
    height: 60px;
    padding: 0 0 0 60px;
    position: relative;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #21a3dc;
    transition: all .3s ease;
    @media (max-width: $tablet-width-m - 1) {
        bottom: 50px;
        right: 37px;
    }
  }
  
  .fromfact__slides-img {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .3s ease;
    
  }
  .fromfact__slides-img {
      img {
        transform: rotate(180deg);
      }
  }
  
  .formfact__download-text {
    width: 0;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    transition: all .3s ease;
    transform: rotate(180deg);
  }
  
  .fromfact__slides-dowload:hover {
    width: 100%;
    padding-right: 20px;
  }
  
  .fromfact__slides-dowload:hover .formfact__download-text {
    width: 100%;
  }