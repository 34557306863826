.fixed {
	@include d-flex;
	@include flex-column;
	@include justify-content-center;
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 100;
	box-sizing: border-box;
	transition: all 0.3 ease-in-out;
	@media (min-width:$desktop-width-m) {
		width: (100%/12);
		top: -80px;
	}

	@media (max-width:$desktop-width-m - 1) {
		display: none;
	}
	
	&.fixed__left {
		padding: 0 20px 0 0;

		@media (min-width:$desktop-width-m) {
			//left: 20px;
			right: 0!important;
		}
	
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
			width: (100%/8);
			left: 30px;
			padding-left: 10px;
		}
	}

	&.fixed__right {
		@media (min-width:$desktop-width-m) {
			//right: 0;
			left: 0;
			padding: 0 20px 0 0;
			z-index: 25;
		}
	}
	
	&.fixed__inner {
		@media (max-width:$desktop-width-m - 1) {
			display: none;
		}
	}
}
.fixed__hidden {
	transition: all 0.5s;
	//opacity: 0!important;
	//pointer-events: none !important;
	opacity: 0;
	pointer-events: none;
}

.fixed__nav {
	@media (min-width:$desktop-width-m) {
		text-align: center;
	}
	
	&.fixed__nav_flex {
		@include d-flex;
		@include flex-column-reverse;
	}
}

.fixed__nav-item {
	@include size(100%,20px);
	@include offset(0 0 18px,6px);
	display: block;
	overflow: hidden;
	position: relative;
	box-sizing: border-box;

	&:hover {
		overflow: visible;
	}
	
	&.fixed__nav-item_scroll {
		&:first-child {
			-ms-flex-order: 1;
			order: 1;
		}
	}
}

.fixed__nav-title {
	@include before;
	@include fs-lh(11px,13px);
	@include offset(0 0 0 -48px,3px 5px);
	@include multi-prop(translate(0,50px));
	position: absolute;
	bottom: 28px;
	width: 115px;
	z-index: 99;
	opacity: 0;
	border-radius: 2px;
	box-sizing: border-box;
	pointer-events: none;
	text-align: center;
	text-transform: lowercase;
	background: $color__theme_black;
	border: 1px solid $color__theme_white;
	color: $color__theme_white;
	
	-webkit-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	-moz-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	-o-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	-ms-transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	transition: transform 0.5s ease 0s,opacity 0.3s ease 0s;
	@media (min-width:$desktop-width-xl){
		left: 43%;
	}
	@media (min-width:$desktop-width-m) and (max-width:$desktop-width-xl - 1){
		left: 40%;
	}
	
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		left: 10px;
	}
	
	&:before {
		@include border(8px,$color__theme_white rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0));
		position: absolute;
		bottom: -16px;
		left: 50%;
		margin: 0 0 0 -8px;
	}
	
	&:after {
		@include border(6px,$color__theme_black rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0));
		content: '';
		position: absolute;
		bottom: -12px;
		left: 50%;
		margin: 0 0 0 -6px;
	}
}

.fixed__nav-bullet {
	@include before;
	@include size(8px,8px);
	display: block;
	border-radius: 50%;
	border: 1px solid #cacaca;
	background: $color__theme_white;

	@media (min-width:$desktop-width-m) {
		margin-left: auto;
		margin-right: auto;
	}

	&:before {
		@include pos(0,0);
		@include size(8px,8px);
		@include default-transition;
		background: #cacaca;
		border-radius: 50%;
		z-index: -1;
	}
	
	&:hover,
	&.active {
		&:before {
			@include pos(-4px,-4px);
			@include size(16px,16px);
		}
	}
	
	&:hover {
		& + .fixed__nav-title {
			@include multi-prop(translate(0,0));
			opacity: 1;
		}
	}
}

@for $i from 1 through 11 {
	.stage-#{$i} {
		.fixed__nav-item:nth-child(#{$i}) {
			.fixed__nav-bullet_index {
				&:before {
					@include pos(-4px,-4px);
					@include size(16px,16px);
				}
			}
		}
	}
}

.fixed__nav-social {
	@include default-transition;
	opacity: 0.8;
	display: block;
	margin: 0 auto 30px;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);
	background-position: center center;

	&:hover {
		opacity: 1;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.fixed__nav-social_vk {
		@include size(23px,14px);
		background-image: url(../img/icons/icon-vk-grey.svg);
	}

	&.fixed__nav-social_inst {
		@include size(20px,20px);
		background-image: url(../img/icons/icon-inst-grey.svg);
	}

	&.fixed__nav-social_fb {
		@include size(20px,20px);
		background-image: url(../img/icons/icon-fb-grey.svg);
	}

	&.fixed__nav-social_tg {
		@include size(20px,16px);
		background-image: url(../img/icons/icon-tg-grey.svg);
	}
	&.fixed__nav-social_ns {
		@include size(25px,19px);
		background-image: url(../img/icons/icon-ns-grey.svg);
	}
}

.fixed__footer {
	@include multi-prop(rotate(-90deg));
	@include fs-lh(12px,15px);
	white-space: nowrap;
	position: absolute;
	left: 50%;
	bottom: 24px;
	color: #cacaca;
	
	-o-transform-origin: left bottom;
	-moz-transform-origin: left bottom;
	-webkit-transform-origin: left bottom;
	transform-origin: left bottom;
	
	@media (min-width:$tablet-width-m) and (max-width:$desktop-width-m - 1) {
		display: none;
	}
}
.fixed__nav {
	position: relative;
	z-index: 15;
}