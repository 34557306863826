.blockquote {
	box-sizing: border-box;

	@media (min-width:$tablet-width-m) {
		margin: 0 0 32px;
	}

	@media (max-width:$tablet-width-m - 1) {
		margin: 0 0 120px;
	}

	&.blockquote_col {
		@media (min-width:$tablet-width-m) and (max-width:$desktop-width-s - 1) {
			@include flex-width(100%/8*6);
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 0;
		}
	}
}

.blockquote__text {
	@include before;
	font-weight: bold;
	color: #21A3DC;
	z-index: 2;
	box-sizing: border-box;
	
	&:before {
		background: url(../img/icons/icon-blockquote.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
		background-size: 100% auto;
	}

	@media (min-width:$tablet-width-m) {
		@include fs-lh(20px,24px);
		padding: 0 50px;

		&:before {
			@include size(30px,30px);
			@include pos(-4px,10px);
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(16px,20px);

		&:before {
			@include inline-block;
			@include size(23px,23px);
			position: relative;
			margin: -4px 8px 0 0;
		}
	}
	
	&:after {
		@include inline-block;
		@include size(30px,30px);
		@include multi-prop(rotate(180deg));
		content: '';
		position: relative;
		background: url(../img/icons/icon-blockquote.svg) no-repeat scroll 0 0 rgba(0,0,0,0);
		background-size: 100% auto;
	}

	@media (min-width:$tablet-width-m) {
		&:after {
			@include size(30px,30px);
			bottom: -8px;
			margin: 0 0 0 10px;
		}
	}

	@media (max-width:$tablet-width-m - 1) {
		&:after {
			@include size(23px,23px);
			bottom: -4px;
			margin: 0 0 0 8px;
		}
	}

	a {
		color: #283584;
		text-decoration: underline;
	}
}

.blockquote__bottom {
	@include before;
	z-index: 1;
	font-weight: 900;
	color: rgba(33, 163, 220, 0.22);

	&:before {
		@include pos(0,0);
		right: 0;
		bottom: 20px;
		background: url(../img/icons/icon-data.svg) no-repeat scroll center bottom rgba(0,0,0,0);
	}
}

.blockquote__value {
	@media (min-width:$tablet-width-m) {
		@include fs-lh(194px,236px);
		margin: -118px 0 0;
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(130px,176px);
	}
}

.blockquote__intro {
	@media (min-width:$tablet-width-m) {
		@include fs-lh(48px,45px);
	}

	@media (max-width:$tablet-width-m - 1) {
		@include fs-lh(44px,44px);
	}
}